<div class="w-100-p p-24">
  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <span class="help">
      <h1 class="mr-8" mat-dialog-title>{{"csv_add_recipients" | i18n}}</h1>
      <mat-icon
        class="primary-100-fg font-size-20"
        matTooltip="
S-9K9L-4CB5-88Y5-F5G4Z,200
15346065480176948044,20
13736966403016142704,100
">help_outline
      </mat-icon>
    </span>
    <div mat-dialog-content>
      <mat-form-field class="w-100-p">
        <mat-select placeholder="{{'choose_delimiter' | i18n}}" formControlName="delimiter">
          <mat-option *ngFor="let delimiter of delimiters" [value]="delimiter.value">
            {{delimiter.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="w-100-p">
        <textarea
          matInput
          cdkTextareaAutosize
          cdkAutosizeMinRows="4"
          cdkAutosizeMaxRows="10"
          placeholder="{{'paste_csv_content' | i18n}}"
          formControlName="recipientsInput"
        ></textarea>
      </mat-form-field>
      <span class="s-24 status-message">
        <small>{{message}}</small>
        <mat-icon *ngIf="isValid" class="green-800-fg ml-8">done</mat-icon>
        <mat-icon *ngIf="!isValid" class="warn-700-fg ml-8">remove_circle</mat-icon>
      </span>
    </div>
    <div mat-dialog-actions class="right">
      <button class="btn-common" [mat-dialog-close]>{{ 'cancel' | i18n }}</button>
      <button class="{{isValid ? 'btn-common' : 'btn-common-disable'}}" type="submit">{{ 'submit' | i18n }}</button>
    </div>
  </form>
</div>
