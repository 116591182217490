import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { Account, AttachmentEncryptedMessage, AttachmentMessage, Transaction } from '@fruitsjs/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { AccountService } from 'app/setup/account/account.service';
import { StoreService } from 'app/store/store.service';
import hashicon from 'hashicon';
import { Amount } from '@fruitsjs/util';
import {environment} from 'environments/environment';
import {Title} from '@angular/platform-browser';
import {constants} from '../../../constants';

type TransactionDetailsCellValue = string | AttachmentMessage | AttachmentEncryptedMessage | number;
type TransactionDetailsCellValueMap = [string, TransactionDetailsCellValue];

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss']
})
export class AccountDetailsComponent implements OnInit, AfterViewInit {
  @ViewChild('avatar', { static: false }) avatar: ElementRef<HTMLCanvasElement>;

  detailsData: Map<string, TransactionDetailsCellValue>;
  account: Account;
  currentAccount: Account;
  transactions: Transaction[];
  dataSource: MatTableDataSource<Transaction>;
  public dataSourceAsset: MatTableDataSource<any>;
  public displayedColumnsAsset: string[];
  accountQRCodeURL: Promise<string>;
  language: string;
  isAdmin = environment.isAdmin;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private accountService: AccountService,
              private storeService: StoreService,
              private title: Title) {

    if (!environment.isAdmin) {
      this.router.navigate(['/dashboard']);
    }
    this.title.setTitle(constants.pageTitle + 'Account Details');
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.loadAccountAndSetData();
      }
    });
  }

  ngAfterViewInit(): void {
    // this.dataSourceAsset.data = this.account['assetBalances'];
  }

  public getDetailsData(): TransactionDetailsCellValueMap[] {
    return Array.from(this.detailsData.entries());
  }

  ngOnInit(): void {
    this.storeService.getSelectedAccount().then(account => {
      this.currentAccount = account;
    });
    this.displayedColumnsAsset = ['asset', 'quantity'];
    this.loadAccountAndSetData();
    setTimeout(() => {
      this.updateAvatar();
    }, 250);
  }

  getBalanceFormat(balanceQNT, decimals): string {
    const balance = Amount.fromPlanck(balanceQNT).getRaw().dividedBy(Amount.fromPlanck(Math.pow(10, decimals)).getRaw()).toString();
    const intPart = balance.substr(0, balance.indexOf('.'));
    return intPart ? intPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + balance.substr(balance.indexOf('.'))
      : balance.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  loadAccountAndSetData(): void {
    this.account = this.route.snapshot.data.account as Account;
    if (this.account.assetBalances) {
      this.dataSourceAsset = new MatTableDataSource<any>(this.account['assetBalances']);
      this.account.assetBalances.forEach(assets => {
        this.accountService.getAsset(assets.asset).then(asset => {
          assets.balance = this.getBalanceFormat(assets.balanceQNT, asset.decimals);
        });
      });
    } else {
      this.dataSourceAsset = new MatTableDataSource<any>();
    }
    const blockDetails = Object.keys(this.account).map((key: string): TransactionDetailsCellValueMap => [key, this.account[key]]);
    this.detailsData = new Map(blockDetails);
    this.dataSource = new MatTableDataSource<Transaction>();
    this.dataSource.data = this.route.snapshot.data.transactions;
    this.accountQRCodeURL = this.getAccountQRCodeUrl();
    this.language = this.storeService.settings.value.language;
  }

  async getAccountQRCodeUrl(): Promise<string> {
    return this.accountService.generateSendTransactionQRCodeAddress(this.account.account);
  }

  private updateAvatar(): void {
    if (this.avatar) {
      hashicon(this.account.account, {
        size: 100,
        createCanvas: () => this.avatar.nativeElement
      });
      const img = document.getElementById('avatar-converted');
      if (!img) {
        const image = new Image();
        image.id = 'avatar-converted';
        image.src = this.cropPlusExport(this.avatar.nativeElement, 0, 0, 100, 100).toDataURL();
        document.getElementById('fruits-detail').style.background = 'url(' + image.src + ') center no-repeat';
      }
    }
  }

  cropPlusExport(root, cropX, cropY, cropWidth, cropHeight): HTMLCanvasElement {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = cropWidth;
    canvas.height = cropHeight;
    ctx.drawImage(root, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);
    return canvas;
  }

  isTransferAccount(): boolean {
    return this.currentAccount && this.currentAccount.oldAccount === this.account.account;
  }
}
