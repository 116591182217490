import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {constants} from '../constants';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Address} from '@fruitsjs/core';
import {INodeVerify} from './nft/my-page/my-page.component';
import {COINTYPE} from './nft/sell-nft/sell-nft.component';
import {I18nService} from "../layout/components/i18n/i18n.service";
import {generateSignature, generateSignedTransactionBytes, verifySignature} from '@fruitsjs/crypto/src';
import {NotifierService} from "angular-notifier";

@Injectable({
  providedIn: 'root'
})
export class NftService {

  marketUrl = environment.isMainNet ? constants.MARKET_MAIN : constants.MARKET_TEST;
  paymentUrl = environment.isMainNet ? constants.paymentApiMainnet : constants.paymentApiTestnet;
  multiCoinUrl = constants.coinMarketCapURL;

  constructor(private http: HttpClient,
    private i18nService: I18nService,
    private notifierService: NotifierService) {
  }

  public getAccountNfts(account: string): Observable<any> {
    return this.http.get(this.marketUrl + `/fruits/nft/account/${account}`);
  }

  public getNftUtilityType(params: HttpParams): Observable<any> {
    return this.http.get(this.marketUrl + '/fruits/nft/utilities', {
      params: params
    });
  }

  public createNft(data: FormData): Observable<any> {
    return this.http.post(this.marketUrl + '/fruits/nft/create', data);
  }

  public createMultiToken(data: FormData): Observable<any> {
      return this.http.post(this.marketUrl + '/fruits/collection/nft/create-multi', data);
  }

  public createNftUtility(data: FormData): Observable<any> {
    return this.http.post(this.marketUrl + '/fruits/nft/utility/create', data);
  }

  public createNftTransaction(data: FormData): Observable<any> {
    return this.http.post(this.marketUrl + '/fruits/nft/transaction/create', data);
  }

  public createNftUtilityTransaction(data: FormData): Observable<any> {
    return this.http.post(this.marketUrl + '/fruits/nft/utility/transaction/create', data);
  }

  public transferNft(data: FormData): Observable<any> {
    return this.http.post(this.marketUrl + '/fruits/nft/transfer', data);
  }

  public transferMultiTokenNft(data: any): Observable<any> {
    return this.http.post(this.marketUrl + '/fruits/collection/nft/transfer-multi', data);
  }

  public findNftByName(params: HttpParams): Observable<any> {
    return this.http.get(`${this.marketUrl}/fruits/nft/find-by-name`, {
      params: params
    });
  }

  public findNftTransactionByName(params: HttpParams): Observable<any> {
    return this.http.get(this.marketUrl + `/fruits/nft/transaction/find-by-name`, {
      params: params
    });
  }

  public putForSale(data: FormData): Observable<any> {
    return this.http.post(this.marketUrl + '/fruits/nft/put-for-sale', data);
  }

  public putForSaleMultiToken(data: any): Observable<any> {
    return this.http.post(this.marketUrl + '/fruits/collection/nft/sell-multi', data);
  }

  public cancelSale(data: FormData): Observable<any> {
    return this.http.post(this.marketUrl + '/fruits/nft/put-not-for-sale', data);
  }

   public cancelSaleMultiToken(data: any): Observable<any> {
    return this.http.post(this.marketUrl + '/fruits/collection/nft/cancel-sale', data);
  }

  public updateSellAmount(data: FormData): Observable<any> {
    return this.http.post(this.marketUrl + '/fruits/nft/sell', data);
  }

  public getNftType(): Observable<any> {
    return this.http.get(this.marketUrl + '/fruits/nft/type/index');
  }

  public getCollections(): Observable<any> {
    return this.http.get(this.marketUrl + '/fruits/nft/collections');
  }

  public buyNft(data: FormData): Observable<any> {
    return this.http.post(`${this.marketUrl}/fruits/nft/buy`, data);
  }

  public createNftType(data: FormData): Observable<any> {
    return this.http.post(this.marketUrl + '/fruits/nft/type/create', data);
  }

  public updateNftType(data: FormData): Observable<any> {
    return this.http.post(this.marketUrl + '/fruits/nft/type/update', data);
  }

  public getUtilityById(params: HttpParams): Observable<any> {
    return this.http.get(`${this.marketUrl}/fruits/nft/utility/find-by-id`, {
      params: params
    });
  }

  public convertAccountIdToAddress(id: string): string {
    return Address.fromNumericId(id).getReedSolomonAddress();
  }

  public getNftTransaction(nft: string): Observable<any> {
    return this.http.get(`${this.marketUrl}/fruits/nft/transaction/history?nftName=${encodeURIComponent(nft)}`);
  }

  public getNftUtilityTransaction(params: HttpParams): Observable<any> {
    return this.http.get(`${this.marketUrl}/fruits/nft/utility/transaction/history`, {params});
  }

  public getNftOrder(params: HttpParams): Observable<any> {
    return this.http.get(`${this.marketUrl}/fruits/order`, {params});
  }

  public submitOrder(params: HttpParams): Observable<any> {
    return this.http.post(`${this.marketUrl}/fruits/order/submit`, null, {params});
  }

  public getRate(merchantId: string): Observable<any> {
    return this.http.get(`${this.paymentUrl}/webapi/master/swap-rate?merchantId=${merchantId}` );
  }

  public getFruitsRateToOtherCurrency(currency: COINTYPE): Observable<any> {
    const data = {
      source: COINTYPE.frts,
      destination: currency
    };
    return this.http.get(`${this.multiCoinUrl}/fruits/converter/api`, { params: data });
  }

  // My page APIs
  public createProfile(address: string): Observable<any> {
    const data = { walletAddress: address };
    return this.http.post(`${this.marketUrl}/fruits/profile/create`, data);
  }

  public updateProfile(data: any): Observable<any> {
    return this.http.post(`${this.marketUrl}/fruits/profile/update-profile`, {
      address: data.address,
      preProfileId: data.preProfileId
    }, {
      headers: {
        'signature': data.signature,
        'message': data.accountId
      }
    });
  }

  public createPreProfile(data: any): Observable<any> {
    return this.http.post(`${this.marketUrl}/fruits/profile/update-profile`, data);
  }

  public getProfileDetail(id: number): Observable<any> {
    return this.http.get(`${this.marketUrl}/fruits/profile/${id}`);
  }

  public nodeVerify(data: INodeVerify): Observable<any> {
    const httpParams = new HttpParams()
      .set('requestType', 'requestVerifySignature')
      .set('recipient', data.recipient)
      .set('publicKey', data.publicKey)
      .set('deadline', '1140');
    return this.http.post(`${data.node}/fruits`, null, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      params: httpParams
    });
  }
  public createMultipleNftTransaction(data: FormData): Observable<any> {
    return this.http.post(this.marketUrl + '/fruits/nft/transaction/create-multi', data);
  }

  public findMultiNftByName(params: HttpParams): Observable<any> {
    return this.http.get(`${this.marketUrl}/fruits/nft/get-multi-nft`, {
      params: params
    });
  }

  public async verifyAndLock(data: ILockMultipleToken): Promise<any> {

    try {

      const signature = await this.nodeVerify({
        node: data.node,
        recipient: data.seller.getNumericId(),
        publicKey: data.sellerPublicKey
      }).toPromise();

      if (signature && signature.errorCode === 1) {
        console.warn('create signature failed');
        return;
      }

      let lockToken;
      let accountSignature;
      if (data.sellerPrivateKey) {
        accountSignature = this.createSignature(signature.unsignedTransactionBytes, data.sellerPublicKey, data.sellerPrivateKey);

        lockToken = await this.lockMultipleToken({
          nftName: data.nftName,
          seller: data.seller.getNumericId(),
          buyer: data.buyer ? data.buyer.getNumericId() : null,
          quantity: data.quantity,
          action: data.action,
          offerId: data.offerId,
          signature: accountSignature,
          message: data.seller.getNumericId()
        }).toPromise();
      } else {
        lockToken = await this.lockMultipleTokenOtherCoin({
          nftName: data.nftName,
          seller: data.seller.getNumericId(),
          buyer: data.buyer ? data.buyer.getNumericId() : null,
          quantity: data.quantity,
          action: data.action,
          offerId: data.offerId
        }).toPromise();
      }


      if (lockToken && lockToken.errorCode === 1) {
        console.warn('lock token failed');
        this.notifierService.notify('error', this.i18nService.getTranslation(lockToken.message));
        return;
      }

      return {
        lockId: lockToken.result,
        signature: accountSignature
      };

    } catch (e) {
      console.warn('Verify multiple token failed');
      return;
    }
  }

  public lockMultipleToken(data: any): Observable<any> {
    const params = {
      nftName: data.nftName,
      seller: data.seller,
      buyer: data.buyer,
      quantity: data.quantity,
      action: data.action,
      offerId: data.offerId,
    };
    return this.http.post(`${this.marketUrl}/fruits/nft/lock-multi-nft`, params, {
      headers: {
        'signature': data.signature,
        'message': data.message
      }
    });
  }

  public lockMultipleTokenOtherCoin(data: any): Observable<any> {
    return this.http.post(`${this.marketUrl}/fruits/nft/fiat/lock-multi-nft`, data);
  }

  public unLockMultipleTokenOtherCoin(data: any): Observable<any> {
    return this.http.post(`${this.marketUrl}/fruits/nft/fiat/unlock-multi-nft`, null, {
      params: {
        nftName: data.nftName,
        buyer: data.buyer,
        lockId: data.lockId,
        seller: data.seller,
        transaction: data.transaction
      }
    });
  }

  public unlockMultipleToken(data: IUnlockMultipleToken): Observable<any> {
    const lockData = {
      seller: data.seller,
      nftName: data.nftName,
      buyer: data.buyer,
      lockId: data.lockId,
      transaction: data.transaction
    };
    return this.http.post(`${this.marketUrl}/fruits/nft/unlock-multi-nft`, lockData, {
      headers: {
        'signature': data.signature,
        'message': data.message
      }
    });
  }

  public getNftByAccount(data: any): Observable<any> {
    return this.http.post(
      `${this.marketUrl}/fruits/nft/all-by-account`,
      data
    );
  }


  public getNftDetailMulti(name: string): Observable<any> {
    return this.http.get(
      `${this.marketUrl}/fruits/nft/get-multi-nft?nftName=${encodeURIComponent(name)}`
    );
  }

  public getMultiTokenTransaction(params: HttpParams): Observable<any> {
    return this.http.get(`${this.marketUrl}/fruits/collection/nft/transaction-multi-token/history`, {params});
  }

  public getOwnerByNftName(name: string): Observable<any> {
    return this.http.get(
      `${this.marketUrl}/fruits/nft/get-all-owners-by-nft?nftName=${encodeURIComponent(name)}`
    );
  }

  createSignature(txByte: string, pub: any, pri: any): string {
    const signature = generateSignature(txByte, pri);
    if (verifySignature(signature, txByte, pub)) {
      return generateSignedTransactionBytes(txByte, signature);
    }
  }

  /**
   *
   * lock in case payment with fiat and coin
   * @param nftName
   */
  public lockNft(nftName: string): Observable<any> {
    return this.http.post(`${this.marketUrl}/fruits/nft/lock-nft?nftName=${encodeURIComponent(nftName)}`, {});
  }

 async isValidNFT(name): Promise<any> {
   try {
     // lock nft
     const lock = await this.lockNft(name).toPromise();
     if (lock && lock.errorCode !== 0) {
       this.notifierService.notify('error', this.i18nService.getTranslation('message.exception.rejectPayout'));
       return false;
     }

     return {
       nftName: name
     };

   } catch (err) {
     this.notifierService.notify('error', this.i18nService.getTranslation('network_error'));
     return false;
   }
 }

  public unlockNft(nft: string): Observable<any> {
    return this.http.post(`${this.marketUrl}/fruits/nft/unlock?nftName=${encodeURIComponent(nft)}`, {});
  }

  public issueMoreMultipleToken(data: FormData): Observable<any> {
      return this.http.post(this.marketUrl + '/fruits/collection/nft/isssue-more-multiple-token', data);
  }

  public createMultiTokenThirdParty(data: any): Observable<any> {
      return this.http.post(this.marketUrl + '/fruits/collection/nft/third-party/submit', data);
  }

  public updateTransaction(data: any): Observable<any> {
    return this.http.post(this.marketUrl + '/fruits/collection/nft/update-transaction', data);
  }

  public updateTransactionSell(data: any): Observable<any> {
    return this.http.post(this.marketUrl + '/fruits/collection/nft/sell-multi/submit', data);
  }

  public updateTransactionCancelSell(data: any): Observable<any> {
    return this.http.post(this.marketUrl + '/fruits/collection/nft/cancel-sale/submit', data);
  }

  public updateTransactionTransfer(data: any): Observable<any> {
    return this.http.post(this.marketUrl + '/fruits/collection/nft/transfer-multi/submit', data);
  }

  public getGoldNftConf(): Observable<any> {
    return this.http.get(`${this.marketUrl}/fruits/nft/gold/conf`);
  }

  public getBurnAddress(): Observable<any> {
    return this.http.get(`${this.marketUrl}/fruits/nft/accounts/burn`);
  }
}


export interface ILockMultipleToken {
  nftName: string;
  seller: Address;
  sellerPublicKey?: string;
  buyer: Address;
  quantity: number;
  offerId?: number;
  action: LockAction;
  node?: any;
  signature?: any;
  message?: any;
  sellerPrivateKey?: any;
}

export interface IUnlockMultipleToken {
  nftName: string;
  buyer: string;
  lockId: string;
  transaction: string;
  signature: string;
  message: string;
  seller: string;
}

export enum LockAction {
  TRANSFER = 'TRANSFER',
  CANCEL_SALE = 'CANCEL',
  BUY = 'BUY',
  SALE = 'SALE',
  INCREASE_QUANTITY = 'INCREASE_QUANTITY'
}
