import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SelectAccountComponent} from './select-account.component';
import {RouterModule, Routes} from '@angular/router';
import {I18nModule} from '../../layout/components/i18n/i18n.module';
import {MatToolbarModule} from '@angular/material/toolbar';
import {ItemComponent} from './item/item.component';
import {FuseSharedModule} from '../../../@fuse/shared.module';
import {MatFormFieldModule, MatIconModule, MatInputModule, MatProgressSpinnerModule} from '@angular/material';
import {ManageWalletDialogComponent} from './manage-wallet-dialog/manage-wallet-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {AppSharedModule} from "../../shared/shared.module";

const routes: Routes = [

];


@NgModule({
  declarations: [
      SelectAccountComponent,
      ItemComponent,
      ManageWalletDialogComponent
  ],
    imports: [
        CommonModule,
        I18nModule,
        MatToolbarModule,
        FuseSharedModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatProgressSpinnerModule,
        RouterModule,
        MatDialogModule,
        MatButtonModule,
        AppSharedModule,
    ],
  entryComponents: [
    ManageWalletDialogComponent
  ]
})
export class SelectAccountModule { }
