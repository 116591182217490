<mat-form-field [appearance]="appearance" floatLabel="always">
  <mat-label>{{ 'recipient' | i18n }}</mat-label>
  <div class="container" fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
    <input matInput
           [(ngModel)]="recipientValue"
           (ngModelChange)="onRecipientFieldInputChange(recipientValue)"
           (blur)="validateRecipient(recipientValue)"
           placeholder="{{ 'recipient' | i18n }}"
           [disabled]="disabled">

    <div *ngIf="recipient.type !== 1 && recipient.status !== 'zil-outage' && recipient.status != 'burn'"
         class="recipient-address"
         title="{{ recipient.addressRS }}">
        {{ recipient.addressRS }}
    </div>
    <div fxFlex fxLayout="row"
         *ngIf="!!recipientValue"
         [ngClass]="getValidationClass()"
         matTooltip="{{getValidationHint()}}"
    >
      <div>{{getRecipientTypeName()}}</div>
      <mat-icon>
        {{getValidationIcon()}}
      </mat-icon>
    </div>
    <ng-container *ngIf="withQrCode">
      <input #file type="file" accept="image/*" (click)="resetFile($event)" (change)="parseQR()" id="{{fileId}}" class="inputfile" capture="camera">
      <label for="{{fileId}}" class="camera-icon" *ngIf="!disabled">
<!--        <mat-icon>camera_alt</mat-icon>-->
        <img width="30" height="30" src="./assets/icons/menu/icon-camera.png" alt="">
      </label>
    </ng-container>
    <mat-progress-bar *ngIf="loading" mode="query"></mat-progress-bar>
  </div>
</mat-form-field>
