<div id="wallet" fxLayout="column">

  <div id="wallet-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

    <div id="wallet-form">
      <div class="container parent">

        <div class="select-account">

          <h1 class="title">{{ 'account_not_exist_title' | i18n }}</h1>

          <div class="item-content">
            <div class="content"
                 style="color: #3A4667; text-align: center;">{{ 'account_not_exist_content' | i18n }}</div>
          </div>

          <div class="btn-action">
            <button class="btn-common" (click)="logout(false)">{{ 'no' | i18n }}</button>
            <button class="btn-common" (click)="logout(true)">{{ 'yes' | i18n }}</button>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
