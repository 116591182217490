<section class="login" [fusePerfectScrollbar]="false" [fusePerfectScrollbarOptions]="{suppressScrollY:true}">
<div class="login__panel">
    <div class="login__header">
      <a [routerLink]="[ '' ]">
        <img alt="FRUITS logo" class="login__logo" src="assets/images/logos/logo_disclaimer.png"/>
      </a>
      <p class="logo-title">FRUITS</p>
      <small class="login__version">Fruits Wallet Version {{version}}</small>
    </div>
    <div class="login__body">
      <h1 *ngIf="!settings.isMultiWallet" class="login__title">{{ 'get_fruits_account' | i18n }}</h1>
      <h1 *ngIf="settings.isMultiWallet" class="login__title">{{ 'get_multi_coin_account' | i18n }}</h1>
      <p class="login__info">{{ 'new_user_text' | i18n }}</p>
      <div class="login__actions">

        <a *ngIf="settings.isMultiWallet && redirect != undefined" class="login__link--yes" [routerLink]="[ '/login/active' ]" [queryParams]="getRedirectQueryParams()">{{ 'account_create_new' | i18n }}</a>
        <a *ngIf="redirect" class="login__link--no" [routerLink]="[ '/login/active' ]" [queryParams]="redirect" >{{ 'account_import' | i18n }}</a>

        <a *ngIf="settings.isMultiWallet && redirect == undefined" class="login__link--yes" [routerLink]="[ '/login/active' ]" [queryParams]="{ newUser: true }">{{ 'account_create_new' | i18n }}</a>
        <a *ngIf="!redirect" class="login__link--no" [routerLink]="[ '/login/active' ]" >{{ 'account_import' | i18n }}</a>

        <div *ngIf="!settings.isMultiWallet && !hasCallbackUrl() && isAdmin">
          <p class="login__divider">{{ 'or' | i18n }}</p>
          <a class="login__link--default" [routerLink]="[ '/login/passive' ]" >{{ 'account_watch' | i18n }}</a>
        </div>
      </div>
    </div>
  </div>
</section>
