import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-create-nft',
  templateUrl: './confirm-create-nft.component.html',
  styleUrls: ['./confirm-create-nft.component.scss']
})
export class ConfirmCreateNftComponent {

  constructor(public dialogRef: MatDialogRef<ConfirmCreateNftComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onSubmit(event): void {
    event.stopImmediatePropagation();

    this.dialogRef.close({ ok: true });
  }

}
