<div class="dialog">
  <h1
    mat-dialog-title>{{ !passphrase ? ('backup_passphrase_title_1' | i18n) : ('backup_passphrase_title_3' | i18n) }}</h1>
  <div mat-dialog-content>
    <div
      class="dialog-sub-title">{{ !passphrase ? ('backup_passphrase_title_2' | i18n) : ('backup_passphrase_title_4' | i18n) }}</div>

    <div class="backup" *ngIf="!passphrase">
      <backup-check (agreeAll)="checkAgree($event)"></backup-check>
    </div>

    <mat-form-field *ngIf="!passphrase" style="width: 100%;" class="password-field" floatLabel="always"
                    appearance="outline">
      <mat-label>{{ 'enter_pin' | i18n }}<span style="color:red"> *</span></mat-label>
      <input type="password" matInput placeholder="{{'enter_pin' | i18n}}" [(ngModel)]="pin" name="pin">
    </mat-form-field>

    <div *ngIf="passphrase && tokens && tokens.length" class="chip-list-wrapper export-passphrase">
      <mat-chip-list>
        <mat-chip *ngFor="let token of this.tokens"
                  [ngClass]="{'button-ok': token.ok === true, 'button-not-ok': token.ok === false}">
          <span class="token-index">{{ (token.index + 1) + '.' }}</span>
          <span class="token-text">{{token.text}}</span>
        </mat-chip>
      </mat-chip-list>
    </div>

    <div *ngIf="passphrase && tokens && tokens.length" class="copy-btn" (click)="copy()">
      {{ 'copy' | i18n }}
    </div>

    <div *ngIf="passphrase && tokens && tokens.length" class="backup-warning">
      <img src="assets/icons/mobile/ErrorRed.svg" alt="" style="margin-right: 15px;"/>
      <span style="line-height: 24px;">{{ 'backup_passphrase_warning_1' | i18n }}</span>
    </div>

  </div>
  <div mat-dialog-actions>
    <button class="btn-common" [mat-dialog-close]="false">{{ 'close' | i18n }}</button>
    <button *ngIf="!passphrase" class="{{canSubmit() ? 'btn-common' : 'btn-common-disable'}}" [disabled]="!canSubmit()"
            (click)="export();">{{ 'export' | i18n }}</button>
  </div>
</div>
