<app-page title="{{ 'info' | i18n }}" *ngIf="fees">
  <div class="account-info pl-20 pr-20 mb-8">
    <div class="account-info__field">
      <small class="secondary-text text-uppercase">{{'account'| i18n}}</small>
      <p class="pb-8">{{account.accountRS}}</p>
      <small class="secondary-text" style="font-size: 12px">
        {{'noti_name_public'| i18n}}
      </small>
    </div>
  </div>

  <div class="p-24 update-form">
    <form #setAccountInfoForm="ngForm">
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>{{ 'name' | i18n }}</mat-label>
        <input matInput
               name="name"
               placeholder="{{ 'name' | i18n }}"
               [(ngModel)]="name"
               [disabled]="immutable || !isEditing"
        >
      </mat-form-field>

      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>{{ 'description' | i18n }}</mat-label>
        <textarea matInput
                  name="description"
                  placeholder="{{ 'description' | i18n }}"
                  [(ngModel)]="description"
                  [disabled]="immutable || !isEditing"
        >
        </textarea>
      </mat-form-field>
      <div *ngIf="isDescriptionTooLong()" style="color: red; margin-top: -20px; margin-bottom: 20px;" class="error">{{ "description_too_long" | i18n }}</div>

      <ng-container *ngIf="!immutable && isEditing">

        <burst-fee-selector [fees]="fees" [(fee)]="fee"></burst-fee-selector>

        <div *ngIf="advanced" class="{{advanced ? 'mt-10' : ''}}">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>{{ 'deadline_hours' | i18n }}</mat-label>
            <input matInput [(ngModel)]="deadline" name="deadline" placeholder="{{ 'deadline_hours' | i18n }}">
          </mat-form-field>
        </div>

      </ng-container>

      <div *ngIf="isEditing" class="submit-transaction {{advanced ? '' : 'mt-10'}}">
        <mat-form-field class="message-text password-field" floatLabel="always" appearance="outline">
          <mat-label>{{ 'enter_pin' | i18n }}<span style="color:red"> *</span></mat-label>
          <input type="password" matInput placeholder="{{'enter_pin' | i18n}}" [(ngModel)]="pin" name="pin">
        </mat-form-field>
      </div>

      <a *ngIf="!immutable && isEditing" (click)="advanced=!advanced">
        <em>{{ advanced ? ("basic" | i18n) : "advanced" | i18n }}</em>
      </a>

      <div class="send-button">
        <button *ngIf="isEditing" disableRipple="true"
                mat-raised-button
                mat-button color="primary"
                class="btn-common"
                (click)="onCancel()">{{ 'cancel' | i18n }}</button>
        <button *ngIf="!isEditing && account.keys" disableRipple="true"
                mat-raised-button
                mat-button color="primary"
                class="btn-common"
                (click)="isEditing = true;">{{ 'edit' | i18n }}</button>
        <button *ngIf="isEditing" disableRipple="true"
                class="{{canSubmit() && !isSending ? 'btn-common' : 'btn-common-disable'}}"
                [disabled]="!canSubmit() || isSending" mat-raised-button mat-button color="primary"
                (click)="onSubmit($event);">
          {{ 'update' | i18n }}
          <mat-icon *ngIf="isSending" style="vertical-align: middle;">
            <mat-spinner diameter="20"></mat-spinner>
          </mat-icon>
        </button>
      </div>
    </form>

  </div>
</app-page>
