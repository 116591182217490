<div>
  <div class="title">{{ 'backup_passphrase_title_1' | i18n }}</div>
  <div class="sub-title">{{ 'backup_passphrase_title_2' | i18n }}</div>
  <backup-check (agreeAll)="checkAgree($event)"></backup-check>
  <div class="text-center navigation">
    <button class="{{canSubmit() && !isLoading ? 'btn-common' : 'btn-common-disable'}}"
            [disabled]="!canSubmit() || isLoading" (click)="next()">
      {{"next" | i18n}}
      <mat-icon *ngIf="isLoading" style="vertical-align: middle;">
        <mat-spinner diameter="20"></mat-spinner>
      </mat-icon>
    </button>
  </div>
</div>


