import {ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Account} from '@fruitsjs/core';
import {NgForm} from '@angular/forms';
import {TransactionService} from 'app/main/transactions/transaction.service';
import {I18nService} from 'app/layout/components/i18n/i18n.service';
import {StoreService} from '../../../../store/store.service';
import {takeUntil} from 'rxjs/operators';
import {UnsubscribeOnDestroy} from '../../../../util/UnsubscribeOnDestroy';
import {ETHSymbol} from '@fruitsjs/util/src';
import {EthService} from '../../../../multi-coin/eth.service';
import {Amount} from '@fruitsjs/util';
import {AccountService} from '../../../../setup/account/account.service';
import {ActivatedRoute, Router} from '@angular/router';
import {constants} from '../../../../constants';
import jsQR from 'jsqr';
import {NotifierService} from 'angular-notifier';
import {TimeCreatedService} from '../../../../multi-coin/time-created.service';
import {IOrderDetail, PaymentGatewayService} from '../../payment-gateway.service';
import {environment} from '../../../../../environments/environment';
import {formatBalance} from '../../../../util/formatAmount';
import {NotificationService} from '../../../notification.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {WarnSendFromPaymentGatewayDialogComponent} from '../../warn-send-from-payment-gateway-dialog/warn-send-from-payment-gateway-dialog.component';
import {Settings} from '../../../../settings';
import {IUnlockMultipleToken} from '../../../../payment-gateway/nft.service';
import {BroadcastData} from '../../../../multi-coin/btc.service';
import {CoinType} from '../../../../shared/pipes/coin-amount.pipe';
import {decryptAES, hashSHA256} from '@fruitsjs/crypto/src';

const isNotEmpty = (value: string) => value && value.length > 0;
let nextId = 0;

@Component({
  selector: 'app-send-eth-form',
  templateUrl: './send-eth-form.component.html',
  styleUrls: ['./send-eth-form.component.scss']
})
export class SendEthFormComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {

  @ViewChild('sendBurstForm', {static: true}) public sendBurstForm: NgForm;
  @ViewChild('amount', {static: true}) public amount: string;
  @ViewChild('gas', {static: true}) public gas: any;
  @ViewChild('file', {static: false}) file: ElementRef;
  @ViewChild('pin', {static: true}) public pin: string;

  @Input() account: Account;

  public unSubscriber = takeUntil(this.unsubscribeAll);

  public recipientAddress: string;
  fileId = `file-${nextId++}`;
  nonce: number;

  public maxFee = '0';
  priorityIndex = 1;
  isSending = false;
  isGettingData = false;
  language: string;
  estimateGas = [
    {
      index: 0,
      priority: 'Low',
      value: '1'
    },
    {
      index: 1,
      priority: 'Medium',
      value: '1.5'
    },
    {
      index: 2,
      priority: 'High',
      value: '2'
    }
  ];
  gasLimit = '21000';
  maxGasFee;
  baseFee;
  maxPriority;
  fetchData;

  public balances = '0';
  symbol = ETHSymbol;
  advanced = false;
  disableInputField = false;
  paymentId: string;

  settings: Settings;
  requestCurrency = constants.requestCurrency;
  currencyListEth;
  currencySelectedEth;
  currencyListCopy;
  priceEth;
  isSelectingEth = false;
  priceCurrency;
  changeEth;
  maxlength;
  key;
  total;
  decimals = constants.MAX_LENGTH_ETH;
  node: any;
  tokenName: string;

  constructor(private transactionService: TransactionService,
              private i18nService: I18nService,
              private storeService: StoreService,
              private ethService: EthService,
              private accountService: AccountService,
              private router: Router,
              private route: ActivatedRoute,
              private notifierService: NotifierService,
              private gatewayService: PaymentGatewayService,
              private timeCreatedService: TimeCreatedService,
              private notificationService: NotificationService,
              private warnDialog: MatDialog,
              private http: HttpClient,
              private cd: ChangeDetectorRef) {
    super();
    this.storeService.settings
      .pipe(
        takeUntil(this.unsubscribeAll)
      )
      .subscribe(async ({language}) => {
        this.language = language;
      });
    this.storeService.getSettings().then((settings: any) => {
      this.settings = settings;
    });
    this.storeService.getSettings().then((result: any) => {
      if (result && result.node) {
        this.node = result.node;
      }
    });
  }

  ngOnInit(): void {
    if (!this.account.multiWallet) {
      this.router.navigate(['/dashboard']);
      this.warnDialog.open(WarnSendFromPaymentGatewayDialogComponent, {
        width: '500px'
      });
    }

    this.getData().then(() => {
      if (this.route.snapshot.queryParams) {
        setTimeout(async () => {
          this.paymentId = this.route.snapshot.queryParams.paymentId;
          await this.getPaymentOrder(this.paymentId);
        }, 500);
      }

        this.currencyListEth = constants.currencies.map(currency => this.requestCurrency.includes(currency.code) ? currency : undefined).filter(currency => currency !== undefined);
      this.currencySelectedEth = this.settings && this.settings.currency && this.settings.currency.eth
        ? this.currencyListEth.filter(item => item.code === this.settings.currency.eth)[0] : this.currencyListEth[0];
        this.currencyListCopy = this.currencyListEth;
      this.getPriceEth();
    });

    this.fetchData = setInterval(() => {
      this.getData();
    }, 10000);

  }
  async getPriceEth(): Promise<void>{
    if (this.currencySelectedEth) {
      const params = new HttpParams()
        .set('source', ETHSymbol)
        .set('destination', this.currencySelectedEth.code);
      this.http.get(constants.coinMarketCapURL + '/fruits/converter/api', {
        params: params
      }).subscribe((response: any) => {
        if (response && response.errorCode === 0) {
          this.priceEth = response.result;
          this.priceCurrency = this.priceEth.priceSelectedCurrency;
        }
        this.isSelectingEth = false;
      }, (e) => {
        console.warn(e);
        this.isSelectingEth = false;
      });
    }
  }

  valueChange(newValue): void {
    if (this.priceCurrency) {
      this.amount = newValue;
      const value = newValue ? (newValue.endsWith('.') ? newValue.replace('.', '') : newValue) : '0';
      this.changeEth = this.removeTrailingZeros(Amount.fromPlanck(value).getRaw().multipliedBy(Amount.fromPlanck(this.priceCurrency).getRaw()).toFixed(4));
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.fetchData);
  }

  async getData(): Promise<void> {
    this.isGettingData = true;

    if (this.account.multiKeys) {
      this.balances = this.account.multiKeys.eth.balance;
      await this.ethService.getTransactionCount(this.account.multiKeys.eth.address).then(result => {
        this.nonce = result;
      });
    }

    await this.ethService.getFeeHistory().then(async result => {
      const blocks = await this.formatOutput(result, false);

      this.estimateGas[0].value = await this.ethService.toGwei(this.avg(blocks.map(b => b.priorityFeePerGas[0])));
      this.estimateGas[1].value = await this.ethService.toGwei(this.avg(blocks.map(b => b.priorityFeePerGas[1])));
      this.estimateGas[2].value = await this.ethService.toGwei(this.avg(blocks.map(b => b.priorityFeePerGas[2])));

      this.gas = this.estimateGas[this.priorityIndex];
    });

    await this.ethService.getLatestBlock().then(async block => {
      this.baseFee = await this.ethService.toGwei(block.baseFeePerGas.toString());
      const gasPrice = await Amount.fromPlanck(this.gas.value).getRaw().plus(Amount.fromPlanck(this.baseFee).getRaw()).toString();
      this.maxFee = await this.removeTrailingZeros(Amount.fromPlanck(this.gasLimit).getRaw()
        .multipliedBy(Amount.fromPlanck(gasPrice).getRaw()).dividedBy(10E8).toFixed(18));
      this.maxGasFee = await Amount.fromPlanck(this.baseFee).getRaw().plus(this.gas.value).multipliedBy(10E8).toString();
      this.maxPriority = await (this.gas.value * 10E8).toString();
    });

    this.isGettingData = false;
  }

  public removeTrailingZeros(s: string): string {
    return s.replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1');
  }

  public formatOutput(result, includePending): any {
    let blockNum = this.ethService.hexToNumber(result.oldestBlock);
    let index = 0;
    const blocks = [];
    while (blockNum < this.ethService.hexToNumber(result.oldestBlock) + constants.historicalBlocks) {
      blocks.push({
        number: blockNum,
        baseFeePerGas: Number(result.baseFeePerGas[index]),
        gasUsedRatio: Number(result.gasUsedRatio[index]),
        priorityFeePerGas: result.reward[index].map(x => Number(x)),
      });
      blockNum += 1;
      index += 1;
    }
    if (includePending) {
      blocks.push({
        number: 'pending',
        baseFeePerGas: Number(result.baseFeePerGas[constants.historicalBlocks]),
        gasUsedRatio: NaN,
        priorityFeePerGas: [],
      });
    }
    return blocks;
  }

  public avg(arr): string {
    const sum = arr.reduce((a, v) => a + v);
    return Math.round(sum / arr.length).toString();
  }

  getTotal(): string {
    try {
      return (this.amount && Number(this.amount) && this.amount !== '0')
        ? Amount.fromPlanck(this.amount).getRaw().plus(Amount.fromPlanck(this.maxFee).getRaw()).toString()
        : this.maxFee;
    } catch (e) {
      return '0';
    }
  }

  async onSubmit(event): Promise<void> {
    event.stopImmediatePropagation();

    const validateForm = this.validateForm();
    if (validateForm) {
      this.notifierService.notify('error', validateForm);
      return;
    }

    const privateKey = decryptAES(this.account.keys.signPrivateKey, hashSHA256(this.pin));
    if (!privateKey) {
      this.notifierService.notify('error', this.i18nService.getTranslation('wrong_pin'));
      return;
    }

    // Check payment gateway
    let order;
    let lockData;
    let unlockData: IUnlockMultipleToken;
    if (this.paymentId) {
      const paymentOrder = await this.gatewayService.isValidNFTOrder(
        this.paymentId,
        this.account.keys.publicKey,
        null,
        this.account.account,
        this.node
      );

      if (!paymentOrder) {
        return;
      }

      if (paymentOrder && !paymentOrder.isDonate) {
        if (paymentOrder && paymentOrder.orderDetail) {
          order = paymentOrder.orderDetail.data;
          this.tokenName = paymentOrder.nftName;
        }

        if (order.multiple) {
          lockData = paymentOrder.lock;
          unlockData = {
            nftName: this.tokenName,
            message: this.account.account,
            signature: lockData.signature,
            lockId: lockData.lockId,
            seller: this.account.account,
            buyer: order.nftName,
            transaction: null
          };
        }
      }
    }

    try {
      const checkTime = await this.timeCreatedService.checkTimeCreated(this.account.multiKeys.eth.address);
      await this.timeCreatedService.updateTimeCreated(this.account.multiKeys.eth.address);
      if (!checkTime.result) {
        this.notifierService.notify('error', this.i18nService.getTranslation('error_time_created'));
        return;
      }

      this.isSending = true;
      const data = {
        from: this.account.multiKeys.eth.address,
        to: this.recipientAddress,
        amount: this.amount,
        gasLimit: this.gasLimit,
        privateKey: this.account.multiKeys.eth.privateKey,
        maxFeeGas: this.maxGasFee,
        maxPriority: this.maxPriority,
        nonce: this.nonce
      };

      this.ethService.sendAmount(data).then(async (result) => {
        if (result.rawTransaction) {
          const broadcastData: BroadcastData = {
            txhex: result.rawTransaction,
            data: {
              from: data.from,
              to: data.to,
              amount: data.amount.toString(),
              fee: this.maxFee,
              coinType: CoinType.eth
            }
          };
          const signedTransaction = await this.ethService.broadcastTransactionWithData(broadcastData);
          if (signedTransaction && signedTransaction.data && signedTransaction.data.item && signedTransaction.data.item.transactionId) {
            this.notifierService.notify('success', this.i18nService.getTranslation('send_coin_success').split('__coin__').join('ETH'));
            this.isSending = false;

            if (this.paymentId) {
              this.gatewayService.updateTransactionId(
                this.paymentId,
                signedTransaction.data.item.transactionId,
                this.account.accountRS,
                order && order.multiple ? unlockData.lockId : null
              ).subscribe((res: any) => {
                if (res.code === 0) {
                  const url = `${environment.isMainNet ? constants.paymentStatusMainnet : constants.paymentStatusTestnet}/#/buyer/transaction-status/${this.paymentId}`;
                  window.location.href = url;
                } else {
                  this.notifierService.notify('error', this.i18nService.getTranslation('payment_connect_error'));
                }
              });
            } else {
              await this.router.navigate(['/eth-transactions']);
            }

          } else {
            // if transaction fail, check and unlock nft
            this.unlockToken(unlockData, order.multiple);
            this.notifierService.notify('error', this.i18nService.getTranslation('error_send_eth'));
            this.isSending = false;
          }
        } else {
          this.isSending = false;
          this.notifierService.notify('error', this.i18nService.getTranslation('error_send_eth'));
        }
      }).catch(() => {
        // if transaction fail, check and unlock nft
        this.unlockToken(unlockData, order.multiple);
        this.isSending = false;
        this.notifierService.notify('error', this.i18nService.getTranslation('error_send_eth'));
      });
    } catch (e) {
      this.isSending = false;
      this.notifierService.notify('error', this.i18nService.getTranslation('error_send_eth'));
    }
  }

  async unlockToken(unlockData: any, isErc1155: boolean): Promise<void> {
    if (this.tokenName) {
      try {
        if (isErc1155) {
          await this.gatewayService.unlockMultipleToken(unlockData);
        } else {
          await this.gatewayService.unlockNft(this.tokenName).toPromise();
        }
      } catch (ex) {}
    }
  }

  hasSufficientBalance(): boolean {
    return Amount.fromPlanck(this.getTotal()).getRaw().isLessThanOrEqualTo(Amount.fromPlanck(this.balances).getRaw());
  }

  canSubmit(): boolean {
    const amount = this.amount && this.amount.endsWith('.') ? this.amount.replace('.', '') : this.amount;
    return isNotEmpty(this.recipientAddress)
      && isNotEmpty(amount)
      && isNotEmpty(this.pin)
      && !Amount.fromPlanck(amount).getRaw().eq(0)
      && this.hasSufficientBalance();
  }

  validateForm(): string {
    if (!this.ethService.isValidAddress(this.recipientAddress)) {
      return this.i18nService.getTranslation('invalid_eth_address');
    }

    const isValidAmount = Number(this.amount);
    if (!isValidAmount && isValidAmount !== 0) {
      return this.i18nService.getTranslation('invalid_eth_amount');
    }
    if (isValidAmount < 0) {
      return this.i18nService.getTranslation('invalid_eth_amount');
    }

    try {
      // check if amount has scientific notation format
      Amount.fromPlanck(this.amount);
    } catch (e) {
      return this.i18nService.getTranslation('invalid_eth_amount');
    }

    const checkDecimals = this.amount.indexOf('.');
    if (checkDecimals !== -1) {
      const decimals = this.amount.substr(this.amount.indexOf('.') + 1);
      if (decimals.length > 18) {
        return this.i18nService.getTranslation('error_price_7');
      }
    }

    return '';
  }

  async onGasChanged(): Promise<void> {
    this.gas = await this.gas ? this.gas : this.estimateGas[1];
    this.priorityIndex = this.estimateGas.find(item => item.value === this.gas.value).index;
    const gasPrice = await this.gas ? Amount.fromPlanck(this.gas.value).getRaw().plus(Amount.fromPlanck(this.baseFee).getRaw()).toString() : '0';
    this.maxFee = await Amount.fromPlanck(this.gasLimit).getRaw().multipliedBy(Amount.fromPlanck(gasPrice).getRaw()).dividedBy(10E8).toString();
    this.maxGasFee = await Amount.fromPlanck(this.baseFee).getRaw().multipliedBy(2).plus(this.gas.value).multipliedBy(10E8).toString();
    this.maxPriority = await this.gas.value * 10E8;
  }

  maxGasFeeToWei(): string {
    return this.maxGasFee ? Amount.fromPlanck(this.maxGasFee).getRaw().dividedBy(10E8).toString() : '0';
  }

  resetFile(event): void {
    event.target.value = null;
  }

  parseQR(): void {
    const file = this.file.nativeElement.files[0];
    if (!file) {
      return;
    }

    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {

      const width = img.naturalWidth,
        height = img.naturalHeight;

      const reader = new FileReader();
      reader.onload = (e: ProgressEvent): void => {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        let qrCodeImageFormat = ctx.getImageData(0, 0, width, height);
        let qr = jsQR(qrCodeImageFormat.data, qrCodeImageFormat.width, qrCodeImageFormat.height);
        if (!qr) {
          ctx.drawImage(img, 10, 10, width, height);
          qrCodeImageFormat = ctx.getImageData(0, 0, width, height);
          qr = jsQR(qrCodeImageFormat.data, qrCodeImageFormat.width, qrCodeImageFormat.height);
        }
        if (qr) {
          const urlText = qr.data;
          if (!urlText.startsWith('eth')) {
            if (!this.ethService.isValidAddress(urlText)) {
              this.notifierService.notify('error', this.i18nService.getTranslation('invalid_eth_address'));
              return;
            } else {
              this.recipientAddress = urlText;
              this.amount = '0';
              this.notifierService.notify('success', this.i18nService.getTranslation('qr_parse_success'));
            }

            // this.notifierService.notify('error', this.i18nService.getTranslation('error_qr_format'));
            // return;
          } else {
            this.recipientAddress = urlText.substring(urlText.indexOf(':') + 1, urlText.lastIndexOf('?'));
            const paramsObj = new URLSearchParams(urlText.substring(urlText.lastIndexOf('?') + 1));
            const amount = paramsObj.get('amount');
            if (paramsObj.get('paymentId')) {
              this.disableInputField = true;
              this.paymentId = paramsObj.get('paymentId');
            }
            this.amount = amount;
            this.notifierService.notify('success', this.i18nService.getTranslation('qr_parse_success'));
          }
        } else {
          this.notifierService.notify('error', this.i18nService.getTranslation('qr_parse_fail'));
        }
      };

      window.URL.revokeObjectURL(img.src);
      reader.readAsDataURL(file);
    };
  }


  getPaymentOrder(paymentId: string): void {
    if (!paymentId) {
      return;
    }
    this.gatewayService.getPaymentOrder(paymentId).subscribe(
      (res: any) => {
        if (res && res.code === 0) {

          const data: IOrderDetail = res.data;
          this.amount = formatBalance(data.remaining + '', 'ETH');
          this.recipientAddress = data.address;
          this.disableInputField = true;

        }
      },
      (error: any) => {
        this.notifierService.notify('error', this.i18nService.getTranslation('error_unknown'));
      }
    );
  }
}
