import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'qr-dialog',
  templateUrl: 'qr-coin.component.html',
  styleUrls: ['qr-coin.component.scss']
})
export class QrCoinComponent {

  constructor(public dialogRef: MatDialogRef<QrCoinComponent>,
              @Inject(MAT_DIALOG_DATA) public data?: any) {
  }
}
