import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Transaction } from '@fruitsjs/core';
import {SwapService} from '../swap.service';

@Injectable()
export class SwapOrderResolver implements Resolve<Promise<Transaction>> {
  constructor(private swapService: SwapService) {
    this.swapService = swapService;
  }

  resolve(route: ActivatedRouteSnapshot): Promise<any> {
    return this.swapService.getSwapOrderDetails(route.paramMap.get('orderId'));
  }
}
