import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Account} from '@fruitsjs/core';
import {decryptAES, generateSignature, hashSHA256} from '@fruitsjs/crypto';
import {NotifierService} from 'angular-notifier';
import {I18nService} from '../../../layout/components/i18n/i18n.service';
import {AccountService} from '../../../setup/account/account.service';

@Component({
    selector: 'item-account',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit {

    @Input() account: Account;

    @Input() accounts: Account[];

    @Input() message: string;

    isSending = false;

    pin: string;
    show = false;
    @Output() showHideAccountButtonEmitter = new EventEmitter<string>();

    constructor(private notifierService: NotifierService,
                private i18nService: I18nService,
                private accountService: AccountService) {
    }

    ngOnInit(): void {
    }

    getMultiWalletIndex(address: string): string {
        if (this.accounts && this.accounts.length) {
            let index = 0;
            for (let i = 0; i < this.accounts.length; i++) {
                if (this.accounts[i].account === address) {
                    break;
                }
                index++;
            }
            return index === 0 ? '' : index.toString();
        }
        return '0';
    }

    async onSubmit(): Promise<void> {
        this.isSending = true;
        const privateKey = decryptAES(this.account.keys.signPrivateKey, hashSHA256(this.pin));
        if (!privateKey) {
            this.notifierService.notify('error', this.i18nService.getTranslation('wrong_pin'));
            this.isSending = false;
            return;
        }

        await this.accountService.selectAccount(this.account);

        const callbackUrl = decodeURIComponent(sessionStorage.getItem('callBack'));
        sessionStorage.removeItem('status');
        sessionStorage.removeItem('callBack');
        sessionStorage.removeItem('message');

        const signature = generateSignature(this.message, privateKey);
        const separator = callbackUrl.includes('?') ? '&' : '?';
        window.open(callbackUrl + `${separator}account=${this.account.accountRS}&signature=${signature}&message=${this.message}`, '_self');
        this.isSending = false;
    }

    showHideAccountButton(): void {
        this.show = !this.show;
        this.showHideAccountButtonEmitter.emit(this.account.account);
    }

    getWalletName(account: Account): string {
        if (!account) {
            return '';
        }
        return account.name ? account.name
            : 'Wallet name ' + this.getMultiWalletIndex(account.account);
    }
}
