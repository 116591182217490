import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';
import {StoreService} from 'app/store/store.service';
import {AccountService} from 'app/setup/account/account.service';
import {Account} from '@fruitsjs/core';
import {MatDialog} from '@angular/material/dialog';
import {decryptAES, hashSHA256, Keys} from '@fruitsjs/crypto';
import {KeyDecryptionException} from '../../../util/exceptions/KeyDecryptionException';
import {NotifierService} from 'angular-notifier';
import {I18nService} from '../../../layout/components/i18n/i18n.service';
import {takeUntil} from 'rxjs/operators';
import {UnsubscribeOnDestroy} from '../../../util/UnsubscribeOnDestroy';
import {TransactionService} from '../../transactions/transaction.service';
import {constants} from '../../../constants';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {SwapService} from '../../swap.service';
import {environment} from '../../../../environments/environment';
import * as moment from 'moment';
import {Amount} from '@fruitsjs/util';
import {SwapOrderDetailsComponent} from "../swap-order-details/swap-order-details.component";
import {CoinType} from "../../../shared/pipes/coin-amount.pipe";

@Component({
  selector: 'app-assets',
  styleUrls: ['./juice-btc-history.component.scss'],
  templateUrl: './juice-btc-history.component.html'
})
export class JuiceBtcHistoryComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {

  public locale;
  public isLoading = false;
  public dataSourceSellOrders: MatTableDataSource<any>;
  public displayedColumns: string[];
  public selectedAccount: Account;
  public node;
  fetchData;
  pageIndex = 0;
  pageSize = 10;
  totalElements: any;
  data: any;
  public unSubscriber = takeUntil(this.unsubscribeAll);

  marketUrl = environment.isMainNet ? constants.MARKET_MAIN : constants.MARKET_TEST;

  blockstreamUrl = environment.blockstreamUrl;

  constructor(private storeService: StoreService,
              private accountService: AccountService,
              public router: Router,
              private warnDialog: MatDialog,
              private route: ActivatedRoute,
              private notifierService: NotifierService,
              private i18nService: I18nService,
              private transactionService: TransactionService,
              private http: HttpClient,
              private title: Title,
              private swapService: SwapService) {
    super();
    this.title.setTitle(constants.pageTitle + this.i18nService.getTranslation('swap_history_btc'));
  }

  async getDataBTC(data): Promise<void> {
    this.isLoading = true;

    try {
      await this.swapService.getExternalCoinHistory(data).subscribe((response: any) => {
        if (response && response.errorCode === 0) {
          this.dataSourceSellOrders.data = response.result.content;
          this.totalElements = response.result.totalElements;
        }
      });

    } catch (e) {
      this.isLoading = false;
      console.warn(e);
    }
    this.isLoading = false;

  }


  getSendersSignPrivateKey(pin: string, keys: Keys): string {
    const privateKey = decryptAES(keys.signPrivateKey, hashSHA256(pin));
    if (!privateKey) {
      throw new KeyDecryptionException();
    }
    return privateKey;
  }

  pageChange(event): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    this.data.page = event.pageIndex;
    this.data.size = event.pageSize;
    this.getDataBTC(this.data);
  }

  async ngOnInit(): Promise<void> {
    this.displayedColumns = ['transaction_id', 'request_date', 'swap_content', 'fee', 'total_amount', 'rate', 'status', 'action'];
    this.dataSourceSellOrders = new MatTableDataSource<any>();
    this.storeService.getSettings().then((result: any) => {
      if (result && result.node) {
        this.node = result.node;
      }
    });
    this.storeService.settings
      .pipe(
        takeUntil(this.unsubscribeAll)
      )
      .subscribe(({language}) => {
        this.locale = language;
      });
    this.selectedAccount = await this.storeService.getSelectedAccount();
    this.data = {
      address: this.selectedAccount.accountRS,
      coinType: 'BTC',
      size: this.pageSize,
      page: this.pageIndex,
    };

    setTimeout(async () => {
      this.getDataBTC(this.data);
    }, 1000);
    this.fetchData = setInterval(() => {
      this.getDataBTC(this.data);
    }, 30000);
  }

  ngOnDestroy(): void {
    clearInterval(this.fetchData);
  }

  getQuantity(quantity): string {
    const intPart = quantity.substr(0, quantity.indexOf('.'));
    return intPart ? intPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + quantity.substr(quantity.indexOf('.'))
      : quantity.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  convertTimestamp(timestamp): any {
    return moment.unix(timestamp).format('MM/DD/YYYY HH:mm A');
  }

  getTotalAmount(asset): string {
    const networkFee = asset.networkFee ? Amount.fromPlanck(asset.networkFee).getRaw() : 0;
    return asset ? (Amount.fromPlanck(asset.total).getRaw().plus(Amount.fromPlanck(asset.swapFee).getRaw()).plus(networkFee).toString()) : '0';
  }

  openDetailDialog(data: any): void {
    const dialogRef = this.warnDialog.open(SwapOrderDetailsComponent, {
      width: '600px',
      height: '500px',
      data: {
        result: data,
        type: CoinType.btc
      }
    });

    dialogRef.afterClosed().subscribe(ok => {
      this.getDataBTC(this.data);
    });
  }

  convertStatus(status): string {
    switch (status) {
      case 'CANCELLED':
        return 'CANCELED';
      case 'COMPLETE':
        return 'COMPLETED';
      default:
        return status;
    }
  }
}
