import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  Input,
  ElementRef,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { delay, filter, take, takeUntil } from 'rxjs/operators';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { StoreService } from 'app/store/store.service';
import { Account } from '@fruitsjs/core';
import { AccountService } from 'app/setup/account/account.service';
import { environment } from 'environments/environment';
import { I18nService } from 'app/layout/components/i18n/i18n.service';
import { NotifierService } from 'angular-notifier';
import { FuseNavigation } from '../../../../../../@fuse/types';
import {Amount} from '@fruitsjs/util';

@Component({
  selector: 'navbar-vertical-style-1',
  templateUrl: './style-1.component.html',
  styleUrls: ['./style-1.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavbarVerticalStyle1Component implements OnInit, OnDestroy, OnChanges {

  @ViewChild('avatar', { static: false }) avatar: ElementRef<HTMLCanvasElement>;
  @Input() selectedAccount: Account;
  navigation: any;
  fuseConfig: any;
  selectedAccountQRCode: string;
  language: string;
  node = environment.defaultNode;
  isAdmin = environment.isAdmin;

  // Private
  private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _accountService: AccountService,
    private _storeService: StoreService,
    private i18nService: I18nService,
    private _notifierService: NotifierService,
    private _router: Router
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  @ViewChild(FusePerfectScrollbarDirective, { static: true })
  set directive(theDirective: FusePerfectScrollbarDirective) {
    if (!theDirective) {
      return;
    }

    this._fusePerfectScrollbar = theDirective;

    // Update the scrollbar on collapsable item toggle
    this._fuseNavigationService.onItemCollapseToggled
      .pipe(
        delay(500),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(() => {
        this._fusePerfectScrollbar.update();
      });

    // Scroll to the active item position
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe(() => {
        setTimeout(() => {
          const activeNavItem: any = document.querySelector('navbar .nav-link.active');

          if (activeNavItem) {
            const activeItemOffsetTop = activeNavItem.offsetTop,
              activeItemOffsetParentTop = activeNavItem.offsetParent.offsetTop,
              scrollDistance = activeItemOffsetTop - activeItemOffsetParentTop - (48 * 3) - 168;

            this._fusePerfectScrollbar.scrollToTop(scrollDistance);
          }
        });
      }
      );
  }

  async ngOnInit(): Promise<void> {
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(() => {
        if (this._fuseSidebarService.getSidebar('navbar')) {
          this._fuseSidebarService.getSidebar('navbar').close();
        }
      });

    this._storeService.settings
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(async ({ language, node }) => {
        this.language = language;
        this.node = node;
        // await this.updateAvatar();
      });

    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.fuseConfig = config;
      });

    // Get current navigation
    // this._fuseNavigationService.onNavigationChanged
    //   .pipe(
    //     filter(value => value !== null),
    //     takeUntil(this._unsubscribeAll)
    //   )
    //   .subscribe(async () => {
    //     // await this.updateAvatar();
    //   });

    this._accountService.currentAccount.subscribe((account) => {
        this.selectedAccount = account;
        this.updateNavigation();
        // await this.updateAvatar();
      });
  }

  formatBalance(balance): string {
    const intPart = balance.substr(0, balance.indexOf('.'));
    return intPart ? intPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + balance.substr(balance.indexOf('.'))
      : balance.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  // private async updateAvatar(): Promise<void> {
  //   if (this.avatar) {
  //     hashicon(this.selectedAccount.account, {
  //       size: 100,
  //       createCanvas: () => this.avatar.nativeElement
  //     });
  //     const img = document.getElementById('avatar-converted');
  //     if (!img) {
  //       const image = new Image();
  //       image.id = 'avatar-converted';
  //       image.src = this.cropPlusExport(this.avatar.nativeElement, 0, 0, 100, 100).toDataURL();
  //       document.getElementById('fruits').style.background = 'url(' + image.src + ') center no-repeat';
  //     }
  //   }
  // }
  //
  // cropPlusExport(root, cropX, cropY, cropWidth, cropHeight): HTMLCanvasElement {
  //   const canvas = document.createElement('canvas');
  //   const ctx = canvas.getContext('2d');
  //   canvas.width = cropWidth;
  //   canvas.height = cropHeight;
  //   ctx.drawImage(root, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);
  //   return canvas;
  // }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar opened status
   */
  toggleSidebarOpened(): void {
    this._fuseSidebarService.getSidebar('navbar').toggleOpen();
  }

  /**
   * Toggle sidebar folded status
   */
  toggleSidebarFolded(): void {
    this._fuseSidebarService.getSidebar('navbar').toggleFold();
  }

  getQRCode(id: string): Promise<string> {
    return this._accountService.generateSendTransactionQRCodeAddress(id);
  }

  getAccountName(): string {
    return this.selectedAccount.name || this.i18nService.getTranslation('account_info');
  }

  getVersion(): string {
    return environment.version;
  }

  copy(val: string): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    const success = document.execCommand('copy');
    document.body.removeChild(selBox);

    if (success) {
      this._notifierService.notify('success',
        this.i18nService.getTranslation('success_clipboard_copy')
      );
    } else {
      this._notifierService.notify('error',
        this.i18nService.getTranslation('error_clipboard_copy')
      );
    }
  }

  getBalance(): string {
    return Amount.fromPlanck(this.selectedAccount.balanceNQT).getSigna();
  }

  getBtcBalance(): any {
    return this.selectedAccount.multiKeys && this.selectedAccount.multiKeys.btc && this.selectedAccount.multiKeys.btc.balance ? this.selectedAccount.multiKeys.btc.balance : '0';
  }

  getEthBalance(): string {
    return this.selectedAccount.multiKeys && this.selectedAccount.multiKeys.eth && this.selectedAccount.multiKeys.eth.balance ? this.selectedAccount.multiKeys.eth.balance : '0';
  }

  getUSDTBalance(): string {
    return this.selectedAccount.multiKeys && this.selectedAccount.multiKeys.eth && this.selectedAccount.multiKeys.eth.usdtBalance ? this.selectedAccount.multiKeys.eth.usdtBalance : '0';
  }

  public updateNavigation(): void {

    const navigation = this._fuseNavigationService.getCurrentNavigation() as FuseNavigation[];

    const isFullAccount = this.selectedAccount && this.selectedAccount.type !== 'offline';

    const isMultiWallet = this.selectedAccount && this.selectedAccount.multiWallet;

    const traverse = (n: FuseNavigation) => {
      n.hidden = n.fullAccountOnly && !isFullAccount;
      if ((n.id === 'send_multi' || n.id === 'request_multi') && !isMultiWallet) {
        n.hidden = true;
      }
      if (n.id === 'transactions_frts_only' && isMultiWallet) {
        n.hidden = true;
      }
      if (n.id === 'transactions_multi' && !isMultiWallet) {
        n.hidden = true;
      }
      if ((n.id === 'farming_history' || n.id === 'farming') && !isMultiWallet) {
        n.hidden = true;
      }
      if ((n.id === 'swap_history_btc' || n.id === 'swap_history_eth') && !isMultiWallet) {
        n.hidden = true;
      }
      if (n.children) {
        n.children.forEach(traverse);
      }
    };

    navigation.forEach(traverse);

    this.navigation = navigation;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.selectedAccount) {
      const icon = document.getElementById('icon-menu-custom');
      if (icon) {
        icon.style.width = this.selectedAccount.multiWallet ? '40px' : '20px';
        icon.style.height = this.selectedAccount.multiWallet ? '46px' : '23px';
      }
    }
  }
}
