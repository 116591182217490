import {ChainService} from '../../../service';
import {GetAccountBlocksArgs} from '../../../typings/args';
import {BlockList} from '../../../typings/blockList';

export const getLatestAccountBlock = (service: ChainService):
    (args: GetAccountBlocksArgs) => Promise<BlockList> =>
    (args): Promise<BlockList> => {

        const params = {
            account: args.accountId,
            includeTransactions: args.includeTransactions || false
        };

        return service.query('getLatestAccountBlocks', params);
    };
