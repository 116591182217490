<div class="page-layout blank simple" fusePerfectScrollbar>

  <!-- HEADER -->
  <div class="header accent p-40 h-140" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
       fxLayoutAlign.gt-xs="space-between center">

    <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="center start">
      <div fxLayout="row" fxLayoutAlign="start center">
        <a [routerLink]="['/dashboard']">
          <!--          <mat-icon class="secondary-text s-18">home</mat-icon>-->
          <img src="./assets/icons/menu/icon-home.png" height="16" width="16" alt=""/>
        </a>
        <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
        <span class="secondary-text">{{ 'swap_history_tfsts' | i18n }}</span>
      </div>
      <div class="current-screen h1 mt-16">{{ 'swap_history_tfsts' | i18n }}</div>
    </div>
  </div>
  <!-- / HEADER -->

  <div class="p-40 content">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <div class="assets-table">
      <mat-table [dataSource]="dataSourceSellOrders" matSort>

        <ng-container matColumnDef="transaction_id">
          <mat-header-cell *matHeaderCellDef>{{ 'transaction_id' | i18n }}</mat-header-cell>
          <mat-cell *matCellDef="let asset">
            <a [routerLink]="['/frts-transactions/transaction', asset.transaction]">{{asset.transaction }}</a>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="request_date">
          <mat-header-cell *matHeaderCellDef>{{ 'request_date' | i18n }}</mat-header-cell>
          <mat-cell *matCellDef="let asset">
            <span>{{ convertTimestamp(asset.createdDate / 1000) }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="swap_content">
          <mat-header-cell *matHeaderCellDef>{{ 'swap_content' | i18n }}</mat-header-cell>
          <mat-cell *matCellDef="let asset">
            <div *ngIf="asset.type === 'ASK'">
              <div>{{ 'from' | i18n }}: {{ asset.tokenQuantity  | number: '1.0-8'}} {{asset.assetName}}</div>
              <div> {{ 'to' | i18n }}: {{ asset.frtsQuantity | number: '1.0-8'}} FRTS</div>
            </div>
            <div *ngIf="asset.type === 'BID'">
              <div>{{ 'from' | i18n }}: {{ asset.frtsQuantity | number: '1.0-8' }} FRTS</div>
              <div> {{ 'to' | i18n }}: {{ asset.tokenQuantity | number: '1.0-8' }} {{ asset.assetName }}</div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="fee">
          <mat-header-cell *matHeaderCellDef>{{ 'fee' | i18n }}</mat-header-cell>
          <mat-cell *matCellDef="let asset">
            <div>
              <div>
                {{ 'network_fee' | i18n }}
                : {{!asset.networkFee ? 0 : asset.networkFee | number: '1.0-8'}} FRTS
              </div>
              <div>
                {{ 'swap_fee' | i18n }}: {{ asset.swapFee | number: '1.0-8' }} FRTS
              </div>
            </div>

          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="total_amount">
          <mat-header-cell *matHeaderCellDef>{{ 'total_amount' | i18n }}</mat-header-cell>
          <mat-cell *matCellDef="let asset">
            <span *ngIf="asset.type === 'ASK'">{{ asset.tokenQuantity  | number: '1.0-8'}} {{ asset.assetName }}
              + {{ asset.swapFee | number: '1.0-8' }} FRTS</span>
            <span *ngIf="asset.type === 'BID'">{{ calculateTotalAmount(asset.frtsQuantity, asset.swapFee) | number: '1.0-8'}} FRTS</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="rate">
          <mat-header-cell *matHeaderCellDef>{{ 'rate' | i18n }}</mat-header-cell>
          <mat-cell *matCellDef="let asset">
             <span
               *ngIf="asset.type === 'ASK'"> 1  {{ asset.assetName + ' = '  }} {{   asset.ratePerUnit | number: '1.0-8'}}
               FRTS</span>
            <span *ngIf="asset.type === 'BID'">  {{asset.ratePerUnit | number: '1.0-8'}}
              FRTS  =  1   {{ asset.assetName}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef>{{ 'status' | i18n }}</mat-header-cell>
          <mat-cell *matCellDef="let asset">
            <span>{{ convertStatus(asset.status) }}</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef>{{ 'action' | i18n }}</mat-header-cell>
          <mat-cell *matCellDef="let asset">
            <button class="btn-common" (click)="openDetailDialog(asset)">{{ 'details' | i18n }}</button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>

      <div [hidden]="!totalElements || totalElements <= 10">
        <mat-paginator
          #paginator
          [length]="totalElements"
          [pageSize]="pageSize"
          [pageIndex]="pageIndex"
          [pageSizeOptions]="[10, 20, 50]"
          [showFirstLastButtons]="true"
          (page)="pageChange($event)"
        >
        </mat-paginator>
      </div>

    </div>
  </div>
</div>
