<div class="warn-dialog p-24">
  <h1 mat-dialog-title>{{ 'unknown_recipients' | i18n }}</h1>
  <div mat-dialog-content style="width: 100%; word-break: break-all;">
    <p>{{ 'unknown_recipients_text' | i18n }}</p>
    <ul class="recipient-list">
      <li *ngFor="let recipient of recipients">
        <div>
          {{recipient.addressRaw}}
        </div>
      </li>
    </ul>
    <p style="text-align: center" *ngIf="!hasUnknownAliasAccount">{{ 'are_you_sure' | i18n }}</p>
  </div>
  <div mat-dialog-actions>
    <button class="btn-common" *ngIf="hasUnknownAliasAccount" [mat-dialog-close]>{{ 'cancel' | i18n }}</button>
    <button class="btn-common" *ngIf="!hasUnknownAliasAccount" [mat-dialog-close]>{{ 'cancel' | i18n }}</button>
    <button class="btn-common" *ngIf="!hasUnknownAliasAccount" [mat-dialog-close]="true" color="accent">{{ 'submit' | i18n }}</button>
  </div>
</div>
