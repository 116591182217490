<section class="login" [fusePerfectScrollbar]="false"
         [fusePerfectScrollbarOptions]="{suppressScrollY:true}">
  <div class="login-passive__container">
    <div class="signup">
      <div class="signup__container">
        <h1 class="signup__title">{{ 'observe_frts_account' | i18n }}</h1>
        <app-create-passive></app-create-passive>
      </div>
    </div>
  </div>
</section>
