import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { AccountService } from '../../../setup/account/account.service';
import jsQR from 'jsqr';
import { NotifierService } from 'angular-notifier';
import { DomainService } from 'app/main/send-burst/domain/domain.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Address, AddressPrefix } from '@fruitsjs/core';
import {Amount} from '@fruitsjs/util';
import {I18nService} from '../i18n/i18n.service';
import {environment} from 'environments/environment';
import {NftService} from "../../../payment-gateway/nft.service";

// generate a unique id for 'for', see https://github.com/angular/angular/issues/5145#issuecomment-226129881
let nextId = 0;

export enum RecipientType {
  UNKNOWN = 0,
  ADDRESS = 1,
  ID,
  ALIAS,
  ZIL,
  BURN
}

export enum RecipientValidationStatus {
  UNKNOWN = 'unknown',
  INVALID = 'invalid',
  VALID = 'valid',
  ZIL_OUTAGE = 'zil-outage',
  BURN = 'burn'
}

export class Recipient {

  constructor(
    public addressRaw = '',
    public amount = '',
    public addressRS = '',
    public status = RecipientValidationStatus.UNKNOWN,
    public type = RecipientType.UNKNOWN,
    public publicKey = ''
  ) {

  }
}

@Component({
  selector: 'burst-recipient-input',
  templateUrl: './burst-recipient-input.component.html',
  styleUrls: ['./burst-recipient-input.component.scss']
})
export class BurstRecipientInputComponent implements OnChanges {

  loading = false;
  fileId = `file-${nextId++}`;
  recipient = new Recipient();
  _recipientValue = '';
  recipientFieldInputChange$: Subject<string> = new Subject<string>();
  burnAddress: string[] = [];

  @Input() withQrCode = true;
  // tslint:disable-next-line: no-input-rename
  @Input('appearance') appearance = 'outline';
  // tslint:disable-next-line: no-input-rename
  @Input('disabled') disabled = false;
  // tslint:disable-next-line: no-input-rename
  @Input('isTestNet') isTestNet = false;

  @Input() symbol: string;

  @Output()
  recipientChange = new EventEmitter();
  @Output()
  qrCodeUpload = new EventEmitter();


  @Input()
  get recipientValue(): string {
    return this._recipientValue;
  }

  set recipientValue(recipientValue: string) {
    this._recipientValue = recipientValue;
    this.onRecipientFieldInputChange(recipientValue);
  }

  @ViewChild('file', { static: false }) file: ElementRef;

  constructor(private accountService: AccountService,
    private notifierService: NotifierService,
    private domainService: DomainService,
    private i18nService: I18nService,
    private nftService: NftService) {

    this.getBurnAddress();
    this.recipientFieldInputChange$.pipe(
      debounceTime(500), distinctUntilChanged()
    )
      .subscribe((model: string) => {
        this.applyRecipientType(model);
        this.validateRecipient(model);
      });
  }

  onRecipientFieldInputChange(query: string): void {
    this.recipientFieldInputChange$.next(query);
  }

  ngOnChanges(): void {
    if (!this.recipientValue) {
      this.recipient = new Recipient();
      return;
    }
  }

  private isReedSolomonAddress(address: string): boolean {
    try {
      Address.fromReedSolomonAddress(address);
      return true;
    } catch (e) {
      return false;
    }
  }

  applyRecipientType(recipient: string): void {
    const r = recipient.trim();
    this.recipient.addressRaw = r;
    this.recipient.addressRS = '';
    this.recipient.status = RecipientValidationStatus.UNKNOWN;
    if (environment.isAdmin) {
      if (r.length === 0) {
        this.recipient.type = RecipientType.UNKNOWN;
      } else if (this.isReedSolomonAddress(r)) {
        this.recipient.type = RecipientType.ADDRESS;
      } else if (r.toUpperCase().endsWith('.ZIL') || r.toUpperCase().endsWith('.CRYPTO')) {
        this.recipient.type = RecipientType.ZIL;
      } else if (/^\d+$/.test(r)) {
        this.recipient.type = RecipientType.ID;
      } else {
        this.recipient.type = RecipientType.UNKNOWN;
      }
    } else {
      if (r.length === 0) {
        this.recipient.type = RecipientType.UNKNOWN;
      } else if (this.isReedSolomonAddress(r)) {
        this.recipient.type = RecipientType.ADDRESS;
      } else {
        this.recipient.type = RecipientType.UNKNOWN;
      }
    }
  }

  async validateRecipient(recipient: string): Promise<void> {
    let accountFetchFn;
    this.recipient.addressRaw = recipient.trim();
    let id = this.recipient.addressRaw;
    switch (this.recipient.type) {
      case RecipientType.ALIAS:
        accountFetchFn = this.accountService.getAlias;
        break;
      case RecipientType.ADDRESS:
        try {
          const address = Address.fromReedSolomonAddress(id);
          this.recipient.addressRaw = address.getReedSolomonAddress();
          this.recipient.publicKey = address.getPublicKey();
          id = address.getNumericId();
          accountFetchFn = this.accountService.getAccount;
        } catch (e) {}
        break;
      case RecipientType.ZIL:
        try {
          id = await this.domainService.getZilAddress(id);
          accountFetchFn = this.accountService.getAccount;

          if (id === null) {
            this.recipient.status = RecipientValidationStatus.INVALID;
          }
        } catch (e) {
          // this.recipient.status = RecipientValidationStatus.ZIL_OUTAGE;
          this.recipient.status = RecipientValidationStatus.INVALID;
        }
        break;
      // tslint:disable-next-line:no-switch-case-fall-through
      case RecipientType.ID:
        accountFetchFn = this.accountService.getAccount;
        break;
      default:
      // no op
    }

    if (!accountFetchFn || !id) {
      return;
    }

    this.loading = true;

    accountFetchFn.call(this.accountService, id).then(({ accountRS }) => {
      this.recipient.addressRS = accountRS;
      this.recipient.status = RecipientValidationStatus.VALID;
    }).catch(() => {
      if (this.isReedSolomonAddress(this.recipient.addressRaw)) {
        this.recipient.addressRS = this.recipient.addressRaw;
      } else if (this.recipient.type === RecipientType.ZIL) {
        this.recipient.addressRS = id;
      } else {
        const prefix = this.isTestNet ? AddressPrefix.TestNet : AddressPrefix.MainNet;
        this.recipient.addressRS = Address.fromNumericId(this.recipient.addressRaw, prefix).getReedSolomonAddress();
      }
      this.recipient.status = RecipientValidationStatus.INVALID;
    }).finally(() => {

      if (this.burnAddress.indexOf(this.recipient.addressRS) > -1) {
        this.recipient.type = RecipientType.BURN;
        this.recipient.status = RecipientValidationStatus.BURN;
      }

      this.loading = false;
      this.recipientChange.emit(this.recipient);
    });

  }

  getRecipientTypeName = (): string => RecipientType[this.recipient.type];

  getValidationHint(): string {
    // TODO: localization
    switch (this.recipient.status) {
      case RecipientValidationStatus.UNKNOWN:
        return this.i18nService.getTranslation('unknown_address_alert');
      case RecipientValidationStatus.VALID:
        return this.i18nService.getTranslation('valid_address_alert');
      case RecipientValidationStatus.INVALID:
        return this.i18nService.getTranslation('verify_address_alert');
      case RecipientValidationStatus.ZIL_OUTAGE:
        return this.i18nService.getTranslation('outage_address_alert');
      case RecipientValidationStatus.BURN: {
        return this.i18nService.getTranslation('burn_address_alert');
      }
    }
  }

  getValidationIcon(): string {
    switch (this.recipient.status) {
      case RecipientValidationStatus.UNKNOWN:
        return 'help_outline';
      case RecipientValidationStatus.VALID:
        return 'check_circle';
      case RecipientValidationStatus.INVALID:
      case RecipientValidationStatus.ZIL_OUTAGE:
      case RecipientValidationStatus.BURN:
        return 'error_outline';
    }
  }

  getValidationClass(): string {
    return 'badge ' + this.recipient.status.toString().toLocaleLowerCase();
  }

  resetFile(event): void {
    event.target.value = null;
  }

  parseQR(): void {
    const file = this.file.nativeElement.files[0];
    if (!file) {
      return;
    }

    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {

      const width = img.naturalWidth,
        height = img.naturalHeight;

      const reader = new FileReader();
      reader.onload = (e: ProgressEvent): void => {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        let qrCodeImageFormat = ctx.getImageData(0, 0, width, height);
        let qr = jsQR(qrCodeImageFormat.data, qrCodeImageFormat.width, qrCodeImageFormat.height);
        if (!qr) {
          ctx.drawImage(img, 10, 10, width, height);
          qrCodeImageFormat = ctx.getImageData(0, 0, width, height);
          qr = jsQR(qrCodeImageFormat.data, qrCodeImageFormat.width, qrCodeImageFormat.height);
        }
        if (qr) {
          const urlText = qr.data;
          if (!urlText.toLowerCase().startsWith(this.symbol.toLowerCase())) {
            if (this.symbol === 'frts') {
              const addressRs = urlText;
              this.applyRecipientType(addressRs);
              this.validateRecipient(addressRs);
              this.qrCodeUpload.emit({
                recipient: this.recipient
              });
              this.notifierService.notify('success', this.i18nService.getTranslation('qr_parse_success'));
              return;
            } else {
              this.notifierService.notify('error', this.i18nService.getTranslation('error_qr_format'));
              return;
            }
          }
          const addressRS = urlText.substring(urlText.indexOf(':') + 1, urlText.lastIndexOf('?'));
          // const amount = urlText.substring(urlText.lastIndexOf('=') + 1);
          const params = urlText.substring(urlText.lastIndexOf('?') + 1);
          const paramsObj = new URLSearchParams(params);
          const amount = paramsObj.get('amount');
          const paymentId = paramsObj.get('paymentId');
          this.applyRecipientType(addressRS);
          this.validateRecipient(addressRS);
          this.qrCodeUpload.emit({
            recipient: this.recipient,
            amountNQT: Amount.fromSigna(amount).getPlanck(),
            quantity: amount,
            paymentId: paymentId
          });
          this.notifierService.notify('success', this.i18nService.getTranslation('qr_parse_success'));
        } else {
          this.notifierService.notify('error', this.i18nService.getTranslation('qr_parse_fail'));
        }
      };

      window.URL.revokeObjectURL(img.src);
      reader.readAsDataURL(file);
    };
  }

  decodeUnicode(str): string {
    return decodeURIComponent(atob(str).split('').map((c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

  getBurnAddress(): void {
    this.nftService.getBurnAddress().subscribe({
      next: (res) => {
        this.burnAddress = res.result;
      }
    });
  }

}
