<div class="coin-info">
  <img src="{{ coinSelected ? coinSelected.iconHome : '' }}" height="50" width="50" alt=""/>
  <span class="coin-name">{{ coinSelected ? coinSelected.name : '' }}</span>
  <mat-form-field *ngIf="account.multiWallet" class="currency" appearance="outline" floatLabel="always">
    <mat-select (closed)="coinFilter = ''; coinList = coinListCopy;" [(ngModel)]="coinSelected" name="currency"
                disableOptionCentering panelClass="myPanelClass myPanelClassCurrency"
                (selectionChange)="onCoinChange($event)">
      <mat-select-trigger><span style="font-size: 20px; font-weight: 400;">{{ coinSelected.symbol.toUpperCase() }}</span></mat-select-trigger>
      <mat-form-field style="width: 96%; min-width: 80px; margin: 0 0 0 5px;" appearance="outline">
        <input [(ngModel)]="coinFilter" matInput name="keySearch" (keyup)="filterCoin($event)" placeholder="{{ 'search' | i18n }}">
      </mat-form-field>
      <div class="currency-item" style="max-height: 200px; overflow: auto;">
        <mat-option *ngFor="let item of coinList" [value]="item">
          <img class="coin-item-icon" alt="" src="{{ item.icon }}" width="36" height="36">
          <div>
            <span class="coin-item-name">{{ item.name }}</span>
            <span class="coin-item-symbol">{{ item.symbol.toUpperCase() }}</span>
          </div>
        </mat-option>
      </div>
    </mat-select>
  </mat-form-field>
</div>
<div class="border-line-multi-coin"></div>
