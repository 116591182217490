import {Component, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '../../../@fuse/animations';
import {FuseConfigService} from '../../../@fuse/services/config.service';
import {StoreService} from '../../store/store.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector     : 'wallet',
  templateUrl  : './wallet.component.html',
  styleUrls    : ['./wallet.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class WalletComponent {

  redirect: any;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private storeService: StoreService,
    private router: Router,
    private activatedRouter: ActivatedRoute
  )
  {
    if (activatedRouter.snapshot.queryParams) {
      this.redirect = this.activatedRouter.snapshot.queryParams;
    }
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar   : {
          hidden: true
        },
        toolbar  : {
          hidden: true
        },
        footer   : {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };
  }

  async frtsWallet(): Promise<void> {
    const settings = await this.storeService.getSettings();
    settings.isMultiWallet = false;
    await this.storeService.saveSettings(settings);
    if (this.redirect) {
      this.router.navigate(['/login'], {queryParams: this.redirect});
    } else {
      this.router.navigate(['/login']);
    }

  }

  async multiWallet(): Promise<void> {
    const settings = await this.storeService.getSettings();
    settings.isMultiWallet = true;
    await this.storeService.saveSettings(settings);
    if (this.redirect) {
      this.router.navigate(['/login'], {queryParams: this.redirect});
    } else {
      this.router.navigate(['/login']);
    }
  }
}
