import {Component, OnInit, ViewChild, Input, ChangeDetectorRef} from '@angular/core';
import { NgForm } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import {Amount, CurrencySymbol} from '@fruitsjs/util';
import {SuggestedFees, Account, MultioutRecipientAmount, Address, AddressPrefix, TransactionId} from '@fruitsjs/core';
import { I18nService } from 'app/layout/components/i18n/i18n.service';
import { TransactionService } from 'app/main/transactions/transaction.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WarnSendDialogComponent } from '../warn-send-dialog/warn-send-dialog.component';
import {
  Recipient, RecipientType,
  RecipientValidationStatus
} from '../../../layout/components/burst-recipient-input/burst-recipient-input.component';
import { filter, takeUntil } from 'rxjs/operators';
import { StoreService } from '../../../store/store.service';
import { UnsubscribeOnDestroy } from 'app/util/UnsubscribeOnDestroy';
import { BatchRecipientsDialogComponent } from '../batch-recipients-dialog/batch-recipients-dialog.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { constants } from '../../../constants';
import { Router } from '@angular/router';
import { AccountBalances, getBalancesFromAccount } from '../../../util/balance';
import { isKeyDecryptionError } from '../../../util/exceptions/isKeyDecryptionError';
import { NetworkService } from '../../../network/network.service';
import {AppService} from '../../../app.service';
import {Settings} from '../../../settings';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

const isNotEmpty = (value: string) => value && value.length > 0;

@Component({
  selector: 'app-send-multi-out-form',
  templateUrl: './send-multi-out-form.component.html',
  styleUrls: ['./send-multi-out-form.component.scss']
})
export class SendMultiOutFormComponent extends UnsubscribeOnDestroy implements OnInit {

  @ViewChild('sendBurstForm', { static: true }) public sendBurstForm: NgForm;
  @ViewChild('recipientAddress', { static: false }) public recipientAddress: string;
  @ViewChild('amount', { static: true }) public amount: string;
  @ViewChild('message', { static: false }) public message: string;
  @ViewChild('fullHash', { static: false }) public fullHash: string;
  @ViewChild('encrypt', { static: false }) public encrypt: string;
  @ViewChild('pin', { static: true }) public pin: string;

  @ViewChild('recipients', { static: true }) public recipients: Array<Recipient> = [];

  // tslint:disable-next-line:no-input-rename
  @Input('account') account: Account;
  // tslint:disable-next-line:no-input-rename
  @Input('fees') fees: SuggestedFees;
  settings: Settings;
  requestCurrency = constants.requestCurrency;
  currencyListFrts;
  currencySelectedFrts;
  currencyListCopy;
  priceFrts;
  isSelectingFrts = false;
  priceCurrency;
  changeFrts;
  fee: string;
  sameAmount = false;
  advanced = false;
  showMessage = false;
  isFree = true;
  freeAccounts = [];

  deadline = '24';
  isSending = false;
  language: string;
  symbol = CurrencySymbol;
  key;
  total;
  oldVal;
  private balances: AccountBalances;

  constructor(
    private warnDialog: MatDialog,
    private batchRecipientsDialog: MatDialog,
    private transactionService: TransactionService,
    private notifierService: NotifierService,
    private i18nService: I18nService,
    private storeService: StoreService,
    private networkService: NetworkService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private http: HttpClient,
    private appService: AppService,
    private cd: ChangeDetectorRef
  ) {
    super();
    this.storeService.settings
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(async ({ language }) => {
        this.language = language;
      }
      );
    this.storeService.getSettings().then((settings: any) => {
      this.settings = settings;
      this.currencyListFrts = constants.currencies.map(currency => this.requestCurrency.includes(currency.code) ? currency : undefined).filter(currency => currency !== undefined);
      this.currencySelectedFrts = this.settings && this.settings.currency && this.settings.currency.frts
        ? this.currencyListFrts.filter(item => item.code === this.settings.currency.frts)[0] : this.currencyListFrts[0];
      this.currencyListCopy = this.currencyListFrts;
      this.getPriceFrts();
    });
  }

  ngOnInit(): void {
    this.balances = getBalancesFromAccount(this.account);
    // this.fee = Amount.fromPlanck(this.fees.standard.toString(10)).getSigna();
    this.fee = '0';
    this.resetRecipients();
    // this.storeService.getSettings().then(result => {
    //   if (result && result.node) {
    //     const getFreeAccounts = async () => {
    //       const response = await fetch(`${result.node}/fruits?requestType=getFreeAccounts`);
    //       return await response.json();
    //     };
    //     getFreeAccounts().then(accounts => {
    //       this.freeAccounts = accounts.freeTransferAccountList;
    //     });
    //   }
    // });
  }
  async getPriceFrts(): Promise<void>{
    if (this.currencySelectedFrts) {
      const params = new HttpParams()
        .set('source', CurrencySymbol)
        .set('destination', this.currencySelectedFrts.code);
      this.http.get(constants.coinMarketCapURL + '/fruits/converter/api', {
        params: params
      }).subscribe((response: any) => {
        if (response && response.errorCode === 0) {
          this.priceFrts = response.result;
          this.priceCurrency = this.priceFrts.priceSelectedCurrency;
        }
        this.isSelectingFrts = false;
      }, (e) => {
        console.warn(e);
        this.isSelectingFrts = false;
      });
    }
  }
  // tslint:disable-next-line:typedef
  valueChange(newValue){
    this.total = newValue;

    this.cd.detectChanges();

    const isValid = this.isValidValue(newValue);
    if (!isValid) {
      // Restore old val
      this.amount = this.oldVal;
    }
    else {
      // @ts-ignore
      this.get_total = Number(this.total).toLocaleString();
      try {
        const amount = Amount.fromPlanck(this.amount);
        // tslint:disable-next-line:max-line-length
        this.key = Amount.fromPlanck(this.amount).getRaw().multipliedBy(Amount.fromPlanck(this.priceCurrency).getRaw()).toFixed(4);
        if (Number(newValue) < 0 || isNaN(newValue)) {
          return this.changeFrts = '0';
        }
        this.changeFrts = Number(this.key).toLocaleString('fullwide', { useGrouping: false });
      } catch (e) {
        this.key = '0';
        return this.changeFrts = '0';
      }
      this.oldVal = newValue;
    }
  }
  private isValidValue(value: any): boolean {
    const dotIndex = this.total.indexOf('.');
    let isValidValue = false;
    if (dotIndex === -1 && this.total.length > 16) {
      isValidValue = false;
    }
    else {
      const array = value.split('.');
      let maxDecimalLength;
      switch (this.symbol) {
        case 'FRTS':
        case 'BTC':
        default:
          maxDecimalLength = constants.MAX_LENGTH_FRTS;
          break;
        case 'ETH':
          maxDecimalLength = constants.MAX_LENGTH_ETH;
          break;
      }
      isValidValue = !(`${array[0] || ''}`.length > 16 || `${array[1] || ''}`.length > maxDecimalLength) && array.length <= 2;
    }
    return isValidValue;
  }
  onSubmit(event): void {
    event.stopImmediatePropagation();

    const nonValidAccounts = this.getNonValidAccounts();
    if (nonValidAccounts.length > 0) {
      const dialogRef = this.openWarningDialog(nonValidAccounts);
      dialogRef.afterClosed().subscribe(ok => {
        if (ok) {
          this.sendBurst();
        }
      });
    } else {
      this.sendBurst();
    }
  }

  private async sendBurstSameAmount(): Promise<TransactionId> {
    const fee = Amount.fromSigna(this.fee || 0);
    const amount = Amount.fromSigna(this.amount || 0);

    const request = {
      recipientIds: this.recipients.map(r => Address.fromReedSolomonAddress(r.addressRS).getNumericId()),
      fee: fee.getPlanck(),
      amountNQT: amount.getPlanck(),
      pin: this.pin,
      keys: this.account.keys,
      deadline: parseFloat(this.deadline) * 60
    };
    return await this.transactionService.sendSameAmountToMultipleRecipients(request);
  }

  private async sendBurstArbitraryAmount(): Promise<TransactionId> {

    const fee = Amount.fromSigna(this.fee || 0);

    const request = {
      recipientAmounts: this.recipients.map(r => ({
        recipient: Address.fromReedSolomonAddress(r.addressRS).getNumericId(),
        amountNQT: Amount.fromSigna(r.amount || 0).getPlanck(),
      })),
      fee: fee.getPlanck(),
      pin: this.pin,
      keys: this.account.keys,
      deadline: parseFloat(this.deadline) * 60
    };
    return await this.transactionService.sendAmountToMultipleRecipients(request);
  }


  private async sendBurst(): Promise<void> {

    if (!this.nonEmptyRecipients().length) {
      return;
    }

    this.isSending = true;
    try {
      if (this.sameAmount) {
        const transaction: TransactionId = await this.sendBurstSameAmount();
      } else {
        const transaction: TransactionId = await this.sendBurstArbitraryAmount();
      }
      this.sendBurstForm.resetForm();
      this.resetRecipients();
      this.notifierService.notify('success', this.i18nService.getTranslation('success_send_money'));
      await this.router.navigate(['/frts-transactions']);
    } catch (e) {
      if (isKeyDecryptionError(e)) {
        this.notifierService.notify('error', this.i18nService.getTranslation('wrong_pin'));
      } else {
        this.notifierService.notify('error', this.i18nService.getTranslation('error_send_money'));
      }
    }
    this.isSending = false;
  }

  private openWarningDialog(recipients: Array<Recipient>): MatDialogRef<any> {
    const width = this.breakpointObserver.isMatched(Breakpoints.Handset) ? '90%' : '50%';
    return this.warnDialog.open(WarnSendDialogComponent, {
      width,
      data: recipients
    });
  }

  private openBatchRecipientsDialog(): MatDialogRef<any> {
    const width = this.breakpointObserver.isMatched(Breakpoints.Handset) ? '90%' : '50%';
    return this.batchRecipientsDialog.open(BatchRecipientsDialogComponent, { width });
  }

  trackByIndex(index): number {
    return index;
  }

  addRecipient(event): void {
    this.recipients.push(new Recipient());
    // event.stopImmediatePropagation();
    event.preventDefault();
    this.checkRecipient();
  }

  clearRecipients(): void {
    this.recipients = [];
  }

  addBatchedRecipient(event: MouseEvent): void {
    event.stopImmediatePropagation();
    event.preventDefault();
    this.openBatchRecipientsDialog()
      .afterClosed()
      .pipe(
        filter(recipientAmounts => recipientAmounts && recipientAmounts.length > 0)
      )
      .subscribe(this.handleBatchRecipients.bind(this));
  }

  private getTotalForMultiOut(): Amount {
    return this.nonEmptyRecipients()
      .filter(recipient => Number(recipient.amount))
      .map(({ amount }) => Amount.fromSigna(amount || 0))
      .reduce((acc, curr) => acc.add(curr), Amount.Zero());
  }

  private getTotalForSameAmount(): Amount {
    return Amount.fromSigna(this.amount || 0).multiply(this.recipients.length);
  }

  getTotal(): Amount {
    const total = this.sameAmount ? this.getTotalForSameAmount() : this.getTotalForMultiOut();
    return total.add(Amount.fromSigna(this.fee || 0));
  }

  private nonEmptyRecipients(): Array<Recipient> {
    return this.recipients.filter(
      r => r.amount !== '' || r.addressRS !== ''
    );
  }

  getNonValidAccounts(): Array<Recipient> {
    return this.nonEmptyRecipients().filter(({ status }) => status !== 'valid');
  }

  hasSufficientBalance(): boolean {
    const available = this.balances.availableBalance.clone();
    const total = this.getTotal();

    return available.subtract(total).greaterOrEqual(Amount.Zero());
  }


  canSubmit(): boolean {

    const deadline = Number(this.deadline);
    const checkValid = (deadline && deadline >= 0 && deadline <= 24) || deadline === 0;

    const nonEmptyRecipients = this.nonEmptyRecipients();

    if (nonEmptyRecipients.length < 2) {
      return false;
    }

    if (this.hasRecipientsExceeded()) {
      return false;
    }

    const unknownRecipients = this.recipients.filter(r => r.status === RecipientValidationStatus.UNKNOWN);
    if (unknownRecipients.length > 0) {
      return false;
    }

    const hasCompletedRecipients = nonEmptyRecipients
      .reduce(
        (isComplete, recipient) => isComplete
          && (!this.sameAmount ? recipient.amount && recipient.amount.length > 0 : true)
        , true);

    return hasCompletedRecipients
      && this.hasSufficientBalance()
      && isNotEmpty(this.pin)
      && (this.sameAmount ? isNotEmpty(this.amount) : true)
      && this.deadline && checkValid;

  }

  onRecipientChange(recipient: Recipient, i: number): void {
    const amount = this.recipients[i].amount;
    this.recipients[i] = {
      ...recipient,
      amount
    };
    this.checkRecipient();
  }

  checkRecipient(): void {
    // if (this.recipients.every(r => r.addressRaw && this.freeAccounts.map(a => a.toString()).includes(Address.fromReedSolomonAddress(r.addressRS).getNumericId()))) {
    //   this.fee = '0';
    //   this.isFree = true;
    // } else {
    //   this.fee = Amount.fromPlanck(this.fees.standard.toString(10)).getSigna();
    //   this.isFree = false;
    // }
  }

  private handleBatchRecipients(recipientAmounts: MultioutRecipientAmount[]): void {

    let previousAmount = null;
    let isSameAmount = true;

    const prefix = this.networkService.isMainNet() ? AddressPrefix.MainNet : AddressPrefix.TestNet;

    this.recipients = recipientAmounts.map(ra => {
      const r = new Recipient();
      r.amount = Amount.fromPlanck(ra.amountNQT).getSigna();
      r.addressRaw = ra.recipient;
      r.addressRS = Address.fromNumericId(ra.recipient, prefix).getReedSolomonAddress();

      if (previousAmount) {
        isSameAmount = isSameAmount && (previousAmount === r.amount);
      }
      previousAmount = r.amount;
      return r;
    });

    if (isSameAmount) {
      this.sameAmount = isSameAmount;
      this.amount = previousAmount;
    }
  }

  resetRecipients(): void {
    this.clearRecipients();
    this.recipients.push(new Recipient());
  }

  private getMaxAllowedRecipients(): number {
    return this.sameAmount ? constants.maxRecipientsSameMultiout : constants.maxRecipientsMultiout;
  }

  hasRecipientsExceeded(): boolean {
    return this.nonEmptyRecipients().length > this.getMaxAllowedRecipients();
  }

  getRecipientCounter(): string {
    return `${this.nonEmptyRecipients().length}/${this.getMaxAllowedRecipients()} ${this.i18nService.getTranslation('recipients')}`;
  }

  isLastRecipientItem(index: number): boolean {
    return this.recipients.length - 1 === index;
  }

  removeRecipientItem(index: number): void {
    if (this.recipients.length > 1) {
      this.recipients.splice(index, 1);
    }
    this.checkRecipient();
  }
}
