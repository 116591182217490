const keyMap = {
  baseTarget: 'base_target',
  block: 'block_id',
  blockReward: 'reward',
  blockSignature: 'block_signature',
  generator: 'generator_id',
  generatorRS: 'grower',
  generationSignature: 'generation_signature',
  generatorPublicKey: 'generator_public_key',
  height: 'block_height',
  nextBlock: 'next_block',
  nonce: 'nonce',
  numberOfTransactions: 'number_transactions',
  payloadHash: 'payload_hash',
  payloadLength: 'payload_length',
  previousBlock: 'previous_block',
  previousBlockHash: 'previous_block_hash',
  requestProcessingTime: 'request_processing_time',
  scoopNum: 'scoop_num',
  timestamp: 'timestamp',
  totalAmountNQT: 'frts_amount',
  totalFeeNQT: 'fee',
  transactions: 'transactions',
  version: 'version',
  averageCommitmentNQT: "averange_commitment_nqt",
  blockRewardNQT: "block_reward_nqt"
};

export function getBlockFieldTranslationKey(key: string): string {
  return keyMap[key] || key;
}
