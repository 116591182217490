import { MatPaginatorIntl } from '@angular/material';
import { I18nService } from './layout/components/i18n/i18n.service';

export class PaginatorI18n {

  i18n;

  constructor(private i18nService: I18nService) {
    this.i18n = i18nService;
  }

  private getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      return '';
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${this.i18n.getTranslation('page_of')} ${length}`;
  }

  getPaginatorIntl(): MatPaginatorIntl {
    const paginatorIntl = new MatPaginatorIntl();

    paginatorIntl.itemsPerPageLabel = this.i18nService.getTranslation('items_per_page');
    paginatorIntl.nextPageLabel = this.i18nService.getTranslation('next_page');
    paginatorIntl.previousPageLabel = this.i18nService.getTranslation('previous_page');
    paginatorIntl.firstPageLabel = this.i18nService.getTranslation('first_page');
    paginatorIntl.lastPageLabel = this.i18nService.getTranslation('last_page');
    paginatorIntl.getRangeLabel = this.getRangeLabel.bind(this);

    return paginatorIntl;
  }
}
