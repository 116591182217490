<div class="p-24 dialog">
  <div mat-dialog-content>

    <mat-horizontal-stepper #stepper
                            linear
                            [linear]="true"
                            [selectedIndex]="stepIndex"
                            labelPosition="bottom"
    >
      <mat-step>
        <ng-template matStepLabel>{{ 'transfer' | i18n }}</ng-template>
        <div class="step-container">
          <h1 class="step-header">{{ 'transfer_multi_coin_1' | i18n }}</h1>
          <div class="text-container" style="text-align: center;">
            <div>{{ 'transfer_multi_coin_q' | i18n }}</div>
            <div style="margin: 10px 0;">{{ account.accountRS }}</div>
            <div>{{ 'transfer_multi_coin_q_1' | i18n }}</div>
          </div>
          <div class="transfer-warning">
            <img src="assets/icons/mobile/ErrorRed.svg" alt=""/>
            <span style="color: #FA0202; margin-left: 5px;">{{ 'transfer_account_warning' | i18n }}</span>
          </div>
          <mat-form-field style="width: 100%;" class="password-field" floatLabel="always" appearance="outline">
            <mat-label>{{ 'enter_pin' | i18n }}<span style="color:red"> *</span></mat-label>
            <input type="password" matInput placeholder="{{'enter_pin' | i18n}}" [(ngModel)]="pin" name="pin">
          </mat-form-field>
          <div class="btn-container">
            <button class="{{!isLoading ? 'btn-common' : 'btn-common-disable'}}" [disabled]="isLoading" [mat-dialog-close]="false">{{ 'cancel' | i18n }}</button>
            <button class="{{canSubmit() && !isLoading ? 'btn-common' : 'btn-common-disable'}}" [disabled]="!canSubmit() || isLoading" (click)="transfer();">
              {{ 'confirm' | i18n }}
              <mat-icon *ngIf="isLoading" style="vertical-align: middle;">
                <mat-spinner diameter="20"></mat-spinner>
              </mat-icon>
            </button>
          </div>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>{{ 'complete' | i18n }}</ng-template>
        <div *ngIf="newAccount" class="step-container">
          <h1 class="step-header">{{ 'transfer_complete' | i18n }}</h1>
          <div class="new-account-info">
            <div class="info-title">{{ 'new_address' | i18n }}</div>
            <div class="info-detail">
              {{ newAccount.accountRS }}
              <span>
                <button class="copy-button" matTooltip="{{'click_to_copy_address' | i18n}}" (click)="copy(newAccount.accountRS)">
                  <mat-icon>file_copy</mat-icon>
                </button>
              </span>
            </div>
          </div>
          <div class="new-account-info">
            <div class="info-title">{{ 'new_passphrase' | i18n }}</div>
            <div class="info-detail">
              {{ passphrase }}
              <span>
                <button class="copy-button" matTooltip="{{'click_to_copy_passphrase' | i18n}}" (click)="copy(passphrase)">
                  <mat-icon>file_copy</mat-icon>
                </button>
              </span>
            </div>
          </div>
          <div class="new_wallet_hint"><span class="hint-text">{{ 'new_wallet_hint' | i18n }}</span></div>

          <div class="btn-container">
            <button class="btn-common" [mat-dialog-close]="false">{{ 'stay_current_account' | i18n }}</button>
            <button class="btn-common" (click)="login();">{{ 'login_new_account' | i18n }}</button>
          </div>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
  <div mat-dialog-actions>
  </div>
</div>
