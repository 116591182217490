import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Account} from '@fruitsjs/core';
import {NgForm} from '@angular/forms';
import {TransactionService} from 'app/main/transactions/transaction.service';
import {NotifierService} from 'angular-notifier';
import {I18nService} from 'app/layout/components/i18n/i18n.service';
import {StoreService} from '../../../../store/store.service';
import {takeUntil} from 'rxjs/operators';
import {UnsubscribeOnDestroy} from '../../../../util/UnsubscribeOnDestroy';
import {BTCSymbol} from '@fruitsjs/util/src';
import {Amount} from '@fruitsjs/util';
import {AccountService} from '../../../../setup/account/account.service';
import {ActivatedRoute, Router} from '@angular/router';
import {constants} from '../../../../constants';
import jsQR from 'jsqr';
import {BroadcastData, BtcService} from '../../../../multi-coin/btc.service';
import {environment} from 'environments/environment';
import {TimeCreatedService} from '../../../../multi-coin/time-created.service';
import {IOrderDetail, PaymentGatewayService} from '../../payment-gateway.service';
import {formatBalance} from '../../../../util/formatAmount';
import {HttpClient, HttpParams} from '@angular/common/http';
import {NotificationService} from '../../../notification.service';
import {MatDialog} from '@angular/material/dialog';
import {
  WarnSendFromPaymentGatewayDialogComponent
} from '../../warn-send-from-payment-gateway-dialog/warn-send-from-payment-gateway-dialog.component';
import {Settings} from '../../../../settings';
import {IUnlockMultipleToken} from '../../../../payment-gateway/nft.service';
import {CoinType} from '../../../../shared/pipes/coin-amount.pipe';
import {decryptAES, hashSHA256} from '@fruitsjs/crypto/src';

const isNotEmpty = (value: string) => value && value.length > 0;
let nextId = 0;

@Component({
  selector: 'app-send-btc-form',
  templateUrl: './send-btc-form.component.html',
  styleUrls: ['./send-btc-form.component.scss']
})
export class SendBtcFormComponent extends UnsubscribeOnDestroy implements OnInit {

  @ViewChild('sendBurstForm', {static: true}) public sendBurstForm: NgForm;
  @ViewChild('gas', {static: true}) public gas: any;
  @ViewChild('file', {static: false}) file: ElementRef;
  @ViewChild('pin', {static: true}) public pin: string;

  @Input() account: Account;

  public unSubscriber = takeUntil(this.unsubscribeAll);

  public recipientAddress: string;
  fileId = `file-${nextId++}`;
  amount;
  isSending = false;
  isGettingData = false;
  language: string;
  utxos;
  transactionFee;
  public balances = '0';
  symbol = BTCSymbol;
  disableInputField = false;
  paymentId: string;
  settings: Settings;
  requestCurrency = constants.requestCurrency;
  currencyListBtc;
  currencySelectedBtc;
  currencyListCopy;
  priceBtc;
  isSelectingBtc = false;
  priceCurrency;
  changeBtc;
  key;
  total;
  decimals = constants.MAX_LENGTH_FRTS;
  node: any;
  tokenName;
  customize = false;
  input: any;
  output: any;
  data: any;
  satsByte = '0';
  btcFee = [
    {
      index: 0,
      priority: 'Low',
      key: 'low',
      value: 0
    },
    {
      index: 1,
      priority: 'Medium',
      key: 'medium',
      value: 0
    },
    {
      index: 2,
      priority: 'High',
      key: 'high',
      value: 0
    },
    {
      index: 3,
      priority: 'Customize',
      key: 'customize',
      value: 0
    }
  ];
  selectedOption = this.btcFee[1];


  constructor(private transactionService: TransactionService,
              private i18nService: I18nService,
              private storeService: StoreService,
              private btcService: BtcService,
              private accountService: AccountService,
              private router: Router,
              private notifierService: NotifierService,
              private timeCreatedService: TimeCreatedService,
              private gatewayService: PaymentGatewayService,
              private route: ActivatedRoute,
              private notificationService: NotificationService,
              private warnDialog: MatDialog,
              private http: HttpClient) {
    super();
    this.storeService.settings
      .pipe(
        takeUntil(this.unsubscribeAll)
      )
      .subscribe(async ({language}) => {
        this.language = language;
      });
    this.storeService.getSettings().then((settings: any) => {
      this.settings = settings;
    });
    this.storeService.getSettings().then((result: any) => {
      if (result && result.node) {
        this.node = result.node;
      }
    });
  }

  ngOnInit(): void {
    if (!this.account.multiWallet) {
      this.router.navigate(['/dashboard']);
      this.warnDialog.open(WarnSendFromPaymentGatewayDialogComponent, {
        width: '500px'
      });
    }

    if (this.route.snapshot.queryParams) {
      setTimeout(() => {
        this.paymentId = this.route.snapshot.queryParams.paymentId;
        this.getPaymentOrder(this.paymentId);
      }, 500);
    }
    this.currencyListBtc = constants.currencies.map(currency => this.requestCurrency.includes(currency.code) ? currency : undefined).filter(currency => currency !== undefined);
    this.currencySelectedBtc = this.settings && this.settings.currency && this.settings.currency.btc
      ? this.currencyListBtc.filter(item => item.code === this.settings.currency.btc)[0] : this.currencyListBtc[0];
    this.currencyListCopy = this.currencyListBtc;
    this.getPriceBtc();
    this.getData();
  }

  async getPriceBtc(): Promise<void> {
    if (this.currencySelectedBtc) {
      const params = new HttpParams()
        .set('source', BTCSymbol)
        .set('destination', this.currencySelectedBtc.code);
      this.http.get(constants.coinMarketCapURL + '/fruits/converter/api', {
        params: params
      }).subscribe((response: any) => {
        if (response && response.errorCode === 0) {
          this.priceBtc = response.result;
          this.priceCurrency = this.priceBtc.priceSelectedCurrency;
        }
        this.isSelectingBtc = false;
      }, (e) => {
        console.warn(e);
        this.isSelectingBtc = false;
      });
    }
  }

  valueChange(newValue): void {
    if (this.priceCurrency) {
      this.amount = newValue;
      const value = newValue ? (newValue.endsWith('.') ? newValue.replace('.', '') : newValue) : '0';
      this.changeBtc = this.removeTrailingZeros(Amount.fromPlanck(value).getRaw().multipliedBy(Amount.fromPlanck(this.priceCurrency).getRaw()).toFixed(4));
    }
  }

  public removeTrailingZeros(s: string): string {
    return s.replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1');
  }

  async getData(): Promise<void> {
    this.isGettingData = true;

    if (this.account.multiKeys) {
      this.balances = this.account.multiKeys.btc.balance;
      this.btcService.getUnspentTransactions(this.account.multiKeys.btc.address).then(async (result) => {
        this.utxos = result;
        this.data = await this.btcService.calculateBTCFee(result);
        this.btcFee.forEach(i => {
          if (i.key === 'low') {
            i.value = Number(this.data.low);
          }
          if (i.key === 'medium') {
            i.value = Number(this.data.medium);
            this.transactionFee = i.value;
          }
          if (i.key === 'high') {
            i.value = Number(this.data.high);
          }
        });
      });
    }
    this.isGettingData = false;
  }

  getTotal(): any {
    const isValidAmount = Number(this.amount);
    return (isValidAmount && this.transactionFee
      ? Amount.fromPlanck(isValidAmount).getRaw().plus(Amount.fromPlanck(this.transactionFee).getRaw().toString())
      : (isValidAmount ? isValidAmount : (this.transactionFee ? this.transactionFee : '0')));
  }

  async onSubmit(event): Promise<void> {
    event.stopImmediatePropagation();

    const validateForm = this.validateForm();
    if (validateForm) {
      this.notifierService.notify('error', validateForm);
      return;
    }

    const privateKey = decryptAES(this.account.keys.signPrivateKey, hashSHA256(this.pin));
    if (!privateKey) {
      this.notifierService.notify('error', this.i18nService.getTranslation('wrong_pin'));
      return;
    }

    try {
      const checkTime = await this.timeCreatedService.checkTimeCreated(this.account.multiKeys.btc.address);
      await this.timeCreatedService.updateTimeCreated(this.account.multiKeys.btc.address);
      if (!checkTime.result) {
        this.notifierService.notify('error', this.i18nService.getTranslation('error_time_created'));
        return;
      }

      // Check payment gateway
      let order;
      let lockData;
      let unlockData: IUnlockMultipleToken;
      if (this.paymentId) {
        const paymentOrder = await this.gatewayService.isValidNFTOrder(
          this.paymentId,
          this.account.keys.publicKey,
          null,
          this.account.account,
          this.node
        );

        if (!paymentOrder) {
          return;
        }

        if (paymentOrder && !paymentOrder.isDonate) {
          if (paymentOrder && paymentOrder.orderDetail) {
            order = paymentOrder.orderDetail.data;
            this.tokenName = paymentOrder.nftName;
          }

          if (order.multiple) {
            lockData = paymentOrder.lock;
            unlockData = {
              nftName: this.tokenName,
              message: this.account.account,
              signature: lockData.signature,
              lockId: lockData.lockId,
              seller: this.account.account,
              buyer: order.nftName,
              transaction: null
            };
          }
        }
      }

      this.isSending = true;
      const data = {
        privateKey: this.account.multiKeys.btc.privateKey,
        address: this.account.multiKeys.btc.address,
        amount: Number(this.amount),
        recipient: this.recipientAddress,
        utxos: this.utxos,
        fee: this.transactionFee
      };
      this.btcService.createTransaction(data.privateKey, data.address, data.amount, data.recipient, data.utxos, data.fee).then(async result => {
        const broadcastData: BroadcastData = {
          txhex: result.txhex,
          data: {
            from: data.address,
            to: data.recipient,
            amount: data.amount.toString(),
            fee: data.fee,
            coinType: CoinType.btc
          }
        };
        const transaction = await this.btcService.broadcastTransactionWithData(broadcastData);
        if (transaction && transaction.data && transaction.data.item && transaction.data.item.transactionId) {
          this.notifierService.notify('success', this.i18nService.getTranslation('send_coin_success').split('__coin__').join('BTC'));
          this.isSending = false;

          if (this.paymentId) {
            this.gatewayService.updateTransactionId(
              this.paymentId, transaction.data.item.transactionId, this.account.accountRS,
              order && order.multiple ? unlockData.lockId : null
            ).subscribe({
              next: (res: any) => {
                if (res.code === 0) {
                  const url = `${environment.isMainNet ? constants.paymentStatusMainnet : constants.paymentStatusTestnet}/#/buyer/transaction-status/${this.paymentId}`;
                  window.location.href = url;
                } else {
                  this.notifierService.notify('error', this.i18nService.getTranslation('payment_connect_error'));
                }
              },
              error: () => {
                this.notifierService.notify('error', this.i18nService.getTranslation('payment_connect_error'));
              }
            });

          } else {
            await this.router.navigate(['/btc-transactions']);
          }

        } else {
          // if transaction fail, check and unlock nft
          this.unlockToken(unlockData, order.multiple);
          this.notifierService.notify('error', this.i18nService.getTranslation('error_send_btc'));
        }
        this.isSending = false;
      }).catch(() => {
        // if transaction fail, check and unlock nft
        this.unlockToken(unlockData, order.multiple);
        this.isSending = false;
        this.notifierService.notify('error', this.i18nService.getTranslation('error_send_btc'));
      });
    } catch (e) {
      this.isSending = false;
      this.notifierService.notify('error', this.i18nService.getTranslation('error_send_btc'));
    }
  }

  async unlockToken(unlockData: any, isErc1155: boolean): Promise<void> {
    if (this.tokenName) {
      try {
        if (isErc1155) {
          await this.gatewayService.unlockMultipleToken(unlockData);
        } else {
          await this.gatewayService.unlockNft(this.tokenName).toPromise();
        }
      } catch (ex) {
      }
    }
  }

  hasSufficientBalance(): boolean {
    return Amount.fromPlanck(this.getTotal()).getRaw().isLessThanOrEqualTo(Amount.fromPlanck(this.balances).getRaw());
  }

  canSubmit(): boolean {
    const amount = this.amount && this.amount.endsWith('.') ? this.amount.replace('.', '') : this.amount;
    return isNotEmpty(this.recipientAddress)
      && isNotEmpty(amount)
      && isNotEmpty(this.pin)
      && !Amount.fromPlanck(amount).getRaw().eq(0)
      && this.hasSufficientBalance()
      && this.transactionFee !== 0;
  }

  validateForm(): string {
    if (!this.btcService.isValidAddress(this.recipientAddress)) {
      return this.i18nService.getTranslation('invalid_eth_address');
    }

    const isValidAmount = Number(this.amount);
    if (!isValidAmount && isValidAmount !== 0) {
      return this.i18nService.getTranslation('invalid_eth_amount');
    }
    if (isValidAmount < 0) {
      return this.i18nService.getTranslation('invalid_eth_amount');
    }

    try {
      if (Amount.fromPlanck(this.amount).getRaw().isLessThan(Amount.fromPlanck(constants.btcDust).getRaw())) {
        return this.i18nService.getTranslation('invalid_btc_dust');
      }
    } catch (e) {
      return this.i18nService.getTranslation('invalid_eth_amount');
    }


    const checkDecimals = this.amount.indexOf('.');
    if (checkDecimals !== -1) {
      const decimals = this.amount.substr(this.amount.indexOf('.') + 1);
      if (decimals.length > 8) {
        return this.i18nService.getTranslation('error_price_8');
      }
    }

    return '';
  }

  resetFile(event): void {
    event.target.value = null;
  }

  parseQR(): void {
    const file = this.file.nativeElement.files[0];
    if (!file) {
      return;
    }

    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {

      const width = img.naturalWidth,
        height = img.naturalHeight;

      const reader = new FileReader();
      reader.onload = (e: ProgressEvent): void => {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        let qrCodeImageFormat = ctx.getImageData(0, 0, width, height);
        let qr = jsQR(qrCodeImageFormat.data, qrCodeImageFormat.width, qrCodeImageFormat.height);
        if (!qr) {
          ctx.drawImage(img, 10, 10, width, height);
          qrCodeImageFormat = ctx.getImageData(0, 0, width, height);
          qr = jsQR(qrCodeImageFormat.data, qrCodeImageFormat.width, qrCodeImageFormat.height);
        }
        if (qr) {
          const urlText = qr.data;
          if (!urlText.startsWith('btc')) {
            if (!this.btcService.isValidAddress(urlText)) {
              this.notifierService.notify('error', this.i18nService.getTranslation('invalid_eth_address'));
              return;
            } else {
              this.recipientAddress = urlText;
              this.amount = '0';
              this.notifierService.notify('success', this.i18nService.getTranslation('qr_parse_success'));
            }

            // this.notifierService.notify('error', this.i18nService.getTranslation('error_qr_format'));
            // return;
          } else {
            this.recipientAddress = urlText.substring(urlText.indexOf(':') + 1, urlText.lastIndexOf('?'));
            const paramsObj = new URLSearchParams(urlText.substring(urlText.lastIndexOf('?') + 1));
            const amount = paramsObj.get('amount');
            if (paramsObj.get('paymentId')) {
              this.paymentId = paramsObj.get('paymentId');
              this.disableInputField = true;
            }
            this.amount = amount;
            this.notifierService.notify('success', this.i18nService.getTranslation('qr_parse_success'));
          }
        } else {
          this.notifierService.notify('error', this.i18nService.getTranslation('qr_parse_fail'));
        }
      };

      window.URL.revokeObjectURL(img.src);
      reader.readAsDataURL(file);
    };
  }

  getPaymentOrder(paymentId: string): void {
    if (!paymentId) {
      return;
    }
    this.gatewayService.getPaymentOrder(paymentId).subscribe(
      (res: any) => {
        if (res && res.code === 0) {

          const data: IOrderDetail = res.data;
          this.amount = formatBalance(data.remaining + '', 'BTC');
          this.recipientAddress = data.address;
          this.disableInputField = true;

        }
      },
      (error: any) => {
        this.notifierService.notify('error', this.i18nService.getTranslation('error_unknown'));
      }
    );
  }

  onFeeChanged(e): any {
    this.satsByte = '0';
    if (e.key === 'customize') {
      this.customize = true;
    } else {
      this.customize = false;
    }
    this.transactionFee = this.btcFee.find(item => {
      return item.key === e.key;
    }).value;
  }

  onChangeSatsByte(value): any {
    value = value.replace(/[^0-9]/g, ''); // keep number
    const parts = value.split('.');
    if (parts.length > 2) {
      parts.splice(2);
    }
    // limit number of integers
    if (parts[0].length > 16) {
      parts[0] = parts[0].substring(0, 16);
    }

    this.satsByte = parts.join('.');
    this.transactionFee = this.btcService.calculateBTCFeeCustomize(this.utxos, value);
  }
}

export interface IBTCFee {
  low: any;
  medium: any;
  high: any;
}
