import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {constants} from '../constants';
import {Observable} from 'rxjs';
import {StoreService} from '../store/store.service';

@Injectable({
  providedIn: 'root'
})
export class SwapService {

  marketUrl = environment.isMainNet ? constants.MARKET_MAIN : constants.MARKET_TEST;
  public node;

  constructor(private http: HttpClient,
              private storeService: StoreService) {
    this.storeService.getSettings().then((result: any) => {
      if (result && result.node) {
        this.node = result.node;
      }
    });
  }


  public getTFSTsHistory(params: HttpParams): Observable<any> {
    return this.http.get(this.marketUrl + '/fruits/swap/history', {params: params});
  }

  public getExternalCoinHistory(data: any): Observable<any> {
    return this.http.post(this.marketUrl + '/fruits/swap/external-coin-history', data);
  }

  public cancelOrderExternalCoin(data: any): Observable<any> {
    return this.http.post(this.marketUrl + '/fruits/swap/update-status', data);
  }

  public cancelOrder(data: FormData): Observable<any> {
    return this.http.post(this.marketUrl + '/fruits/swap/cancel', data);
  }

  cancelAskOrder = async (data) => {
    const response = await fetch(`${this.node}/fruits?requestType=cancelAskOrder&order=${data.order}&publicKey=${data.publicKey}&feeNQT=${data.fee}&deadline=${data.deadline}`, {
      method: 'POST'
    });
    return await response.json();
  }

  cancelBidOrder = async (data) => {
    const response = await fetch(`${this.node}/fruits?requestType=cancelBidOrder&order=${data.order}&publicKey=${data.publicKey}&feeNQT=${data.fee}&deadline=${data.deadline}`, {
      method: 'POST'
    });
    return await response.json();
  }

  getSwapOrderDetails = async (id) => {
    const response = await fetch(`${this.marketUrl}/fruits/swap/external-coin-history/${id}`, {
      method: 'GET'
    });
    return await response.json();
  }

    public getTFSTsSwapHistory(data: any): Observable<any> {
    return this.http.post(this.marketUrl + '/fruits/swap/tfsts/history', data);
  }

  getTFSTsOrderDetails = async (id, account) => {
    const response = await fetch(`${this.marketUrl}/fruits/swap/tfsts/history/${id}?account=${account}`, {
      method: 'GET'
    });
    return await response.json();
  }
}
