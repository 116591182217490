import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CurrencySymbol } from '@fruitsjs/util';
import {decryptAES, hashSHA256} from '@fruitsjs/crypto';
import {I18nService} from '../../../../layout/components/i18n/i18n.service';
import {NotifierService} from 'angular-notifier';

@Component({
  selector: 'cancel-order',
  templateUrl: 'cancel-tfsts-order.component.html',
  styleUrls: ['cancel-tfsts-order.component.scss']
})
export class CancelTfstsOrderComponent {

  pin: string;
  symbol = CurrencySymbol;
  fee = '0';

  constructor(public dialogRef: MatDialogRef<CancelTfstsOrderComponent>,
              private i18nService: I18nService,
              private notifierService: NotifierService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  canSubmit(): boolean {
    return true;
  }

  onSubmit(): void {
    const privateKey = decryptAES(this.data.account.keys.signPrivateKey, hashSHA256(this.pin));
    if (!privateKey) {
      this.notifierService.notify('error', this.i18nService.getTranslation('wrong_pin'));
      return;
    }

    this.dialogRef.close({
      pin: this.pin,
    });
  }
}
