import {AfterContentInit, Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { fuseAnimations } from '@fuse/animations';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { StoreService } from 'app/store/store.service';
import { Account, Transaction } from '@fruitsjs/core';
import { AccountService } from 'app/setup/account/account.service';
import { MatTableDataSource } from '@angular/material/table';
import { MarketService } from './market/market.service';
import { Settings } from 'app/settings';
import { UnsubscribeOnDestroy } from '../../util/UnsubscribeOnDestroy';
import { NotifierService } from 'angular-notifier';
import { MatGridList } from '@angular/material/grid-list';
import { DashboardGridAttributes, DashboardGridSettings } from './DashboardGridSettings';
import {BalanceChartComponent} from './balance-chart/balance-chart.component';
import {AppComponent} from '../../app.component';
import {Title} from '@angular/platform-browser';
import {constants} from '../../constants';

const GridSettings = new DashboardGridSettings();

@Component({
  selector: 'dashboard-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class DashboardComponent extends UnsubscribeOnDestroy implements OnInit, AfterContentInit {
  @ViewChild('gridList', { static: true }) gridList: MatGridList;
  @ViewChild('appBalanceChart', {static: false}) appBalanceChart: BalanceChartComponent;
  widgets: any;
  account: Account;
  settings: Settings;
  currency: any;
  isLoading = false;

  public dataSource: MatTableDataSource<Transaction>;
  public isActivating = false;

  columnCount: number;
  gridAttributes: DashboardGridAttributes = GridSettings.xl;
  unsubscriber = takeUntil(this.unsubscribeAll);

  constructor(private router: Router,
              private storeService: StoreService,
              private accountService: AccountService,
              private notificationService: NotifierService,
              private marketService: MarketService,
              private observableMedia: MediaObserver,
              private title: Title) {
    super();
    this.title.setTitle(constants.pageTitle + 'Dashboard');
  }

  ngOnInit(): void {

    this.storeService.settings
      .pipe(this.unsubscriber)
      .subscribe((settings: Settings) => {
        this.settings = settings;
      });

    // this.accountService.getCurrentAccount().then(async (account) => {
    //   await this.setTransactions(account);
    //   this.fetchData = setInterval(async () => {
    //     await this.setTransactions(account);
    //   }, 60000);
    // });

    this.accountService.currentAccount
      .pipe(this.unsubscriber)
      .subscribe(this.setTransactions);

    this.accountService.accountSubject
      .pipe(this.unsubscriber)
      .subscribe((account: Account) => {
      this.account = account;
    });
  }

  ngAfterContentInit(): void {
    this.observableMedia.asObservable()
      .pipe(this.unsubscriber)
      .subscribe((change: MediaChange[]) => {
        this.gridAttributes = GridSettings[change[0].mqAlias];
      });
  }

  async synchronize(): Promise<void> {
    this.isLoading = true;
    await this.accountService.synchronizeAccount(this.account);
    this.isLoading = false;
    await this.setTransactions(this.account);
    this.appBalanceChart.getBalance();
    this.appBalanceChart.updateChart();
  }

  setTransactions = async (account) => {
    this.account = account;
    this.dataSource = new MatTableDataSource<Transaction>();
    this.dataSource.data = account.transactions.concat().splice(0, 10);
    const uniqueValuesSet = new Set();
    this.dataSource.data = this.dataSource.data.filter(item => {
      const isPresentInSet = uniqueValuesSet.has(item.transaction);
      uniqueValuesSet.add(item.transaction);
      return !isPresentInSet;
    });
  }

  closeWelcomeNotification = () => {
    this.settings.welcomeMessageHiddenFrom.push(this.account.account);
    this.storeService.saveSettings(this.settings);
  }

  async activateAccount(): Promise<void> {
    try {
      this.isActivating = true;
      await this.accountService.activateAccount(this.account);
      this.notificationService.notify('success', 'Successfully requested activation. Your account will be activated in a few moments.');
    } catch (e) {
      this.notificationService.notify('error', `Activation failed: ${e.message}`);
    } finally {
      this.isActivating = false;
    }
  }

  marketServiceName(): string {
    return this.marketService.serviceName;
  }
}


