<mat-form-field appearance="outline" floatLabel="always">
  <mat-label>{{ 'amount' | i18n }}</mat-label>
  <input matInput [(ngModel)]="amountValue" name="amount"
         (input)="onSourceInputChange($event, decimals)"
         (ngModelChange)="onAmountInputChange($event)"
         [disabled]="disabled">
  <div matSuffix class="prefix">
    <span>{{symbol}}&nbsp;</span>
    <mat-icon *ngIf="spendAll"
      class="all-icon"
      matTooltip="{{'spend_all' | i18n}}"
      (click)="onSpendAll()"
    >
      all_inclusive
    </mat-icon>
  </div>
</mat-form-field>
