import {Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Account, ChainService, transferAsset} from '@fruitsjs/core';
import {decryptAES, hashSHA256} from '@fruitsjs/crypto';
import {NotifierService} from 'angular-notifier';
import {I18nService} from '../../../layout/components/i18n/i18n.service';
import {StoreService} from '../../../store/store.service';
import {MatStepper} from '@angular/material/stepper';
import * as bip39 from 'bip39';
import {AccountService} from '../../../setup/account/account.service';
import {Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Amount} from '@fruitsjs/util';
import {TransactionService} from '../../transactions/transaction.service';
import {AccountTransferService} from '../../../account-transfer.service';
import {environment} from '../../../../environments/environment';
import {constants} from '../../../constants';

@Component({
  selector: 'account-transfer-dialog',
  templateUrl: './transfer-dialog.component.html',
  styleUrls: ['./transfer-dialog.component.scss']
})
export class TransferDialogComponent {

  @ViewChild(MatStepper, {static: true}) stepper: MatStepper;

  pin: string;
  passphrase: string;
  stepIndex = 0;
  newAccount: Account;
  isLoading = false;
  node;
  currentLocked = '0';
  gtId = environment.isMainNet ? constants.governmentTokenMainnet : constants.governmentTokenTestnet;

  getAskOrders = async (assetId) => {
    const response = await fetch(`${this.node}/fruits?requestType=getAskOrders&asset=${assetId}`);
    return await response.json();
  }

  constructor(public dialogRef: MatDialogRef<TransferDialogComponent>,
              public notificationService: NotifierService,
              public i18nService: I18nService,
              public storeService: StoreService,
              public accountService: AccountService,
              public router: Router,
              public http: HttpClient,
              public transactionService: TransactionService,
              private accountTransferService: AccountTransferService,
              @Inject(MAT_DIALOG_DATA) public account: Account) {
    this.storeService.getSettings().then((result: any) => {
      if (result && result.node) {
        this.node = result.node;
      }
    });
    const params = new HttpParams()
      .set('account', account.account);
    this.accountService.getGTLocked(params).subscribe(response => {
      if (response && response.errorCode === 0) {
        this.currentLocked = response.result;
      }
    }, () => {
      this.currentLocked = '0';
    });
  }

  canSubmit(): boolean {
    return this.pin && this.pin.length >= 1;
  }

  async transfer(): Promise<void> {
    this.isLoading = true;

    const privateKey = decryptAES(this.account.keys.signPrivateKey, hashSHA256(this.pin));
    if (!privateKey) {
      this.notificationService.notify('error', this.i18nService.getTranslation('wrong_pin'));
      this.isLoading = false;
      return;
    }

    const { unconfirmedTransactions } = await this.accountService.getUnconfirmedTransactions(this.account.account);
    if (unconfirmedTransactions && unconfirmedTransactions.length) {
      this.notificationService.notify('error', this.i18nService.getTranslation('old_account_unconfirmed'));
      this.isLoading = false;
      return;
    }

    const settings = await this.storeService.getSettings();
    settings.isMultiWallet = true;
    await this.storeService.saveSettings(settings);

    this.passphrase = bip39.generateMnemonic();
    this.newAccount = await this.accountService.quickCreateActiveAccount({passphrase: this.passphrase, pin: this.pin});

    let count = 0;
    if (this.account.unconfirmedBalanceNQT && this.account.unconfirmedBalanceNQT !== '0') {
      await this.sendFrts(this.newAccount, this.pin).catch(e => {
        this.isLoading = false;
        console.warn(e);
      });
      count++;
    }

    if (this.account.assetBalances && this.account.assetBalances.length) {
      for (const asset of this.account.assetBalances) {
        const { askOrders } = await this.getAskOrders(asset.asset);
        const assetResponse = await this.accountService.getAsset(asset.asset);
        let amountAvailable = this.getAvailableAmount(asset.balanceQNT, askOrders.filter(askOrder => askOrder.account === this.account.account), assetResponse.decimals);
        if (this.isGT(asset)) {
          amountAvailable = Amount.fromPlanck(amountAvailable).getRaw().minus(Amount.fromPlanck(this.currentLocked).getRaw()).toString();
          amountAvailable = Amount.fromPlanck(amountAvailable).getRaw().isLessThan(0) ? '0' : amountAvailable;
        }
        if (amountAvailable !== '0') {
          await this.transferAssets(this.newAccount.account, asset.asset, amountAvailable, privateKey, assetResponse.decimals).catch(e => {
            this.isLoading = false;
            console.warn(e);
          });
          count++;
        }
      }
    }

    if (count === 0) {
      this.notificationService.notify('error', this.i18nService.getTranslation('transfer_balance_error'));
      this.isLoading = false;
      return;
    }

    await this.accountService.synchronizeAccount(this.newAccount);
    this.notificationService.notify('success', this.i18nService.getTranslation('transfer_asset_success'));

    // create account transfer
    const data = new FormData();
    data.append('oldAccount', this.account.account);
    data.append('newAccount', this.newAccount.account);
    this.accountTransferService.createAccountTransfer(data).subscribe(async (response: any) => {
      if (response && response.errorCode === 1) {
        this.notificationService.notify('error', this.i18nService.getTranslation(response.message));
        this.isLoading = false;
      }
    }, () => {});

    this.stepIndex += 1;
    this.isLoading = false;
    this.notificationService.notify('success', this.i18nService.getTranslation('account_added'));
    this.notificationService.notify('success', this.i18nService.getTranslation('transfer_success'));
  }

  public copy(data): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = data;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  async login(): Promise<void> {
    await this.storeService.selectAccount(this.newAccount);
    this.notificationService.notify('success', this.i18nService.getTranslation('account_added'));
    this.dialogRef.close();
    await this.router.navigate(['/']);
  }

  async sendFrts(recipient: Account, pin): Promise<any> {
    try {
      const response = await this.transactionService.sendAmount({
        amount: this.account.unconfirmedBalanceNQT,
        fee: Amount.fromSigna(0).getPlanck(),
        recipientId: recipient.accountRS,
        recipientPublicKey: recipient.keys.publicKey,
        keys: this.account.keys,
        pin: pin,
        messageIsText: true,
      });
      this.notificationService.notify('success', this.i18nService.getTranslation('success_send_money'));
      return response;
    } catch (e) {
      console.warn(e);
      this.notificationService.notify('error', this.i18nService.getTranslation('error_send_money'));
    }
  }

  async transferAssets(recipientId, asset, quantity, privateKey, decimals): Promise<any> {
    try {
      const {transaction} = await transferAsset(new ChainService({nodeHost: this.node}))({
        senderPublicKey: this.account.keys.publicKey,
        asset: asset,
        senderPrivateKey: privateKey,
        deadline: 24 * 60,
        quantity: Amount.fromPlanck(quantity).getRaw().multipliedBy(Amount.fromPlanck(Math.pow(10, decimals)).getRaw()).toString(),
        feePlanck: '0',
        recipientId: recipientId
      });
      if (transaction) {
        // this.notificationService.notify('success', this.i18nService.getTranslation('transfer_asset_success'));
      }
    } catch (e) {
      console.warn(e);
    }
  }

  getAvailableAmount(amount, askOrders, decimals): string {
    let amountQNT = Amount.fromPlanck(amount).getRaw();
    askOrders.forEach(order => {
      amountQNT = amountQNT.minus(Amount.fromPlanck(order.quantityQNT).getRaw());
    });
    return amountQNT.dividedBy(Amount.fromPlanck(Math.pow(10, decimals)).getRaw()).toString();
  }

  isGT(asset): boolean {
    return asset.asset === this.gtId;
  }
}
