import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {constants} from '../../../constants';
import {Router} from '@angular/router';


@Component({
  selector: 'request-selector',
  templateUrl: './request-selector.component.html',
  styleUrls: ['./request-selector.component.scss']
})
export class RequestSelectorComponent implements OnInit {

  @Input() coin;
  @Input() redirectTo;
  @Input() account;

  coinList = [];
  coinListCopy = [];
  coinFilter = '';
  coinSelected: any = {};

  constructor(private router: Router) {
  }

  filterCoin(event): void {
    if (event.target.value) {
      this.coinList = this.coinListCopy.filter(coin => coin.name.toLowerCase().includes(event.target.value.toLowerCase())
        || coin.symbol.toLowerCase().includes(event.target.value.toLowerCase()));
    } else {
      this.coinList = this.coinListCopy;
    }
  }

  onCoinChange(event): void {
    this.router.navigate([this.redirectTo === 'request' ? event.value.request : event.value.send]);
  }

  ngOnInit(): void {
    this.coinList = constants.coinList;
    this.coinListCopy = constants.coinList;
    this.coinSelected = this.coinList.find(coin => coin.symbol === this.coin);
  }
}
