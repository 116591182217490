<div class="card">

  <div class="card-header">
    <img src="assets/images/logos/fruits_banner.jpg">
    <div class="h1 title">
      {{'update_available' | i18n}}
    </div>
  </div>

  <div class="card-content">
    <div class="card-content-grid">
      <div class="item">
        <div class="h4 secondary-text">{{'current_version' | i18n }}: {{updateInfo.currentVersion}}</div>
        <div class="h4 secondary-text new-version">
          <div>{{'new_version' | i18n }}: {{updateInfo.newVersion}}</div>
          <a href="{{updateInfo.releaseUrl}}"
             target="_blank"
             class="font-size-12 secondary-text"
             matTooltip="{{'click_for_changes' | i18n }}">
            <mat-icon class="pl-4 s-16">help_outline</mat-icon>
          </a>
        </div>

        <div class="h4 secondary-text new-version">
          <div>{{'platform' | i18n }}: {{platform}}</div>
          <a href="{{updateInfo.releaseUrl}}"
             target="_blank"
             class="font-size-12 secondary-text"
             matTooltip="{{'click_for_other_platforms' | i18n }}">
            <mat-icon class="pl-4 s-16">help_outline</mat-icon>
          </a>
        </div>
      </div>
      <div class="cert">
        <div class="cert-info">
          <span class="font-size-10 grey-500-fg">{{'domain' | i18n}}: {{updateInfo.certInfo.domain}}</span>
          <span class="font-size-10 grey-500-fg">{{'issuer' | i18n}}: {{updateInfo.certInfo.issuerName}}</span>
          <span class="font-size-10 grey-500-fg">{{'valid_thru' | i18n}}
            : {{updateInfo.certInfo.validThru | date:'MMM d, y, h:mm:ss'}}</span>
        </div>
        <mat-icon *ngIf="updateInfo.certInfo.isValid"
                  class='cert-valid s-48 green-800-fg'
                  matTooltip="{{'verified_source' | i18n}}">verified_user
        </mat-icon>
        <mat-icon *ngIf="!updateInfo.certInfo.isValid"
                  class='cert-invalid s-48 warn-700-fg'
                  matTooltip="{{'non_verified_source' | i18n}}">remove_circle
        </mat-icon>
      </div>
    </div>

    <mat-form-field class="mt-24">
      <mat-select placeholder="Choose file to download" [(value)]="selectedAssetIndex">
        <mat-option *ngFor="let asset of assets" [value]="asset.value">
          {{asset.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div>
      <a *ngIf="updateInfo.certInfo.isValid" class="font-size-10 grey-500-fg"
         href="{{updateInfo.releaseUrl}}" target="_blank">{{'download_at_verified_source' | i18n}}</a>
      <a *ngIf="!updateInfo.certInfo.isValid" class="font-size-12"
         href="{{updateInfo.releaseUrl}}" target="_blank">{{'download_at_verified_source' | i18n}}</a>
    </div>

  </div>

  <div class="card-footer">
    <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]>{{ 'cancel' | i18n }}</button>
    </div>
    <div mat-dialog-actions>
      <button mat-button
              [mat-dialog-close]="this.updateInfo.assets[this.selectedAssetIndex]"
              color="accent"
              disabled="{{!updateInfo.certInfo.isValid}}"
      >{{ 'download' | i18n }}</button>
    </div>
  </div>
</div>
