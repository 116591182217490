import {AfterViewInit, Component, Injectable, OnInit} from '@angular/core';
import { CreateService } from '../create.service';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { burstAddressPattern } from 'app/util/burstAddressPattern';
import { NetworkService } from '../../../network/network.service';
import { AddressPrefix } from '@fruitsjs/core/src';
import {I18nService} from '../../../layout/components/i18n/i18n.service';
import {environment} from "../../../../environments/environment";
import {StoreService} from "../../../store/store.service";

@Injectable()
@Component({
  selector: 'app-create-passive',
  templateUrl: './create-passive.component.html',
  styleUrls: ['./create-passive.component.scss']
})
export class CreatePassiveAccountComponent implements OnInit, AfterViewInit {

  address = '';

  burstAddressPattern = burstAddressPattern;
  addressPrefix: AddressPrefix.TestNet | AddressPrefix.MainNet;
  isAdmin: boolean;
  burnAddress: string[] = [];

  constructor(private createService: CreateService,
    private notificationService: NotifierService,
    private networkService: NetworkService,
    private router: Router,
    private storeService: StoreService,
    private i18nService: I18nService) {
    this.isAdmin = environment.isAdmin;
  }

  ngOnInit(): void {
    if (!this.isAdmin){
      this.router.navigate(['/']);
    }
    this.addressPrefix = this.networkService.isMainNet() ? AddressPrefix.MainNet : AddressPrefix.TestNet;
  }

  ngAfterViewInit(): void {
    this.storeService.getSettings().then(setting => {
      this.networkService.getBurnAddress(setting.node).then((result) => {
        if (result) {
          const { accountsRS } = result;
          this.burnAddress = accountsRS;
        }
      }).catch((err) => {
        // Set burnAddress to undefined if there is an error then use this to check error
        this.notificationService.notify('error', 'error_unknown');
        this.burnAddress = undefined;
      });
    });

  }

  public submit(address: string): void {

    if (this.burnAddress === undefined) {
      this.notificationService.notify('error', 'error_unknown');
      return;
    }

    if (this.burnAddress.includes(`${this.addressPrefix}-${address}`)) {
      this.notificationService.notify('error', this.i18nService.getTranslation('account_not_exist_title'));
      return;
    }

    if (!this.isAddressValid(address)) {
      this.notificationService.notify('error', this.i18nService.getTranslation('invalid_address') + `${this.addressPrefix}-${address}`);
      return;
    }
    this.createService.setAddress(`${this.addressPrefix}-${address}`);
    this.createService.createPassiveAccount().then((success) => {
      this.notificationService.notify('success', `Account added: ${address}`);
      this.createService.reset();
      this.router.navigate(['/']);
    },
      (error) => {
        this.notificationService.notify('error', error.toString());
      });
  }

  isAddressValid(address): boolean {
    const tokens = address.split('-');
    return !(tokens.length < 5 || tokens.length > 7);
  }

}
