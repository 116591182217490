import { formatNumber } from '@angular/common';
import { CurrencySymbol } from '@fruitsjs/util';

export interface AmountFormattingOptions {
  isShortForm: boolean;
  noUnit: boolean;
  locale: string;
}

const DefaultFormattingOptions: AmountFormattingOptions = {
  isShortForm: false,
  noUnit: false,
  locale: 'en',
};

export function formatAmount(value: string | number, formattingOptions: AmountFormattingOptions = DefaultFormattingOptions): string {

  const v = typeof value === 'string' ? parseFloat(value) : value;
  const { isShortForm, noUnit, locale } = formattingOptions;
  const digitsInfo = isShortForm ? '1.0-6' : '1.0-8';
  const unit = noUnit ? '' : ' ' + CurrencySymbol;
  return `${formatNumber(v, locale, digitsInfo)}${unit}`;
}

export const formatBalance = (balance: string, coinType: string): string => {
  let str = '';
  // If the number is not in scientific notation return it as it is.
  if (!/\d+\.?\d*e[+-]*\d+/i.test(balance)) {
    return balance;
  }
  switch (coinType.toUpperCase()) {
    case 'BTC':
    case 'FRTS': {
      str = parseFloat(balance).toFixed(8).replace(/\.?0+$/, '').toString();
      break;
    }
    case 'ETH': {
      str = parseFloat(balance).toFixed(18).replace(/\.?0+$/, '').toString();
      break;
    }
    case 'USDT': {
      str = parseFloat(balance).toFixed(6).replace(/\.?0+$/, '').toString();
      break;
    }
  }
  const intPart = str.substr(0, str.indexOf('.'));
  return intPart ? intPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + str.substr(str.indexOf('.'))
    : str.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
