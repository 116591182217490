import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {burstAddressPattern} from '../../../util/burstAddressPattern';
import {Account, AddressPrefix} from '@fruitsjs/core';
import {CurrencySymbol} from '@fruitsjs/util/src';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountService} from '../../../setup/account/account.service';
import {NotifierService} from 'angular-notifier';
import {NetworkService} from '../../../network/network.service';
import {I18nService} from '../../../layout/components/i18n/i18n.service';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {getBalancesFromAccount} from '../../../util/balance';
import {constants} from '../../../constants';
import {NgForm} from '@angular/forms';
import {StoreService} from '../../../store/store.service';
import {decryptAES, hashSHA256} from '@fruitsjs/crypto';
import {NftService} from '../../nft.service';
import {OrderType} from '../sell-nft/sell-nft.component';

@Component({
  selector: 'app-edit-nft',
  templateUrl: './edit-nft.component.html',
  styleUrls: ['./edit-nft.component.scss', '../nft-payment.scss']
})
export class EditNftComponent implements OnInit {
  @ViewChild('setAliasForm', {static: false}) public setAliasForm: NgForm;
  @ViewChild('fullHash', {static: false}) public fullHash: string;
  @ViewChild('uri', {static: false}) public uri: string;
  @ViewChild('accountAliasURI', {static: true}) public accountAliasURI: string;
  @ViewChild('message', {static: true}) public message: string;
  @ViewChild('encrypt', {static: true}) public encrypt: boolean;
  @ViewChild('fileInput', {static: true}) public fileInput: ElementRef;
  @Output() submit = new EventEmitter<any>();

  public feeNQT: string;
  nftName: string;
  nftId: string;
  advanced = false;
  showMessage = false;
  addressPatternRef = burstAddressPattern;
  type = 'uri';
  account: Account;
  deadline = '24';
  addressPrefix: AddressPrefix.MainNet | AddressPrefix.TestNet;
  symbol = CurrencySymbol;
  nftTypes = [0, 1, 2, 3];
  nftType;
  nftCategories;
  nftCategory;
  isAccountActive = true;
  uploadImage;
  fileAttr;
  file;
  nftNumber;
  nft;
  description;
  pin;
  isLoading = false;
  format;
  url;
  isPro = false;
  collections = [];
  selectedCollection;
  collection;
  orderResponse: any;
  params: any;
  nftWeight: any;
  isSpecialType: boolean = false;

  collectionUrl = environment.isMainNet ? constants.NFT_COLLECTION_MAINNET : constants.NFT_COLLECTION_TESTNET;

  uploadImagePreview;
  fileAttrPreview;
  filePreview;
  urlPreview;
  formatPreview;
  threeDViewerUrl;
  royalties;
  quantity;
  tokenType;
  owners;
  disableEdit = false;

  constructor(private route: ActivatedRoute,
              private accountService: AccountService,
              private notifierService: NotifierService,
              private networkService: NetworkService,
              private i18nService: I18nService,
              private http: HttpClient,
              private domSanitizer: DomSanitizer,
              private router: Router,
              private storeService: StoreService,
              private nftService: NftService,
              private title: Title) {
    this.title.setTitle(constants.pageTitle + 'Edit NFT');

    this.storeService.getSelectedAccount().then(account => {
      this.account = account;
      this.route.queryParams.subscribe(async params => {
        if (params && params.orderId) {
          const rqOrderParams = new HttpParams().set('orderId', params.orderId);
          this.nftService.getNftOrder(rqOrderParams).subscribe(async rqOrderResponse => {
            if (rqOrderResponse && rqOrderResponse.errorCode === 1) {
              this.notifierService.notify('error', this.i18nService.getTranslation(rqOrderResponse.message));
              await this.router.navigate(['/']);
              return;
            }
            if (rqOrderResponse && rqOrderResponse.errorCode === 0) {
              this.orderResponse = rqOrderResponse.result;
              if (this.orderResponse.orderType !== OrderType[OrderType.EDIT]) {
                this.notifierService.notify('error', this.i18nService.getTranslation('invalid_order_type'));
                await this.router.navigate(['/']);
              }
              this.params = JSON.parse(rqOrderResponse.result.params);
              this.nftId = decodeURIComponent(this.params.nft);
              this.tokenType = this.params.tokenType;
              this.params.tokenType === constants.MULTIPLE_TOKEN ? this.populateMultiTokenInfo() : this.populateNftInfo();
            }
          });
        } else {
          this.notifierService.notify(constants.ERROR, this.i18nService.getTranslation('order_not_found'));
          await this.router.navigate(['/']);
        }
      });
    });
  }

  populateNftInfo(): any {
    const rqParams = new HttpParams()
      .set(constants.NAME, this.nftId)
      .set(constants.COLLECTION, constants.TRUE);
    this.nftService.findNftByName(rqParams).subscribe(async (response: any) => {
      if (response && response.errorCode === 1) {
        this.notifierService.notify(constants.ERROR, this.i18nService.getTranslation(response.message));
      }
      if (response && response.errorCode === 0) {
        this.nft = response.result;
        this.isPro = this.nft.pro;
        this.url = this.nft.fileUrl;
        this.formatPreview = (this.nft.type === NFT_TYPE.THREE_DIMENSIONAL || this.nft.type === NFT_TYPE.AUDIO) ? constants.IMAGE : undefined;
        this.urlPreview = (this.nft.type === NFT_TYPE.THREE_DIMENSIONAL || this.nft.type === NFT_TYPE.AUDIO) ? this.nft.thumbnail : undefined;
        this.royalties = this.nft.royalties;
        this.quantity = this.nft.quantity;
        this.getFileFormat();
        this.isSpecialType = response && response.result.specialType == 1;

        if (this.nft.type === 2) {
          this.threeDViewerUrl = constants.threeDViewer + encodeURIComponent(this.nft.fileUrl);
        }

        this.nftService.getNftType().subscribe(async (rs: any) => {
          if (rs && rs.result && rs.errorCode === 1) {
            this.notifierService.notify(constants.ERROR, this.i18nService.getTranslation(rs.message));
            return;
          }
          this.nftCategories = rs.result;
          this.nftCategory = this.nftCategories.filter(category => category.name === this.nft.nftType)[0];
          this.tokenType = this.nftCategory.tokenType;
          await this.getData();
        });

        this.nftName = this.nft.displayName;
        this.nftType = this.nftTypes[this.nft.type];
        this.nftNumber = this.nft.typeIndex;
        this.description = this.nft.description;
        this.nftWeight = this.nft.weight;
      } else {
        await this.router.navigate(['/']);
      }
    });
  }

  populateMultiTokenInfo(): any {
    const rqParam = new HttpParams().set(constants.NFT_NAME, this.nftId);
    this.nftService.findMultiNftByName(rqParam).subscribe(async (response: any) => {

      if (response && response.errorCode === 1) {
        this.notifierService.notify(constants.ERROR, this.i18nService.getTranslation(response.message));
      }
      if (response && response.errorCode === 0) {
        this.nft = response.result;
        this.isPro = this.nft.pro;
        this.url = this.nft.fileUrl;
        this.formatPreview = (this.nft.type === NFT_TYPE.THREE_DIMENSIONAL || this.nft.type === NFT_TYPE.AUDIO) ? constants.IMAGE : undefined;
        this.urlPreview = (this.nft.type === NFT_TYPE.THREE_DIMENSIONAL || this.nft.type === NFT_TYPE.AUDIO) ? this.nft.thumbnail : undefined;
        this.royalties = this.nft.royalties;
        this.quantity = this.nft.totalItems;
        this.owners = this.nft.accountRS;

        this.getFileFormat();

        if (this.nft.type === 2) {
          this.threeDViewerUrl = constants.threeDViewer + encodeURIComponent(this.nft.fileUrl);
        }

        this.nftService.getNftType().subscribe(async (rs: any) => {
          if (rs && rs.result && rs.errorCode === 1) {
            this.notifierService.notify(constants.ERROR, this.i18nService.getTranslation(rs.message));
            return;
          }
          this.nftCategories = rs.result;
          this.nftCategory = this.nftCategories.filter(category => category.name === this.nft.nftType)[0];
          this.tokenType = this.nftCategory.tokenType;
          await this.getData();
        });

        this.nftName = this.nft.displayName;
        this.nftType = this.nftTypes[this.nft.type];
        this.nftNumber = this.nft.typeIndex;
        this.description = this.nft.description;
      } else {
        await this.router.navigate(['/']);
      }
    });
  }

  ngOnInit(): void {
    this.feeNQT = '0';
    this.addressPrefix = this.networkService.isMainNet() ? AddressPrefix.MainNet : AddressPrefix.TestNet;
    this.accountAliasURI = this.account.accountRS.substr(this.account.accountRS.indexOf('-'));
  }

  async getData(): Promise<void> {
    await this.nftService.getCollections().subscribe(async (rs: any) => {
      if (rs && rs.errorCode === 1) {
        this.notifierService.notify('error', this.i18nService.getTranslation(rs.message));
      }
      if (rs && rs.errorCode === 0) {
        this.collections = rs.result;
        this.selectedCollection = rs.result.find(collection => collection.id === this.nft.groupId);
        this.collections = this.selectedCollection.name === 'Official' ? this.collections : rs.result.filter(c => c.name !== 'Official');
        this.collection = this.selectedCollection.name;
      }
    });
  }

  async onSubmit(event): Promise<void> {
    this.isLoading = true;

    event.stopImmediatePropagation();
    if (this.nft.royaltyOwner !== this.account.account) {
      this.isLoading = false;
      return;
    }

    if (this.isDescriptionTooLong()) {
      this.isLoading = false;
      return;
    }

    const validateForm = await this.validateForm();
    if (validateForm) {
      this.notifierService.notify('error', validateForm);
      this.isLoading = false;
      return;
    }

    try {
      const data = new FormData();
      data.append('type', this.nftType);
      data.append('file', this.file);
      data.append('nftType', this.nftCategory.name);
      data.append('id', this.nft.aliasURI);
      data.append('description', this.description);
      data.append('createdBy', this.account.account);
      data.append('isPro', String(this.isPro));
      data.append('groupId', this.selectedCollection.id);
      data.append('name', this.nftName);
      data.append('royalties', this.royalties);
      data.append('quantity', this.quantity);
      if(this.nftWeight){
        data.append('weight', this.nftWeight);
      }
      if (this.nftType === NFT_TYPE.THREE_DIMENSIONAL || this.nftType === NFT_TYPE.AUDIO) {
        data.append('filePreview', this.filePreview);
      }

      this.tokenType === constants.MULTIPLE_TOKEN ? this.editMultiToken(data) : this.editNft(data);

    } catch (e) {
      this.notifierService.notify(constants.ERROR, this.i18nService.getTranslation('error_edit_nft'));
      this.isLoading = false;
    }
  }

  async editNft(data: any): Promise<void> {
    try {
      if (this.nft.name) {
        const canSubmit = await this.nftService.isValidNFT(this.nft.name);
        if (!canSubmit) {
          this.isLoading = false;
          return;
        }
      }

      await this.nftService.createNft(data).subscribe({
        next: async (response: any) => {
          if (response && response.errorCode === 1) {
            this.notifierService.notify('error', this.i18nService.getTranslation(response.message));
            this.isLoading = false;
            await this.nftService.unlockNft(this.nft.name).toPromise();
          }
          if (response && response.errorCode === 0) {
            this.notifierService.notify('success', this.i18nService.getTranslation('success_alias_edited'));
            if (this.nft.name) {
              try {
                await this.nftService.unlockNft(this.nft.name).toPromise();
              } catch (e) {
              }
            }

            setTimeout(() => {
              this.isLoading = false;
              window.open(`${this.collectionUrl}/nft/my-nfts?account=${this.account.accountRS}`, '_self');
            }, 2000);
          }
        },
        error: async () => {
          this.notifierService.notify('error', this.i18nService.getTranslation('error_edit_nft'));
          this.isLoading = false;
          await this.nftService.unlockNft(this.nft.name).toPromise();
        }
      });

    } catch (e) {
      this.notifierService.notify('error', this.i18nService.getTranslation('error_edit_nft'));
      this.isLoading = false;
      if (this.nft.name) {
        try {
          await this.nftService.unlockNft(this.nft.name).toPromise();
        } catch (e) {
        }
      }
    }
  }

  editMultiToken(data: any): any {
    this.nftService.createMultiToken(data).subscribe(async (response: any) => {
      if (response && response.errorCode === 1) {
        this.notifierService.notify(constants.ERROR, this.i18nService.getTranslation(response.message));
        this.isLoading = false;
      }
      if (response && response.errorCode === 0) {
        this.notifierService.notify(constants.SUCCESS, this.i18nService.getTranslation('success_alias_edited'));
        this.disableEdit = true;
        setTimeout(() => {
          window.open(`${this.collectionUrl}/nft/my-nfts?account=${this.account.accountRS}`, '_self');
          this.isLoading = false;
        }, 2000);
      }
    });
  }

  async validateForm(): Promise<string> {

    if (!this.nftName || this.nftName.trim().length === 0) {
      return this.i18nService.getTranslation('nft_name_error2');
    }

    if (!this.isNftNameValid(this.nftName.trim())) {
      return this.i18nService.getTranslation('nft_name_error_3');
    }
    if (this.nftName.length > 256) {
      return this.i18nService.getTranslation('error_nft_name_length_1');
    }

    if(this.nftWeight < 0){
      return this.i18nService.getTranslation('error_weight_1');
    }

    if (!this.nftTypes.includes(this.nftType)) {
      return this.i18nService.getTranslation('type_error1');
    }

    if (!this.nftCategory) {
      return this.i18nService.getTranslation('category_error1');
    }

    if (!this.file) {
      // return this.i18nService.getTranslation('nft_file_error1');
      if (this.nft.type === 0 && this.nftType !== 0) {
        if (this.nftType === 1) {
          return this.i18nService.getTranslation('error_file_type_6').replace('__type__', constants.videoSupported);
        }
        if (this.nftType === 2) {
          return this.i18nService.getTranslation('error_file_type_6').replace('__type__', constants.threeDSupported);
        }
        if (this.nftType === NFT_TYPE.AUDIO) {
          return this.i18nService.getTranslation('error_file_type_6').replace('__type__', constants.audioSupported);
        }
      }
      if (this.nft.type === 1 && this.nftType !== 1) {
        if (this.nftType === 0) {
          return this.i18nService.getTranslation('error_file_type_6').replace('__type__', constants.imageSupported);
        }
        if (this.nftType === 2) {
          return this.i18nService.getTranslation('error_file_type_6').replace('__type__', constants.threeDSupported);
        }
        if (this.nftType === NFT_TYPE.AUDIO) {
          return this.i18nService.getTranslation('error_file_type_6').replace('__type__', constants.audioSupported);
        }
      }
      if (this.nft.type === 2 && this.nftType !== 2) {
        if (this.nftType === 0) {
          return this.i18nService.getTranslation('error_file_type_6').replace('__type__', constants.imageSupported);
        }
        if (this.nftType === 1) {
          return this.i18nService.getTranslation('error_file_type_6').replace('__type__', constants.videoSupported);
        }
        if (this.nftType === NFT_TYPE.AUDIO) {
          return this.i18nService.getTranslation('error_file_type_6').replace('__type__', constants.audioSupported);
        }
      }
      if (this.nft.type === NFT_TYPE.AUDIO && this.nftType !== NFT_TYPE.AUDIO) {
        if (this.nftType === 0) {
          return this.i18nService.getTranslation('error_file_type_6').replace('__type__', constants.imageSupported);
        }
        if (this.nftType === 1) {
          return this.i18nService.getTranslation('error_file_type_6').replace('__type__', constants.videoSupported);
        }
        if (this.nftType === 2) {
          return this.i18nService.getTranslation('error_file_type_6').replace('__type__', constants.threeDSupported);
        }
      }
    }

    if (this.file && this.nftType === 0
      && !this.file.type.includes('png')
      && !this.file.type.includes('jpeg')
      && !this.file.type.includes('gif')) {
      return this.i18nService.getTranslation('error_file_type_6').replace('__type__', constants.imageSupported);
    }

    if (this.file && this.nftType === 1
      && !this.file.type.includes('mp4')
      && !this.file.type.includes('webm')) {
      return this.i18nService.getTranslation('error_file_type_6').replace('__type__', constants.videoSupported);
    }

    if (this.file && this.nftType === 2 && !this.file.name.endsWith('.glb') && !this.file.name.endsWith('.gltf')) {
      return this.i18nService.getTranslation('error_file_type_6').replace('__type__', constants.threeDSupported);
    }

    if (this.file && this.nftType === 3
      && !this.file.name.endsWith('mp3')
      && !this.file.name.endsWith('wav')
      && !this.file.name.endsWith('ogg')) {
      return this.i18nService.getTranslation('error_file_type_6').replace('__type__', constants.audioSupported);
    }

    if (this.filePreview
      && !this.filePreview.type.includes('png')
      && !this.filePreview.type.includes('jpeg')
      && !this.filePreview.type.includes('gif')) {
      return this.i18nService.getTranslation('error_file_type_6').replace('__type__', constants.imageSupported);
    }

    if (this.nft.type !== NFT_TYPE.THREE_DIMENSIONAL && this.nft.type !== NFT_TYPE.AUDIO) {
      if (this.nftType === NFT_TYPE.THREE_DIMENSIONAL || this.nftType === NFT_TYPE.AUDIO) {
        if (!this.filePreview && !this.urlPreview) {
          return this.i18nService.getTranslation('nft_file_error1');
        }
      }
    }


    if (!this.description || this.description.trim().length === 0) {
      return this.i18nService.getTranslation('issue_token_desc_req');
    }

    const privateKey = decryptAES(this.account.keys.agreementPrivateKey, hashSHA256(this.pin));
    if (!privateKey) {
      return this.i18nService.getTranslation('wrong_pin');
    }

    // check nft is on sale
    const {unconfirmedTransactions} = await this.accountService.getUnconfirmedTransactions(this.account.account);
    const transFilter = unconfirmedTransactions.filter(tran => tran.attachment
      && tran.attachment['version.AliasSell']
      && tran.attachment.alias === this.nftName);
    if (transFilter && transFilter.length) {
      return this.i18nService.getTranslation('error_nft_on_sale');
    }

    if (this.tokenType === constants.MULTIPLE_TOKEN) {
      if (this.account.accountRS !== this.owners) {
        return this.i18nService.getTranslation('not_nft_owner');
      }

      return '';
    }

    // @ts-ignore
    const rqParams = new HttpParams()
      .set('name', this.nftId)
      .set('collection', 'true');
    const {result} = await this.nftService.findNftByName(rqParams).toPromise();
    if (result && result.priceNQT) {
      return this.i18nService.getTranslation('error_nft_on_sale');
    }
    if (DISABLE_TYPE.includes(result.rowType)) {
      return this.i18nService.getTranslation('busy_nft');
    }
    if (this.account.accountRS !== result.accountRS) {
      return this.i18nService.getTranslation('not_nft_owner');
    }

    return '';
  }

  isNftNameValid(str): boolean {
    return /^[a-zA-Z0-9\s#/_-]+$/.test(str);
  }

  canSubmit(): boolean {
    return (this.nftType !== 0 || this.nftType !== 1)
      && this.nftCategory
      && !!this.nftName
      && !!this.description
      && this.pin && this.pin.length >= 1;
  }

  getBalance(): string {
    return getBalancesFromAccount(this.account).availableBalance.getSigna();
  }

  checkTotal(): boolean {
    return false;
  }

  resetFile(): void {
    // remove image first
    this.uploadImage = undefined;
    this.fileAttr = undefined;
    this.file = undefined;
    this.url = this.nft.fileUrl;
    this.getFileFormat();
  }

  resetFilePreview(): void {
    // remove image first
    this.uploadImagePreview = undefined;
    this.fileAttrPreview = undefined;
    this.filePreview = undefined;
    this.formatPreview = this.nft.type === 2 ? 'image' : undefined;
    this.urlPreview = this.nft.thumbnail;
  }

  uploadFileEvt(imgFile): void {
    // validate file
    if (imgFile.target.files && imgFile.target.files[0]) {
      // image
      if (this.nftType === 0
        && !imgFile.target.files[0].type.includes('png')
        && !imgFile.target.files[0].type.includes('jpeg')
        && !imgFile.target.files[0].type.includes('gif')) {
        this.notifierService.notify('error', this.i18nService.getTranslation('error_file_type_6').replace('__type__', constants.imageSupported));
        return;
      }

      // video
      if (this.nftType === 1
        && !imgFile.target.files[0].name.endsWith('mp4')
        && !imgFile.target.files[0].name.endsWith('webm')) {
        this.notifierService.notify('error', this.i18nService.getTranslation('error_file_type_6').replace('__type__', constants.videoSupported));
        return;
      }

      // 3d
      if (this.nftType === 2 && !imgFile.target.files[0].name.endsWith('.glb') && !imgFile.target.files[0].name.endsWith('.gltf')) {
        this.notifierService.notify('error', this.i18nService.getTranslation('error_file_type_6').replace('__type__', constants.threeDSupported));
        return;
      }

      // audio
      if (this.nftType === 3
        && !imgFile.target.files[0].name.endsWith('wav')
        && !imgFile.target.files[0].name.endsWith('mp3')
        && !imgFile.target.files[0].name.endsWith('ogg')) {
        this.notifierService.notify('error', this.i18nService.getTranslation('error_file_type_6').replace('__type__', constants.audioSupported));
        return;
      }

      // file size
      if ((this.nftType === NFT_TYPE.VIDEO || this.nftType === NFT_TYPE.IMAGE) && imgFile.target.files[0].size > constants.maximumFileSize) {
        this.notifierService.notify('error', this.i18nService.getTranslation('error_file_size'));
        return;
      }

      if (this.nftType === NFT_TYPE.THREE_DIMENSIONAL && imgFile.target.files[0].size > constants.maximum3DFileSize) {
        this.notifierService.notify('error', this.i18nService.getTranslation('error_file_size_2'));
        return;
      }

      if (this.nftType === NFT_TYPE.AUDIO && imgFile.target.files[0].size > constants.maximum3DFileSize) {
        this.notifierService.notify('error', this.i18nService.getTranslation('err_audio_max_size'));
        return;
      }

      if (imgFile.target.files[0].size < 10) {
        this.notifierService.notify('error', this.i18nService.getTranslation('error_file_size_1'));
        return;
      }

      // all type
      if (!imgFile.target.files[0].type.includes('video')
        && !imgFile.target.files[0].type.includes('image')
        && !imgFile.target.files[0].type.includes('audio')
        && !imgFile.target.files[0].name.endsWith('.glb')
        && !imgFile.target.files[0].name.endsWith('.gltf')) {
        this.notifierService.notify('error', this.i18nService.getTranslation('token_file_err_2'));
        return;
      }

      // binding data
      this.file = imgFile.target.files[0];
      this.uploadImage = imgFile.target.files[0];
      this.fileAttr = imgFile.target.files[0].name;

      // preview image
      if (this.nftType !== 2) {
        const reader = new FileReader();
        reader.readAsDataURL(this.file);
        if (this.file.type.indexOf('image') > -1) {
          this.format = 'image';
        } else if (this.file.type.indexOf('video') > -1) {
          this.format = 'video';
        } else if (this.file.type.indexOf('audio') > -1) {
          this.format = 'audio';
        }
        reader.onload = (event) => {
          this.url = this.domSanitizer.bypassSecurityTrustUrl((<FileReader>event.target).result.toString());
        };
      } else {
        this.format = undefined;
      }
    } else {
      this.uploadImage = undefined;
    }
  }

  uploadFileEvtPreview(imgFile): void {
    // validate file
    if (imgFile.target.files && imgFile.target.files[0]) {
      // image
      if (!imgFile.target.files[0].type.includes('png')
        && !imgFile.target.files[0].type.includes('jpeg')
        && !imgFile.target.files[0].type.includes('gif')) {
        this.notifierService.notify('error', this.i18nService.getTranslation('error_file_type_6').replace('__type__', constants.imageSupported));
        return;
      }

      // video
      if (imgFile.target.files[0].size > constants.maximumFileSize) {
        this.notifierService.notify('error', this.i18nService.getTranslation('error_file_size'));
        return;
      }

      // file size
      if (imgFile.target.files[0].size < 10) {
        this.notifierService.notify('error', this.i18nService.getTranslation('error_file_size_1'));
        return;
      }

      // binding data
      this.filePreview = imgFile.target.files[0];
      this.uploadImagePreview = imgFile.target.files[0];
      this.fileAttrPreview = imgFile.target.files[0].name;

      // preview image for 3d
      const reader = new FileReader();
      reader.readAsDataURL(this.filePreview);
      this.formatPreview = 'image';
      reader.onload = (event) => {
        this.urlPreview = this.domSanitizer.bypassSecurityTrustUrl((<FileReader>event.target).result.toString());
      };
    } else {
      this.uploadImagePreview = undefined;
    }
  }

  isDescriptionTooLong(): boolean {
    return this.description && this.description.length > 1000;
  }

  onCancel(): void {
    history.back();
  }

  getFileTypeTxt(type): string {
    switch (type) {
      case 0:
        return this.i18nService.getTranslation('image');
      case 1:
        return this.i18nService.getTranslation('video');
      case 2:
        return this.i18nService.getTranslation('3d');
      case 3:
        return this.i18nService.getTranslation('audio');
      default:
        return '';
    }
  }

  getFileTypeSupported(): string {
    switch (this.nftType) {
      case 0:
        return this.i18nService.getTranslation('file_type_support_4').replace('__file__type__', constants.imageSupported);
      case 1:
        return this.i18nService.getTranslation('file_type_support_4').replace('__file__type__', constants.videoSupported);
      case 2:
        return this.i18nService.getTranslation('file_type_support_4').replace('__file__type__', constants.threeDSupported);
      case 3:
        return this.i18nService.getTranslation('file_type_support_4').replace('__file__type__', constants.audioSupported);
      default:
        return this.i18nService.getTranslation('file_type_support_4')
          .replace(
            '__file__type__',
            constants.imageSupported.concat(', ', constants.videoSupported).concat(', ', constants.threeDSupported)
          );
    }
  }

  getMaximumFileByType(): string {
    switch (this.nftType) {
      case 0:
      case 1:
        return this.i18nService.getTranslation('file_type_support_5').replace('__max__size__', constants.maximumSize);
      case 2:
        return this.i18nService.getTranslation('file_type_support_5').replace('__max__size__', constants.maximum3DSize);
      case 3:
        return this.i18nService.getTranslation('file_type_support_5').replace('__max__size__', constants.maximum3DSize);
      default:
        return this.i18nService.getTranslation('file_type_support_5').replace('__max__size__', constants.maximumSize);
    }
  }

  getFileFormat(): void {
    switch (this.nft.type) {
      case NFT_TYPE.IMAGE:
        this.format = constants.IMAGE;
        break;
      case NFT_TYPE.VIDEO:
        this.format = constants.VIDEO;
        break;
      case NFT_TYPE.THREE_DIMENSIONAL:
        this.format = constants.THREE_DIMENSIONAL;
        break;
      case NFT_TYPE.AUDIO:
        this.format = constants.audio;
        break;
    }
  }
}

export enum RowType {
  CREATE_UNCONFIRMED = 0,
  CREATE_CONFIRMED = 1,
  SALE_UNCONFIRMED = 2,
  SALE_CONFIRMED = 3,
  CANCEL_UNCONFIRMED = 4,
  SELLING_UNCONFIRMED = 5,
  BUYING_UNCONFIRMED = 6,
  TRANSFERRING_UNCONFIRMED = 7
}

export const DISABLE_TYPE = [
  RowType.CREATE_UNCONFIRMED.valueOf(),
  RowType.SALE_UNCONFIRMED.valueOf(),
  RowType.CANCEL_UNCONFIRMED.valueOf(),
  RowType.SELLING_UNCONFIRMED.valueOf(),
  RowType.BUYING_UNCONFIRMED.valueOf(),
  RowType.TRANSFERRING_UNCONFIRMED.valueOf(),
];

export enum NFT_TYPE {
  IMAGE = 0,
  VIDEO = 1,
  THREE_DIMENSIONAL = 2,
  AUDIO = 3
}
