
<div id="wallet" fxLayout="column">

  <div id="wallet-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

    <img src="assets/images/logos/fruits-security-wallet.png" height="64" width="64" alt="" style="margin-bottom: 40px;"/>
    <div class="alert">{{ 'create_wallet_alert_1' | i18n }}</div>
    <div class="alert">{{ 'create_wallet_alert_2' | i18n }}</div>
    <div class="alert">{{ 'create_wallet_alert_3' | i18n }}</div>
    <div class="alert" style="margin-bottom: 30px;">{{ 'create_wallet_alert_4' | i18n }}</div>

    <div id="wallet-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

      <div class="wallet-content">
        <img src="assets/images/logos/fruits-logo-wallet.png" height="130" width="208" alt="" style="margin-bottom: 50px;"/>

        <div class="button-row">
          <button class="btn-common" mat-button (click)="multiWallet()">
            <img src="assets/icons/wallet/wallet-minus.png" height="30" width="30" alt=""/>
            {{ 'multi_wallet' | i18n }}
          </button>
        </div>

        <div class="button-row">
          <button class="btn-common-custom" mat-button (click)="frtsWallet()">
            <img src="assets/icons/wallet/fruits-white.png" height="30" width="30" alt=""/>
            {{ 'frts_wallet' | i18n }}
          </button>
        </div>

      </div>

    </div>

  </div>

</div>
