import {NgModule} from '@angular/core';
import {BurstAmountPipe} from './pipes/burst-amount.pipe';
import {AssetAmountPipe} from './pipes/asset-amount.pipe';
import {PasteableAddressDirective} from './directives/pasteable-address.directive';
import {AliasesListPipe} from './pipes/aliases-list.pipe';
import {NftUtilityPipe} from './pipes/nft-utility.pipe';
import {SafeHtmlPipe} from './pipes/safe-html.pipe';
import {CoinAmountPipe} from './pipes/coin-amount.pipe';
import {SafePipe} from './pipes/safe.pipe';

// TODO: move more shared components, directive here! Still a bit cluttered in the entire project

@NgModule({
  imports: [],
  exports: [
    BurstAmountPipe,
    AssetAmountPipe,
    AliasesListPipe,
    PasteableAddressDirective,
    NftUtilityPipe,
    SafeHtmlPipe,
    CoinAmountPipe,
    SafePipe
  ],
  declarations: [
    BurstAmountPipe,
    AssetAmountPipe,
    AliasesListPipe,
    PasteableAddressDirective,
    NftUtilityPipe,
    SafeHtmlPipe,
    CoinAmountPipe,
    SafePipe
  ]
})
export class AppSharedModule {
}
