import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import {SwapService} from '../swap.service';
import {StoreService} from "../../store/store.service";

@Injectable()
export class SwapTfstsOrderResolver implements Resolve<Promise<any>> {
  private selectedAccount;
  constructor(private swapService: SwapService,
              private storeService: StoreService) {
    this.swapService = swapService;
  }

  async resolve(route: ActivatedRouteSnapshot): Promise<any> {
    this.selectedAccount = await this.storeService.getSelectedAccount();
    return this.swapService.getTFSTsOrderDetails(route.paramMap.get('orderId'), this.selectedAccount.account);
  }
}
