import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {constants} from '../../constants';
import {Observable} from 'rxjs';
import {I18nService} from '../../layout/components/i18n/i18n.service';
import {NotifierService} from 'angular-notifier';
import {IUnlockMultipleToken, LockAction, NftService} from '../../payment-gateway/nft.service';
import {Address} from '@fruitsjs/core';

@Injectable({
  providedIn: 'root'
})
export class PaymentGatewayService {

  gatewayUrl: string = environment.isMainNet ? constants.paymentApiMainnet : constants.paymentApiTestnet;
  marketUrl = environment.isMainNet ? constants.MARKET_MAIN : constants.MARKET_TEST;

  constructor(
    private http: HttpClient,
    private i18nService: I18nService,
    private notifierService: NotifierService,
    private nftService: NftService
  ) {

  }

  public getPaymentOrder(paymentId: string): Observable<any> {
    return this.http.get(`${this.gatewayUrl}/webapi/buyer/detail-checkout?paymentId=${paymentId}`);
  }

  public updatePaymentTransaction(data: { transactionId: string, paymentId: string }): Observable<any> {
    return this.http.post(`${this.gatewayUrl}/webapi/buyer/update-transaction-id`, data);
  }

  public updateTransactionId(pid: string, tx: string, account: string, lockId?: string): Observable<any> {
    const body = {
      paymentId: pid,
      transactionId: tx,
      address: account,
      lockId: lockId
    };
    return this.http.post(`${this.gatewayUrl}/webapi/buyer/update-transaction-id`, body);
  }

  public async canUpdateTransactionId(pid: string): Promise<string> {
    try {
      const order = await this.getPaymentOrder(pid).toPromise();
      if (order && order.code !== 0) {
        return order.data.errorMessage;
      } else {
        const data = order.data;
        if (data.status !== 'PENDING') {
          return 'message.exception.orderNotFound';
        }
      }
    } catch (e) {
      return 'payment_connect_error';
    }
  }

  public getMerchantOrder(orderId: string): Observable<any> {
    return this.http.get(`${this.gatewayUrl}/webapi/v2/buyer/check-order-is-nft?orderId=${orderId}`);
  }

  /**
   *
   * lock in case payment with fiat and coin
   * @param nftName
   */
  public lockNft(nftName: string): Observable<any> {
    return this.http.post(`${this.gatewayUrl}/webapi/v2/buyer/lock-nft?nftName=${encodeURIComponent(nftName)}`, {});
  }

  async isValidNFTOrder(paymentId: string, publicKey: string, privateKey: string, seller: string, node?: any): Promise<any> {
    try {
      // get order detail
      const order = await this.getPaymentOrder(paymentId).toPromise();
      if (order && order.code !== 0) {
        this.notifierService.notify('error', this.i18nService.getTranslation(order.data.errorMessage));
        return false;
      } else {
        // only status PENDING can payment
        if (order.data.status !== 'PENDING') {
          this.notifierService.notify('error', this.i18nService.getTranslation('message.exception.orderNotFound'));
          return false;
        }
      }

      // Check if order is nft payment or not
      const merchantOrder = await this.getMerchantOrder(order.data.orderId).toPromise();
      if (merchantOrder && merchantOrder.code !== 0) {
        this.notifierService.notify('error', this.i18nService.getTranslation(merchantOrder.data.errorMessage));
        return false;
      }

      // if order is not nft payment, then no need to lock nft
      if (!merchantOrder.data) {
        // this.notifierService.notify('error', this.i18nService.getTranslation('order_not_found'));
        return {
          isDonate: true,
          orderId: order.data.orderId
        };
      }

      // lock nft
      let lockToken;
      if (order.data.multiple) {
        // @ts-ignore
        lockToken = await this.nftService.verifyAndLock({
          seller: Address.fromNumericId(seller),
          nftName: order.data.nftName,
          buyer: Address.fromNumericId(order.data.buyer),
          quantity: order.data.listProduct[0].quantity,
          offerId: order.data.offerId,
          action: LockAction.BUY,
          node: node,
          sellerPublicKey: publicKey,
          sellerPrivateKey: privateKey
        });

        if (!lockToken) {
          this.notifierService.notify('error', this.i18nService.getTranslation('nft_locked'));
          return false;
        }

      } else {
        lockToken = await this.lockNft(order.data.nftName).toPromise();
        if (lockToken && lockToken.code !== 0) {
          this.notifierService.notify('error', this.i18nService.getTranslation('message.exception.orderNotFound'));
          return false;
        }

        if (!lockToken.data) {
          this.notifierService.notify('error', this.i18nService.getTranslation('payment_connect_error'));
          return false;
        }
      }


      return {
        orderDetail: order,
        lock: lockToken,
        nftName: order.data.nftName,
        isDonate: false
      };

    } catch (err) {
      this.notifierService.notify('error', this.i18nService.getTranslation('payment_connect_error'));
      return false;
    }
  }

  public unlockNft(nft: string): Observable<any> {
    return this.http.post(`${this.marketUrl}/fruits/nft/unlock?nftName=${encodeURIComponent(nft)}`, {});
  }

  public async unlockMultipleToken(data: IUnlockMultipleToken): Promise<any> {
    if (data.signature) {
      return await this.nftService.unlockMultipleToken(data).toPromise();
    } else {
      return await this.nftService.unLockMultipleTokenOtherCoin(data).toPromise();
    }

  }

  public getSmileRate(): Observable<any> {
    return this.http.get(`${this.gatewayUrl}/webapi/buyer/get-smile-exchange-rate`);
  }

}

export interface IOrderDetail {
  address: string;
  orderId: string;
  paymentId: string;
  recipient: string;
  recipientEmail: string;
  remaining: string;
  remainingCoinType: any;
  status: string;
  createdTime: string;
  listProduct: any[];
}
