import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {AccountResolver} from 'app/setup/account/account.resolver';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SetupModule} from 'app/setup/setup.module';
import {I18nModule} from 'app/layout/components/i18n/i18n.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {NetworkModule} from 'app/network/network.module';
import {LayoutModule} from 'app/layout/layout.module';
import {PageModule} from '../../components/page/page.module';
import {LoginGuard} from 'app/login/login-guard.service';
import {MatSelectModule} from '@angular/material/select';
import {AppSharedModule} from '../../shared/shared.module';
import {JuiceBtcHistoryComponent} from './juice-btc-history/juice-btc-history.component';
import {JuiceEthHistoryComponent} from './juice-eth-history/juice-eth-history.component';
import {NgxMaskModule} from 'ngx-mask';
import {MatCardModule} from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {SwapOrderDetailsComponent} from './swap-order-details/swap-order-details.component';
import {SwapOrderResolver} from './swap-order.resolver';
import {CancelOrderEthComponent} from './juice-eth-history/cancel-order-eth/cancel-order-eth.component';
import {CancelOrderBtcComponent} from './juice-btc-history/cancel-order-btc/cancel-order-btc.component';
import {JuiceTfstsHistoryComponent} from './juice-tfsts-history/juice-tfsts-history.component';
import {CancelTfstsOrderComponent} from './juice-tfsts-history/cancel-tfsts-order/cancel-tfsts-order.component';
import {SwapTfstsOrderResolver} from './swap-tfsts-order.resolver';

const routes = [
  {
    path: 'juice-btc-history',
    component: JuiceBtcHistoryComponent,
    canActivate: [LoginGuard],
    resolve: {
      account: AccountResolver
    }
  },
  {
    path: 'juice-btc-history/:orderId',
    component: SwapOrderDetailsComponent,
    canActivate: [LoginGuard],
    resolve: {
      order: SwapOrderResolver,
      account: AccountResolver
    }
  },
  {
    path: 'juice-eth-history',
    component: JuiceEthHistoryComponent,
    canActivate: [LoginGuard],
    resolve: {
      account: AccountResolver
    }
  },
  {
    path: 'juice-eth-history/:orderId',
    component: SwapOrderDetailsComponent,
    canActivate: [LoginGuard],
    resolve: {
      order: SwapOrderResolver,
      account: AccountResolver
    }
  },
  {
    path: 'juice-tfsts-history',
    component: JuiceTfstsHistoryComponent,
    canActivate: [LoginGuard],
    resolve: {
      account: AccountResolver
    }
  },
  {
    path: 'juice-tfsts-history/:orderId',
    component: SwapOrderDetailsComponent,
    canActivate: [LoginGuard],
    resolve: {
      order: SwapTfstsOrderResolver,
      account: AccountResolver
    }
  },
];

@NgModule({
  declarations: [
    JuiceBtcHistoryComponent,
    JuiceEthHistoryComponent,
    JuiceTfstsHistoryComponent,
    SwapOrderDetailsComponent,
    CancelOrderEthComponent,
    CancelOrderBtcComponent,
    CancelTfstsOrderComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SetupModule,
    NgxMaskModule,
    NetworkModule,
    LayoutModule,
    MatAutocompleteModule,
    FuseSharedModule,
    MatCardModule,
    MatCheckboxModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTableModule,
    MatButtonModule,
    MatTooltipModule,
    ReactiveFormsModule,
    I18nModule,
    MatDialogModule,
    RouterModule.forChild(routes),
    PageModule,
    AppSharedModule,
    MatProgressBarModule
  ],
  providers: [
    SwapOrderResolver,
    SwapTfstsOrderResolver
  ],
  entryComponents: [
    CancelOrderEthComponent,
    CancelOrderBtcComponent,
    CancelTfstsOrderComponent
  ]
})
export class JuiceOrderModule {
}
