import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import * as Loki from 'lokijs';
import { StoreConfig } from './store.config';
import { Settings } from 'app/settings';
import { Account } from '@fruitsjs/core';
import { adjustLegacyAddressPrefix } from '../util/adjustLegacyAddressPrefix';
import {constants} from '../constants';
import { LokiLocalStorageAdapter } from 'lokijs';

const CollectionName = {
  Account: 'accounts',
  Settings: 'settings',
  SyncData: 'syncData',
};

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  private store: any;
  private storeOld: any;
  public ready: BehaviorSubject<any> = new BehaviorSubject(false);
  public settings: BehaviorSubject<any> = new BehaviorSubject(false);

  constructor(private storeConfig: StoreConfig) {
    this.store = new Loki(storeConfig.databaseName, {
      autoload: true,
      autoloadCallback: this.init.bind(this),
      adapter: storeConfig.persistenceAdapter
    });

    this.storeOld = new Loki(storeConfig.databaseName, {
      autoload: true,
      adapter: new LokiLocalStorageAdapter(),
    }) 
  }

  /*
  * Called on db start
  */
  public init(): void {
    let accounts = this.store.getCollection(CollectionName.Account);
    if (!accounts) {
      accounts = this.store.addCollection(CollectionName.Account, { unique: ['account'] });
    }

    let syncData = this.store.getCollection(CollectionName.SyncData);
    // In case the data is not synchronized
    if (!syncData) {
      syncData = this.store.addCollection(CollectionName.SyncData, { unique: [CollectionName.SyncData] });

      // list account old need to be synchronized
      const accountOld = this.storeOld.getCollection(CollectionName.Account);
      if (accountOld) {
        accountOld.data.forEach(item => {
          delete item.$loki;
          accounts.insert(item);
        })
      }
    }

    let settings = this.store.getCollection(CollectionName.Settings);
    if (!settings) {
      settings = this.store.addCollection(CollectionName.Settings, { unique: ['id'] });
      settings.insert(new Settings());
    }

    settings.data[0].isAuthorized = settings.data[0].isAuthorized ? settings.data[0].isAuthorized : false;
    if (typeof settings.data[0].currency === 'string') {
      const newSetting = new Settings();
      settings.data[0].currency = newSetting.currency;
    } else {
      settings.data[0].currency.usdt = settings.data[0].currency.usdt || constants.defaultCurrency;
      settings.data[0].currency.trxusdt = settings.data[0].currency.trxusdt || constants.defaultCurrency;
    }
    this.store.saveDatabase();
    this.setReady(true);
    this.getSettings()
      .then(s => {
        this.setSettings(s);
      })
      .catch(error => {
        this.setSettings(new Settings());
      });
  }

  public setReady(state: boolean): void {
    this.ready.next(state);
  }

  public setSettings(state: Settings): void {
    this.settings.next(state);
  }

  async saveAccount(account: Account): Promise<Account> {

    account = adjustLegacyAddressPrefix(account);

    if (account.encryptedPassphrase && account.multiKeys && account.multiKeys.passphrase) {
      account.multiKeys.passphrase = '';
    }

    return new Promise((resolve, reject) => {
      if (this.ready.value) {
        // this.store.loadDatabase({}, () => {
          const accounts = this.store.getCollection(CollectionName.Account);
          const rs = accounts.find({ account: account.account });
          if (rs.length === 0) {
            account.orderIndex = accounts.data.length;
            accounts.insert(account);
          } else {
            accounts.chain().find({ account: account.account }).update(w => {
              // Only update what you really need...
              // ATTENTION: Do not try to iterate over all keys and update then
              // It will fail :shrug
              // look at account.service.ts for the counter part
              w.balanceNQT = account.balanceNQT;
              w.unconfirmedBalanceNQT = account.unconfirmedBalanceNQT;
              w.committedBalanceNQT = account.committedBalanceNQT;
              w.accountRSExtended = account.accountRSExtended;
              w.assetBalances = account.assetBalances;
              w.type = account.type;
              w.selected = account.selected;
              w.name = account.name;
              w.description = account.description;
              w.keys = account.keys;
              w.transactions = account.transactions;
              w.confirmed = account.confirmed;
              w.multiKeys = account.multiKeys;
              w.multiWallet = account.multiWallet;
              w.multiWalletIndex = account.multiWalletIndex;
              w.encryptedPassphrase = account.encryptedPassphrase;
              w.oldAccount = account.oldAccount;
              w.activatorTime = account.activatorTime;
              w.orderIndex = account.orderIndex;
            });
          }
          this.store.saveDatabase();
          resolve(account);
        // });

      } else {
        reject(undefined);
      }
    });
  }

  /*
  * Method reponsible for getting the selected account from the database.
  */
  public getSelectedAccount(): Promise<Account> {
    return new Promise((resolve, reject) => {
      if (!this.ready.value) {
        reject();
      }
      // this.store.loadDatabase({}, () => {
        const accounts = this.store.getCollection(CollectionName.Account);
        let rs = accounts.find({ selected: true });
        if (rs.length > 0) {
          const account = new Account(rs[0]);
          resolve(adjustLegacyAddressPrefix(account));
        } else {
          rs = accounts.find();
          if (rs.length > 0) {
            accounts.chain().find({ account: rs[0].account }).update(a => {
              a.selected = true;
            });
            const storedAccount = new Account(rs[0]);
            this.store.saveDatabase();
            resolve(adjustLegacyAddressPrefix(storedAccount));
          } else {
            reject(undefined);
          }
          reject(undefined);
        }
      // });
    });
  }

  /*
  * Method reponsible for selecting a new Account.
  */
  public async selectAccount(account: Account): Promise<Account> {
    return new Promise((resolve, reject) => {
      if (this.ready.value) {
        // this.store.loadDatabase({}, () => {
          const accounts = this.store.getCollection(CollectionName.Account);
          accounts.chain().find({ selected: true }).update(w => {
            w.selected = false;
          });
          accounts.chain().find({ account: account.account }).update(w => {
            w.selected = true;
          });
          account.selected = true;
          this.store.saveDatabase();
          resolve(account);
        // });
      } else {
        reject(undefined);
      }
    });
  }

  /*
  * Method reponsible for fetching all accounts from the database.
  */
  public getAllAccounts(): Promise<Account[]> {
    return new Promise((resolve, reject) => {
      if (this.ready.value) {
        const accounts = this.store.getCollection(CollectionName.Account);
        const rs = accounts.find();
        const ws = [];
        rs.map(storedAccount => {
          const account = adjustLegacyAddressPrefix(new Account(storedAccount));
          ws.push(account);
        });

        // Sort accounts by orderId
        ws.sort((a, b) => a.orderIndex - b.orderIndex);

        resolve(ws);
      } else {
        reject([]);
      }
    });
  }

  /*
  * Method reponsible for finding an account by its numeric id from the database.
  */
  public findAccount(accountId: string): Promise<Account> {
    return new Promise((resolve, reject) => {
      if (this.ready.value) {
        const accounts = this.store.getCollection(CollectionName.Account);
        const rs = accounts.find({ account: accountId });
        if (accountId && rs.length > 0) {
          const storedAccount = new Account(rs[0]);
          resolve(adjustLegacyAddressPrefix(storedAccount));
        } else {
          resolve(undefined);
        }
      } else {
        reject(undefined);
      }
    });
  }

  /*
  * Method reponsible for removing an account from the database.
  */
  public removeAccount(account: Account): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.ready.value) {
        // this.store.loadDatabase({}, async () => {
          const accounts = this.store.getCollection(CollectionName.Account);
          
          // Get the orderIndex of the account being removed
          const removedOrderIndex = account.orderIndex;
  
          // Remove the account from the collection
          const rs = accounts.chain().find({ account: account.account }).remove();
  
          // Update the orderIndex of the remaining accounts
          const remainingAccounts = accounts.chain().find({ orderIndex: { '$gt': removedOrderIndex } }).data();
  
          for (const accountToUpdate of remainingAccounts) {
            accounts.chain().find({ account: accountToUpdate.account }).update(doc => {
              doc.orderIndex -= 1;
            });
          }
          this.store.saveDatabase();
          resolve(true);
        // });
      } else {
        reject(false);
      }
    });
  }

  /*
  * Method reponsible for updating the order of account list after drag & drop
  */
  public async updateAccountList(dataSourceData: Account[]): Promise<Account[]> {
    return new Promise((resolve, reject) => {
      if (!this.ready.value) {
        return reject();
      }
      // this.store.loadDatabase({}, () => {
        const accounts = this.store.getCollection(CollectionName.Account);
        dataSourceData.forEach((updatedAccount, index) => {
          accounts.chain().find({ account: updatedAccount.account }).update(w => {
            w.orderIndex = index; 
          });
        });
        this.store.saveDatabase();
        resolve(accounts);
      // });
    });
  }

  public saveSettings(newSettings: Settings): Promise<Settings> {
    return new Promise((resolve, reject) => {
      if (!this.ready.value) {
        return reject();
      }

      // this.store.loadDatabase({}, () => {
        const settings = this.store.getCollection(CollectionName.Settings);
        const rs = settings.find({ id: newSettings.id });

        if (rs.length > 0) {
          settings.update(newSettings);
        } else {
          settings.insert(newSettings);
        }
        this.store.saveDatabase();
        this.setSettings(newSettings);
        resolve(newSettings);
      // });
    });
  }

  public getSettings(): Promise<Settings> {
    return new Promise((resolve, reject) => {
      if (this.ready.value) {
        const settings = this.store.getCollection(CollectionName.Settings);
        const rs = settings.find();
        if (typeof rs[0].currency !== 'object') {
          rs[0].currency = {};
        }
        resolve(rs[0]);
      } else {
        resolve(new Settings());
      }
    });
  }
}
