<div class="page-layout blank simple" fusePerfectScrollbar>

  <!-- HEADER -->
  <div class="header accent p-40 h-140" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
    fxLayoutAlign.gt-xs="space-between center">

    <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="center start">
      <div fxLayout="row" fxLayoutAlign="start center">
        <a [routerLink]="['/dashboard']">
          <!--          <mat-icon class="secondary-text s-18">home</mat-icon>-->
          <img src="./assets/icons/menu/icon-home.png" height="16" width="16" alt=""/>
        </a>
        <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
        <a [routerLink]="['/accounts']" class="secondary-text">{{ 'accounts' | i18n }}</a>
<!--        <mat-icon class="secondary-text s-16">chevron_right</mat-icon>-->
<!--        <span class="secondary-text">{{ account.accountRS }}</span>-->
      </div>
      <div *ngIf="!isTransferAccount();" class="current-screen h1 mt-16">
        {{ account.accountRS }}
      </div>
      <div *ngIf="isTransferAccount();" class="current-screen h1 mt-16">
        {{ 'old_wallet_history' | i18n }}
      </div>
    </div>
  </div>
  <!-- / HEADER -->

  <div class="p-40">

    <div class="row">
      <div class="left account-details" style="width: 60%;">
        <div class="hashicon">
          <!--        <canvas #avatar></canvas>-->
          <!--        <div id="fruits-detail"></div>-->
          <img src="./assets/icons/menu/logox4.png" alt=""/>
        </div>
        <div class="details">
          <div *ngIf="isTransferAccount()" style="color: #ed6d00; font-weight: bold; font-size: 20px;">{{ 'old_wallet' | i18n }}</div>
          <h2 class="account-address" [ngStyle]="{'marginTop': isAdmin ? '20px' : '40px'}">{{ account.name || account.accountRS }}</h2>
          <h3 *ngIf="isAdmin" class="account-id">{{ account.account }}</h3>
          <h4 class="account-id">{{ account.balanceNQT | burstAmount:'planck' }}</h4>
        </div>
      </div>
      <div *ngIf="isTransferAccount()" class="right" style="width: 40%;"><span class="warning-text">
        <div>{{ 'warning_old_account' | i18n }}</div>
        <div>{{ 'warning_old_account_1' | i18n }}</div>
      </span></div>
    </div>

    <mat-tab-group class="account-details">
      <mat-tab label="{{ 'transactions' | i18n }}">
        <app-transaction-table [dataSource]="dataSource" [account]="account"></app-transaction-table>
      </mat-tab>
      <mat-tab label="{{ 'assets' | i18n }}">
        <mat-table class="asset-table" #table [dataSource]="dataSourceAsset">
          <ng-container matColumnDef="asset">
            <mat-header-cell *matHeaderCellDef>{{ 'asset' | i18n }}</mat-header-cell>
            <mat-cell *matCellDef="let asset">
<!--              {{ block.block }}-->
              {{ asset.asset }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="quantity">
            <mat-header-cell *matHeaderCellDef>{{ 'quantity' | i18n }}</mat-header-cell>
            <mat-cell *matCellDef="let asset">
<!--              {{ asset.height }}-->
              {{ asset.balance }}
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsAsset"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsAsset;"></mat-row>
        </mat-table>
<!--        <table>-->
<!--          <tr>-->
<!--            <th>{{ 'asset_name' | i18n }}</th>-->
<!--            <th>{{ 'quantity' | i18n }}</th>-->
<!--          </tr>-->
<!--          <tr *ngFor="let row of account['assetBalances']">-->
<!--            <td>{{row.asset}}</td>-->
<!--            <td>{{row.balance}}</td>-->
<!--          </tr>-->
<!--        </table>-->
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
