import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {StoreService} from 'app/store/store.service';
import {AccountService} from 'app/setup/account/account.service';
import {Account} from '@fruitsjs/core';
import {MatPaginator} from '@angular/material/paginator';
import {Amount, ChainTime} from '@fruitsjs/util';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {decryptAES, hashSHA256, Keys} from '@fruitsjs/crypto';
import {KeyDecryptionException} from '../../../util/exceptions/KeyDecryptionException';
import {NotifierService} from 'angular-notifier';
import {I18nService} from '../../../layout/components/i18n/i18n.service';
import {takeUntil} from 'rxjs/operators';
import {UnsubscribeOnDestroy} from '../../../util/UnsubscribeOnDestroy';
import {TransactionService} from '../../transactions/transaction.service';
import {formatDate} from '@angular/common';
import {environment} from '../../../../environments/environment';
import {constants} from '../../../constants';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {SwapService} from '../../swap.service';
import * as moment from 'moment';
import {SwapOrderDetailsComponent} from '../swap-order-details/swap-order-details.component';
import {CoinType} from '../../../shared/pipes/coin-amount.pipe';

@Component({
  selector: 'app-assets',
  styleUrls: ['./juice-tfsts-history.component.scss'],
  templateUrl: './juice-tfsts-history.component.html'
})
export class JuiceTfstsHistoryComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {

  public locale;
  public isLoading = false;
  public dataSourceSellOrders: MatTableDataSource<any>;
  public displayedColumns: string[];
  public selectedAccount: Account;
  public node;
  fetchData;
  isCancel = false;
  pageIndex = 0;
  pageSize = 10;
  totalElements: any;
  data: any;
  public unSubscriber = takeUntil(this.unsubscribeAll);

  @ViewChild('sortSellOrders', {static: true}) sortSellOrders: MatSort;
  @ViewChild('paginatorSellOrders', {static: false}) paginatorSellOrders: MatPaginator;

  marketUrl = environment.isMainNet ? constants.MARKET_MAIN : constants.MARKET_TEST;

  constructor(private storeService: StoreService,
              private accountService: AccountService,
              public router: Router,
              private warnDialog: MatDialog,
              private route: ActivatedRoute,
              private notifierService: NotifierService,
              private i18nService: I18nService,
              private transactionService: TransactionService,
              private http: HttpClient,
              private title: Title,
              private swapService: SwapService) {
    super();
    this.title.setTitle(constants.pageTitle + this.i18nService.getTranslation('swap_history_tfsts'));
  }


  async getDataTFSTs(data): Promise<void> {
    this.isLoading = true;
    try {
      await this.swapService.getTFSTsSwapHistory(data).subscribe((response: any) => {
        if (response && response.errorCode === 0) {
          if (response.result) {
            this.dataSourceSellOrders.data = response.result.content;
            this.totalElements = response.result.totalElements;
          }
        }
      });

    } catch (e) {
      this.isLoading = false;
      console.warn(e);
    }
    this.isLoading = false;

  }

  convertTimestamp(timestamp): any {
    return moment.unix(timestamp).format('MM/DD/YYYY HH:mm A');
  }

  pageChange(event): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    this.data.page = event.pageIndex;
    this.data.size = event.pageSize;
    this.getDataTFSTs(this.data);
  }

  getSendersSignPrivateKey(pin: string, keys: Keys): string {
    const privateKey = decryptAES(keys.signPrivateKey, hashSHA256(pin));
    if (!privateKey) {
      throw new KeyDecryptionException();
    }
    return privateKey;
  }

  async ngOnInit(): Promise<void> {
    this.displayedColumns = ['transaction_id', 'request_date', 'swap_content', 'fee', 'total_amount', 'rate', 'status', 'action'];
    this.dataSourceSellOrders = new MatTableDataSource<any>();
    this.storeService.getSettings().then((result: any) => {
      if (result && result.node) {
        this.node = result.node;
      }
    });
    this.storeService.settings
      .pipe(
        takeUntil(this.unsubscribeAll)
      )
      .subscribe(({language}) => {
        this.locale = language;
      });
    this.selectedAccount = await this.storeService.getSelectedAccount();
    this.data = {
      account: this.selectedAccount.account,
      size: this.pageSize,
      page: this.pageIndex,
      sortBy: 'createdTime',
      sortDesc: true
    };

    setTimeout(async () => {
      this.getDataTFSTs(this.data);
    }, 1000);
    this.fetchData = setInterval(() => {
      this.getDataTFSTs(this.data);
    }, 30000);
  }

  ngOnDestroy(): void {
    clearInterval(this.fetchData);
  }

  // tslint:disable-next-line:use-life-cycle-interface
  public ngAfterViewInit(): void {
    this.dataSourceSellOrders.sort = this.sortSellOrders;
    this.dataSourceSellOrders.paginator = this.paginatorSellOrders;
  }

  getDate(timestamp): string {
    const time = ChainTime.fromChainTimestamp(Number(timestamp));
    return formatDate(time.getDate(), 'short', this.locale);
  }

  getQuantity(quantity): string {
    const intPart = quantity.substr(0, quantity.indexOf('.'));
    return intPart ? intPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + quantity.substr(quantity.indexOf('.'))
      : quantity.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  openDetailDialog(data: any): void {
    const dialogRef =  this.warnDialog.open(SwapOrderDetailsComponent, {
      width: '600px',
      height: '500px',
      data: {
        result: data,
        type: CoinType.frts
      },
      panelClass: 'swap-order-details-dialog'
    });

    dialogRef.afterClosed().subscribe(ok => {
       this.getDataTFSTs(this.data);
    });
  }

   calculateTotalAmount(value1, value2): string {
    return (Amount.fromPlanck(value1).getRaw().plus(Amount.fromPlanck(value2).getRaw()).toString());
  }

  convertStatus(status): string {
    switch (status) {
      case 'CANCELLED':
        return 'CANCELED';
      case 'COMPLETE':
        return 'COMPLETED';
      default:
        return status;
    }
  }
}
