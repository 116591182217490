<h2 class="pin-component">
    {{"define_your_pin" | i18n}}
</h2>
<div class="pin-container">
<!--    <mat-form-field class="example-full-width">-->
<!--        <input matInput type="password" placeholder="{{'enter_pin' | i18n}}" [(ngModel)]="pin">-->
<!--    </mat-form-field>-->
    <mat-form-field style="width: 100%;" appearance="outline">
      <mat-label>{{ 'enter_pin' | i18n }}</mat-label>
      <input matInput type="password" placeholder="{{'enter_pin' | i18n}}" [(ngModel)]="pin">
    </mat-form-field>
</div>
<small class="pin-hint">
   {{"pin_hint" | i18n }}
</small>
<div class="text-center navigation">
    <button class="btn-danger btn-common" (click)="back()">{{"back" | i18n }}</button>
    <button *ngIf="lastStep" class="{{isValid ? 'btn-common' : 'btn-common-disable'}}" (click)="finish()">{{"finish" | i18n }}</button>
    <button *ngIf="!lastStep" class="{{isValid ? 'btn-common' : 'btn-common-disable'}}" (click)="next()">{{"next" | i18n }}</button>
</div>
