export enum TransactionVotingSubtype {
    ProposalCreation = 0,
    ProposalVote = 1,
    ProposalCancellation = 2,
    ProposalEndVote = 3,
    ProposalExecution = 4,
    ProposalExpire = 5,
    ProposalConfirm = 6,
    ProposalCreationWithType = 7
}
