import { Component, OnInit } from '@angular/core';
import { SuggestedFees, Account } from '@fruitsjs/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from 'app/setup/account/account.service';
import { StoreService } from '../../store/store.service';
import { takeUntil } from 'rxjs/operators';
import { getBalancesFromAccount } from '../../util/balance';
import { UnsubscribeOnDestroy } from '../../util/UnsubscribeOnDestroy';
import { ApiService } from '../../api.service';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Amount} from '@fruitsjs/util/src';
import {environment} from '../../../environments/environment';
import {constants} from '../../constants';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-set-commitment',
  templateUrl: './set-commitment.component.html',
  styleUrls: ['./set-commitment.component.scss']
})
export class SetCommitmentComponent extends UnsubscribeOnDestroy implements OnInit {
  account: Account;
  fees: SuggestedFees;
  language: string;
  isSupported = false;

  webCommitment: any;
  lightNodeCommitted: any = 0;
  lightNodeUrl = environment.isMainNet ? constants.lightNodeMainnet : constants.lightNodeTestnet;

  constructor(private route: ActivatedRoute,
              private apiService: ApiService,
              private accountService: AccountService,
              private storeService: StoreService,
              private httpClient: HttpClient,
              private title: Title) {
    super();
    this.title.setTitle(constants.pageTitle + 'Fertilizer');
  }

  ngOnInit(): void {
    this.account = this.route.snapshot.data.account as Account;
    this.fees = this.route.snapshot.data.suggestedFees as SuggestedFees;

    this.apiService.supportsPocPlus().then(supportsPocPlus =>
      this.isSupported = supportsPocPlus
    );

    const unsubscribeAll = takeUntil(this.unsubscribeAll);
    this.storeService.settings
      .pipe(unsubscribeAll)
      .subscribe(({ language }) => {
          this.language = language;
        }
      );

    this.storeService.ready
      .pipe(unsubscribeAll)
      .subscribe((ready) => {
        if (!ready) {
          return;
        }
        this.accountService.currentAccount
          .pipe(unsubscribeAll)
          .subscribe((account: Account) => {
            this.account = account;
            this.getLightNodeCommitment(account.accountRS).subscribe({
              next: (data) => {
                if (data.errorCode === 0) {
                  const nodeCommit = Amount.fromPlanck(account.committedBalanceNQT);
                  const lNodeCommit = Amount.fromSigna(data.result.amount);
                  const diff = nodeCommit.subtract(lNodeCommit);
                  this.webCommitment = diff.less(Amount.Zero()) ? Amount.Zero().getPlanck() : diff.getPlanck();
                  this.lightNodeCommitted = data.result.amount;
                } else {
                  this.webCommitment = account.committedBalanceNQT;
                }
              },
              error: (error) => {
                this.webCommitment = account.committedBalanceNQT;
              }
            });
          });
      });
  }

  getBalance(): string {
    return getBalancesFromAccount(this.account).availableBalance.getSigna();
  }

  getLightNodeCommitment(address: string): Observable<any> {
    return this.httpClient.get(`${this.lightNodeUrl}/fruits/light-node/${address}/get-detail`);
  }
}
