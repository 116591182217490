

// Add commas to number, IE. 1234567.89 -> 1,234,567.89
export const addCommasToNumber = (num: number): string => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const formatBalances = (balance): string => {
  const intPart = balance.substr(0, balance.indexOf('.'));
  return intPart ? intPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + balance.substr(balance.indexOf('.'))
    : balance.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const isOverflowing = (element: HTMLElement): boolean => {
  const currentOverflow = element.style.overflow;

  if (!currentOverflow || currentOverflow === 'visible') {
    element.style.overflow = 'hidden';
  }

  const isOverflowingElement = element.clientWidth < element.scrollWidth || element.clientHeight < element.scrollHeight;
  element.style.overflow = currentOverflow;

  return isOverflowingElement;
};

  export const formatNumber = (amount): any  => {
    const intPart = amount.substr(0, amount.indexOf('.'));
    return intPart ? intPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + amount.substr(amount.indexOf('.'))
      : amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };
