import {Injectable} from '@angular/core';
import {ElectronService} from 'ngx-electron';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  remoteAddress = null;

  constructor(private electronService: ElectronService) {
  }

  public getRemoteAddress(): string {
    return this.remoteAddress;
  }

  public setRemoteAddress(address): void {
    this.remoteAddress = address;
  }

  public isDesktop(): boolean {
    return this.electronService.isElectronApp;
  }

  public showDesktopMessage(title: string, body: string): void {
    if (!this.electronService.isElectronApp) {
      return;
    }

    // @ts-ignore
    // tslint:disable-next-line:no-unused-expression
    new window.Notification(title, {body, title: 'Fruits Eco-Blockchain'});

  }

  public openInBrowser(url: string): Promise<void> {
    if (!this.electronService.isElectronApp) {
      console.log('Opening url:', url);
      return;
    }
    return this.electronService.shell.openExternal(url);
  }

  public onIpcMessage(eventName: string, handler: (payload: any) => void): void {
    if (!this.electronService.isElectronApp) {
      return;
    }
    this.electronService.ipcRenderer.on(eventName, (event, data) => {
      handler(data);
    });
  }

  public sendIpcMessage(eventName: string, payload: any): void {
    if (!this.electronService.isElectronApp) {
      return;
    }
    this.electronService.ipcRenderer.send(eventName, payload);
  }
}
