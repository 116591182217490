/**
 * Symbol/Character for currency unit
 * @module util
 * */
export const CurrencySymbol = 'FRTS';

export const ETHSymbol = 'ETH';

export const BTCSymbol = 'BTC';

export const USDTSymbol = 'USDT';

export const gTokenSymbol = 'GT';
export const smileTokenSymbol = 'Smile';

/**
 * Symbol/Character for Planck (the smallest possible unit)
 * @module util
 * */
export const CurrencyPlanckSymbol = 'frts';

/**
 * The smallest possible fee
 * @module util
 */
export const FeeQuantPlanck = 5000000;

/**
 * One FRUITS expressed in Planck
 * @module util
 */
export const OneSignaPlanck = 100000000;