
<div id="wallet" fxLayout="column">

  <div id="wallet-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

    <div id="wallet-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

      <div class="wallet-content">
        <div class="wallet-title">WebWallet {{getVersion()}}Beta Login</div>

        <div>
          <mat-form-field appearance="outline" floatLabel="always" style="width: 70%;">
            <mat-label>{{ 'username' | i18n }}</mat-label>
            <input matInput [(ngModel)]="username" name="username">
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="outline" floatLabel="always" style="width: 70%;">
            <mat-label>{{ 'password' | i18n }}</mat-label>
            <input matInput [(ngModel)]="password" name="password" type="password">
          </mat-form-field>
        </div>

        <div class="button-row">
          <button class="btn-common" mat-button (click)="login()">{{ 'login' | i18n }}</button>
        </div>

      </div>

    </div>

  </div>

</div>
