import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'backup-check',
  templateUrl: './backup-check.component.html',
  styleUrls: ['./backup-check.component.scss']
})
export class BackupCheckComponent implements OnInit {

  check1 = false;
  check2 = false;
  check3 = false;

  @Output() agreeAll = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit(): void {
  }

  // send check result to parent
  checkAllCheckbox(): void {
    this.agreeAll.emit(this.check1 && this.check2 && this.check3);
  }
}
