import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {Amount, CurrencySymbol} from '@fruitsjs/util';
import {Account} from '@fruitsjs/core';

@Component({
  selector: 'amount-input',
  templateUrl: './amount-input.component.html',
  styleUrls: ['./amount-input.component.scss']
})
export class AmountInputComponent {

  _amountValue = '';
  amountInputChange$: Subject<string> = new Subject<string>();

  // tslint:disable-next-line:no-input-rename
  @Input('disabled') disabled = false;
  // tslint:disable-next-line:no-input-rename
  @Input('symbol') symbol = CurrencySymbol;
  // tslint:disable-next-line:no-input-rename
  @Input('balances') balances;
  // tslint:disable-next-line:no-input-rename
  @Input('fees') fees = 0;
  // tslint:disable-next-line:no-input-rename
  @Input('account') account: Account;
  // tslint:disable-next-line:no-input-rename
  @Input('decimals') decimals = 8;
  // tslint:disable-next-line:no-input-rename
  @Input('spendAll') spendAll = true;

  @Input()
  get amountValue(): string {
    return this._amountValue;
  }

  set amountValue(amountValue: string) {
    this._amountValue = amountValue;
    this.onAmountInputChange(amountValue);
  }

  @Output()
  amountChange = new EventEmitter();

  amount: any;
  constructor() {
  }

  onAmountInputChange(query: string): void {
    this.amountInputChange$.next(query);
    this.amountChange.emit(this._amountValue);
  }

  onSourceInputChange(event: any, decimals: number): void {
    if (event && event.target && event.target.value) {
      let value = event.target.value;
      value = value.replace(/[^0-9.]/g, ''); // keep number and dot only
      const parts = value.split('.');
      if (parts.length > 2) {
        parts.splice(2); // if number of dots > 1, remove all from 2nd dot, include itself
      }
      // limit number of integers
      if (parts[0].length > 16) {
        parts[0] = parts[0].substring(0, 16);
      }
      // limit number of decimals
      if (parts[1] && parts[1].length > decimals) {
        parts[1] = parts[1].substring(0, decimals);
      }
      const newValue = parts.join('.');

      event.target.value = decimals === 0 ? (newValue.endsWith('.') ? newValue.replace('.', '') : newValue) : newValue;

      this._amountValue = event.target.value;
      this.amountChange.emit(this._amountValue);
    } else {
      this.amountChange.emit('');
    }
  }

  onSpendAll(): void {
    if (!this.balances) {
      return;
    }

    if (this.disabled) {
      return;
    }
    this.amount = Amount.fromPlanck(this.balances).getRaw().minus(Amount.fromPlanck(this.fees).getRaw());
    this._amountValue = parseFloat(this.amount) < 0 ? Amount.fromPlanck(this.balances).getRaw().toString() : this.amount.toString();
    if (/\d+\.?\d*e[+-]*\d+/i.test(this._amountValue)) {
      this._amountValue = parseFloat(this._amountValue).toFixed(18).toString();
    }
    this.amountChange.emit(this._amountValue);
  }
}
