<form #sendForm="ngForm">
  <mat-radio-group fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center" fxLayout.xs="column"
                   fxLayoutAlign.xs="start start" [(ngModel)]="mode" name="mode">
    <mat-radio-button class="mb-12" value="add"
                      [checked]="mode === 'add'">{{'add_fertilizer' | i18n}}</mat-radio-button>
    <mat-radio-button class="mb-12" value="revoke">{{'revoke_fertilizer' | i18n}}</mat-radio-button>
  </mat-radio-group>

  <ng-container *ngIf="isRevoking()">
    <div *ngIf="hasMissingBlocks()" class="mt-24 p-24 full-width message-box warning">
      {{blocksMissingUntilRevoke}}&nbsp;{{'fertilizer_blocks_enabled' | i18n}}
    </div>
    <div *ngIf="hasNothingCommitted()" class="mt-24 p-24 full-width message-box warning">
      {{'fertilizer_zero_balance'| i18n}}

    </div>
  </ng-container>

  <ng-container *ngIf="!isRevoking() || (isRevoking() && !hasMissingBlocks() && !hasNothingCommitted())">
    <div class="amount-wrapper">
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>{{ 'amount' | i18n }}</mat-label>
        <input matInput [(ngModel)]="amount" name="amount">
        <div matSuffix style="display:flex; align-items: center; justify-content: center; position: relative; bottom: 4px;">
          <span>{{symbol}}&nbsp;</span>
          <mat-icon
            class="all-icon"
            matSuffix
            matTooltip="{{'spend_all' | i18n}}"
            (click)="onSpendAll()"
          >
            all_inclusive
          </mat-icon>
        </div>
      </mat-form-field>

      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>{{ 'fee' | i18n }}</mat-label>
        <input matInput [(ngModel)]="fee" name="fee">
        <span matSuffix style="position: relative; bottom: 4px;">{{symbol}}&nbsp;</span>
      </mat-form-field>
    </div>

    <burst-fee-selector [fees]="fees" [(fee)]="fee"></burst-fee-selector>

    <div class="submit-transaction mt-10">
      <mat-form-field class="message-text password-field" floatLabel="always" appearance="outline">
        <mat-label>{{'enter_pin' | i18n}}<span style="color:red"> *</span></mat-label>
        <input type="password" matInput placeholder="{{'enter_pin' | i18n}}" [(ngModel)]="pin" name="pin">
      </mat-form-field>
    </div>

    <!--    <app-submit-transaction [disabled]="!canSubmit()"-->
    <!--                            [isSubmitting]="isSending"-->
    <!--                            (pinChange)="setPin($event)">-->
    <!--    </app-submit-transaction>-->

    <strong>{{'total' | i18n}}:&nbsp;</strong>
    <span [class.error]="!hasSufficientBalance()">{{ getTotal().getSigna() | burstAmount }}</span>
  </ng-container>

  <div class="send-button">
    <button class="{{canSubmit() && !isSending ? 'btn-common' : 'btn-common-disable'}}" [disabled]="!canSubmit() || isSending" mat-button (click)="onSubmit()">
      {{ 'submit' | i18n }}
      <mat-icon *ngIf="isSending" style="vertical-align: middle;">
        <mat-spinner diameter="20"></mat-spinner>
      </mat-icon>
    </button>
  </div>

</form>
