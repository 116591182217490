import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {constants} from '../constants';

@Injectable({
  providedIn: 'root'
})
export class TimeCreatedService {
  marketUrl = environment.isMainNet ? constants.MARKET_MAIN : constants.MARKET_TEST;

  constructor() {
  }

  checkTimeCreated = async (address) => {
    const response = await fetch(`${this.marketUrl}/fruits/transaction/check-time-created/${address}`);
    return await response.json();
  }

  updateTimeCreated = async (address) => {
    const response = await fetch(this.marketUrl + '/fruits/transaction/update-time', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf8'
      },
      body: JSON.stringify({
        address: address
      })
    });
    return await response.json();
  }
}
