<div class="dialog-container">
  <div mat-dialog-title>
    <div class="flex justify-center w-full" style="margin-top: 10px; color: #3A4667;">
      <span style="font-weight: bold">{{ 'confirmations' | i18n }}</span>
    </div>
  </div>
  <div mat-dialog-content>
    <div class="manage-warning" *ngIf="data.status === 'connect'">{{ 'manage_wallet_confirmation_warning_1' | i18n }}</div>
    <div class="manage-warning" *ngIf="data.status === 'home'">{{ 'manage_wallet_confirmation_warning_2' | i18n }}</div>
  </div>
  <div mat-dialog-actions style="margin: 16px 0 24px;">
    <button class="btn-common" (click)="dialogRef.close(false)">{{ 'cancel' | i18n }}</button>
    <button class="btn-common" (click)="toManageAccounts()">{{ 'ok' | i18n }}</button>
  </div>
</div>
