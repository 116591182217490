import { Component, OnInit, Input, Output } from '@angular/core';
import { convertNQTStringToNumber } from '@fruitsjs/util';
import { SuggestedFees } from '@fruitsjs/core';
import { EventEmitter } from '@angular/core';
import { LabelType, Options } from 'ng5-slider';
import { BurstAmountPipe } from '../../../shared/pipes/burst-amount.pipe';
import { formatAmount } from '../../../util/formatAmount';
import { I18nService } from '../i18n/i18n.service';
import Color from 'color';

// @ts-ignore
const SliderAxisBaseColor = Color('#ED6D00');

@Component({
  selector: 'burst-fee-selector',
  templateUrl: './burst-fee-selector.component.html',
  styleUrls: ['./burst-fee-selector.component.scss']
})
export class BurstFeeSelectorComponent implements OnInit {
  @Input() fees: SuggestedFees;

  feeValue = 0;
  burstAmountPipe: BurstAmountPipe;
  options: Options;
  color = 1;

  @Input()
  get fee(): number {
    return this.feeValue;
  }

  @Output()
  feeChange = new EventEmitter();

  set fee(feeValue: number) { // not Planck
    if (!feeValue) {
      this.feeValue = 0;
      return;
    }
    this.feeValue = feeValue;
    this.feeChange.emit(this.feeValue);
  }

  constructor(private i18nService: I18nService) {
  }

  ngOnInit(): void {

    const floor = this.convertFeeToBurst(this.fees.minimum);
    const ceil = this.convertFeeToBurst(this.fees.priority);

    const normalize = (v): number => (v - floor) / (ceil - floor);

    this.options = {
      step: 0.0000001,
      floor,
      ceil,
      showSelectionBar: true,
      getSelectionBarColor: (value: number): string => {
        const n = normalize(value);
        let color = Color('#ED6D00');
        if (n < 0.5) {
          color = Color('#FFC444');
          this.color = 0;
        } else if (n !== 1) {
          color = Color('#ED6D00');
          this.color = 1;
        } else {
          color = Color('#00D770');
          this.color = 2;
        }
        return color.hsl();
      },
      translate: (value: number, label: LabelType): string => {
        return formatAmount(value, {
          locale: this.i18nService.currentLanguage.code,
          noUnit: false,
          isShortForm: false
        });
      }
    };
  }

  convertFeeToBurst(feeNQT: number): number {
    return convertNQTStringToNumber(feeNQT.toString());
  }

  getSlider(): any {
    switch (this.color) {
      case 0: return 'slider1';
      case 1: return 'slider2';
      default: return 'slider3';
    }
  }
}
