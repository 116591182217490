import {Component, OnInit, ViewChild, AfterViewInit, ViewChildren, ElementRef} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource, MatTable} from '@angular/material/table';
import {NotifierService} from 'angular-notifier';
import {DeleteAccountDialogComponent} from './delete-account-dialog/delete-account-dialog.component';
import {StoreService} from 'app/store/store.service';
import {AccountService} from 'app/setup/account/account.service';
import {Account} from '@fruitsjs/core';
import {takeUntil} from 'rxjs/operators';
import {UnsubscribeOnDestroy} from '../../util/UnsubscribeOnDestroy';
import {I18nService} from '../../layout/components/i18n/i18n.service';
import {ExportDialogComponent} from './export-passphrase/export-dialog.component';
import {TransferDialogComponent} from './transfer-account/transfer-dialog.component';
import {AccountTransferService} from '../../account-transfer.service';
import {environment} from 'environments/environment';
import {getAddressParams} from '../../utils';
import {isOverflowing} from '../../util.service';
import {constants} from '../../constants';
import {Title} from '@angular/platform-browser';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-accounts',
  styleUrls: ['./accounts.component.scss'],
  templateUrl: './accounts.component.html'
})
export class AccountsComponent extends UnsubscribeOnDestroy implements OnInit, AfterViewInit {
  public dataSource: MatTableDataSource<Account>;
  public displayedColumns: string[];
  public accounts: Account[];
  public selectedAccount: Account;
  public selectedAccounts: object;
  public locale: string;
  public oldAccounts: string[] = [];
  public isAdmin = environment.isAdmin;
  public orderSaved = true;

  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(private storeService: StoreService,
              private accountService: AccountService,
              private notificationService: NotifierService,
              private i18nService: I18nService,
              private matDialog: MatDialog,
              public router: Router,
              private accountTransferService: AccountTransferService,
              private title: Title) {
    super();
    this.title.setTitle(constants.pageTitle + 'Manage Accounts');
  }

  public ngOnInit(): void {
    this.accounts = [];
    this.selectedAccounts = {};
    this.displayedColumns = this.isAdmin
      ? ['delete', 'account', 'accountRS', 'name', 'walletAlias', 'balanceNQT', 'multi_wallet', 'description', 'type', 'export']
      : ['delete', 'accountRS', 'name', 'walletAlias', 'balanceNQT', 'multi_wallet', 'description', 'type', 'export'];
    this.dataSource = new MatTableDataSource<Account>();

    this.storeService.ready
      .pipe(
        takeUntil(this.unsubscribeAll)
      )
      .subscribe(() => {
        this.getOldAccount();

        this.selectedAccount = this.accountService.currentAccount.value;
      });

    this.storeService.settings
      .pipe(
        takeUntil(this.unsubscribeAll)
      )
      .subscribe(({language}) => {
        this.locale = language;
      });

  }

  @ViewChild('table', {static: false}) table: MatTable<any>;

  drop(event: CdkDragDrop<Account[]>) {
    moveItemInArray(this.dataSource.data, event.previousIndex, event.currentIndex);
    this.dataSource.data = cloneDeep(this.dataSource.data);
    this.orderSaved = false;
  }

  saveAccountOrder() {
    this.storeService.updateAccountList(this.dataSource.data);
    this.orderSaved = true;
  }

  public getOldAccount(): void {
    this.storeService.getAllAccounts().then((accounts) => {
      this.accounts = accounts;
      this.dataSource.data = this.accounts;
      if (!this.isAdmin){
        this.dataSource.data = this.dataSource.data.filter((account: any) => {
          return account.type !== 'offline';
        });
      }
    });
    this.accountTransferService.getOldAccounts().subscribe((response: any) => {
      if (response && response.errorCode === 1) {
        this.notificationService.notify('error', this.i18nService.getTranslation(response.message));
      }
      if (response && response.errorCode === 0) {
        this.oldAccounts = response.result;
      }
    });
  }

  public getSelectedAccounts(): Array<Account> {
    return this.accounts.filter(({account}) => this.selectedAccounts[account]);
  }

  public deleteSelectedAccounts(): void {

    const selectedAccounts = this.getSelectedAccounts();

    const dialogRef = this.matDialog.open(DeleteAccountDialogComponent, {
      width: '400px',
      data: selectedAccounts
    });

    dialogRef.afterClosed()
      .pipe(
        takeUntil(this.unsubscribeAll)
      )
      .subscribe(confirm => {
        if (!confirm) {
          return;
        }
        let hasError = false;
        selectedAccounts.forEach((account) => {
          this.accountService
            .removeAccount(account)
            .then(() => {
              this.storeService.getAllAccounts().then((accounts) => {
                this.accounts = accounts;
                this.dataSource.data = this.accounts;

                if (!accounts || !accounts.length) {
                  this.router.navigate(['/']);
                  this.accountService.selectAccount(null);
                } else if (accounts.map(({account: a}) => a).indexOf(this.selectedAccount.account) < 0) {
                  this.accountService.selectAccount(accounts[0]);
                }
              });
            }).catch(e => hasError = true);
        });
        if (!hasError) {
          this.notificationService.notify('success', `Account(s) Deleted`);
        }
      });
  }

  openDialog(): void {
  }

  public ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    if (this.table) {
      this.table.renderRows();
    }
  }

  public applyFilter(filterValue: string): void {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  async activateAccount(account: Account): Promise<void> {
    try {
      await this.accountService.activateAccount(account);
      this.notificationService.notify('success', this.i18nService.getTranslation('activation_success'));
    } catch (e) {
      const activationFailed = this.i18nService.getTranslation('activation_failed');
      this.notificationService.notify('error', `${activationFailed} ${e.message}`);
    }
  }

  async importAccount(): Promise<void> {
    const settings = await this.storeService.getSettings();
    settings.isMultiWallet = false;
    await this.storeService.saveSettings(settings);
    this.router.navigate(['/login/passive']);
  }

  export(account: Account): void {
    const dialogRef = this.matDialog.open(ExportDialogComponent, {
      width: '750px',
      data: account
    });
    dialogRef.afterClosed().subscribe(ok => {
      this.getOldAccount();
    });
  }

  transfer(account: Account): void {
    const dialogRef = this.matDialog.open(TransferDialogComponent, {
      width: '800px',
      data: account
    });
    dialogRef.afterClosed().subscribe(ok => {
      this.getOldAccount();
    });
  }

  viewAccountDetail(account: Account): void {
    window.location.href = environment.fruitscanAccount + getAddressParams(account.accountRS);
    // this.router.navigate(['/account', getAddressParams(account.accountRS)]).catch(() => {
    //   this.notificationService.notify('error', this.i18nService.getTranslation('unknown_account'));
    // });
  }

  displayAlias(accountRS: any): string {
    return localStorage.getItem(accountRS);
  }

  updateAlias(accountRS: any, event: any): void {
    const value = event.target.value.trim();
    if (!value){
      localStorage.removeItem(accountRS);
      this.focusAliasWallet(accountRS, false);
      return;
    }
    if (value.length > 100){
      this.focusAliasWallet(accountRS, true);
      return this.notificationService.notify('error', this.i18nService.getTranslation('max_length_wallet_alias'));
    }

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key === accountRS){
        continue;
      } else {
        if (localStorage.getItem(key) === value){
          this.notificationService.notify('error', this.i18nService.getTranslation('wallet_alias_already_used'));
          this.focusAliasWallet(accountRS, true);
          return;
        }
      }
    }

    localStorage.setItem(accountRS, value);
    this.focusAliasWallet(accountRS, false);
  }

  focusAliasWallet(id: any, showInput): void {
    const inputAlias = document.getElementById(id);
    const nameAlias = document.getElementById(id + '1');
    if (showInput) {
      if (inputAlias) {
        setTimeout(() => {
          inputAlias.focus();
        });
        inputAlias.setAttribute('style', 'display: block;');
      }
      if (nameAlias) {
        nameAlias.setAttribute('style', 'display: none;');
      }
    } else {
      if (inputAlias) {
        inputAlias.setAttribute('style', 'display: none;');
      }
      if (nameAlias) {
        nameAlias.setAttribute('style', 'display: block;');
      }
    }
  }

  isOverflowing(element: HTMLElement): boolean {
    return isOverflowing(element);
  }

}
