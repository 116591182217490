import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {Account, Address, SuggestedFees, TransactionId} from '@fruitsjs/core';
import {Amount, convertBase64StringToString, convertHexStringToByteArray, CurrencySymbol} from '@fruitsjs/util';
import {NgForm} from '@angular/forms';
import {TransactionService} from 'app/main/transactions/transaction.service';
import {NotifierService} from 'angular-notifier';
import {I18nService} from 'app/layout/components/i18n/i18n.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {WarnSendDialogComponent} from '../warn-send-dialog/warn-send-dialog.component';
import {Recipient, RecipientType, RecipientValidationStatus} from '../../../layout/components/burst-recipient-input/burst-recipient-input.component';
import {StoreService} from '../../../store/store.service';
import {takeUntil} from 'rxjs/operators';
import {UnsubscribeOnDestroy} from '../../../util/UnsubscribeOnDestroy';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';
import {AccountBalances, getBalancesFromAccount} from '../../../util/balance';
import {isKeyDecryptionError} from '../../../util/exceptions/isKeyDecryptionError';
import {AccountService} from '../../../setup/account/account.service';
import {environment} from '../../../../environments/environment';
import {constants} from '../../../constants';
import {HttpClient, HttpParams} from '@angular/common/http';
import {PaymentGatewayService} from '../payment-gateway.service';
import {formatBalance} from '../../../util/formatAmount';
import {decryptAES, hashSHA256} from '@fruitsjs/crypto/src';
import {Settings} from '../../../settings';
import {IUnlockMultipleToken} from '../../../payment-gateway/nft.service';

interface CIP22Payload {
  amountPlanck: string | number;
  deadline: string | number;
  encrypt: string | boolean;
  feePlanck: string | number;
  immutable: string | boolean;
  message: string;
  messageIsText: string | boolean;
  recipient: string;
}

interface QRData {
  recipient: Recipient;
  amountNQT: string;
  feeNQT: string;
  immutable: boolean;
  feeSuggestionType: string;
  encrypt: boolean;
  messageIsText: boolean;
  message?: string;
  paymentId?: string;
}

const isNotEmpty = (value: string) => value && value.length > 0;
const asBool = (value: any, defaultValue: boolean): boolean => {
  if (value === undefined) {
    return defaultValue;
  }
  return value === 'true' || value === true;
};

@Component({
  selector: 'app-send-burst-form',
  templateUrl: './send-burst-form.component.html',
  styleUrls: ['./send-burst-form.component.scss']
})
export class SendBurstFormComponent extends UnsubscribeOnDestroy implements OnInit, AfterViewInit {
  @ViewChild('sendBurstForm', {static: true}) public sendBurstForm: NgForm;
  @ViewChild('amount', {static: true}) public amount: string;
  @ViewChild('message', {static: true}) public message: string;
  @ViewChild('fullHash', {static: false}) public fullHash: string;
  @ViewChild('encrypt', {static: true}) public encrypt: boolean;
  @ViewChild('pin', {static: true}) public pin: string;
  @ViewChild('messageIsText', {static: true}) public messageIsText: boolean;

  @Input() account: Account;
  @Input() fees: SuggestedFees;
  settings: Settings;
  advanced = false;
  showMessage = false;
  deadline = '24';
  immutable = false;
  isFree = true;
  freeAccounts = [];
  isAccountActive = false;
  paymentId;
  public recipient = new Recipient();
  public fee: string;
  isSending = false;
  language: string;
  balances: AccountBalances;
  symbol = CurrencySymbol;
  currencyListFrts;
  requestCurrency = constants.requestCurrency;
  currencySelectedFrts;
  currencyListCopy;
  priceFrts;
  isSelectingFrts = false;
  priceCurrency;
  changeFrts;
  key;
  total;
  price;
  decimals = constants.MAX_LENGTH_FRTS;
  node;

  constructor(private warnDialog: MatDialog,
              private transactionService: TransactionService,
              private notifierService: NotifierService,
              private i18nService: I18nService,
              private storeService: StoreService,
              private route: ActivatedRoute,
              private router: Router,
              private accountService: AccountService,
              private http: HttpClient,
              private paymentService: PaymentGatewayService) {
    super();
    this.storeService.settings
      .pipe(
        takeUntil(this.unsubscribeAll)
      )
      .subscribe(async ({language}) => {
        this.language = language;
      });

    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.applyDeepLinkParams(this.route.snapshot.queryParams);
      }
    });
    this.storeService.getSettings().then((settings: any) => {
      this.settings = settings;
    });

    this.storeService.getSettings().then((result: any) => {
      if (result && result.node) {
        this.node = result.node;
      }
    });
  }

  ngOnInit(): void {
    if (!this.account.keys) {
      this.router.navigate(['/dashboard']);
    }
    setTimeout(async () => {
      this.fee = '0';
      this.balances = getBalancesFromAccount(this.account);

      this.currencyListFrts = constants.currencies.map(currency => this.requestCurrency.includes(currency.code) ? currency : undefined).filter(currency => currency !== undefined);
      this.currencySelectedFrts = this.settings && this.settings.currency && this.settings.currency.frts
        ? this.currencyListFrts.filter(item => item.code === this.settings.currency.frts)[0] : this.currencyListFrts[0];
      this.currencyListCopy = this.currencyListFrts;
      await this.getPriceFrts();
    });

  }

  async getPriceFrts(): Promise<void> {
    if (this.currencySelectedFrts) {
      const params = new HttpParams()
        .set('source', CurrencySymbol)
        .set('destination', this.currencySelectedFrts.code);
      this.http.get(constants.coinMarketCapURL + '/fruits/converter/api', {
        params: params
      }).subscribe((response: any) => {
        if (response && response.errorCode === 0) {
          this.priceFrts = response.result;
          this.priceCurrency = this.priceFrts.priceSelectedCurrency;
        }
        this.isSelectingFrts = false;
      }, (e) => {
        console.warn(e);
        this.isSelectingFrts = false;
      });
    }
  }

  valueChange(newValue): void {
    if (this.priceCurrency) {
      this.amount = newValue;
      const value = newValue ? (newValue.endsWith('.') ? newValue.replace('.', '') : newValue) : '0';
      this.changeFrts = this.removeTrailingZeros(Amount.fromPlanck(value).getRaw().multipliedBy(Amount.fromPlanck(this.priceCurrency).getRaw()).toFixed(4));
    }
  }

  public removeTrailingZeros(s: string): string {
    return s.replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1');
  }

  ngAfterViewInit(): void {
    this.messageIsText = true;

    if (this.route.snapshot.queryParams) {
      setTimeout(() => {
        this.applyDeepLinkParams(this.route.snapshot.queryParams);
      }, 500);
    }
  }

  private applyDeepLinkParams(queryParams: Params): void {
    try {
      if (queryParams.cip22) {
        this.applyCIP22DeepLinkParams(queryParams);
      } else {
        this.applyLegacyDeepLinkParams(queryParams);
      }
    } catch (e) {
      this.notifierService.notify('warning', 'Invalid Deeplink parameters. Ignored');
    }
  }

  private applyCIP22DeepLinkParams(queryParams: Params): void {
    const {payload} = queryParams;
    const decodedPayload = convertBase64StringToString(payload);
    const {
      amountPlanck,
      deadline,
      encrypt,
      feePlanck,
      immutable,
      message,
      messageIsText,
      recipient,
    } = JSON.parse(decodedPayload) as CIP22Payload;

    this.onRecipientChange(new Recipient(recipient));

    this.amount = amountPlanck ? Amount.fromPlanck(amountPlanck).getSigna() : this.amount;
    this.fee = feePlanck ? Amount.fromPlanck(feePlanck).getSigna() : this.fee;
    this.message = message;
    this.messageIsText = asBool(messageIsText, this.messageIsText);
    this.immutable = asBool(immutable, this.immutable);
    this.encrypt = asBool(encrypt, this.encrypt);
    this.deadline = typeof deadline === 'number' && deadline > 0 ? '' + deadline : deadline as string;

    this.showMessage = !!message;
  }

  private applyLegacyDeepLinkParams(queryParams: Params): void {
    const {
      receiver,
      feeNQT,
      amountNQT,
      message,
      encrypt,
      immutable,
      messageIsText,
      feeSuggestionType,
      paymentId
    } = queryParams;

    if (paymentId) {
      this.paymentId = paymentId;
      this.getPayment(paymentId);
      return;
    }

    this.onRecipientChange(new Recipient(receiver));
    if (feeNQT) {
      this.fee = Amount.fromPlanck(feeNQT).getSigna();
    }

    if (amountNQT) {
      this.amount = Amount.fromPlanck(amountNQT).getSigna();
    }
    this.message = message;
    this.encrypt = asBool(encrypt, this.encrypt);
    this.immutable = asBool(immutable, this.immutable);
    this.messageIsText = asBool(messageIsText, this.messageIsText);
    if (feeSuggestionType && this.fees[feeSuggestionType]) {
      this.fee = Amount.fromPlanck(this.fees[feeSuggestionType]).getSigna();
    }
    this.showMessage = !!this.message;
  }

  getTotal(): Amount {
    try {
      return this.amount !== undefined && this.fee !== undefined
        ? Amount.fromSigna(this.amount).add(Amount.fromSigna(this.fee))
        : Amount.Zero();
    } catch (e) {
      return Amount.Zero();
    }
  }

  onSubmit(event): void {
    event.stopImmediatePropagation();

    try {
      Amount.fromPlanck(this.amount);
    } catch (e) {
      this.notifierService.notify('error', this.i18nService.getTranslation('invalid_eth_amount'));
      return;
    }

    if (this.recipient.status !== RecipientValidationStatus.VALID) {
      const dialogRef = this.openWarningDialog([this.recipient]);
      dialogRef.afterClosed().subscribe(ok => {
        if (ok) {
          this.sendBurst(this.recipient.addressRaw, this.recipient.publicKey);
        }
      });
    } else {
      this.sendBurst(this.recipient.addressRS, this.recipient.publicKey);
    }
  }

  async sendBurst(addressRS: string, recipientPublicKey = ''): Promise<void> {
    if (!this.messageIsText && !this.encrypt) {
      try {
        convertHexStringToByteArray(this.message);
      } catch (e) {
        this.notifierService.notify('error', this.i18nService.getTranslation('error_send_money_message'));
        return;
      }
    } else if (this.encrypt && !this.messageIsText) {
      this.notifierService.notify('error', this.i18nService.getTranslation('error_send_money_message1'));
      return;
    }

    const privateKey = decryptAES(this.account.keys.signPrivateKey, hashSHA256(this.pin));
    if (!privateKey) {
      this.notifierService.notify('error', this.i18nService.getTranslation('wrong_pin'));
      return;
    }

    let nftName;
    let order;
    let lockData;
    let unlockData: IUnlockMultipleToken;
    if (this.paymentId) {
      const senderPrivateKey = decryptAES(this.account.keys.signPrivateKey, hashSHA256(this.pin));
      const paymentOrder = await this.paymentService.isValidNFTOrder(
        this.paymentId,
        this.account.keys.publicKey,
        senderPrivateKey,
        this.account.account,
        this.node
      );

      if (!paymentOrder) {
        return;
      }

      if (paymentOrder && !paymentOrder.isDonate) {
        if (paymentOrder && paymentOrder.orderDetail) {
          order = paymentOrder.orderDetail.data;
          nftName = paymentOrder.nftName;
        }


        if (order.multiple) {
          lockData = paymentOrder.lock;
          unlockData = {
            nftName: nftName,
            message: this.account.account,
            signature: lockData.signature,
            lockId: lockData.lockId,
            seller: this.account.account,
            buyer: order.nftName,
            transaction: null
          };
        }
      }
    }

    try {
      this.isSending = true;
      const checkAddress = addressRS.startsWith('FRUITS-');
      const transaction: TransactionId = await this.transactionService.sendAmount({
        amount: Amount.fromSigna(this.amount).getPlanck(),
        fee: Amount.fromSigna(this.fee).getPlanck(),
        recipientId: checkAddress ? Address.fromReedSolomonAddress(addressRS).getNumericId() : addressRS,
        recipientPublicKey,
        keys: this.account.keys,
        pin: this.pin,
        message: this.message,
        shouldEncryptMessage: this.encrypt,
        messageIsText: this.messageIsText,
        deadline: Number(this.deadline)
      });
      this.notifierService.notify('success', this.i18nService.getTranslation('success_send_money'));

      if (this.paymentId) {
        await this.paymentService.updateTransactionId(
          this.paymentId, transaction.transaction, this.account.accountRS, order && order.multiple ? unlockData.lockId : null
        ).subscribe(async (res: any) => {
          if (res.code === 0) {
            window.location.href = `${environment.isMainNet ? constants.paymentStatusMainnet : constants.paymentStatusTestnet}/#/buyer/transaction-status/${this.paymentId}`;
          } else {
            this.notifierService.notify('error', this.i18nService.getTranslation('payment_connect_error'));
          }
        }, () => {
          this.notifierService.notify('error', this.i18nService.getTranslation('payment_connect_error'));
        });
      } else {
        this.sendBurstForm.resetForm();
        await this.router.navigate(['/frts-transactions']);
      }
    } catch (e) {

      // if transaction fail, check and unlock nft
      if (nftName) {
        try {
          if (order.multiple) {
            await this.paymentService.unlockMultipleToken(unlockData);
          } else {
            await this.paymentService.unlockNft(nftName).toPromise();
          }

        } catch (ex) {}
      }

      if (isKeyDecryptionError(e)) {
        this.notifierService.notify('error', this.i18nService.getTranslation('wrong_pin'));
      } else {
        if (e.message && e.message.indexOf('Invalid arbitrary') !== -1) {
          this.notifierService.notify('error', this.i18nService.getTranslation('error_message_length'));
        } else {
          this.notifierService.notify('error', this.i18nService.getTranslation('error_send_money'));
        }
      }
    } finally {
      this.immutable = false;
      this.isSending = false;
    }
  }


  private openWarningDialog(recipients: Array<Recipient>): MatDialogRef<any> {
    return this.warnDialog.open(WarnSendDialogComponent, {
      width: '400px',
      data: recipients
    });
  }

  hasSufficientBalance(): boolean {
    if (!this.balances) {
      return false;
    }

    const available = this.balances.availableBalance.clone();
    return available
      .subtract(this.getTotal())
      .greaterOrEqual(Amount.Zero());
  }

  isMessageTooLong(): boolean {
    return this.message && this.message.length > 1000;
  }

  canSubmit(): boolean {
    const deadline = Number(this.deadline);
    const checkValid = (deadline && deadline >= 0 && deadline <= 24) || deadline === 0;
    return (isNotEmpty(this.recipient.addressRaw) || isNotEmpty(this.recipient.addressRS)) &&
      this.recipient.status !== RecipientValidationStatus.UNKNOWN &&
      isNotEmpty(this.amount) &&
      isNotEmpty(this.pin) &&
      !this.isMessageTooLong() &&
      this.hasSufficientBalance()
      && this.deadline && checkValid;
  }

  onRecipientChange(recipient: Recipient): void {
    this.recipient = recipient;
    this.accountService.getAccount(this.recipient.addressRaw).then((account: any) => {
      if (!account || !account.publicKey) {
        this.isAccountActive = false;
        this.encrypt = false;
      } else {
        this.isAccountActive = true;
      }
    }).catch(() => {
      this.isAccountActive = false;
      this.encrypt = false;
    });
    // if (recipient.addressRS && this.freeAccounts.map(item => item.toString()).includes(Address.fromReedSolomonAddress(recipient.addressRS).getNumericId())) {
    //   this.fee = '0';
    //   this.isFree = true;
    // } else {
    //   // this.fee = Amount.fromPlanck(this.fees.standard.toString(10)).getSigna();
    //   this.isFree = false;
    // }
  }

  onQRUpload(qrData: QRData): void {
    this.recipient = qrData.recipient;
    this.amount = qrData.amountNQT ? Amount.fromPlanck(qrData.amountNQT).getSigna() : '0';
    this.paymentId = qrData.paymentId;
    if (this.paymentId) {
      this.immutable = true;
    }
    // this.fee = Amount.fromPlanck(qrData.feeNQT).getSigna();
    // this.immutable = qrData.immutable;
    // this.encrypt = qrData.encrypt;
    // this.message = qrData.message;
    // this.messageIsText = true;
    // this.showMessage = !!qrData.message;
    // if (qrData.feeSuggestionType && this.fees[qrData.feeSuggestionType]) {
    //   this.fee = Amount.fromPlanck(this.fees[qrData.feeSuggestionType]).getSigna();
    // }
  }

  onSpendAll(): void {
    if (!this.balances) {
      return;
    }

    if (this.immutable) {
      return;
    }

    const available = this.balances.availableBalance.clone();
    const fee = Amount.fromSigna(this.fee || '0');
    this.amount = available.subtract(fee).getSigna();
    this.valueChange(this.amount);
  }

  async getPayment(id: string): Promise<any> {
    const url = `${environment.isMainNet ? constants.paymentApiMainnet : constants.paymentApiTestnet}/webapi/buyer/detail-checkout?paymentId=${id}`;
    try {
      const fetchData = await fetch(url);
      const response = await fetchData.json();
      if (response.code === 0) {
        this.amount = response.data.remaining ? formatBalance(response.data.remaining + '', 'FRTS') : '0';
        this.onRecipientChange(new Recipient(response.data.address));
        this.immutable = true;
      } else {
        this.notifierService.notify('error', this.i18nService.getTranslation('payment_connect_error'));
        await this.router.navigate(['/send']);
      }
    } catch (e) {
      this.notifierService.notify('error', this.i18nService.getTranslation('payment_connect_error'));
      await this.router.navigate(['/send']);
    }
  }
}

export enum NotificationTransactionType {
  FRTS = 'FRTS',
  ETH = 'ETH',
  BTC = 'BTC'
}

export enum NotificationTransactionSubType {
  PAYMENT = 'PAYMENT'
}
