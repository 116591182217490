import {Component, Input, OnInit} from '@angular/core';

export interface BreadcrumbInfo {
  path: string;
  label: string;
}

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit{

  @Input()
  title: string;

  @Input()
  breadcrumbs: BreadcrumbInfo[];

  @Input()
  wide: boolean;

  @Input()
  createProposal: boolean;

  @Input()
  subtitle: string;

  bgImgSrc: string;

  ngOnInit(): void {
    // const bgSrc = sample(BackgroundImages);
    // this.bgImgSrc = `url(${bgSrc})`;
  }


}
