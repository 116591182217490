import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';

@Component({
    selector       : 'fuse-navigation',
    templateUrl    : './navigation.component.html',
    styleUrls      : ['./navigation.component.scss']
})
export class FuseNavigationComponent implements OnInit
{
    @Input()
    layout = 'vertical';

    @Input()
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Load the navigation either from the input or from the service
        this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();
        this.navigation = this.navigation.map(item => {
          if (item.id === 'account') {
            item.children = item.children.filter(child => child.id !== 'assets');
            if (!environment.isAdmin) {
              item.children = item.children.filter(child => child.id !== 'nfts'
                && child.id !== 'aliases_list'
                && child.id !== 'nft_collection'
                && child.id !== 'nft_utility'
                && child.id !== 'messages'
                && child.id !== 'create_utility_nft'
                && child.id !== 'notifications'
                && child.id !== 'banner');
            }
          }
          if (item.id === 'mining' || item.id === 'network') {
            item.type = 'group';
            item.hidden = undefined;
          }
          if (item.id === 'smile/tfsts') {
            if (!environment.isAdmin) {
              item.children = item.children.filter(child => child.id !== 'issue_nft' && child.id !== 'create_utility_nft' && child.id !== 'issueToken');
            }
          }
          return item;
        });

        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Load the navigation
                this.navigation = this._fuseNavigationService.getCurrentNavigation();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
         .subscribe(() => {

             // Mark for check
             this._changeDetectorRef.markForCheck();
         });
    }
}
