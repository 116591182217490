<mat-toolbar class="p-0 mat-elevation-z1 h-75">

  <div *ngIf="!isMainNet" class="testnet-stamp" fxHide fxShow.gt-sm>TestNet</div>

    <div class="toolbar-content" fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && !rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

<!--            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>-->

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <img class="logo-icon" src="assets/images/logos/icon_white.svg">
                </div>
            </div>

            <div class="px-8 px-md-16">
                <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>
            </div>

        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">

            <a 
              [href]="fruitscanAccount + getAddressParams(convertIdToAddress(selectedAccount.oldAccount))"
               target="_blank"
               *ngIf="selectedAccount.oldAccount"
               style="min-width: 200px; text-decoration: none; padding: 0 15px;"
               class="history-button">
              <div>{{ 'view_old_wallet' | i18n }}</div>
              <div>{{ 'view_old_wallet_1' | i18n }}</div>
            </a>

            <button mat-button [matMenuTriggerFor]="userMenu"
                    class="user-button account-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <span class="username mr-12" fxHide fxShow.gt-md>
                        <ng-container *ngIf="selectedAccount.type === 'offline'">
                          <img style="margin-right: 10px;" src="./assets/icons/menu/icon-user-watch.png" height="24" width="24" alt=""/>
                        </ng-container>
                        <ng-container *ngIf="selectedAccount.type !== 'offline' && selectedAccount.confirmed">
                           <img style="margin-right: 10px;" src="./assets/icons/menu/icon-user-active.png" height="24" width="24" alt=""/>
                        </ng-container>
                        <ng-container *ngIf="selectedAccount.type !== 'offline' && !selectedAccount.confirmed">
                            <img style="margin-right: 10px;" src="./assets/icons/menu/icon-user-locked.png" height="24" width="24" alt=""/>
                        </ng-container>
                      <div *ngIf="!selectedAccount.multiWallet">
                        <div class="wallet-type">{{ 'frts_wallet' | i18n }}</div>
                        <div class="user">{{selectedAccount.accountRS}}</div>
                        <div class="user flex">
                          <div #address class="address"
                               matTooltip="{{displayAddress(selectedAccount)}}"
                               [matTooltipDisabled]="!isOverflowing(address)"
                               matTooltipClass="break-all"
                          >

                          {{displayAddress(selectedAccount)}}
                        </div>
                        </div>

                      </div>
                      <div *ngIf="selectedAccount.multiWallet">
                        <div class="wallet-type">{{ 'multi_wallet' | i18n }}</div>
                        <div class="user">{{selectedAccount.accountRS}}</div>
                        <div class="user flex">
                          <div #address class="address"
                             matTooltip="{{displayAddress(selectedAccount)}}"
                             [matTooltipDisabled]="!isOverflowing(address)"
                             matTooltipClass="break-all"
                          >
                            {{displayAddress(selectedAccount)}}
                          </div>
                        </div>
                      </div>
                    </span>
                    <mat-icon class="s-16">keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu class="user-mat-menu" #userMenu="matMenu" [overlapTrigger]="false">

                <div *ngFor="let account of accounts">
                    <button mat-menu-item class="option-account" (click)="setAccount(account)"
                            [ngStyle]="{'background-color': selectedAccount.accountRS === account.accountRS ? '#fcc9b4' : ''}"
                            *ngIf="(!isAdmin && account.type !== 'offline') || (isAdmin)"
                    >
                        <span *ngIf="account" class="username mr-12">
                            <ng-container *ngIf="account.type === 'offline'">
                              <img style="margin-right: 10px;" class="account-type-icon" src="./assets/icons/menu/icon-user-watch.png" height="24" width="24" alt=""/>
                            </ng-container>
                            <ng-container *ngIf="account.type !== 'offline' && account.confirmed">
                              <img style="margin-right: 10px;" class="account-type-icon" src="./assets/icons/menu/icon-user-active.png" height="24" width="24" alt=""/>
                            </ng-container>
                            <ng-container *ngIf="account.type !== 'offline' && !account.confirmed">
                              <img style="margin-right: 10px;" class="account-type-icon" src="./assets/icons/menu/icon-user-locked.png" height="24" width="24" alt=""/>
                            </ng-container>
                            <div *ngIf="!account.multiWallet">
                              <div class="wallet-type">{{ 'frts_wallet' | i18n }}</div>
                              <div class="user">{{account.accountRS}}</div>
                              <div #address class="user address"
                                   matTooltip="{{displayAddress(account)}}"
                                   [matTooltipDisabled]="!isOverflowing(address)"
                                   matTooltipClass="break-all"
                              >
                                {{displayAddress(account)}}
                              </div>
                            </div>
                            <div *ngIf="account.multiWallet">
                              <div class="wallet-type">{{ 'multi_wallet' | i18n }}</div>
                              <div class="user">{{account.accountRS}}</div>
                              <div #address class="user address"
                                   matTooltip="{{displayAddress(account)}}"
                                   [matTooltipDisabled]="!isOverflowing(address)"
                                   matTooltipClass="break-all"
                              >
    <!--                            {{ ('multi_wallet' | i18n) + ' ' + (account.multiWalletIndex < 10 ? '0' + account.multiWalletIndex : account.multiWalletIndex) }}-->
                                {{displayAddress(account)}}
                              </div>
                            </div>
                        </span>
                    </button>
                </div>
                <mat-divider></mat-divider>
                <button mat-menu-item class="manage-accounts" routerLink="/accounts">
                  <span class="username mr-12">
                    <img style="margin-right: 10px;" class="account-type-icon" src="./assets/icons/menu/icon-payout.png" height="24" width="24" alt=""/>
                    <span class="user">{{ 'manage_accounts' | i18n }}</span>
                  </span>
<!--                    <mat-icon>people</mat-icon>-->
                </button>

            </mat-menu>

<!--            <div class="toolbar-separator"></div>-->

            <button *ngIf="selectedLanguage" mat-button
                    class="language-button account-button"
                    [matMenuTriggerFor]="languageMenu">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <span style="margin-right: 10px;" class="iso text-uppercase user" fxHide fxShow.gt-xs>{{selectedLanguage.name}}</span>
                    <span class="iso text-uppercase" fxHide.gt-xs>
                      <mat-icon class="secondary-text">language</mat-icon>
                    </span>
                    <mat-icon class="s-16">keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
                <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <span class="iso">{{lang.name}}</span>
                    </span>
                </button>
            </mat-menu>

<!--            <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>-->

            <button mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

        </div>

    </div>

</mat-toolbar>
