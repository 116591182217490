import {environment} from '../environments/environment';
import {AddressPrefix} from '@fruitsjs/core/src';
import {Address} from '@fruitsjs/core';

export function getErrormessage(message): string {
  switch (message) {
    case 'Not enough funds':
      return 'error_not_enough_funds';
    case 'Transaction is invalid!':
      return 'create_swap_order_failed';
    default:
      return message;
  }
}


export const getReedSolomonAddress = (address: string): string => {
  const prefix = environment.isMainNet ? AddressPrefix.MainNet : AddressPrefix.TestNet;
  const addressArr = address.match(/.{1,4}/g);
  return [prefix, ...addressArr].join('-');
};

export const isReedSolomonAddress = (address: string): boolean => {
  try {
    Address.fromReedSolomonAddress(getReedSolomonAddress(address));
    return true;
  } catch (e) {
    return false;
  }
};

export const nFormatter = (num: number, digits: number): any => {
        const lookup = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'K' },
        { value: 1e6, symbol: 'M' },
        { value: 1e9, symbol: 'G' },
        { value: 1e12, symbol: 'T' },
        { value: 1e15, symbol: 'P' },
        { value: 1e18, symbol: 'E' }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        const item = lookup.slice().reverse().find((i: any) => num >= i.value);
        return item ? (num / item.value).toFixed(digits).replace(rx, '$1').replace('.', ',') + item.symbol : '0';
};

export const getAddressParams = (reedSolomonAddress: string): string => {
  return reedSolomonAddress.substr(reedSolomonAddress.indexOf('-') + 1).split('-').join('');
};

export const formatAmount = (balance: any): string => {
    const intPart = balance.substr(0, balance.indexOf('.'));
    return intPart ? intPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + balance.substr(balance.indexOf('.'))
      : balance.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
