import { Component, OnInit } from '@angular/core';
import { Account } from '@fruitsjs/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from 'app/setup/account/account.service';
import { StoreService } from 'app/store/store.service';
import { UnsubscribeOnDestroy } from '../../../util/UnsubscribeOnDestroy';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import {constants} from '../../../constants';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-send-burst',
  templateUrl: './send-eth.component.html',
  styleUrls: ['./send-eth.component.scss']
})
export class SendEthComponent extends UnsubscribeOnDestroy implements OnInit {
  account: Account;
  language: string;
  isAdmin = false;

  coin = 'eth';
  redirectTo = 'send';

  constructor(private route: ActivatedRoute,
              private accountService: AccountService,
              private storeService: StoreService,
              private title: Title) {
    super();
    this.title.setTitle(constants.pageTitle + 'Send ETH');
  }


  ngOnInit(): void {
    this.account = this.route.snapshot.data.account as Account;
    this.isAdmin = environment.isAdmin;

    this.storeService.settings
      .pipe(
        takeUntil(this.unsubscribeAll)
      )
      .subscribe(async ({ language }) => {
        this.language = language;
      }
      );

    this.storeService.ready
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((ready) => {
        if (!ready) {
          return;
        }
        this.accountService.currentAccount
          .pipe(takeUntil(this.unsubscribeAll))
          .subscribe((account) => {
            this.account = account;
          });
      });
  }
}
