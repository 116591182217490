import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {filter, switchMap} from 'rxjs/operators';
import {StoreService} from 'app/store/store.service';
import {AccountService} from 'app/setup/account/account.service';
import {ApiService} from '../api.service';
import {FuseConfigService} from '../../@fuse/services/config.service';
import {environment} from 'environments/environment';

const errorHandler = console.error;

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  authorized = false;

  constructor(private storeService: StoreService,
              private accountService: AccountService,
              private apiService: ApiService,
              private router: Router,
              private _fuseConfigService: FuseConfigService) {
  }

  hideMenus(): void {
    this._fuseConfigService.config = {
      layout: {
        navbar   : {
          hidden: true
        },
        toolbar  : {
          hidden: true
        },
        footer   : {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.storeService.ready.pipe(
      filter(Boolean),
      switchMap(async () => {
        const settings: any = await this.storeService.getSettings().catch(errorHandler);
        const selectedAccount = await this.storeService.getSelectedAccount().catch(errorHandler);
        const allAccounts = await this.storeService.getAllAccounts().catch(errorHandler);
        // caches the current nodes api version
        await this.apiService.fetchBrsApiVersion();

        const queryParams: any = {};
        if (route.queryParams) {
          const redirect = route.routeConfig.path;
          if (ACCEPT_REDIRECT_LINK.includes(redirect)) {
            queryParams.redirect = redirect;
            queryParams.paymentId = route.queryParams.paymentId;
            settings.agree = true;
            await this.storeService.saveSettings(settings);
          }
        }

        // User must agree to disclaimer
        if (environment.isBeta && !settings.isAuthorized) {
          await this.router.navigate(['/authorize'], {queryParams: queryParams});
          this.hideMenus();
          return false;
        } else if (!(settings && settings.agree)) {
          await this.router.navigate(['/disclaimer'], {queryParams: queryParams});
          this.hideMenus();
          return false;
        } else if (selectedAccount) {
          this.accountService.setCurrentAccount(selectedAccount);
          return true;
        } else if (allAccounts && allAccounts.length) {
          await this.router.navigate(['/dashboard']);
          return false;
        } else {
          await this.router.navigate(['/language'], {queryParams: queryParams});
          this.hideMenus();
          return false;
        }
      })
    );
  }
}

// Redirect to send coin, used for payment gateway if user is not logged in
export const ACCEPT_REDIRECT_LINK = ['send', 'send/btc', 'send/eth', 'send/payment'];
