import {Component, Inject, OnInit} from '@angular/core';
import {burstAddressPattern} from '../../../util/burstAddressPattern';
import {Account, AddressPrefix} from '@fruitsjs/core';
import {Amount, CurrencySymbol} from '@fruitsjs/util/src';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountService} from '../../../setup/account/account.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {NotifierService} from 'angular-notifier';
import {NetworkService} from '../../../network/network.service';
import {I18nService} from '../../../layout/components/i18n/i18n.service';
import {StoreService} from '../../../store/store.service';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {HttpClient, HttpParams} from '@angular/common/http';
import {NodeService} from '../../../main/node.service';
import {environment} from '../../../../environments/environment';
import {getBalancesFromAccount} from '../../../util/balance';
import {KeyDecryptionException} from '../../../util/exceptions/KeyDecryptionException';
import {decryptAES, hashSHA256, Keys} from '@fruitsjs/crypto';
import {ConfirmCreateNftComponent} from '../confirm-create-nft/confirm-create-nft.component';
import {constants} from '../../../constants';
import {forkJoin} from 'rxjs';
import * as _ from 'lodash';
import {NftService} from '../../nft.service';
import {OrderType} from '../sell-nft/sell-nft.component';
import {isNumeric} from "rxjs/internal-compatibility";
import {NFT_TYPE} from "../edit-nft/edit-nft.component";

@Component({
  selector: 'app-create-nft',
  templateUrl: './create-nft.component.html',
  styleUrls: ['./create-nft.component.scss', '../nft-payment.scss']
})
export class CreateNftComponent implements OnInit {

  MAX_QUANTITY = 1000000;

  isVerify = true;
  fee = 1;
  nftName: string;
  advanced = false;
  showMessage = false;
  addressPatternRef = burstAddressPattern;
  type = 'uri';
  account: Account;
  deadline = '24';
  addressPrefix: AddressPrefix.MainNet | AddressPrefix.TestNet;
  symbol = CurrencySymbol;
  nftTypes = [0, 1, 2, 3];
  tokenType = 'MULTIPLE_TOKEN';
  tokenTypes = [];
  nftType;
  key;
  quantity = 1;
  nftCategories;
  nftCategoriesSingle;
  nftCategory;
  isAccountActive = true;
  uploadImage;
  fileAttr;
  file;
  nftNumber;
  pin;
  description;
  isLoading = false;
  node;
  format;
  url;
  isPro = false;
  collections = [];
  selectedCollection;
  royalties = '0';
  collectionUrl = environment.isMainNet ? constants.NFT_COLLECTION_MAINNET : constants.NFT_COLLECTION_TESTNET;
  balances;
  orderResponse: any;
  params: any;
  nft3DAccounts = environment.isMainNet ? constants.nft3DAccountsMainNet : constants.nft3DAccountsTestnet;
  is3DAccount = true;

  uploadImagePreview;
  fileAttrPreview;
  filePreview;
  urlPreview;
  formatPreview;
  nftCategoryMultiple;
  token;
  nft;
  disableRegister = false;

  // Gold NFT
  serialNumber: string;
  eSpecialTypes: ISpecialType[] = [];
  selectedType: any = '';
  goldNftAccounts: string[] = [];
  nftWeight: any;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private accountService: AccountService,
              private warnDialog: MatDialog,
              private notifierService: NotifierService,
              private networkService: NetworkService,
              private i18nService: I18nService,
              private storeService: StoreService,
              private domSanitizer: DomSanitizer,
              private http: HttpClient,
              private nodeService: NodeService,
              private nftService: NftService,
              private title: Title) {
    this.title.setTitle(constants.pageTitle + 'Create NFT');

    this.storeService.getSettings().then((result: any) => {
      if (result && result.node) {
        this.node = result.node;
      }
    });
    this.storeService.getSelectedAccount().then(account => {
      this.account = account;
      this.is3DAccount = this.nft3DAccounts.includes(this.account.account);
      this.balances = getBalancesFromAccount(this.account);
      this.accountService.getAccount(this.account.account).catch(() => {
        this.isVerify = false;
      });

      this.route.queryParams.subscribe(async params => {
        if (params && params.orderId) {
          const rqOrderParams = new HttpParams().set('orderId', params.orderId);
          this.nftService.getNftOrder(rqOrderParams).subscribe(async rqOrderResponse => {
            if (rqOrderResponse && rqOrderResponse.errorCode === 1) {
              this.notifierService.notify('error', this.i18nService.getTranslation(rqOrderResponse.message));
              await this.router.navigate(['/']);
              return;
            }

            if (rqOrderResponse && rqOrderResponse.errorCode === 0) {
              this.orderResponse = rqOrderResponse.result;
              if (this.orderResponse.orderType !== OrderType[OrderType.CREATE]) {
                this.notifierService.notify('error', this.i18nService.getTranslation('invalid_order_type'));
                await this.router.navigate(['/']);
              }
              this.params = JSON.parse(rqOrderResponse.result.params);
            }
          });
        } else {
          this.notifierService.notify('error', this.i18nService.getTranslation('order_not_found'));
          await this.router.navigate(['/']);
        }
      });
    });
    this.tokenTypes = Object.keys(Token);
    this.tokenType = this.tokenTypes[1];
  }

  ngOnInit(): void {
    this.tokenType = 'MULTIPLE_TOKEN'; // Fix to multi NFT
    if (!this.account.keys) {
      this.router.navigate(['/']);
    }
    this.addressPrefix = this.networkService.isMainNet() ? AddressPrefix.MainNet : AddressPrefix.TestNet;

    this.getTypeAndNumber();
    this.getSpecialTokenTypes();
  }

  resetForm(): void {
    this.file = '';
    this.description = '';
    this.nftName = '';
    this.royalties = '0';
    this.quantity = 1;
    this.filePreview = '';
    this.pin = '';
    this.resetFile();
    this.resetFilePreview();
  }

  async getTypeAndNumber(): Promise<void> {

    const utilitiesReq = this.nftService.getCollections();
    const typesReq = this.nftService.getNftType();

    await forkJoin([utilitiesReq, typesReq]).subscribe(async (response: any) => {

      if (response[0].errorCode !== 0) {
        this.notifierService.notify('error', this.i18nService.getTranslation(response[0].message));
      } else if (response[1].errorCode !== 0) {
        this.notifierService.notify('error', this.i18nService.getTranslation(response[1].message));
      }

      const utilities = response[0].result;
      const types = response[1].result;

      this.collections = this.nft3DAccounts.includes(this.account.account) ? utilities : utilities.filter(u => u.name !== 'Official');

      this.nftCategories = types;
      this.nftCategoryMultiple = types.filter(category => category.tokenType === Token.MULTIPLE_TOKEN);
      this.nftCategoriesSingle = types.filter(category => category.tokenType === Token.SINGLE_TOKEN);
      this.nftCategory = this.nftCategoriesSingle.find(category => category.name === 'PFT');
      this.nftCategory = this.tokenType === Token.SINGLE_TOKEN ? this.nftCategory || this.nftCategories[0] : this.nftCategoryMultiple[0];
      this.nftCategories = this.tokenType === Token.SINGLE_TOKEN ? this.nftCategoriesSingle : this.nftCategoryMultiple;
      this.nftNumber = this.nftCategory.currentIndex + +1;
    }, error => {
      console.warn(error);
    });

    this.deadline = '24';
    this.isPro = false;
  }

  openDialog(): MatDialogRef<any> {
    return this.warnDialog.open(ConfirmCreateNftComponent, {
      width: '400px',
      data: {
        nftNumber: this.nftNumber
      }
    });
  }

  async onSubmit(event): Promise<void> {
    event.stopImmediatePropagation();

    const validateForm = this.validateForm();
    if (validateForm) {
      this.notifierService.notify('error', validateForm);
      return;
    }

    if (this.isDescriptionTooLong()) {
      return;
    }

    try {
      this.isLoading = true;
      const data = new FormData();
      data.append('type', this.nftType);
      data.append('file', this.file);
      data.append('nftType', this.nftCategory.name);
      data.append('description', this.description);
      data.append('name', this.nftName.trim());
      data.append('createdBy', this.account.account);
      data.append('isPro', String(this.isPro));
      data.append('publicKey', this.account.keys.publicKey);
      data.append('feeNQT', Amount.fromSigna(this.fee).getPlanck());
      data.append('deadline', (Number(this.deadline) * 60).toString());
      data.append('groupId', this.selectedCollection.id);
      data.append('royalties', this.royalties);
      data.append('quantity', this.quantity.toString());
      if (this.nftType === 2 || this.nftType === 3) {
        data.append('filePreview', this.filePreview);
      }

      if (this.selectedType) {
        data.append('specialType', this.selectedType);
        data.append('serialNumber', this.serialNumber);
        if(this.nftWeight){
          data.append('weight', this.nftWeight);
        }
      }

      if (this.selectedType) {
        this.createNft(data);
      } else {
        this.tokenType === Token.SINGLE_TOKEN ? this.createNft(data) : this.createMultiToken(data);
      }

    } catch (e) {
      this.notifierService.notify(constants.ERROR, this.i18nService.getTranslation('error_create_nft'));
      this.isLoading = false;
    }
  }

  createNft(data: any): any {
    this.nftService.createNft(data).subscribe(async (response: any) => {
      if (response && response.errorCode === 1) {
        this.notifierService.notify('error', this.i18nService.getTranslation(response.message));
        this.isLoading = false;
      }
      if (response && response.errorCode === 0) {

        const unsignedTransactionBytes = response.result.unsignedTransactionBytes;
        const {transaction} = await this.nodeService.signAndBroadcastTransaction(unsignedTransactionBytes,
          this.getSendersSignPrivateKey(this.pin, this.account.keys),
          this.account.keys.publicKey);
        if (transaction) {
          const transactionData = new FormData();
          transactionData.append('nftName', this.nftName.trim());
          transactionData.append('transactionId', transaction);
          transactionData.append('type', NftStatus[NftStatus.CREATE]);
          transactionData.append('seller', this.account.account);

          this.nftService.createNftTransaction(transactionData).subscribe(async (txResponse: any) => {
            if (txResponse && txResponse.errorCode === 1) {
              this.notifierService.notify('error', this.i18nService.getTranslation(txResponse.message));
              this.isLoading = false;
            }
          }, error => {
            console.warn(error);
          });
          this.notifierService.notify('success', this.i18nService.getTranslation('create_nft_1')
            + ' #' + response.result.nftIndex + ' '
            + this.i18nService.getTranslation('successful'));
          this.type = 'uri';
          await this.getTypeAndNumber();

          this.disableRegister = true;
          setTimeout(() => {
            // this.isLoading = false;
            window.open(`${this.collectionUrl}/nft/my-nfts?account=${this.account.accountRS}&status=success`, '_self');
          }, 2000);

        } else {
          this.disableRegister = false;
          this.isLoading = false;
          this.notifierService.notify('error', this.i18nService.getTranslation('error_create_nft'));
        }
      }
    }, () => {
      this.notifierService.notify('error', this.i18nService.getTranslation('error_create_nft'));
      this.isLoading = false;
    });
  }

  createMultiToken(data: any): any {
    this.nftService.createMultiToken(data).subscribe(async (response: any) => {
      if (response && response.errorCode === 1) {
        this.notifierService.notify(constants.ERROR, this.i18nService.getTranslation(response.message));
        this.isLoading = false;
      }
      if (response && response.errorCode === 0) {

        const unsignedTransactionBytes = response.result.unsignedTransactionBytes;
        const {transaction} = await this.nodeService.signAndBroadcastTransaction(unsignedTransactionBytes,
          this.getSendersSignPrivateKey(this.pin, this.account.keys),
          this.account.keys.publicKey);
        if (transaction) {
          const transactionData = new FormData();
          transactionData.append('nftName', this.nftName.trim());
          transactionData.append('transactionId', transaction);
          transactionData.append('type', NftStatus[NftStatus.CREATE]);
          transactionData.append('quantity', this.quantity.toString());
          transactionData.append('seller', this.account.account);

          this.nftService.createMultipleNftTransaction(transactionData).subscribe(async (txResponse: any) => {
            if (txResponse && txResponse.errorCode === 1) {
              this.notifierService.notify(constants.ERROR, this.i18nService.getTranslation(txResponse.message));
              this.isLoading = false;
            }
          }, error => {
            console.warn(error);
          });

          this.notifierService.notify(constants.SUCCESS, this.i18nService.getTranslation('create_nft_1')
            + ' #' + response.result.nftIndex + ' '
            + this.i18nService.getTranslation('successful'));
          this.type = 'uri';
          await this.getTypeAndNumber();
          this.disableRegister = true;
          setTimeout(() => {
            // this.isLoading = false;
            window.open(`${this.collectionUrl}/nft/my-nfts?account=${this.account.accountRS}&status=success`, '_self');
          }, 2000);

        } else {
          this.disableRegister = false;
          this.isLoading = false;
          this.notifierService.notify('error', this.i18nService.getTranslation('error_create_nft'));
        }
      }
    }, () => {
      this.notifierService.notify('error', this.i18nService.getTranslation('error_create_nft'));
      this.isLoading = false;
    });
  }

  getUtilityCollection(): any {
    // tslint:disable-next-line:triple-equals
    return _.filter(this.collections, (el) => el.type == 1);
  }

  getCollection(): any {
    // tslint:disable-next-line:triple-equals
    return _.filter(this.collections, (el) => el.type == 0);
  }

  canSubmit(): boolean {

    if (this.selectedType && !this.serialNumber) {
      return false;
    }

    return this.pin && this.pin.length >= 1
      && ([0, 1, 2, 3].includes(this.nftType))
      && this.nftCategory
      && (this.nftName && !!this.nftName.trim())
      && !!this.file
      && !!this.description && this.description.length <= 1000
      && !!this.selectedCollection
      && !!this.deadline
      && this.quantity
      && (this.nftType !== 2 || (this.nftType === 2 && !!this.filePreview));
  }

  validateForm(): string {
    if (!this.isVerify) {
      return this.i18nService.getTranslation('error_not_enough_funds');
    }

    const fee = Amount.fromSigna(this.fee || '0');
    const hasBalanceToPayFee = this.balances.availableBalance.clone()
      .greaterOrEqual(fee);
    if (!hasBalanceToPayFee) {
      return this.i18nService.getTranslation('error_not_enough_funds');
    }

    if(this.nftWeight < 0){
      return this.i18nService.getTranslation('error_weight_1');
    }
    if (![0, 1, 2, 3].includes(this.nftType)) {
      return this.i18nService.getTranslation('type_error1');
    }

    if (!this.nftCategory) {
      return this.i18nService.getTranslation('category_error1');
    }

    if (!this.nftName || this.nftName.trim().length === 0) {
      return this.i18nService.getTranslation('nft_name_error2');
    }

    if (!this.isNftNameValid(this.nftName.trim())) {
      return this.i18nService.getTranslation('nft_name_error_3');
    }

    if (this.nftName.length > 256) {
      return this.i18nService.getTranslation('error_nft_name_length_1');
    }

    if (!this.selectedCollection) {
      return this.i18nService.getTranslation('collection_required');
    }

    if (!this.file) {
      return this.i18nService.getTranslation('nft_file_error1');
    }

    if (this.nftType === 0
      && !this.file.type.includes('png')
      && !this.file.type.includes('jpeg')
      && !this.file.type.includes('gif')) {
      return this.i18nService.getTranslation('error_file_type_6').replace('__type__', constants.imageSupported);
    }

    if (this.nftType === 1
      && !this.file.name.endsWith('mp4')
      && !this.file.name.endsWith('webm')) {
      return this.i18nService.getTranslation('error_file_type_6').replace('__type__', constants.videoSupported);
    }

    if (this.nftType === 2) {
      if (!this.filePreview) {
        return this.i18nService.getTranslation('error_file_type_6').replace('__type__', constants.imageSupported);
      }

      if (!this.file.name.endsWith('.glb') && !this.file.name.endsWith('.gltf')) {
        return this.i18nService.getTranslation('error_file_type_6').replace('__type__', constants.threeDSupported);
      }

      if (!this.filePreview.type.includes('image')) {
        return this.i18nService.getTranslation('error_file_type_6').replace('__type__', constants.imageSupported);
      }

      // file size
      if ((this.nftType === NFT_TYPE.VIDEO || this.nftType === NFT_TYPE.IMAGE) && this.file.size > constants.maximumFileSize) {
        this.notifierService.notify('error', this.i18nService.getTranslation('error_file_size'));
        return;
      }

      if (this.nftType === NFT_TYPE.THREE_DIMENSIONAL && this.file.size > constants.maximum3DFileSize) {
        this.notifierService.notify('error', this.i18nService.getTranslation('error_file_size_2'));
        return;
      }

    }

    if (this.nftType === NFT_TYPE.AUDIO) {
      if (!this.filePreview) {
        return this.i18nService.getTranslation('error_file_type_6').replace('__type__', constants.imageSupported);
      }

      if (!this.filePreview.type.includes('image')) {
        return this.i18nService.getTranslation('error_file_type_6').replace('__type__', constants.imageSupported);
      }

      if (this.file.size > constants.maximum3DFileSize) {
        this.notifierService.notify('error', this.i18nService.getTranslation('err_audio_max_size'));
        return;
      }
    }

    // validate quantity
    if (!this.quantity) {
      return this.i18nService.getTranslation('error_quantity_1');
    }

    if (/^[-+]?[0-9]+\.[0-9]+$/.test(this.quantity.toString())) {
      return this.i18nService.getTranslation('error_quantity_1');
    }

    if (!/^-?\d*(\.\d+)?$/.test(this.quantity.toString())) {
      return this.i18nService.getTranslation('error_quantity_4');
    }
    const isValidQuantity = Number(this.quantity);
    if (!isValidQuantity && isValidQuantity !== 0) {
      return this.i18nService.getTranslation('error_quantity_4');
    }
    if (!Amount.fromPlanck(this.quantity).getRaw().isGreaterThan(0)) {
      return this.i18nService.getTranslation('error_quantity_2.2');
    }

    // FRUITSC-2831 limit quantity
    if (!Amount.fromPlanck(this.quantity).getRaw().isLessThanOrEqualTo(this.MAX_QUANTITY)) {
      return this.i18nService.getTranslation('error_exceed_max_quantity');
    }

    if (!this.description || this.description.trim().length === 0) {
      return this.i18nService.getTranslation('issue_token_desc_req');
    }

    // validate royalties
    if (!this.royalties || this.royalties.trim().length === 0) {
      return this.i18nService.getTranslation('royalties_message');
    }
    if (/\s/.test(this.royalties)) {
      return this.i18nService.getTranslation('royalties_message');
    }
    if (this.royalties.indexOf('.') !== -1) {
      return this.i18nService.getTranslation('royalties_message');
    }
    const isValidRoyalties = Number(this.royalties);
    if (!isValidRoyalties && isValidRoyalties !== 0) {
      return this.i18nService.getTranslation('royalties_message');
    }
    if (!Number.isInteger(isValidRoyalties)) {
      return this.i18nService.getTranslation('royalties_message');
    }
    if (isValidRoyalties < 0 || isValidRoyalties > 25) {
      return this.i18nService.getTranslation('royalties_message');
    }

    // validate deadline
    if (!this.deadline || this.deadline.toString().trim().length === 0) {
      return this.i18nService.getTranslation('error_deadline_required');
    }
    if (/\s/.test(this.deadline)) {
      return this.i18nService.getTranslation('error_deadline_1');
    }
    const isValidDeadline = Number(this.deadline);
    if (!isValidDeadline && isValidDeadline !== 0) {
      return this.i18nService.getTranslation('error_deadline_numeric');
    }
    if (isValidDeadline < 1 || isValidDeadline > 24) {
      return this.i18nService.getTranslation('error_deadline_min_max');
    }
    if (!Number.isInteger(isValidDeadline)) {
      return this.i18nService.getTranslation('error_deadline_invalid');
    }

    if (this.nftNumber > this.nftCategory.quantity) {
      return this.i18nService.getTranslation('error_nft_type_number')
        + ' ' + this.nftCategory.quantity + ' '
        + this.i18nService.getTranslation('error_nft_type_number_1');
    }

    if (this.selectedType && !this.serialNumber) {
      return this.i18nService.getTranslation('serial_number_required');
    }

    const privateKey = decryptAES(this.account.keys.agreementPrivateKey, hashSHA256(this.pin));
    if (!privateKey) {
      return this.i18nService.getTranslation('wrong_pin');
    }

    return '';
  }

  isNftNameValid(str): boolean {
    return /^[a-zA-Z0-9\s#/_-]+$/.test(str);
  }

  getBalance(): string {
    return getBalancesFromAccount(this.account).availableBalance.getSigna();
  }

  isDescriptionTooLong(): boolean {
    return this.description && this.description.length > 1000;
  }

  checkTotal(): boolean {
    return false;
  }

  uploadFileEvt(imgFile): void {
    // validate file
    if (imgFile.target.files && imgFile.target.files[0]) {
      // image
      if (this.nftType === 0
        && !imgFile.target.files[0].type.includes('png')
        && !imgFile.target.files[0].type.includes('jpeg')
        && !imgFile.target.files[0].type.includes('gif')) {
        this.notifierService.notify('error', this.i18nService.getTranslation('error_file_type_6').replace('__type__', constants.imageSupported));
        return;
      }

      // video
      if (this.nftType === 1
        && !imgFile.target.files[0].name.endsWith('mp4')
        && !imgFile.target.files[0].name.endsWith('webm')) {
        this.notifierService.notify('error', this.i18nService.getTranslation('error_file_type_6').replace('__type__', constants.videoSupported));
        return;
      }

      // 3d
      if (this.nftType === 2 && !imgFile.target.files[0].name.endsWith('.glb') && !imgFile.target.files[0].name.endsWith('.gltf')) {
        this.notifierService.notify('error', this.i18nService.getTranslation('error_file_type_6').replace('__type__', constants.threeDSupported));
        return;
      }

      // audio
      if (this.nftType === 3
        && !imgFile.target.files[0].name.endsWith('wav')
        && !imgFile.target.files[0].name.endsWith('mp3')
        && !imgFile.target.files[0].name.endsWith('ogg')) {
        this.notifierService.notify('error', this.i18nService.getTranslation('error_file_type_6').replace('__type__', constants.audioSupported));
        return;
      }

      // file size
      if ((this.nftType === 0 || this.nftType === 1) && imgFile.target.files[0].size > constants.maximumFileSize) {
        this.notifierService.notify('error', this.i18nService.getTranslation('error_file_size'));
        return;
      }

      if (this.nftType === 2 && imgFile.target.files[0].size > constants.maximum3DFileSize) {
        this.notifierService.notify('error', this.i18nService.getTranslation('error_file_size_2'));
        return;
      }

      if (this.nftType === 3 && imgFile.target.files[0].size > constants.maximum3DFileSize) {
        this.notifierService.notify('error', this.i18nService.getTranslation('err_audio_max_size'));
        return;
      }

      if (imgFile.target.files[0].size < 10) {
        this.notifierService.notify('error', this.i18nService.getTranslation('error_file_size_1'));
        return;
      }

      // all file type
      if (!imgFile.target.files[0].type.includes('video')
        && !imgFile.target.files[0].type.includes('image')
        && !imgFile.target.files[0].type.includes('audio')
        && !imgFile.target.files[0].name.endsWith('.glb')
        && !imgFile.target.files[0].name.endsWith('.gltf')) {
        this.notifierService.notify('error', this.i18nService.getTranslation('token_file_err_2'));
        return;
      }

      // binding data
      this.file = imgFile.target.files[0];
      this.uploadImage = imgFile.target.files[0];
      this.fileAttr = imgFile.target.files[0].name;

      // preview image/video
      if (this.nftType !== 2) {
        const reader = new FileReader();
        reader.readAsDataURL(this.file);
        if (this.file.type.indexOf('image') > -1) {
          this.format = 'image';
        } else if (this.file.type.indexOf('video') > -1) {
          this.format = 'video';
        } else if (this.file.type.indexOf('audio') > -1) {
          this.format = 'audio';
        }
        reader.onload = (event) => {
          this.url = this.domSanitizer.bypassSecurityTrustUrl((<FileReader>event.target).result.toString());
        };
      }
    } else {
      this.uploadImage = undefined;
    }
  }

  uploadFileEvtPreview(imgFile): void {
    // validate file
    if (imgFile.target.files && imgFile.target.files[0]) {
      // image
      if (!imgFile.target.files[0].type.includes('png')
        && !imgFile.target.files[0].type.includes('jpeg')
        && !imgFile.target.files[0].type.includes('gif')) {
        this.notifierService.notify('error', this.i18nService.getTranslation('error_file_type_6').replace('__type__', constants.imageSupported));
        return;
      }

      // file size
      if (imgFile.target.files[0].size > constants.maximumFileSize) {
        this.notifierService.notify('error', this.i18nService.getTranslation('error_file_size'));
        return;
      }

      if (imgFile.target.files[0].size < 10) {
        this.notifierService.notify('error', this.i18nService.getTranslation('error_file_size_1'));
        return;
      }

      // binding data
      this.filePreview = imgFile.target.files[0];
      this.uploadImagePreview = imgFile.target.files[0];
      this.fileAttrPreview = imgFile.target.files[0].name;

      // preview image for 3d
      const reader = new FileReader();
      reader.readAsDataURL(this.filePreview);
      this.formatPreview = 'image';
      reader.onload = (event) => {
        this.urlPreview = this.domSanitizer.bypassSecurityTrustUrl((<FileReader>event.target).result.toString());
      };
    } else {
      this.uploadImagePreview = undefined;
    }
  }

  handleNftTypeChanged(): void {
    this.nftNumber = this.nftCategory.currentIndex + +1;
  }

  getSendersSignPrivateKey(pin: string, keys: Keys): string {
    const privateKey = decryptAES(keys.signPrivateKey, hashSHA256(pin));
    if (!privateKey) {
      this.notifierService.notify('error', this.i18nService.getTranslation('wrong_pin'));
      this.isLoading = false;
      throw new KeyDecryptionException();
    }
    return privateKey;
  }

  getSpecialTokenTypes(): void {
    const types: any = this.nftService.getGoldNftConf().subscribe((res: any) => {
      if (res && res.errorCode === 0) {
        this.eSpecialTypes = res.result.types;
        this.goldNftAccounts = res.result.accounts;
      } else {
        this.eSpecialTypes = [];
      }
    }, () => {
      this.eSpecialTypes = [];
    });
  }

  allowCreateGoldNft(): boolean {
    return this.goldNftAccounts.includes(this.account.accountRS);
  }

  resetFile(): void {
    // remove image first
    this.uploadImage = undefined;
    this.fileAttr = undefined;
    this.file = undefined;
    this.format = undefined;
    this.url = undefined;
  }

  resetFilePreview(): void {
    // remove image first
    this.uploadImagePreview = undefined;
    this.fileAttrPreview = undefined;
    this.filePreview = undefined;
    this.formatPreview = undefined;
    this.urlPreview = undefined;
  }

  onCancel(): void {
    window.open(`${this.collectionUrl}/nft/my-nfts?account=${this.account.accountRS}`, '_self');
  }

  getFileTypeTxt(type): string {
    switch (type) {
      case 0:
        return this.i18nService.getTranslation('image');
      case 1:
        return this.i18nService.getTranslation('video');
      case 2:
        return this.i18nService.getTranslation('3d');
      case 3:
        return this.i18nService.getTranslation('audio');
      default:
        return '';
    }
  }

  getFileTypeSupported(): string {
    switch (this.nftType) {
      case 0:
        return this.i18nService.getTranslation('file_type_support_4').replace('__file__type__', constants.imageSupported);
      case 1:
        return this.i18nService.getTranslation('file_type_support_4').replace('__file__type__', constants.videoSupported);
      case 2:
        return this.i18nService.getTranslation('file_type_support_4').replace('__file__type__', constants.threeDSupported);
      case 3:
        return this.i18nService.getTranslation('file_type_support_4').replace('__file__type__', constants.audioSupported);
      default:
        return this.i18nService.getTranslation('file_type_support_4')
          .replace(
            '__file__type__',
            constants.imageSupported.concat(', ', constants.videoSupported).concat(', ', constants.threeDSupported)
          );
    }
  }

  getMaximumFileByType(): string {
    switch (this.nftType) {
      case 0:
      case 1:
        return this.i18nService.getTranslation('file_type_support_5').replace('__max__size__', constants.maximumSize);
      case 2:
        return this.i18nService.getTranslation('file_type_support_5').replace('__max__size__', constants.maximum3DSize);
      case 3:
        return this.i18nService.getTranslation('file_type_support_5').replace('__max__size__', constants.maximum3DSize);
      default:
        return this.i18nService.getTranslation('file_type_support_5').replace('__max__size__', constants.maximumSize);
    }
  }

  changeQuantity(): any {
    if (isNumeric(this.quantity) && !Amount.fromPlanck(this.quantity).getRaw().isGreaterThan(1)) {
      return this.quantity = 1;
    }

    if (this.quantity > this.MAX_QUANTITY) {
      this.quantity = this.MAX_QUANTITY;
    }
  }

  onChangeSpecialTokenType(event): void {
    this.nftCategories = !event.value ? this.nftCategoryMultiple : this.nftCategoriesSingle;
    this.nftCategory = this.nftCategories[0];
  }

}

export enum NftStatus {
  CREATE,
  SALE,
  CANCEL,
  BUY,
  TRANSFER,
  INCREASE_QUANTITY
}

export enum Token {
  MULTIPLE_TOKEN = 'MULTIPLE_TOKEN',
  SINGLE_TOKEN = 'SINGLE_TOKEN'
}

interface ISpecialType {
  id: number;
  name: string;
}
