import { Component, ViewEncapsulation } from '@angular/core';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { StoreService } from 'app/store/store.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector     : 'disclaimer',
    templateUrl  : './disclaimer.component.html',
    styleUrls    : ['./disclaimer.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class DisclaimerComponent
{
    redirect: any;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private storeService: StoreService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    )
    {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    async agree(): Promise<any> {
      try {

        if (this.activatedRoute.snapshot.queryParams) {
          this.redirect = this.activatedRoute.snapshot.queryParams;
        }

        const settings = await this.storeService.getSettings();
        settings.agree = true;
        await this.storeService.saveSettings(settings);
        this.router.navigate(['/wallet'], { queryParams: this.redirect });

      } catch (e) {
        console.warn(e);
      }
    }
}
