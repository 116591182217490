<div class="page-layout blank simple" fusePerfectScrollbar>
  <div class="header accent p-40 h-140" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
       fxLayoutAlign.gt-xs="space-between center">

    <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="center start">
      <div fxLayout="row" fxLayoutAlign="start center">

        <a [routerLink]="['/dashboard']">
          <img src="./assets/icons/menu/icon-home.png" height="16" width="16" alt=""/>
        </a>
        <mat-icon class="secondary-text s-16">chevron_right</mat-icon>

        <ng-container *ngFor="let breadcrumb of breadcrumbs">
          <a class="secondary-text" [routerLink]="[breadcrumb.path]">{{breadcrumb.label}}</a>
          <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
        </ng-container>

        <span class="secondary-text">{{title}}</span>
      </div>
      <div class="current-screen h1 mt-16">{{title}}</div>
      <div class="sub-text h3 mt-16">{{subtitle}}</div>
    </div>
  </div>

  <!-- CENTER -->
  <div class="content-container" [style.background-image]="bgImgSrc">
    <div [ngClass]="{content: true, wide:wide===true, createProposal:createProposal===true}">
      <ng-content></ng-content>
    </div>
  </div>
</div>
