<div class="checkbox-group">
  <div (click)="check1 = !check1; checkAllCheckbox()" class="checkbox-child-container {{ check1 ? 'container-checked' : 'container-not-checked' }}">
    <div class="checkbox-content {{ check1 ? 'content-checked' : 'content-not-checked' }}">{{ 'backup_passphrase_check_1' | i18n }}</div>
    <div class="checkbox">
      <mat-checkbox (click)="$event.stopImmediatePropagation()" (change)="checkAllCheckbox()" class="backup-checkbox" [(ngModel)]="check1"></mat-checkbox>
    </div>
  </div>
  <div (click)="check2 = !check2; checkAllCheckbox()" class="checkbox-child-container {{ check2 ? 'container-checked' : 'container-not-checked' }}">
    <div class="checkbox-content {{ check2 ? 'content-checked' : 'content-not-checked' }}">{{ 'backup_passphrase_check_2' | i18n }}</div>
    <div class="checkbox">
      <mat-checkbox (click)="$event.stopImmediatePropagation()" (change)="checkAllCheckbox()" class="backup-checkbox" [(ngModel)]="check2"></mat-checkbox>
    </div>
  </div>
  <div (click)="check3 = !check3; checkAllCheckbox()" class="checkbox-child-container {{ check3 ? 'container-checked' : 'container-not-checked' }}">
    <div class="checkbox-content {{ check3 ? 'content-checked' : 'content-not-checked' }}">{{ 'backup_passphrase_check_3' | i18n }}</div>
    <div class="checkbox">
      <mat-checkbox (click)="$event.stopImmediatePropagation()" (change)="checkAllCheckbox()" class="backup-checkbox" [(ngModel)]="check3"></mat-checkbox>
    </div>
  </div>
</div>
