import {SignedTransaction, Transaction} from 'web3-core';
const Web3 = require('web3');
import {Injectable} from '@angular/core';
import {constants} from '../constants';
import {environment} from 'environments/environment';
import {EthKeys} from '@fruitsjs/core/src';
import {BlockTransactionObject, FeeHistoryResult} from 'web3-eth';
import {BroadcastData} from './btc.service';
import {ABI} from '../util/contractABI';
import {HttpClient} from '@angular/common/http';
import {TransactionStatus} from '../main/btc-transaction/transaction-table/btc-transaction-table.component';
const HDWalletProvider = require('@truffle/hdwallet-provider');

const web3 = new Web3(new Web3.providers.HttpProvider(environment.isMainNet ? constants.ETH_MAINNET : constants.GOERLI_ETH_TESTNET));

@Injectable({
  providedIn: 'root'
})
export class EthService {

  usdtAddress = environment.isMainNet ? constants.usdtMainnetAddress : constants.usdtTestnetAddress;

  multiCoinUrl = environment.isMainNet ? constants.coinMarketCapURL : constants.multicoinTestURL;

  confirmedTransactions =  async (address) => {
    const url = `${this.multiCoinUrl}/fruits/multi-coin/api/confirmed-transactions?address=${address}&blockchain=${constants.ethNetwork}&network=${environment.isMainNet ? constants.mainnet : constants.goerli}`;

    const response = await fetch(url, {
      method: 'GET'
    });
    return await response.json();
  }

  unconfirmedTransactions =  async (address) => {
    const url = `${this.multiCoinUrl}/fruits/multi-coin/api/unconfirmed-transactions?address=${address}&blockchain=${constants.ethNetwork}&network=${environment.isMainNet ? constants.mainnet : constants.goerli}`;

    const response = await fetch(url, {
      method: 'GET'
    });
    return await response.json();
  }

  transactionsStatus = async (address: string, coinType: string) => {
    const url = `${this.multiCoinUrl}/fruits/multi-coin/api/transactions-status?address=${address}&coinType=${coinType}`;

    const response = await fetch(url, {
      method: 'GET'
    });
    return await response.json();
  }

  broadcastTransaction = async (txhex) => {
    const url = `${this.multiCoinUrl}/fruits/multi-coin/api/broadcast-transaction`;

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');

    const response = await fetch(url, {
      method: 'POST',
      headers: myHeaders,

      body: JSON.stringify({
        txhex,
        blockchain: constants.ethNetwork,
        network: environment.isMainNet ? constants.mainnet : constants.goerli
      })
    });
    return await response.json();
  }

  broadcastTransactionWithData = async (broadcastData: BroadcastData) => {
    const url = `${this.multiCoinUrl}/fruits/multi-coin/api/broadcast-transaction`;

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');

    const response = await fetch(url, {
      method: 'POST',
      headers: myHeaders,

      body: JSON.stringify({
        txhex: broadcastData.txhex,
        blockchain: constants.ethNetwork,
        network: environment.isMainNet ? constants.mainnet : constants.goerli,
        data: broadcastData.data
      })
    });
    return await response.json();
  }

  constructor(private http: HttpClient) {
  }

  async getBalance(address): Promise<string> {
    const balance = await web3.eth.getBalance(address);
    return balance ? web3.utils.fromWei(balance, 'ether') : '0';
  }

  async getGasPrice(): Promise<string> {
    const gas = await web3.eth.getGasPrice();
    return gas ? web3.utils.fromWei(gas, 'ether') : '0';
  }

  async generateKey(passphrase): Promise<EthKeys> {
    try {
      const walletProvider = new HDWalletProvider({
        mnemonic: {
          phrase: passphrase
        },
        numberOfAddresses: 1,
        providerOrUrl: environment.isMainNet ? constants.ETH_MAINNET : constants.GOERLI_ETH_TESTNET
      });
      // tslint:disable-next-line:no-unused-expression
      walletProvider.engine;
      const address = walletProvider.getAddresses();
      walletProvider.engine.stop();
      return {
        address: address[0],
        privateKey: walletProvider.wallets[address].getPrivateKeyString(),
        balance: await this.getBalance(address[0]),
        usdtBalance: await this.getUSDTBalance(address[0])
      };
    } catch (e) {
      return undefined;
    }
  }

  async sendAmount(data): Promise<SignedTransaction> {
    return await web3.eth.accounts.signTransaction({
      from: data.from,
      to: data.to,
      value: web3.utils.toWei(data.amount, 'ether'),
      gas: data.gasLimit,
      maxFeePerGas: data.maxFeeGas,
      maxPriorityFeePerGas: data.maxPriority,
      nonce: data.nonce
    }, data.privateKey);
  }

  async getLatestBlock(): Promise<BlockTransactionObject> {
    return await web3.eth.getBlock('pending');
  }

  public isValidAddress(address): boolean {
    return web3.utils.isAddress(address);
  }

  public toGwei(gas): string {
    return web3.utils.fromWei(gas, 'gwei');
  }

  public toEther(amount): string {
    return web3.utils.fromWei(amount, 'ether');
  }

  async getFeeHistory(): Promise<FeeHistoryResult> {
    return web3.eth.getFeeHistory(constants.historicalBlocks, 'latest', [25, 50, 75]);
  }

  public hexToNumber(hex): number {
    return web3.utils.hexToNumber(hex);
  }

  public getTransactionCount(address): Promise<number> {
    return web3.eth.getTransactionCount(address, 'pending');
  }

  async getTransaction(hash): Promise<Transaction> {
    return web3.eth.getTransaction(hash);
  }

  async getUSDTBalance(accountAddress: string): Promise<string> {
    const tokenContract = new web3.eth.Contract(ABI, this.usdtAddress);
    return await tokenContract.methods.balanceOf(accountAddress).call();
  }

  async sendUSDT(data: any): Promise<SignedTransaction> {
    const tokenContract = new web3.eth.Contract(ABI, this.usdtAddress);
    const transfer = tokenContract.methods.transfer(data.toAddress, data.amount);
    const encodedABI = transfer.encodeABI();
    return await web3.eth.accounts.signTransaction({
      to: this.usdtAddress,
      data: encodedABI,
      gas: data.gas,
      gasPrice: data.gasPrice,
    }, data.privateKey);
  }

  async getTokenTransaction(address: string): Promise<any> {
    return this.http.get(`${this.multiCoinUrl}/fruits/multi-coin/api/token-transactions?contract=${this.usdtAddress}&address=${address}`).toPromise();
  }

  async estimateGas(data: any): Promise<any> {
    if (!(data.to && this.isValidAddress(data.to) && data.amount) || data.amount.indexOf('.') !== -1) {
      return new Promise((resolve) => {
        resolve(constants.transferTokenMaxGasLimit);
      });
    }
    const tokenContract = new web3.eth.Contract(ABI, this.usdtAddress);
    return tokenContract.methods.transfer(data.to, data.amount).estimateGas({ from: data.from });
  }
}

export interface TokenTransaction {
  blockNumber: string;
  timeStamp: string;
  hash: string;
  nonce: string;
  blockHash: string;
  from: string;
  contractAddress: string;
  to: string;
  value: string;
  tokenName: string;
  tokenSymbol: string;
  tokenDecimal: string;
  transactionIndex: string;
  gas: string;
  gasPrice: string;
  gasUsed: string;
  cumulativeGasUsed: string;
  input: string;
  confirmations: string;
  fee: string;
  status: TransactionStatus;
}
