import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {some} from 'lodash';


@Component({
  selector: 'warn-send-eth-dialog',
  templateUrl: 'warn-send-from-payment-gateway-dialog.component.html',
  styleUrls: ['warn-send-from-payment-gateway-dialog.component.scss']
})
export class WarnSendFromPaymentGatewayDialogComponent {

  constructor(public dialogRef: MatDialogRef<WarnSendFromPaymentGatewayDialogComponent>) {
  }

}
