import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {interval, Observable} from 'rxjs';
import {flatMap, pluck, startWith} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MarketService {

  constructor(private httpClient: HttpClient) {
  }

  get serviceName(): string {
    return new URL(environment.market.tickerUrl).hostname;
  }

  createTicker(source: string, destination: string): Observable<any> {
    const params = new HttpParams()
      .set('source', source)
      .set('destination', destination);
    const {tickerInterval, tickerUrl} = environment.coinMarketCap;
    return interval(tickerInterval)
      .pipe(
        startWith(0),
        flatMap(_ => this.httpClient.get(tickerUrl, { params: params })),
        pluck('result'),
      );
  }
}
