<div>

  <form (ngSubmit)="onSubmit($event);" #sendBurstForm="ngForm" class="{{ isGettingData ? 'unconfirmed' : '' }}">
    <div class="account-info mb-8">
      <div class="account-info__field">
        <small class="info-title">{{'account'| i18n}}</small>
        <p class="pb-8 info-detail">{{account.multiKeys ? account.multiKeys.btc.address : ''}}</p>
      </div>
      <div class="account-info__field">
        <small class="info-title">{{'balance_available'| i18n}}</small>
        <p class="info-detail">{{ balances }} {{ symbol }}</p>
      </div>
    </div>

    <mat-form-field appearance="outline" floatLabel="always" class="eth-address">
      <mat-label>{{ 'address' | i18n }}</mat-label>
      <input matInput [(ngModel)]="recipientAddress" name="address" [disabled]="isGettingData || disableInputField">
      <ng-container>
        <input #file type="file" accept="image/*" (click)="resetFile($event)" (change)="parseQR()" id="{{fileId}}"
               class="inputfile" capture="camera" [disabled]="isGettingData || disableInputField">
        <label for="{{fileId}}" class="camera-icon">
          <img width="30" height="30" src="./assets/icons/menu/icon-camera.png" alt="">
        </label>
      </ng-container>
    </mat-form-field>

    <div class="amount-wrapper">
      <amount-input [disabled]="isGettingData || disableInputField"
                    (amountChange)="valueChange($event)"
                    [symbol]="symbol"
                    [amountValue]="amount"
                    [balances]="balances"
                    [fees]="transactionFee"
                    [decimals]="decimals"
                    [account]="account"></amount-input>
    </div>
    <p class="text-right changeBtc"  matSuffix>
      ~ {{ changeBtc || '' }}
      {{this.settings?.currency.btc}}
    </p>
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>{{ 'btc_network_fee' | i18n }}</mat-label>
      <input matInput [(ngModel)]="transactionFee" name="transactionFee" disabled>
      <span class="mr-8 prefix" matSuffix>{{symbol}}</span>
    </mat-form-field>




    <mat-radio-group class="estimate-gas-group" [(ngModel)]="selectedOption" name="gas"
                     (ngModelChange)="onFeeChanged($event)" [disabled]="isGettingData">
      <div>
        <mat-radio-button class="estimate-gas-item" *ngFor="let gasFee of btcFee" [value]="gasFee"
                          [name]="gasFee.priority">
          {{ gasFee.priority }}
        </mat-radio-button>
      </div>
    </mat-radio-group>

    <div *ngIf="customize">
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>{{ 'stas_btc_network_fee' | i18n }}</mat-label>
        <input matInput [(ngModel)]="satsByte" (keyup)="onChangeSatsByte($event.target.value)" name="gasLimit">
      </mat-form-field>
    </div>

    <mat-form-field class="message-text password-field" floatLabel="always" appearance="outline">
      <mat-label>{{ 'enter_pin' | i18n }}<span style="color:red"> *</span></mat-label>
      <input type="password" matInput placeholder="{{'enter_pin' | i18n}}" [(ngModel)]="pin" name="pin">
    </mat-form-field>

    <strong>{{'total' | i18n}}:&nbsp;</strong>
    <span [class.error]="!hasSufficientBalance()">{{ getTotal() }} {{ symbol }}</span>
    <div class="send-button">
      <button class="{{canSubmit() && !isSending && !isGettingData ? 'btn-common' : 'btn-common-disable'}}"
              [disabled]="!canSubmit() || isSending || isGettingData" mat-raised-button mat-button color="primary">
        {{ 'send' | i18n }}
        <mat-icon *ngIf="isSending" style="vertical-align: middle;">
          <mat-spinner diameter="20"></mat-spinner>
        </mat-icon>
      </button>
    </div>
  </form>

</div>
