<app-page title="{{ 'set_reward_recipient' | i18n }}" *ngIf="fees" [wide]="true">
  <div class="account-info pl-20 pr-20 mb-8">
    <div class="account-info__field">
      <small class="secondary-text text-uppercase">{{'account'| i18n}}</small>
      <p class="pb-8">{{account.accountRS}}</p>
    </div>
    <div class="account-info__field reward-recipient">
      <small class="secondary-text text-uppercase">{{'reward_recipient'| i18n}}</small>
      <ng-container *ngIf="isLoadingRewardRecipient">
        <div class="skeleton">
          <ngx-skeleton-loader count="1" [theme]="{height: '16px', margin: '0'}"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="2" [theme]="{height: '10px', margin: '0'}"></ngx-skeleton-loader>
        </div>
      </ng-container>
      <ng-container *ngIf="!isLoadingRewardRecipient">
        <div *ngIf="rewardRecipient && rewardRecipient.account !== account.account">
          <p>{{rewardRecipient.accountRS}}</p>
          <div class="secondary-text font-size-10">{{rewardRecipient.name}}</div>
          <div class="secondary-text font-size-10">{{rewardRecipient.description}}</div>
        </div>
        <ng-container *ngIf="!rewardRecipient || rewardRecipient.account === account.account">
          <p>{{'no_reward_recipient' | i18n}}</p>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div class="pl-20 pr-20">
    <form #setRewardRecipientForm="ngForm">
      <burst-recipient-input (recipientChange)="onRecipientChange($event)" [recipientValue]="recipient && recipient.addressRaw ? recipient.addressRaw : ''"></burst-recipient-input>

      <burst-fee-selector [fees]="fees" [(fee)]="fee"></burst-fee-selector>

      <div *ngIf="advanced" class="{{advanced ? 'mt-10' : ''}}">
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>{{ 'deadline_hours' | i18n }}</mat-label>
          <input matInput [(ngModel)]="deadline" name="deadline" placeholder="{{ 'deadline_hours' | i18n }}">
        </mat-form-field>
        <mat-checkbox hidden [(ngModel)]="broadcast" name="broadcast" disabled>{{ "do_not_broadcast" | i18n }}</mat-checkbox>
      </div>

      <div class="submit-transaction {{advanced ? '' : 'mt-10'}}">
        <mat-form-field class="message-text password-field" floatLabel="always" appearance="outline">
          <mat-label>{{'enter_pin' | i18n}}<span style="color:red"> *</span></mat-label>
          <input type="password" matInput placeholder="{{'enter_pin' | i18n}}" [(ngModel)]="pin" name="pin">
        </mat-form-field>
      </div>

<!--      <app-submit-transaction [disabled]="canSubmit()"-->
<!--                              [isSubmitting]="isSending"-->
<!--                              (pinChange)="setPin($event)"></app-submit-transaction>-->
    </form>
    <a (click)="advanced=!advanced"><em>{{ advanced ? ("basic" | i18n) : "advanced" | i18n }}</em></a>
  </div>

  <div class="send-button">
    <button class="{{canSubmit() && !isSending ? 'btn-common' : 'btn-common-disable'}}" [disabled]="!canSubmit() || isSending" mat-button (click)="onSubmit($event)">
      {{ 'submit' | i18n }}
      <mat-icon *ngIf="isSending" style="vertical-align: middle;">
        <mat-spinner diameter="20"></mat-spinner>
      </mat-icon>
    </button>
  </div>
</app-page>
