<!-- TODO seems that this component is not being used anymor -->
<mat-grid-list cols="2" gutterSize="10px">
  <mat-grid-tile>
    <mat-card>
      <h3><a routerLink="active"><i class="fa fa-lg fa-fw fa-exchange"></i> Create Active Account</a></h3>
      <strong>Active accounts</strong> are used for making transactions. Requires user generate a
      passphrase or provide one. Requires a user create a PIN for transactions.
      The passphrase is only used once locally to encrypt your private key; it is not stored anywhere.
    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-card>
      <h3><a routerLink="passive"><i class="fa fa-lg fa-fw fa-eye"></i> Create Passive Account</a></h3>
      <strong>Passive accounts</strong> are useful if you know a FRUITS address, but don't intend on making any
      transactions.
      Adds increased security. Can be converted to an active account at any time.
    </mat-card>
  </mat-grid-tile>
</mat-grid-list>
