<div class="page-layout blank simple" fusePerfectScrollbar *ngIf="accounts">

  <div style="min-width: 1580px;" class="header accent p-40 h-140" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
       fxLayoutAlign.gt-xs="space-between center">

    <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="center start">
      <div fxLayout="row" fxLayoutAlign="start center">
        <a [routerLink]="['/dashboard']">
<!--          <mat-icon class="secondary-text s-18">home</mat-icon>-->
          <img src="./assets/icons/menu/icon-home.png" height="16" width="16" alt=""/>
        </a>
        <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
        <span class="secondary-text">{{ 'accounts' | i18n }}</span>
      </div>
      <div class="current-screen h1 mt-16">{{ 'accounts' | i18n }}</div>
    </div>
  </div>

  <div class="p-40">

    <div style="min-width: 1500px; margin-bottom: 1.25em" class="action-row">
      <div class="action-row">
        <div class="inputs">
          <form autocomplete="off">
            <mat-form-field style="min-width: 300px; margin-bottom: -1.25em" appearance="outline" floatLabel="always">
              <mat-label>{{ 'filter' | i18n }}</mat-label>
              <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{ 'filter' | i18n }}">
            </mat-form-field>
          </form>
        </div>
        <button class="{{ orderSaved ? 'btn-common-custom-disable' : 'btn-common-custom' }}" (click)="saveAccountOrder()" [disabled]="orderSaved">
          <span style="margin-left: 10px;">{{ 'save_accounts_order' | i18n }}</span>
        </button>
      </div>
      <div style="text-align: right" class="buttons">
        <ng-container>
          <button [disabled]="dataSource.data.length >= 20"
            class="{{ dataSource.data.length >= 20 ? 'btn-common-custom-disable' : 'btn-common-custom' }}"
            (click)="router.navigate(['/wallet'])">
            <img src="assets/icons/menu/icon-btn-create-import.png" height="16" width="16" alt="" />
            <span style="margin-left: 10px;">{{ 'account_import_create' | i18n }}</span>
          </button>
          <button *ngIf="isAdmin" class="btn-common-custom"
                  (click)="importAccount()">
            <img src="assets/icons/menu/icon-btn-watch.png" height="16" width="16" alt=""/>
            <span style="margin-left: 10px;">{{ 'watch_frts_account' | i18n }}</span>
          </button>
          <button [disabled]="!getSelectedAccounts().length"
                  class="{{ getSelectedAccounts().length ? 'btn-common-custom' : 'btn-common-custom-disable' }}"
                  (click)="deleteSelectedAccounts()">
            <img src="assets/icons/menu/icon-delete-bin.png" height="16" width="16" alt=""/>
          </button>
        </ng-container>
      </div>
    </div>

    <mat-table style="min-width: 1500px;" #table [dataSource]="dataSource" matSort cdkDropList (cdkDropListDropped)="drop($event)">
      <ng-container matColumnDef="selected">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          <mat-icon>keyboard_arrow_down</mat-icon>
          Selected
        </mat-header-cell>
        <mat-cell *matCellDef="let account"> {{account.selected}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="delete">
        <mat-header-cell style="min-width: 50px;" *matHeaderCellDef>
          <img src="./assets/icons/menu/icon-reset-recipt.png" height="24" width="24" alt="delete"/>
        </mat-header-cell>
        <mat-cell style="min-width: 50px;" *matCellDef="let account" class="select-icon">
          <mat-checkbox [(ngModel)]="selectedAccounts[account.account]"></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="account">
        <mat-header-cell style="max-width: 150px;" *matHeaderCellDef mat-sort-header>
          <img width="24" height="24" src="./assets/icons/menu/icon-account.png" alt="Address">
          <span style="margin-left: 5px;">{{ 'id' | i18n }}</span>
        </mat-header-cell>
        <mat-cell style="max-width: 150px;" *matCellDef="let account"> {{account.account}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="accountRS">
        <mat-header-cell [ngStyle]="{'maxWidth': isAdmin ? '150px' : '250px'}" *matHeaderCellDef mat-sort-header>
          <img width="24" height="24" src="./assets/icons/menu/icon-address.png" alt="Address">
          <span style="margin-left: 5px;">{{ 'address' | i18n }}</span>
        </mat-header-cell>
        <mat-cell [ngStyle]="{'maxWidth': isAdmin ? '150px' : '250px'}" *matCellDef="let account">
          <a (click)="viewAccountDetail(account)">{{account.accountRS}}</a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell style="max-width: 150px;" *matHeaderCellDef mat-sort-header>
          <img width="24" height="24" src="./assets/icons/menu/icon-wallet-name.png" alt="Alias">
          <span style="margin-left: 5px;">{{ 'wallet_name' | i18n }}</span>
        </mat-header-cell>
        <mat-cell style="max-width: 150px;" *matCellDef="let account"> {{account.name}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="walletAlias">
        <mat-header-cell style="max-width: 150px;" *matHeaderCellDef mat-sort-header>
          <img width="24" height="24" src="./assets/icons/menu/icon-wallet-name.png" alt="Alias">
          <span style="margin-left: 5px;">{{ 'wallet_alias' | i18n }}</span>
        </mat-header-cell>
        <mat-cell class="wallet-alias" style="max-width: 150px; padding-right: 10px" *matCellDef="let account">

          <div>
            <input id="{{account.accountRS}}" style="display: none" matInput autofocus class="input-alias" (blur)="updateAlias(account.accountRS, $event)"
                   value="{{displayAlias(account.accountRS)}}">
          </div>

          <div class="w-full" id="{{account.accountRS + 1}}" style="display: block">
            <div class="wallet-alias">
              <div
                #address class="address"
                matTooltip="{{displayAlias(account.accountRS)}}"
                [matTooltipDisabled]="!isOverflowing(address)"
                matTooltipClass="break-all"
              >
                {{displayAlias(account.accountRS)}}
              </div>
              <div style="align-items: center; display: grid">
                <a (click)="focusAliasWallet(account.accountRS, true)">
                  <img class="icon-edit-alias" (click)="focusAliasWallet(account.accountRS, true)" width="15" height="15" src="assets/icons/menu/edit.png">
                </a>
              </div>
            </div>
          </div>

        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="balanceNQT">
        <mat-header-cell style="max-width: 190px" *matHeaderCellDef mat-sort-header>
          <img width="24" height="24" src="./assets/icons/menu/icon-amount.png" alt="balance">
          <span style="margin-left: 5px;">{{ 'balance' | i18n }}</span>
        </mat-header-cell>
        <mat-cell style="max-width: 190px"
          *matCellDef="let account">{{account.balanceNQT | burstAmount: 'planck':true }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="multi_wallet">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          <img width="24" height="24" src="./assets/icons/menu/icon-account-type.png" alt="">
          <span style="margin-left: 5px;">{{ 'account_type' | i18n }}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let account">
          <span class="account-type" *ngIf="account.multiWallet">
            {{ 'multi_wallet' | i18n }}
          </span>
          <span class="account-type" *ngIf="!account.multiWallet">
            {{ 'frts_wallet' | i18n }}
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="description">
        <mat-header-cell style="max-width: 150px;" *matHeaderCellDef mat-sort-header>
          <img width="24" height="24" src="./assets/icons/menu/icon-ats.png" alt="Description">
          <span style="margin-left: 5px;">{{ 'description' | i18n }}</span>
        </mat-header-cell>
        <mat-cell style="max-width: 150px;" *matCellDef="let account"> {{account.description}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell style="max-width: 60px; margin-left: 12px" *matHeaderCellDef mat-sort-header>
          <mat-icon>bookmark_border</mat-icon>
        </mat-header-cell>
        <mat-cell style="max-width: 60px;" *matCellDef="let account">
          <ng-container *ngIf="account.type === 'offline'">
            <!--            <mat-icon class="account-type-icon" matTooltip="{{'hint_offline_account' | i18n}}">remove_red_eye</mat-icon>-->
            <img class="account-type-icon" src="./assets/icons/menu/icon-user-watch.png" height="24" width="24" alt=""/>
          </ng-container>
          <ng-container *ngIf="account.type !== 'offline' && account.confirmed">
            <!--            <mat-icon class="account-type-icon" matTooltip="{{'hint_online_account' | i18n}}">verified_user</mat-icon>-->
            <img class="account-type-icon" src="./assets/icons/menu/icon-user-active.png" height="24" width="24" alt=""/>
          </ng-container>
          <ng-container *ngIf="account.type !== 'offline' && !account.confirmed">
            <!--            <mat-icon class="account-type-icon" matTooltip="{{'no_public_key_activate' | i18n}}">lock_open</mat-icon>-->
            <img class="account-type-icon" src="./assets/icons/menu/icon-user-locked.png" height="24" width="24" alt=""/>
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="export">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          <span style="margin-left: 5px;"></span>
        </mat-header-cell>
        <mat-cell *matCellDef="let account">
          <button *ngIf="account.multiWallet && (account.encryptedPassphrase || account.multiKeys.passphrase)" class="btn-common" (click)="export(account);">
            {{ 'export_passphrase' | i18n }}
          </button>
          <button *ngIf="account.keys && !account.multiWallet && !oldAccounts.includes(account.account)" class="btn-common" (click)="transfer(account);">
            {{ 'transfer_multi_coin' | i18n }}
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" cdkDrag cdkDragLockAxis="y" [ngStyle]="{'background-color': selectedAccount.accountRS === row.accountRS ? '#fdf0e6' : ''}"></mat-row>
    </mat-table>
  </div>
</div>
