<div class="container parent">
  <div class="select-account">
    <div>
      <svg width="154" height="40" viewBox="0 0 154 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_5655_1008)">
          <path d="M35.1729 19.7006C35.1729 29.4881 27.3107 37.4013 17.5865 37.4013C7.86218 37.4013 0 29.4881 0 19.7006C0 9.91323 7.86218 2 17.5865 2C27.3107 2 35.1729 9.91323 35.1729 19.7006Z" fill="#ED6D00"/>
          <path d="M4.60406 24.3862L3.93164 26.9372L15.9835 30.3212L19.2939 17.306L28.0871 19.7528L28.7596 17.2019L19.9146 14.755L20.4836 12.4643L30.1561 15.2236L30.7768 12.6726L18.7249 9.28864L15.4146 22.3559L6.62133 19.857L6.00063 22.3559L14.7939 24.8548L14.2249 27.1455L4.60406 24.3862Z" fill="white"/>
          <path d="M42.208 30.7291V7.81653H57.9178V11.7198H46.8196V17.1438H56.3975V21.047H46.8196V30.7291H42.208Z" fill="#ED6D00"/>
          <path d="M61.7695 30.7291V7.81653H71.4995C73.9319 7.81653 75.7056 8.01929 76.8205 8.42483C77.9354 8.83036 78.7969 9.59073 79.5064 10.6046C80.1652 11.6691 80.5199 12.8857 80.5199 14.2037C80.5199 15.9272 80.0131 17.3465 78.9996 18.4618C77.9861 19.577 76.4658 20.2866 74.4387 20.5908C75.4522 21.1991 76.2631 21.8074 76.9219 22.5171C77.5807 23.2268 78.4422 24.4434 79.557 26.2176L82.3443 30.6784H76.8205L73.4758 25.7106C72.3103 23.9364 71.4488 22.8212 71.0434 22.3143C70.5873 21.8581 70.1312 21.5539 69.6751 21.3512C69.1683 21.1991 68.4082 21.0977 67.344 21.0977H66.3811V30.7291H61.7695ZM66.3811 17.4986H69.8271C72.0569 17.4986 73.4252 17.3972 73.9826 17.1945C74.5401 16.9917 74.9455 16.6875 75.3002 16.2313C75.6043 15.7751 75.7563 15.2175 75.7563 14.5078C75.7563 13.7474 75.5536 13.0884 75.1482 12.6322C74.7428 12.176 74.1346 11.8719 73.4252 11.7198C73.0704 11.6691 71.9049 11.6184 70.0298 11.6184H66.4318V17.4986H66.3811Z" fill="#ED6D00"/>
          <path d="M84.8271 7.81665H89.4387V20.2361C89.4387 22.2131 89.4894 23.4803 89.5908 24.0886C89.7935 25.0518 90.2496 25.7615 91.0097 26.3698C91.7699 26.9274 92.7834 27.2315 94.0503 27.2315C95.3679 27.2315 96.3308 26.9781 97.0402 26.4205C97.699 25.8628 98.1044 25.2039 98.2565 24.4435C98.4085 23.6831 98.4592 22.3651 98.4592 20.5402V7.86734H103.071V19.9319C103.071 22.6693 102.969 24.6462 102.716 25.7615C102.463 26.8767 102.007 27.8398 101.348 28.6509C100.689 29.4619 99.7768 30.0702 98.6619 30.5265C97.547 30.9827 96.0774 31.2361 94.253 31.2361C92.0739 31.2361 90.4016 30.9827 89.2867 30.4758C88.1718 29.9689 87.2596 29.3099 86.6008 28.4988C85.942 27.6877 85.4859 26.826 85.2832 25.9642C84.9792 24.6462 84.8271 22.72 84.8271 20.1347V7.81665Z" fill="#ED6D00"/>
          <path d="M107.885 30.7291V7.81653H112.496V30.7291H107.885Z" fill="#ED6D00"/>
          <path d="M122.075 30.7291V11.6691H115.284V7.81653H133.528V11.7198H126.737V30.7291H122.075Z" fill="#ED6D00"/>
          <path d="M135.301 23.2779L139.811 22.8217C140.064 24.3424 140.622 25.4576 141.483 26.1673C142.345 26.877 143.409 27.2318 144.828 27.2318C146.298 27.2318 147.463 26.9277 148.223 26.2687C148.983 25.6604 149.338 24.9 149.338 24.0383C149.338 23.4807 149.186 23.0244 148.882 22.6696C148.578 22.3148 148.021 21.9599 147.21 21.6558C146.652 21.453 145.436 21.1489 143.51 20.6419C141.027 20.0336 139.304 19.2733 138.291 18.3608C136.872 17.0935 136.162 15.5728 136.162 13.7479C136.162 12.582 136.517 11.4668 137.176 10.4529C137.835 9.4391 138.797 8.67873 140.014 8.12112C141.281 7.61421 142.75 7.31006 144.524 7.31006C147.413 7.31006 149.592 7.91836 151.011 9.18565C152.48 10.4529 153.24 12.1258 153.291 14.2548L148.679 14.4576C148.477 13.2917 148.071 12.4299 147.413 11.923C146.754 11.4161 145.791 11.1626 144.473 11.1626C143.105 11.1626 142.091 11.4161 141.331 11.9737C140.825 12.3285 140.571 12.7847 140.571 13.393C140.571 13.9507 140.825 14.4069 141.281 14.7617C141.889 15.2686 143.308 15.7755 145.537 16.2825C147.767 16.7894 149.44 17.347 150.554 17.9553C151.619 18.5129 152.48 19.324 153.088 20.2871C153.696 21.2502 154 22.4668 154 23.9369C154 25.2549 153.646 26.4715 152.886 27.6374C152.176 28.8033 151.112 29.6143 149.794 30.1719C148.477 30.7296 146.804 31.0337 144.828 31.0337C141.939 31.0337 139.71 30.3747 138.139 29.006C136.517 27.7894 135.605 25.8632 135.301 23.2779Z" fill="#ED6D00"/>
        </g>
        <defs>
          <clipPath id="clip0_5655_1008">
            <rect width="154" height="40" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </div>
    <div class="mat-title">
      Select your wallet account
    </div>
    <div>
      <button class="btn-common"
              style="display: flex; justify-content: center; align-items: center;"
              (click)="onOpenManageWalletDialog()"
      >
        <img src="assets/icons/menu/icon-info.png" height="24" width="24" alt="Logo Wallet"/>
        {{ 'manage_wallet' | i18n }}
      </button>
    </div>
    <div class="accounts-container">
      <div class="title">
        {{ 'multi_wallet' | i18n }}
      </div>
      <ul class="accounts">
        <ng-container *ngTemplateOutlet="selectAccountMultiCoin"></ng-container>
      </ul>
    </div>
    <div class="accounts-container">
      <div class="title">
        {{ 'frts_wallet' | i18n }}
      </div>
      <ul class="accounts">
        <ng-container *ngTemplateOutlet="selectAccountFruitsOnly"></ng-container>
      </ul>
    </div>
  </div>
</div>

<ng-template #selectAccountMultiCoin>
    <div *ngIf="!hasMessage">
        <li *ngFor="let el of multiCoinAccounts" (click)="onClickAccount(el)">
            <div class="account-item">
                <ng-container>
                    <ng-container *ngIf="el.type === 'offline'">
                        <img style="margin-right: 10px;" src="./assets/icons/menu/icon-user-watch.png" height="24" width="24" alt=""/>
                    </ng-container>
                    <ng-container *ngIf="el.type !== 'offline' && el.confirmed">
                        <img style="margin-right: 10px;" src="./assets/icons/menu/icon-user-active.png" height="24" width="24" alt=""/>
                    </ng-container>
                    <ng-container *ngIf="el.type !== 'offline' && !el.confirmed">
                        <img style="margin-right: 10px;" src="./assets/icons/menu/icon-user-locked.png" height="24" width="24" alt=""/>
                    </ng-container>
                </ng-container>
                <div *ngIf="!el.multiWallet" style="color: #3A4667; font-size: 14px;">
                    <div class="user" style="font-weight: 800;">{{getWalletName(el, multiCoinAccounts)}}</div>
                    <div class="user">{{el.accountRS}}</div>
                    <div class="user">{{el.balanceNQT | burstAmount: 'planck'}}</div>
                </div>
                <div *ngIf="el.multiWallet" style="color: #3A4667; font-size: 14px;">
                    <div class="user" style="font-weight: 800;">{{getWalletName(el, multiCoinAccounts)}}</div>
                    <div class="user">{{el.accountRS}}</div>
                    <div class="user">{{el.balanceNQT | burstAmount: 'planck'}}</div>
                </div>
            </div>
        </li>
    </div>
    <div *ngIf="hasMessage">
        <li *ngFor="let el of multiCoinAccounts" class="message">
            <item-account #itemAccount
                          [account]="el"
                          [accounts]="multiCoinAccounts"
                          [message]="message"
                          (showHideAccountButtonEmitter)="hideAllAccountButtons($event)"></item-account>
        </li>
    </div>
</ng-template>

<ng-template #selectAccountFruitsOnly>
  <div *ngIf="!hasMessage">
    <li *ngFor="let el of fruitsOnlyAccounts" (click)="onClickAccount(el)">
      <div class="account-item">
        <ng-container>
          <ng-container *ngIf="el.type === 'offline'">
            <img style="margin-right: 10px;" src="./assets/icons/menu/icon-user-watch.png" height="24" width="24" alt=""/>
          </ng-container>
          <ng-container *ngIf="el.type !== 'offline' && el.confirmed">
            <img style="margin-right: 10px;" src="./assets/icons/menu/icon-user-active.png" height="24" width="24" alt=""/>
          </ng-container>
          <ng-container *ngIf="el.type !== 'offline' && !el.confirmed">
            <img style="margin-right: 10px;" src="./assets/icons/menu/icon-user-locked.png" height="24" width="24" alt=""/>
          </ng-container>
        </ng-container>
        <div *ngIf="!el.multiWallet" style="color: #3A4667; font-size: 14px;">
          <div class="user" style="font-weight: 800;">{{getWalletName(el, fruitsOnlyAccounts)}}</div>
          <div class="user">{{el.accountRS}}</div>
          <div class="user">{{el.balanceNQT | burstAmount: 'planck'}}</div>
        </div>
        <div *ngIf="el.multiWallet" style="color: #3A4667; font-size: 14px;">
          <div class="user" style="font-weight: 800;">{{getWalletName(el, fruitsOnlyAccounts)}}</div>
          <div class="user">{{el.accountRS}}</div>
          <div class="user">{{el.balanceNQT | burstAmount: 'planck'}}</div>
        </div>
      </div>
    </li>
  </div>
  <div *ngIf="hasMessage">
    <li *ngFor="let el of fruitsOnlyAccounts" class="message">
      <item-account #itemAccount
                    [account]="el"
                    [accounts]="fruitsOnlyAccounts"
                    [message]="message"
                    (showHideAccountButtonEmitter)="hideAllAccountButtons($event)"></item-account>
    </li>
  </div>
</ng-template>
