import { Injectable } from '@angular/core';
import { Api, ApiSettings, composeApi } from '@fruitsjs/core';
import { environment } from '../environments/environment';
import { Settings } from './settings';
import { StoreService } from './store/store.service';
import semver from 'semver';
import { constants } from './constants';
import { ApiVersion } from '@fruitsjs/core/src/constants/apiVersion';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public api: Api;
  private brsVersion: string;
  public nodeUrl = environment.defaultNode;

  activatorUrl = environment.isMainNet ? constants.activatorUrlMainnet : constants.activatorUrlTestnet;

  constructor(private storeService: StoreService,
              private http: HttpClient) {
    this.storeService.settings.subscribe(this.initApi.bind(this));
  }

  private async initApi(settings: Settings): Promise<void> {
    if (this.nodeUrl === settings.node) {
      return;
    }

    this.nodeUrl = settings.node;
    const reliablePeers = constants.nodes
      .filter(({ reliable }) => reliable)
      .map(({ address, port }) => (port !== 443 && port !== 80) ? `${address}:${port}` : address);

    const apiSettings = new ApiSettings(
      this.nodeUrl,
      ApiVersion.V1,
      reliablePeers,
    );
    this.api = composeApi(apiSettings);
    this.brsVersion = undefined;

    if (settings.nodeAutoSelectionEnabled) {
      this.nodeUrl = await this.selectBestNode();
      settings.node = this.nodeUrl;
      await this.storeService.saveSettings(settings);
    }
  }

  async selectBestNode(): Promise<string> {
    let bestNode = '';
    try {
      bestNode = await this.api.service.selectBestHost(false);
    } catch (e) {
      console.log(e);
    }
    return bestNode;
  }

  async supportsPocPlus(): Promise<boolean> {
    const brsApiVersion = await this.fetchBrsApiVersion();
    const version = semver.coerce(brsApiVersion);
    return semver.gte(version, constants.pocPlusVersion, { includePrerelease: true });
  }

  async fetchBrsApiVersion(): Promise<string> {
    if (!this.brsVersion) {
      const { version } = await this.api.network.getBlockchainStatus();
      this.brsVersion = version;
    }
    return Promise.resolve(this.brsVersion);
  }

  public activeAccount(data: any): Observable<any> {
    return this.http.post(`${this.activatorUrl}/fruits/activator/active`, data);
  }

  public getIpAddress(): Observable<any> {
    return this.http.get(constants.getIpUrl);
  }
}
