<div class="qr-dialog p-24" style="text-align: center;">
  <div>
    <div><img style="margin-bottom: 15px; width: 350px; height: 350px" src="{{data.imgSrc}}" alt=""/></div>
    <div><strong>{{ 'address' | i18n }}</strong>: {{ data.address }}</div>
    <div><strong>{{ 'amount' | i18n }}</strong>: {{ data.amount }} {{ data.symbol }}</div>
  </div>
  <div class="btn-container" style="margin-top: 20px;">
    <button class="btn-common" [mat-dialog-close]="true">{{ "close" | i18n }}</button>
  </div>
</div>
