import {Component, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '../../../@fuse/animations';
import {FuseConfigService} from '../../../@fuse/services/config.service';
import {StoreService} from '../../store/store.service';
import {Router} from '@angular/router';
import {constants} from '../../constants';
import {NotifierService} from 'angular-notifier';
import {I18nService} from '../../layout/components/i18n/i18n.service';
import {environment} from '../../../environments/environment';

@Component({
  selector     : 'wallet',
  templateUrl  : './au.component.html',
  styleUrls    : ['./au.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class AuComponent {
  username: string;
  password: string;

  constructor(private _fuseConfigService: FuseConfigService,
              private storeService: StoreService,
              private router: Router,
              private notifierService: NotifierService,
              private i18nService: I18nService) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar   : {
          hidden: true
        },
        toolbar  : {
          hidden: true
        },
        footer   : {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };
  }

  async login(): Promise<void> {
    if (this.username === constants.username && this.password === constants.password) {
      const settings = await this.storeService.getSettings();
      settings.isAuthorized = true;
      await this.storeService.saveSettings(settings);
      this.notifierService.notify('success', this.i18nService.getTranslation('login_success'));
      this.router.navigate(settings.agree ? ['/dashboard'] : ['/disclaimer']);
    } else {
      this.notifierService.notify('error', this.i18nService.getTranslation('error_login_invalid'));
      return;
    }
  }

  getVersion(): string {
    return environment.version;
  }
}
