import {Component} from '@angular/core';
import {environment} from '../../../environments/environment';
import {constants} from '../../constants';
import {FuseConfigService} from '../../../@fuse/services/config.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpParams} from '@angular/common/http';
import {NotifierService} from 'angular-notifier';
import {I18nService} from '../../layout/components/i18n/i18n.service';
import {NftService} from '../../payment-gateway/nft.service';

@Component({
  selector: 'logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent {

  collectionUrl = environment.isMainNet ? constants.NFT_COLLECTION_MAINNET : constants.NFT_COLLECTION_TESTNET;
  params: any;
  orderId: string;
  callbackUrl: string;

  constructor(private _fuseConfigService: FuseConfigService,
              private route: ActivatedRoute,
              private router: Router,
              private notifierService: NotifierService,
              private i18nService: I18nService,
              private nftService: NftService) {
    this.route.queryParams.subscribe(async params => {
      if (params && params.orderId) {
        const rqParams = new HttpParams().set('orderId', params.orderId);
        const response = await this.nftService.getNftOrder(rqParams).toPromise();
        if (response && response.errorCode === 1) {
          this.notifierService.notify('error', this.i18nService.getTranslation(response.message));
          await this.router.navigate(['/']);
        }

        if (response && response.errorCode === 0) {
          this.params = JSON.parse(response.result.params);
          this.orderId = response.result.id;
          this.callbackUrl = this.params.callbackUrl;
        }
      } else {
        this.notifierService.notify('error', this.i18nService.getTranslation('order_not_found'));
        await this.router.navigate(['/']);
      }
    });

    this._fuseConfigService.config = {
      layout: {
        navbar   : {
          hidden: true
        },
        toolbar  : {
          hidden: true
        },
        footer   : {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };
  }

  logout(logout): void {
    if (logout) {

      switch (this.params.status) {
        case 'transfer-detail':
          window.open(`${this.collectionUrl}/nft/collection/${this.params.collection}/${this.params.nft}?logout=true`, '_self');
          break;
        case 'sell':
        case 'cancel':
        case 'transfer':
        case 'create':
        case 'edit':
        case 'UPDATE_PROFILE':
          window.open(`${this.collectionUrl}/nft/home?logout=true`, '_self');
          break;
        default:
          window.open(`${this.collectionUrl}/nft/home?logout=true`, '_self');
          break;
      }

      // use for third party
      if (this.callbackUrl) {
        const separator = this.callbackUrl.includes('?') ? '&' : '?';
        window.open(`${this.callbackUrl}${separator}${constants.THIRD_PARTY_CANCEL_STATUS}`, '_self');
        return;
      }

      return;
    }

    switch (this.params.status) {
      case 'transfer-detail':
        window.open(`${this.collectionUrl}/nft/collection/${this.params.collection}/${this.params.nft}`, '_self');
        break;
      case 'sell':
      case 'cancel':
      case 'transfer':
      case 'create':
      case 'edit':
        window.open(`${this.collectionUrl}/nft/my-nfts`, '_self');
        break;
      default:
        window.open(`${this.collectionUrl}/nft/home`, '_self');
        break;
    }

    // use for third party
    if (this.callbackUrl) {
      const separator = this.callbackUrl.includes('?') ? '&' : '?';
      window.open(`${this.callbackUrl}${separator}${constants.THIRD_PARTY_CANCEL_STATUS}`, '_self');
      return;
    }
    return;
  }
}
