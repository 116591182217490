import {Component} from '@angular/core';
import {environment} from '../../../environments/environment';
import {constants} from '../../constants';
import {FuseConfigService} from '../../../@fuse/services/config.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountService} from '../../setup/account/account.service';
import {Account} from '@fruitsjs/core';
import {StoreService} from '../../store/store.service';
import {NotifierService} from 'angular-notifier';
import {I18nService} from '../../layout/components/i18n/i18n.service';
import {HttpParams} from '@angular/common/http';
import {NftService} from '../../payment-gateway/nft.service';

@Component({
  selector: 'logout',
  templateUrl: './change-account.component.html',
  styleUrls: ['./change-account.component.scss']
})
export class ChangeAccountComponent {

  accounts: Account[];
  collectionUrl = environment.isMainNet ? constants.NFT_COLLECTION_MAINNET : constants.NFT_COLLECTION_TESTNET;
  params: any;
  orderId: string;
  callbackUrl: string;

  constructor(private _fuseConfigService: FuseConfigService,
              private router: Router,
              private route: ActivatedRoute,
              private accountService: AccountService,
              private storeService: StoreService,
              private notifierService: NotifierService,
              private i18nService: I18nService,
              private nftService: NftService) {
    this.route.queryParams.subscribe(async params => {
      if (params && params.orderId) {
        const rqParams = new HttpParams().set('orderId', params.orderId);
        const response = await this.nftService.getNftOrder(rqParams).toPromise();
        if (response && response.errorCode === 1) {
          this.notifierService.notify('error', this.i18nService.getTranslation(response.message));
          await this.router.navigate(['/']);
        }

        if (response && response.errorCode === 0) {
          this.params = JSON.parse(response.result.params);
          this.orderId = response.result.id;
          this.callbackUrl = this.params.callbackUrl;
        }
      } else {
        this.notifierService.notify('error', this.i18nService.getTranslation('order_not_found'));
        await this.router.navigate(['/']);
      }
    });
    this.storeService.getAllAccounts().then(accounts => {
      this.accounts = accounts;
    });

    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };
  }

  async onSubmit(change): Promise<void> {
    if (!change) {
      // use for third party
      if (this.callbackUrl) {
        const separator = this.callbackUrl.includes('?') ? '&' : '?';
        window.open(`${this.callbackUrl}${separator}${constants.THIRD_PARTY_CANCEL_STATUS}`, '_self');
        return;
      }

      switch (this.params.status) {
        case 'transfer-detail':
          window.open(`${this.collectionUrl}/nft/collection/${this.params.collection}/${this.params.nft}`, '_self');
          break;
        case 'sell':
        case 'cancel':
        case 'transfer':
        case 'create':
        case 'edit':
        case 'transfer-multi-token':
          window.open(`${this.collectionUrl}/nft/my-nfts`, '_self');
          break;
        case 'increase-quantity':
          window.open(`${this.collectionUrl}/nft/my-nfts`, '_self');
          break;
        case 'UPDATE_PROFILE':
          window.open(`${this.collectionUrl}`, '_self');
          break;
      }


      return;
    }

    // use for third party
    if (this.callbackUrl) {
      const findAccountThirdParty: Account = this.accounts.find(a => a.accountRS === this.params.account);
      if (!findAccountThirdParty) {
        const separator = this.callbackUrl.includes('?') ? '&' : '?';
        window.open(`${this.callbackUrl}${separator}${constants.THIRD_PARTY_CANCEL_STATUS}`, '_self');
        return;
      }

      this.router.navigate(['/nft/confirm-transaction'], {
        queryParams: {
          orderId: this.orderId
        }
      });
    }

    const findAccount: Account = this.accounts.find(a => a.accountRS === this.params.account);
    if (!findAccount) {
      window.open(`${this.collectionUrl}/nft/collection/${this.params.collection}/${this.params.nft}`, '_self');
      return;
    }

    await this.storeService.selectAccount(findAccount);

    switch (this.params.status) {
      case 'create':
        await this.router.navigate(['/nft/create'], {queryParams: {orderId: this.orderId}});
        break;
      case 'sell':
        await this.router.navigate(['/nft/sell'], {queryParams: {orderId: this.orderId}});
        break;
      case 'cancel':
        await this.router.navigate(['/nft/cancel'], {queryParams: {orderId: this.orderId}});
        break;
      case 'transfer':
        await this.router.navigate(['/nft/transfer'], {
          queryParams: {
            nft: this.params.nft,
            status: this.params.status,
            account: this.params.account,
            orderId: this.orderId
          }
        });
        break;
      case 'transfer-multi-token':
        await this.router.navigate(['/nft/transfer-multi-token'], {
          queryParams: {
            orderId: this.orderId
          }
        });
        break;
      case 'increase-quantity':
        await this.router.navigate(['/nft/increase-quantity'], {
          queryParams: {
            orderId: this.orderId
          }
        });
        break;
      case 'confirm-transaction':
        await this.router.navigate(['/nft/confirm-transaction'], {
          queryParams: {
            orderId: this.orderId
          }
        });
        break;
      case 'transfer-detail':
        await this.router.navigate(['/nft/transfer'], {
          queryParams: {
            nft: this.params.nft,
            status: this.params.status,
            account: this.params.account,
            collection: this.params.collection,
            orderId: this.orderId
          }
        });
        break;
      case 'edit':
        await this.router.navigate(['/nft/edit'], {queryParams: {orderId: this.orderId}});
        break;
      case 'UPDATE_PROFILE':
        await this.router.navigate(['/nft/my-page'], {queryParams: {orderId: this.orderId}});
        break;
    }
  }
}
