import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CreateService, StepsEnum} from '../../create.service';
import {NotifierService} from 'angular-notifier';
import {sampleSize} from 'lodash';
import {AccountService} from '../../account.service';
import {Account} from '@fruitsjs/core';
import {generateMasterKeys} from '@fruitsjs/crypto';
import {I18nService} from '../../../../layout/components/i18n/i18n.service';
import {StoreService} from '../../../../store/store.service';
import {Settings} from '../../../../settings';
import {constants} from '../../../../constants';
import {environment} from '../../../../../environments/environment';
import {paymentRedirectUrl} from '../pin/pin.component';
import {ApiService} from '../../../../api.service';
import {AppService} from '../../../../app.service';

interface Token {
  text: string;
  ok: boolean;
  index: number;
}

@Component({
  selector: 'app-account-activate',
  styleUrls: ['./activate.component.scss'],
  templateUrl: './activate.component.html'
})
export class AccountActivateComponent {

  isActivating = false;
  passphrase = '';
  randomizedTokens: Token[] = [];
  settings: Settings;
  account: Account;
  collectionUrl = environment.isMainNet ? constants.NFT_COLLECTION_MAINNET : constants.NFT_COLLECTION_TESTNET;
  status;
  queryParams: any;
  callBackUrl;

  constructor(private router: Router,
              private createService: CreateService,
              private accountService: AccountService,
              private i18nService: I18nService,
              private notificationService: NotifierService,
              private storeService: StoreService,
              private activatedRoute: ActivatedRoute,
              private apiService: ApiService,
              private appService: AppService) {
    this.storeService.getSettings().then(settings => {
      this.settings = settings;
    });
    this.activatedRoute.queryParams.subscribe(params => {
      if (params) {
        this.queryParams = params;
      }
    });
  }

  isStepActive(): boolean {
    return this.createService.getStep() === StepsEnum.ActivateAccount;
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngDoCheck(): void {
    if (this.isStepActive()
      && this.createService.getPassphrase()
      && this.randomizedTokens.length !== 0
      && !this.randomizedTokens.map(t => t.text).every(t => this.createService.getPassphrase().includes(t))) {
      this.reset();
      return;
    }
    if (!(this.isStepActive() && this.randomizedTokens.length === 0)) {
      return;
    }
    this.randomizePassphraseTokens();
  }

  public back(): void {
    setTimeout(() => {
      this.createService.previousStep();
    }, 0);
  }

  public reset(): void {
    this.passphrase = '';
    this.randomizedTokens = [];
    this.randomizePassphraseTokens();
  }

  private randomizePassphraseTokens(): void {
    const tokens = this.createService.getPassphrase();
    if (tokens && tokens.length) {
      this.randomizedTokens = sampleSize(tokens, tokens.length).map((t, index) => ({
        text: t,
        ok: null,
        index: index
      }));
    }
  }

  public get isValid(): boolean {
    if (!this.isStepActive()) {
      return false;
    }
    return this.createService.getCompletePassphrase() === this.passphrase;
  }

  async activateAccount(): Promise<void> {
    const newAccount = new Account();
    newAccount.keys = generateMasterKeys(this.createService.getCompletePassphrase());
    newAccount.account = this.createService.getId();

    if (!this.account.confirmed) {
      const data = {
        activeRequests: [{
          'id': this.account.account,
          'publicKey': this.account.keys.publicKey
        }],
        remoteAddress: this.appService.getRemoteAddress()
      };
      this.apiService.activeAccount(data).subscribe(() => {}, () => {});
      this.account.activatorTime = new Date().getTime();
      await this.storeService.saveAccount(this.account);
    }
    // await this.accountService.activateAccount(newAccount);
    // this.notificationService.notify('success', this.i18nService.getTranslation('activate_request_successful'));
  }

  public async activate(): Promise<void> {
    if (!this.isValid) {
      return;
    }
    try {
      this.isActivating = true;
      this.account = await this.createService.createActiveAccount();
      await this.activateAccount();
      this.createService.reset();
      this.notificationService.notify('success', this.i18nService.getTranslation('account_added'));
    } catch (error) {
      this.notificationService.notify('error', error.toString());
    } finally {
      this.status = sessionStorage.getItem('status');
      if (this.queryParams && Object.keys(this.queryParams).length > 0 && this.queryParams.redirect) {
        if (paymentRedirectUrl.includes(this.queryParams.redirect)) {
          await this.router.navigate(
            [this.queryParams.redirect],
            {queryParams: {paymentId: this.queryParams.paymentId}}
          );
        }
      } else if (this.status) {
        this.callBackUrl = decodeURIComponent(sessionStorage.getItem('callBack'));
        sessionStorage.removeItem('status');
        sessionStorage.removeItem('callBack');
        this.checkStatus(this.account);
      }
      this.isActivating = false;
      this.router.navigate(['/']);
    }
  }

  private isPassphraseFine(): boolean {
    return this.createService.getPassphrase().join(' ').startsWith(this.passphrase);
  }

  public confirm($event: MouseEvent, i: number): void {
    // @ts-ignore
    const text = $event.target.innerText;
    this.passphrase += ' ' + text;
    this.passphrase = this.passphrase.trim();

    // @ts-ignore
    const index = this.randomizedTokens.findIndex(t => t.text === text && t.index === i);
    if (index >= 0) {
      this.randomizedTokens[index].ok = this.isPassphraseFine();
    }
  }

  checkStatus(account: Account): void {
    switch (this.status) {
      case 'sell':
      case 'cancel':
      case 'transfer':
      case 'transfer-detail':
      case 'create':
      case 'edit':
        window.open(`${this.collectionUrl}/nft/my-nfts?account=${account.accountRS}`, '_self');
        break;
      case 'connect':
        window.open(this.callBackUrl + `?account=${account.accountRS}&publicKey=${account.keys.publicKey}`, '_self');
        break;
      case 'home':
        window.open(`${this.collectionUrl}/save/account/${account.accountRS}`, '_self');
        break;
      default:
        this.router.navigate(['/']);
        break;
    }
  }
}
