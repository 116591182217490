import {
  Component,
  Input,
  ViewChild,
  AfterViewInit, OnInit
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {
  Transaction,
  Account,
  isMultiOutSameTransaction,
  isMultiOutTransaction,
  getRecipientsAmount,
  TransactionMiningSubtype,
  TransactionType
} from '@fruitsjs/core';
import { Amount, ChainTime } from '@fruitsjs/util';
import { UtilService } from 'app/util.service';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from '../../../util/UnsubscribeOnDestroy';
import { StoreService } from '../../../store/store.service';
import { formatDate } from '@angular/common';
import { convertHexStringToString } from '@fruitsjs/util';
import {environment} from 'environments/environment';
import {getAddressParams} from '../../../utils';

@Component({
  selector: 'app-transaction-table',
  styleUrls: ['./transaction-table.component.scss'],
  templateUrl: './transaction-table.component.html',
})
export class TransactionTableComponent extends UnsubscribeOnDestroy implements OnInit, AfterViewInit {
  public locale: string;
  private currentDataSource: any;
  fruitscanAccount = environment.fruitscanAccount;

  constructor(private utilService: UtilService,
    private storeService: StoreService) {
    super();
    this.storeService.settings
      .pipe(
        takeUntil(this.unsubscribeAll)
      )
      .subscribe(({ language }) => {
        this.locale = language;
      });
  }

  @Input() dataSource: MatTableDataSource<Transaction>;
  @Input() public displayedColumns = environment.isAdmin
    ? ['transaction_id', 'attachment', 'timestamp', 'message', 'type', 'amount', 'fee', 'account', 'confirmations']
    : ['transaction_id', 'attachment', 'timestamp', 'type', 'amount', 'fee', 'account', 'confirmations'];
  @Input() paginationEnabled = true;
  @Input() account: Account;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  public isMultiOutPayment(transaction: Transaction): boolean {
    return isMultiOutSameTransaction(transaction) || isMultiOutTransaction(transaction);
  }

  ngOnInit(): void {
  }

  public ngAfterViewInit(): void {
    const uniqueValuesSet = new Set();
    this.dataSource.data = this.dataSource.data ? this.dataSource.data.filter(item => {
      const isPresentInSet = uniqueValuesSet.has(item.transaction);
      uniqueValuesSet.add(item.transaction);
      return !isPresentInSet;
    }) : [];
    this.dataSource.paginator = this.paginator;
    this.currentDataSource = this.dataSource.data;
  }

  hexToASCII(input): string {
    try {
      return convertHexStringToString(input.replace(/00/g, ''));
    } catch (e) {
      return input;
    }
  }

  reduceLongMessage(input): string {
    return input.length >= 30 ? input.substr(0, 30) + '...' : input;
  }

  public convertTimestamp(timestamp: number): Date {
    return ChainTime.fromChainTimestamp(timestamp).getDate();
  }

  public getTransactionNameFromType(transaction: Transaction): string {
    return this.utilService.translateTransactionSubtype(transaction, this.account);
  }

  public isOwnAccount(accountId: string): boolean {
    return accountId && accountId === this.account.account;
  }

  public getAmount(transaction: Transaction): string {

    if (this.isOwnAccount(transaction.sender)) {
      return Amount.fromPlanck(transaction.amountNQT).multiply(-1).getSigna();
    }

    return this.isMultiOutPayment(transaction)
      ? getRecipientsAmount(this.account.account, transaction).toString(10)
      : Amount.fromPlanck(transaction.amountNQT).getSigna();
  }

  public isAmountNegative(transaction: Transaction): boolean {
    const isZero = parseFloat(transaction.amountNQT) === 0;
    return !isZero && this.isOwnAccount(transaction.sender);
  }

  public isCommitment(transaction: Transaction): boolean {
    return transaction.type === TransactionType.Mining && (
      transaction.subtype === TransactionMiningSubtype.AddCommitment ||
      transaction.subtype === TransactionMiningSubtype.RemoveCommitment ||
      transaction.subtype === TransactionMiningSubtype.AddCommitmentToken ||
      transaction.subtype === TransactionMiningSubtype.RemoveCommitmentToken
    );
  }

  public isCommitmentToken(transaction: Transaction): boolean {
    return transaction.type === TransactionType.Mining && (
      transaction.subtype === TransactionMiningSubtype.AddCommitmentToken ||
      transaction.subtype === TransactionMiningSubtype.RemoveCommitmentToken
    );
  }

  getCommitmentAmount(transaction): string {
    if (this.isCommitmentToken(transaction)) {
      return '0';
    }
    return Amount.fromPlanck(transaction.attachment.amountNQT || '0').getSigna();
  }

  getDate(tx: Transaction): string {
    const time = ChainTime.fromChainTimestamp(tx.timestamp);
    return formatDate(time.getDate(), 'short', this.locale);
  }

  getRowClass(row: Transaction): string {

    const cx = className => row.confirmations === undefined ? `${className} unconfirmed` : className;

    if (
      (!row.recipient && (row.type !== TransactionType.Payment)) ||
      (row.recipient === this.account.account && row.sender === this.account.account)
    ) {
      return cx('self');
    }

    if (
      (row.recipient === this.account.account && row.sender !== this.account.account) ||
      (!row.recipient && (row.type === TransactionType.Payment)) //
    ) {
      return cx('incoming');
    }

    return cx('outgoing');
  }

  public getTransactionAccount(transaction: any): string {

    let address;
    let hasAlias = false;

    if (this.isOwnAccount(transaction.sender)) {
      address = transaction.recipientName ? transaction.recipientName : transaction.recipientRS;
      hasAlias = !!transaction.recipientName;
    } else {
      address = transaction.senderName ? transaction.senderName : transaction.senderRS;
      hasAlias = !!transaction.senderName;
    }

    // Remove prefix
    address = this.chopPrefix(address);

    if (!hasAlias) {
      return '';
    }

    return address;
  }

  public getTransactionAddress(transaction: any, mustChopPrefix: boolean = false): string {
    let address = this.isOwnAccount(transaction.sender) ? transaction.recipientRS : transaction.senderRS;

    if (!mustChopPrefix) {
      return address;
    }

    address = this.chopPrefix(address);
    // ellipsis middle of text
    const firstPart = address.substr(0, 9);
    const lastPart = address.substr(address.length - 9, address.length);
    return `${firstPart}...${lastPart}`;
  }

  public chopPrefix(reedSolomonAddress: string): string {
    return reedSolomonAddress.substr(reedSolomonAddress.indexOf('-') + 1);
  }

  onMatSortChange(event): void {
    const dataSource = this.dataSource.data;
    switch (event.active) {
      case 'transaction_id':
        dataSource.sort((a, b) => a.transaction > b.transaction
          ? this.checkDirection(event.direction) : -this.checkDirection(event.direction));
        break;
      case 'timestamp':
        dataSource.sort((a, b) => a.timestamp > b.timestamp
          ? this.checkDirection(event.direction) : -this.checkDirection(event.direction));
        break;
      case 'type':
        dataSource.sort((a, b) => this.getTransactionNameFromType(a) > this.getTransactionNameFromType(b)
          ? this.checkDirection(event.direction) : -this.checkDirection(event.direction));
        break;
      case 'amount':
        dataSource.sort((a, b) => {
          return Amount.fromPlanck(this.getAmountSort(a)).getRaw().isGreaterThan(Amount.fromPlanck(this.getAmountSort(b)).getRaw())
            ? this.checkDirection(event.direction) : -this.checkDirection(event.direction);
        });
        break;
      case 'fee':
        dataSource.sort((a, b) => Amount.fromPlanck(a.feeNQT).getSigna() > Amount.fromPlanck(b.feeNQT).getSigna()
          ? this.checkDirection(event.direction) : -this.checkDirection(event.direction));
        break;
      case 'account':
        dataSource.sort((a, b) => {
          return this.getAccountName(a) > this.getAccountName(b)
            ? this.checkDirection(event.direction) : -this.checkDirection(event.direction);
        });
        break;
      case 'confirmations':
        dataSource.sort((a, b) => a.confirmations > b.confirmations
          ? this.checkDirection(event.direction) : -this.checkDirection(event.direction));
        break;
    }

    this.dataSource.data = event.direction ? dataSource : this.currentDataSource;
  }

  getAmountSort(transaction: Transaction): string {
    return !this.isCommitment(transaction) ? this.getAmount(transaction) : this.getCommitmentAmount(transaction);
  }

  public getAccountName(transaction: Transaction): string {
    if (this.isOwnAccount(transaction.sender)) {
      if (!this.isMultiOutPayment(transaction) && !transaction.recipientRS) {
        return 'SELF';
      }
      if (!this.isMultiOutPayment(transaction) && transaction.recipientRS) {
        return this.getTransactionAccount(transaction.recipientRS);
      }
      if (this.isMultiOutPayment(transaction)) {
        return 'Multiple Recipients';
      }
    } else {
      return this.getTransactionAccount(transaction.senderRS);
    }
  }

  checkDirection(direction): number {
    return direction === 'asc' ? 1 : (direction === 'desc' ? -1 : 0);
  }

  getAddressParams(address: string): string {
    return getAddressParams(address);
  }
}
