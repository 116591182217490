<app-page title="{{ 'fertilizer' | i18n }}" *ngIf="fees" [wide]="true">
  <div class="account-info pl-20 pr-20 mb-8">
    <div class="account-info__field">
      <small class="secondary-text text-uppercase">{{'account'| i18n}}</small>
      <p class="pb-8">{{account.accountRS}}</p>
    </div>
    <div *ngIf="isSupported" class="account-info__field">
      <small class="secondary-text text-uppercase">{{'applied_fertilizer'| i18n}}</small>
      <p class="pb-8 "><strong>{{webCommitment | burstAmount:'planck':false}}</strong></p>
    </div>
    <div class="account-info__field">
      <small class="secondary-text text-uppercase">{{'balance_available'| i18n}}</small>
      <p>{{getBalance() | burstAmount }}</p>
    </div>
  </div>
  <div class="pl-20 pr-20">
    <app-set-commitment-form *ngIf="isSupported" [fees]="fees" [account]="account" [lightNodeCommit]="lightNodeCommitted"></app-set-commitment-form>
    <p *ngIf="!isSupported" class="message-box warning">
      {{'fertilizer_unsupported' | i18n}}
      <a [routerLink]="['/settings']">{{'settings' | i18n}}</a>
    </p>
  </div>
</app-page>
