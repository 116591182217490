import { Component, Input } from '@angular/core';
import { CreateService } from '../../create.service';
import { Address, AddressPrefix } from '@fruitsjs/core';
import {
  generateMasterKeys,
  PassPhraseGenerator
} from '@fruitsjs/crypto';
import { NetworkService } from '../../../../network/network.service';
import {StoreService} from '../../../../store/store.service';
import * as bip39 from 'bip39';
import {NotifierService} from 'angular-notifier';
import {I18nService} from '../../../../layout/components/i18n/i18n.service';


@Component({
  selector: 'app-account-create-existing',
  styleUrls: ['./existing.component.scss'],
  templateUrl: './existing.component.html'
})
export class AccountCreateExistingComponent {

  @Input('passphrase') passphrase: string;

  passphraseGenerator: PassPhraseGenerator;
  isMultiWallet: boolean;
  burnAddress: string[] = [];
  canGetBurnAddress = false;

  constructor(
    public createService: CreateService,
    private networkService: NetworkService,
    private storeService: StoreService,
    private notifierService: NotifierService,
    private i18nService: I18nService
  ) {
    this.passphraseGenerator = new PassPhraseGenerator();
    this.storeService.getSettings().then(setting => {
      this.isMultiWallet = setting.isMultiWallet;
      this.getBurnAddress(setting.node);
    });
  }

  getBurnAddress = async (node: string) => {
    try {
      const burnAddress = await this.networkService.getBurnAddress(node);
      this.burnAddress = burnAddress.accountsRS;
      this.canGetBurnAddress = true;
    } catch (e) {
      this.notifierService.notify('error', this.i18nService.getTranslation('error_unknown'));
    }
  }

  validatePassphrase(phrase: string): void {
    if (!this.canGetBurnAddress) {
      return;
    }

    if (phrase.split(' ').length > 12) {
      this.notifierService.notify('error', this.i18nService.getTranslation('invalid_passphrase'));
      return;
    }

    if (this.isMultiWallet && !bip39.validateMnemonic(phrase)) {
      this.notifierService.notify('error', this.i18nService.getTranslation('invalid_passphrase'));
      return;
    }

    this.setManualPassphrase(phrase);
  }

  public setPassphraseAndGenerateMasterKeys(phrase: string[]): void {
    const prefix = this.networkService.isMainNet() ? AddressPrefix.MainNet : AddressPrefix.TestNet;
    this.createService.setPassphrase(phrase);
    const keys = generateMasterKeys(this.createService.getCompletePassphrase());
    const address = Address.fromPublicKey(keys.publicKey, prefix);
    this.createService.setId(address.getNumericId());
    this.createService.setAddress(address.getReedSolomonAddress());

    if (this.burnAddress.includes(this.createService.getAddress())) {
      this.notifierService.notify('error', this.i18nService.getTranslation('invalid_passphrase'));
      this.createService.reset();
      return;
    }

    setTimeout(x => {
      this.createService.setStep(1);
    }, 0);
  }

  public setManualPassphrase(phrase: string): void {
    this.setPassphraseAndGenerateMasterKeys(phrase.split(' '));
  }
}
