<div class="address-container">
  <mat-form-field appearance="outline">

    <input matInput prefix="{{addressPrefix}}-" pasteableAddress [patterns]="burstAddressPattern" mask="____-____-____-____-____-____"
           [dropSpecialCharacters]="false" [showMaskTyped]="true" [(ngModel)]="address" name="address"
           placeholder="{{ 'address' | i18n }}">
  </mat-form-field>
  <button class="btn-common" routerLink="/login">{{ 'cancel' | i18n }}</button>
  <button class="btn-common" (click)="submit(this.address)">{{ 'submit' | i18n }}</button>
</div>
