import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import {Account} from '@fruitsjs/core';
import { AccountService } from './account.service';
import { StoreService } from 'app/store/store.service';
import {getReedSolomonAddress, isReedSolomonAddress} from '../../utils';

@Injectable()
export class NewAccountResolver implements Resolve<Promise<Account>> {
  constructor(private storeService: StoreService,
              private accountService: AccountService) {
  }

  resolve(route: ActivatedRouteSnapshot): Promise<Account> {
    const isAddress = route.params.id ? isReedSolomonAddress(route.params.id) : false;
    return route.params.id ?
      (isAddress ? this.accountService.getAccount(getReedSolomonAddress(route.params.id)) : new Promise((resolve, reject) => {
        reject(null);
      })) :
      this.accountService.getCurrentAccount();
  }
}



