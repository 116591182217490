<form (ngSubmit)="onSubmit($event)" #sendBurstForm="ngForm">

  <div class="action-container">
    <mat-checkbox labelPosition="after"
                  name="same-amount-checkbox"
                  class="same-amount"
                  [(ngModel)]="sameAmount"
    >{{ "same_out" | i18n }}?
    </mat-checkbox>
    <div class="button-wrapper">
      <button mat-icon-button type="button" (click)="resetRecipients()" matTooltip="{{'reset_recipients' | i18n}}">
        <img height="20" width="20" src="./assets/icons/menu/icon-reset-recipt.png" alt="">
      </button>
      <button mat-icon-button type="button" (click)="addBatchedRecipient($event)"
              matTooltip="{{'add_recipient_batch' | i18n}}">
        <img height="20" width="20" src="./assets/icons/menu/icon-upload-csv.png" alt="">
      </button>
    </div>
  </div>

  <mat-form-field *ngIf="sameAmount" appearance="outline" floatLabel="always">
    <mat-label>{{ 'amount' | i18n }}</mat-label>
    <input matInput [(ngModel)]="amount" name="amount" (ngModelChange)="valueChange($event)">
  </mat-form-field>
  <p *ngIf="sameAmount" class="text-right changeFrtsMulti"  matSuffix>
    ~ {{ amount ? (changeFrts | number) : '' }}
    {{this.settings.currency.frts}}
  </p>
  <div class="recipients-container {{sameAmount ? '-mt-12' : 'mt-13'}}">
    <small [class.no-balance]="hasRecipientsExceeded()">{{getRecipientCounter()}}</small>
    <div class="recipients-list">
      <ng-container *ngFor="let recipient of recipients; let i = index; trackBy: trackByIndex;" [attr.data-index]="i">
        <div class="recipients-item">
          <burst-recipient-input [recipientValue]="recipient.addressRaw"
                                 (recipientChange)="onRecipientChange($event, i)"
                                 [symbol]="symbol.toLowerCase()"
                                 [withQrCode]="false"></burst-recipient-input>
          <mat-form-field appearance="outline" floatLabel="always" [class.last]="isLastRecipientItem(i) && i !== 0" *ngIf="!sameAmount">
            <mat-label>{{ 'amount' | i18n }}</mat-label>
            <input matInput [(ngModel)]="recipient.amount" [name]="'amount' + i">
          </mat-form-field>
          <button *ngIf="isLastRecipientItem(i)" mat-icon-button type="button" (click)="addRecipient($event)"
                  matTooltip="{{'add_recipient' | i18n}}">
            <img height="30" width="30" src="./assets/icons/menu/icon-add-recipt.png" alt="">
          </button>
          <button *ngIf="this.recipients.length > 1" mat-icon-button type="button" (click)="removeRecipientItem(i)"
                  matTooltip="{{'add_recipient' | i18n}}">
            <mat-icon color="warn">clear</mat-icon>
          </button>
        </div>
      </ng-container>
    </div>
  </div>

  <mat-form-field appearance="outline" floatLabel="always">
    <mat-label>{{ 'fee' | i18n }}</mat-label>
    <input matInput [(ngModel)]="fee" name="fee" [disabled]="isFree">
  </mat-form-field>

  <burst-fee-selector *ngIf="!isFree" [fees]="fees" [(fee)]="fee"></burst-fee-selector>

  <div *ngIf="advanced">
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>{{ 'deadline_hours' | i18n }}</mat-label>
      <input matInput [(ngModel)]="deadline" name="deadline" placeholder="{{ 'deadline_hours' | i18n }}">
    </mat-form-field>
  </div>
  <div class="send-button-wrapper mt-8">
    <mat-form-field  class="message-text password-field" floatLabel="always" appearance="outline">
      <mat-label>{{'enter_pin' | i18n}}<span style="color:red">&nbsp;*</span></mat-label>
      <input type="password" matInput [(ngModel)]="pin" name="pin">
    </mat-form-field>

<!--    <button class="send-button" mat-icon-button type="submit" aria-label="Send message"-->
<!--            [disabled]="!canSubmit()">-->
<!--      <ng-container *ngIf="!isSending">-->
<!--        <mat-icon [class.enabled]="canSubmit() || false">send</mat-icon>-->
<!--      </ng-container>-->
<!--      <ng-container *ngIf="isSending">-->
<!--        <mat-spinner diameter="20"></mat-spinner>-->
<!--      </ng-container>-->
<!--    </button>-->
  </div>

  <strong>{{'total' | i18n}}:&nbsp;</strong>
  <span [class.no-balance]="!hasSufficientBalance()">{{ getTotal().getSigna() | burstAmount }}</span>
<!--  <a (click)="advanced=!advanced"><em>{{ advanced ? ("basic" | i18n) : "advanced" | i18n }}</em></a>-->
  <div class="send-button">
    <button class="{{canSubmit() ? 'btn-common' : 'btn-common-disable'}}" [disabled]="!canSubmit()" mat-raised-button mat-button color="primary">
      {{ 'send' | i18n }}
    </button>
  </div>

</form>
