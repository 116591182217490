import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { I18nService } from '../i18n/i18n.service';
import { constants } from 'app/constants';
import { StoreService } from 'app/store/store.service';
import { AccountService } from 'app/setup/account/account.service';
import { Account } from '@fruitsjs/core';
import { Router } from '@angular/router';
import { NetworkService } from '../../../network/network.service';
import { UnsubscribeOnDestroy } from '../../../util/UnsubscribeOnDestroy';
import {environment} from '../../../../environments/environment';
import {NftService} from '../../../payment-gateway/nft.service';
import {getAddressParams} from '../../../utils';
import {isOverflowing} from '../../../util.service';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  selectedLanguage: any;
  userStatusOptions: any[];
  isMainNet = false;
  isAdmin: boolean;
  fruitscanAccount = environment.fruitscanAccount;

  @Input('selectedAccount') selectedAccount: Account;
  @Input('accounts') accounts: Account[];

  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private i18nService: I18nService,
    private accountService: AccountService,
    private networkService: NetworkService,
    private storeService: StoreService,
    private router: Router,
    private nftService: NftService
  ) {
    super();
    this.languages = constants.languages;
    this.navigation = navigation;
    this.isAdmin = environment.isAdmin;
  }

  ngOnInit(): void {
    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((settings) => {
        this.horizontalNavbar = settings.layout.navbar.position === 'top';
        this.rightNavbar = settings.layout.navbar.position === 'right';
        this.hiddenNavbar = settings.layout.navbar.hidden === true;
      });

    // this.networkService.isMainNet$
    //   .pipe(takeUntil(this.unsubscribeAll))
    //   .subscribe((isMainNet => {
    //     this.isMainNet = isMainNet;
    //   }));
    this.isMainNet = environment.isMainNet;

    this.storeService.ready
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(() => {
        this.selectedLanguage = this.i18nService.currentLanguage;

      });
  }

  convertIdToAddress(id): string {
    return this.nftService.convertAccountIdToAddress(id);
  }

  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  setLanguage(lang): void {
    this.selectedLanguage = lang;
    this.i18nService.setLanguage(lang);
    window.location.reload();
  }

  setAccount(account): void {
    this.selectedAccount = account;
    this.accountService.selectAccount(account);
    this.router.navigate(['/']);
  }

  getMultiWalletIndex(address): string {
    if (this.accounts && this.accounts.length) {
      let index = 0;
      for (let i = 0; i < this.accounts.length; i++) {
        if (this.accounts[i].multiWallet) {
          index++;
          if (this.accounts[i].account === address) {
            break;
          }
        }
      }
      return index < 10 ? '0' + index : index.toString();
    }
    return '0';
  }

  getAddressParams(address: string): string {
    return getAddressParams(address);
  }

  displayAddress(account: any): string {
    const alias = localStorage.getItem(account.accountRS);
    if (alias){
      return alias;
    }
    return account.name;
  }

  isOverflowing(element: HTMLElement): boolean {
    return isOverflowing(element);
  }
}
