import { NgModule } from '@angular/core';
import { TransactionTableComponent } from './transaction-table.component';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { CommonModule } from '@angular/common';
import { I18nModule } from 'app/layout/components/i18n/i18n.module';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { AppSharedModule } from '../../../shared/shared.module';
import { MatSortModule } from '@angular/material';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
  declarations: [
    TransactionTableComponent,
  ],
  exports: [
    TransactionTableComponent
  ],
    imports: [
        CommonModule,
        I18nModule,
        FuseSharedModule,
        MatTableModule,
        MatIconModule,
        MatPaginatorModule,
        RouterModule,
        AppSharedModule,
        MatSortModule,
        MatTooltipModule
    ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' }
    }
  ]
})
export class TransactionTableModule { }
