<form autocomplete="off" (ngSubmit)="onSubmit($event);" #sendBurstForm="ngForm">

  <burst-recipient-input [recipientValue]="recipient.addressRaw"
                         (recipientChange)="onRecipientChange($event)"
                         (qrCodeUpload)="onQRUpload($event)"
                         [disabled]="immutable"
                         [symbol]="symbol.toLowerCase()">
  </burst-recipient-input>

  <div class="amount-wrapper">
    <amount-input [disabled]="immutable"
                  [amountValue]="amount"
                  (amountChange)="valueChange($event)"
                  [symbol]="symbol"
                  [balances]="balances?.availableBalance.getSigna()"
                  [fees]="fee"
                  [decimals]="decimals"
                  [account]="account"></amount-input>

    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>{{ 'fee' | i18n }}</mat-label>
      <input matInput [(ngModel)]="fee" name="fee" [disabled]="immutable || isFree">
      <span class="mr-8 prefix" matSuffix>{{symbol}}</span>
    </mat-form-field>
  </div>

  <burst-fee-selector *ngIf="!immutable && !isFree" [fees]="fees" [(fee)]="fee"></burst-fee-selector>
  <p class="text-right changeFrts"  style="margin-top: -20px" matSuffix>
    ~ {{ changeFrts || '' }}
    {{this.settings?.currency.frts}}
  </p>
  <div class="checkbox not-margin-top">
    <mat-checkbox labelPosition="after" [(ngModel)]="showMessage"
                  name="showMessage">{{ "add_message_q" | i18n }}</mat-checkbox>
  </div>

  <div *ngIf="showMessage">
    <mat-form-field appearance="outline" floatLabel="always" class="not-margin-top">
      <mat-label>{{ 'message' | i18n }}</mat-label>
      <textarea matInput [(ngModel)]="message" name="message"
                cdkTextareaAutosize="true"
                cdkAutosizeMinRows="2"
                cdkAutosizeMaxRows="2"
      ></textarea>
    </mat-form-field>
    <div *ngIf="isMessageTooLong()" class="error">{{ "message_too_long" | i18n }}</div>
    <div class="checkbox not-margin-top2">
      <mat-checkbox class="font-size-14"
                    labelPosition="after"
                    [disabled]="!isAccountActive"
                    [(ngModel)]="encrypt"
                    name="encrypt">{{ "encrypt_message" | i18n }}?
      </mat-checkbox>
    </div>
  </div>

  <div *ngIf="advanced">
    <div class="checkbox">
      <mat-checkbox labelPosition="after" [disabled]="immutable" [(ngModel)]="messageIsText"
                    name="messageIsText">{{ "message_is_text" | i18n }}</mat-checkbox>
    </div>

    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>{{ 'deadline_hours' | i18n }}</mat-label>
      <input matInput [(ngModel)]="deadline" name="deadline" placeholder="{{ 'deadline_hours' | i18n }}">
    </mat-form-field>
  </div>

  <div class="submit-transaction">
    <mat-form-field class="message-text password-field pt-3" floatLabel="always" appearance="outline">
      <mat-label>{{ 'enter_pin' | i18n }}<span style="color:red"> *</span></mat-label>
      <input type="password" matInput placeholder="{{'enter_pin' | i18n}}" [(ngModel)]="pin" name="pin">
    </mat-form-field>

<!--    <button class="send-button" mat-icon-button type="submit" aria-label="Send message"-->
<!--            [disabled]="!canSubmit()">-->
<!--      <ng-container *ngIf="!isSending">-->
<!--        <mat-icon [class.enabled]="canSubmit() || false">send</mat-icon>-->
<!--      </ng-container>-->
<!--      <ng-container *ngIf="isSending">-->
<!--        <mat-spinner diameter="20"></mat-spinner>-->
<!--      </ng-container>-->
<!--    </button>-->
  </div>

  <strong>{{'total' | i18n}}:&nbsp;</strong>
  <span [class.error]="!hasSufficientBalance()">{{ getTotal().getSigna() | burstAmount }}</span>
<!--  -<a (click)="advanced=!advanced"><em>{{ advanced ? ("basic" | i18n) : "advanced" | i18n }}</em></a>-->
  <div class="send-button">
    <button class="{{canSubmit() && !isSending ? 'btn-common' : 'btn-common-disable'}}" [disabled]="!canSubmit() || isSending" mat-raised-button mat-button color="primary">
      {{ 'send' | i18n }}
    </button>
  </div>
</form>
