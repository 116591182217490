import {Component} from '@angular/core';
import {CreateService, StepsEnum} from '../../create.service';
import {generateMasterKeys, PassPhraseGenerator} from '@fruitsjs/crypto';
import {Address} from '@fruitsjs/core/src';
import {NetworkService} from '../../../../network/network.service';
import {AddressPrefix} from '@fruitsjs/core';
import {StoreService} from '../../../../store/store.service';
import * as bip39 from 'bip39';
import {Router} from '@angular/router';

@Component({
  selector: 'app-account-create-seed',
  styleUrls: ['./seed.component.scss'],
  templateUrl: './seed.component.html'
})
export class AccountCreateSeedComponent {

  private seed: any[] = [];
  public progress = 0;
  passphraseGenerator: PassPhraseGenerator;
  settings: any;
  passphraseText: any;
  passphraseMnemonic: any;
  isLoading = false;
  agreeAll = false;

  constructor(private createService: CreateService,
              private networkService: NetworkService,
              private storeService: StoreService,
              private router: Router) {
    this.passphraseGenerator = new PassPhraseGenerator();
    this.storeService.getSettings().then(result => {
      this.settings = result;
      if (result.isMultiWallet === undefined) {
        this.router.navigate(['/wallet']);
      }
    });
  }

  checkAgree(value: boolean): void {
    this.agreeAll = value;
  }

  public checkIsMultiWallet(mnemonic): void {
    if (this.settings.isMultiWallet) {
      this.setPassphraseAndGenerateMasterKeys(mnemonic.split(' '));
    } else {
      this.passphraseGenerator.generatePassPhrase(this.seed)
        .then(phrase => {
          this.setPassphraseAndGenerateMasterKeys(phrase);
        });
    }
  }

  public setPassphraseAndGenerateMasterKeys(phrase: string[]): void {
    const prefix = this.networkService.isMainNet() ? AddressPrefix.MainNet : AddressPrefix.TestNet;
    this.createService.setPassphrase(phrase);
    const keys = generateMasterKeys(this.createService.getCompletePassphrase());
    const address = Address.fromPublicKey(keys.publicKey, prefix);
    this.createService.setId(address.getNumericId());
    this.createService.setAddress(address.getReedSolomonAddress());
    this.seed = [];
    setTimeout(() => {
      this.createService.setStep(StepsEnum.Record);
    }, 0);
  }

  public next(): void {
    this.passphraseMnemonic = bip39.generateMnemonic();
    this.passphraseText = this.passphraseMnemonic.split('  ');

    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
      this.checkIsMultiWallet(this.passphraseMnemonic);
    }, 2000);
  }

  public copy(): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.passphraseText;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  canSubmit(): boolean {
    return this.agreeAll;
  }
}
