import {Injectable} from '@angular/core';
import {generateSignature, generateSignedTransactionBytes, verifySignature} from '@fruitsjs/crypto';
import {StoreService} from '../store/store.service';

@Injectable({
  providedIn: 'root'
})
export class NodeService {

  node;

  broadcastTransaction = async (transactionBytes) => {
    const url = this.node + '/fruits?requestType=broadcastTransaction';

    const response = await fetch(url, {
      method: 'POST',

      body: transactionBytes
    });
    return await response.json();
  }

  constructor(private storeService: StoreService) {
    this.storeService.getSettings().then(settings => {
      this.node = settings.node;
    });
  }

  public signAndBroadcastTransaction(unsignedHexMessage, senderPrivateKey, senderPublicKey): any {
    const signature = generateSignature(unsignedHexMessage, senderPrivateKey);
    if (!verifySignature(signature, unsignedHexMessage, senderPublicKey)) {
      throw new Error('The signed message could not be verified! Transaction not broadcasted!');
    }

    const signedMessage = generateSignedTransactionBytes(unsignedHexMessage, signature);
    return this.broadcastTransaction(signedMessage);
  }
}
