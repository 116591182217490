<div class="p-24 dialog">
  <h1 mat-dialog-title>{{ 'delete_confirmation' | i18n }}</h1>
  <div mat-dialog-content>
    <p class="confirm-text">{{ 'delete_account_qe' | i18n }}</p>
    <ul>
      <li *ngFor="let account of selectedAccounts">{{account.accountRS}}</li>
    </ul>
  </div>
  <div mat-dialog-actions>
    <button class="btn-common" [mat-dialog-close]>Cancel</button>
    <button class="btn-common" [mat-dialog-close]="true">Delete</button>
  </div>
</div>
