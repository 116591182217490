<app-page title="{{ 'send_fruits' | i18n }}" *ngIf="fees" [wide]="true">
  <request-selector [account]="account" [coin]="coin" [redirectTo]="redirectTo"></request-selector>
  <div class="account-info pl-20 pr-20 mb-8">
    <div class="account-info__field">
      <small class="info-title">{{'account'| i18n}}</small>
      <p class="pb-8 info-detail">{{account.accountRS}}</p>
    </div>
    <div class="account-info__field">
      <small class="info-title">{{'balance_available'| i18n}}</small>
      <p class="info-detail">{{getBalance() | burstAmount }}</p>
    </div>
  </div>
  <mat-tab-group class="pl-20 pr-20">
    <mat-tab label="{{ 'ordinary_payment' | i18n }}">
      <app-send-burst-form [fees]="fees" [account]="account"></app-send-burst-form>
    </mat-tab>
    <mat-tab *ngIf="isAdmin" label="{{ 'multi_out_payment' | i18n }}">
      <app-send-multi-out-form [fees]="fees" [account]="account"></app-send-multi-out-form>
    </mat-tab>
  </mat-tab-group>
</app-page>
