<div class="cancel-dialog p-24">
  <h1 mat-dialog-title>{{ 'cancel_swap' | i18n }}</h1>
  <div mat-dialog-content style="width: 90%;">
    <div class="warning">{{ 'confirm_cancel_order' | i18n }}</div>

    <mat-form-field style="width: 100%;" class="message-text password-field" floatLabel="never" appearance="standard">
      <input type="password" matInput required placeholder="{{ 'enter_pin' | i18n }}" [(ngModel)]="pin" name="pin">
    </mat-form-field>

    <span><strong>Fee</strong>: {{ fee }} FRUITS</span>
  </div>
  <div class="dialog-action" mat-dialog-actions>
    <button class="btn-common" [mat-dialog-close]>{{ 'no' | i18n }}</button>
    <button class="{{ canSubmit() ? 'btn-common' : 'btn-common-disable' }}" [disabled]="!canSubmit()"
            (click)="onSubmit()">{{ 'yes' | i18n }}</button>
  </div>
</div>
