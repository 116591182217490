import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import mempoolJS from '@mempool/mempool.js';
import {constants} from '../constants';
import {BtcKeys} from '@fruitsjs/core';
import {Tx} from '@mempool/mempool.js/lib/interfaces/bitcoin/transactions';
import {Address} from '@mempool/mempool.js/lib/interfaces/bitcoin/addresses';
import {Amount} from '@fruitsjs/util';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {IBTCFee} from '../main/send-burst/send-btc/send-btc-form/send-btc-form.component';

const Mnemonic = require('bitcore-mnemonic');
const bitcore = Mnemonic.bitcore;
const {bitcoin: {addresses}} = mempoolJS({
  hostname: 'mempool.space',
  network: environment.isMainNet ? 'mainnet' : 'testnet'
});

@Injectable({
  providedIn: 'root'
})
export class BtcService {

  multiCoinUrl = environment.isMainNet ? constants.coinMarketCapURL : constants.multicoinTestURL;

  utxos = async (network, sourceAddress) => {
    const url = `${this.multiCoinUrl}/fruits/multi-coin/api/unspent-outputs?`;

    const response = await fetch(url + new URLSearchParams({
      address: sourceAddress,
      blockchain: network
    }));

    return await response.json();
  }

  confirmedTransactions = async (address) => {
    const url = `${this.multiCoinUrl}/fruits/multi-coin/api/confirmed-transactions?address=${address}&blockchain=${constants.btcNetwork}&network=${environment.isMainNet ? constants.mainnet : constants.testnet}`;

    const response = await fetch(url, {
      method: 'GET'
    });
    return await response.json();
  }

  unconfirmedTransactions = async (address) => {
    const url = `${this.multiCoinUrl}/fruits/multi-coin/api/unconfirmed-transactions?address=${address}&blockchain=${constants.btcNetwork}&network=${environment.isMainNet ? constants.mainnet : constants.testnet}`;

    const response = await fetch(url, {
      method: 'GET'
    });
    return await response.json();
  }

  transactionsStatus = async (address: string) => {
    const url = `${this.multiCoinUrl}/fruits/multi-coin/api/transactions-status?address=${address}&coinType=BTC`;

    const response = await fetch(url, {
      method: 'GET'
    });
    return await response.json();
  }

  broadcastTransaction = async (txhex) => {
    const url = `${this.multiCoinUrl}/fruits/multi-coin/api/broadcast-transaction`;

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');

    const response = await fetch(url, {
      method: 'POST',
      headers: myHeaders,

      body: JSON.stringify({
        txhex,
        blockchain: constants.btcNetwork,
        network: environment.isMainNet ? constants.mainnet : constants.testnet
      })
    });
    return await response.json();
  }

  broadcastTransactionWithData = async (broadcastData: BroadcastData) => {
    const url = `${this.multiCoinUrl}/fruits/multi-coin/api/broadcast-transaction`;

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');

    const response = await fetch(url, {
      method: 'POST',
      headers: myHeaders,

      body: JSON.stringify({
        txhex: broadcastData.txhex,
        blockchain: constants.btcNetwork,
        network: environment.isMainNet ? constants.mainnet : constants.testnet,
        data: broadcastData.data
      })
    });
    return await response.json();
  }

  constructor(private http: HttpClient) {
  }

  async createWallet(passphrase): Promise<BtcKeys> {
    const code = new Mnemonic(passphrase);
    const xpriv = code.toHDPrivateKey(environment.isMainNet ? bitcore.Networks.livenet : bitcore.Networks.testnet);
    const derived = xpriv.derive('m/0\'');
    const privateKey = derived.privateKey;
    const pk = new bitcore.PrivateKey(privateKey.toString(), environment.isMainNet ? bitcore.Networks.livenet : bitcore.Networks.testnet);
    const privateKeyStr = pk.toString();
    const publicKey = new bitcore.PublicKey(pk);
    const publicKeyStr = publicKey.toString();
    const address = bitcore.Address(publicKey, environment.isMainNet ? bitcore.Networks.livenet : bitcore.Networks.testnet);

    const balance = (await this.getTotalAvailableAmount(address.toString())) / constants.btcSATOSHI;

    return {
      address: address.toString(),
      privateKey: privateKeyStr,
      publicKey: publicKeyStr,
      balance: Amount.fromPlanck(balance).getRaw().toString()
    };
  }

  async getTransactions(address): Promise<Tx[]> {
    return addresses.getAddressTxs({address});
  }

  async getBalance(address): Promise<Address> {
    return addresses.getAddress({address});
  }

  async getUnspentTransactions(sourceAddress): Promise<any> {
    return this.utxos(environment.isMainNet ? 'BTC' : 'BTCTEST', sourceAddress);
  }

  async createTransaction(privateKey, sourceAddress, amountToSend, recipient, utxos, fee): Promise<any> {
    const satoshiToSend = Math.floor(amountToSend * constants.btcSATOSHI);
    const satoshiToFee = Math.floor(fee * constants.btcSATOSHI);
    const transaction = new bitcore.Transaction();
    let totalAmountAvailable = 0;

    const inputs = [];

    utxos.data.outputs.forEach((element) => {
      const utxo: any = {};
      utxo.satoshis = Math.round(element.value * constants.btcSATOSHI);
      utxo.script = element.script;
      utxo.address = element.address;
      utxo.txId = element.hash;
      utxo.outputIndex = element.index;
      totalAmountAvailable += utxo.satoshis;
      inputs.push(utxo);
    });

    // Set transaction input
    transaction.from(inputs);

    // set the reviewing address and the amount to send
    transaction.to(recipient, satoshiToSend);

    // Set change address - Address to receive the left over funds after transfer
    transaction.change(sourceAddress);

    // Set transaction fee
    transaction.fee(satoshiToFee);

    // Sign transaction with your private key
    transaction.sign(privateKey);

    return {
      txhex: transaction.serialize({disableDustOutputs: true}),
      fee: satoshiToFee
    };
  }

  public isValidAddress(address): boolean {
    return bitcore.Address.isValid(address, environment.isMainNet ? bitcore.Networks.livenet : bitcore.Networks.testnet);
  }

  public async getCalculatedFee(utxos): Promise<number> {
    let inputCount = 0;
    const outputCount = 2;
    utxos.data.outputs.forEach(() => {
      inputCount += 1;
    });
    const params = new HttpParams()
      .set('input', String(inputCount))
      .set('output', String(outputCount));

    return await this.calculateFee(params).toPromise();
  }

  async getTotalAvailableAmount(sourceAddress, utxos?): Promise<number> {
    if (!(utxos && utxos.data.outputs.length)) {
      utxos = await this.utxos(environment.isMainNet ? 'BTC' : 'BTCTEST', sourceAddress).catch(() => {
        return undefined;
      });
    }
    let totalAmountAvailable = 0;
    if (utxos && utxos.data && utxos.data.outputs) {
      utxos.data.outputs.forEach((element) => {
        const utxo: any = {};
        utxo.satoshis = Math.round(element.value * constants.btcSATOSHI);
        totalAmountAvailable += utxo.satoshis;
      });
    }
    return totalAmountAvailable;
  }

  public calculateFee(params: HttpParams): Observable<any> {
    return this.http.get(this.multiCoinUrl + '/fruits/multi-coin/api/bitcoin/calculate-fee', {params});
  }


  public getBTCFee(): Observable<any> {
    return this.http.get(`${this.multiCoinUrl}/fruits/multi-coin/api/btc-fee`);
  }

  // Redmine-3154
  public async calculateBTCFee(utxos): Promise<any> {
    // clone logic calculate input and output from getCalculatedFee
    let inputCount = 0;
    const outputCount = 2;
    utxos.data.outputs.forEach(() => {
      inputCount += 1;
    });

    // calculate transaction fee
    try {
      const res = await this.getBTCFee().toPromise();
      const data: IBTCFee = res.result;
      data.low = this.calculatedTransactionFee(inputCount, outputCount, data.low);
      data.medium = this.calculatedTransactionFee(inputCount, outputCount, data.medium);
      data.high = this.calculatedTransactionFee(inputCount, outputCount, data.high);
      return data;
    } catch (ex) {
    }
  }

  // Redmine-3154
  public calculatedTransactionFee(input, output, satoshiPerbyte): any {
    // clone logic from api fruits/multi-coin/api/bitcoin/calculate-fee
    const transactionSize = input * 148 + output * 34 + 10;
    return (transactionSize * satoshiPerbyte) / constants.btcSATOSHI;
  }

  // Redmine-3154
  public calculateBTCFeeCustomize(utxos, stasByte): any {
    // clone logic calculate input and output from getCalculatedFee
    let inputCount = 0;
    const outputCount = 2;
    utxos.data.outputs.forEach(() => {
      inputCount += 1;
    });
    try {
      return this.calculatedTransactionFee(inputCount, outputCount, stasByte);
    } catch (ex) {
    }
  }
}

export interface BroadcastData {
  txhex: string;
  data: {
    from: string;
    to: string;
    amount: string;
    fee: string;
    coinType: string;
  };
}
