
<div id="language" fxLayout="column">

  <div id="language-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

    <img id="white-logo" src="assets/images/logos/fruits-wallet-logo-white.png" height="106" width="176" alt=""/>

    <div id="language-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

      <div class="language-content">
        <div class="choose-language-hint" *ngIf="languageSelected.code === 'ja'">あなたの母国語を選んでください。</div>
        <div class="choose-language-hint" *ngIf="languageSelected.code === 'en'">Please select your native language</div>
        <div class="choose-language-hint" *ngIf="languageSelected.code === 'es-es'">Seleccione su lengua materna</div>
        <div class="choose-language-hint" *ngIf="languageSelected.code === 'zh-cn'">请选择您的母语</div>
        <div class="choose-language-hint" *ngIf="languageSelected.code === 'fr'">Veuillez sélectionner votre langue maternelle</div>
        <div class="choose-language-hint" *ngIf="languageSelected.code === 'vn'">Vui lòng chọn ngôn ngữ</div>
        <div class="choose-language-hint" *ngIf="languageSelected.code !== 'ja'">あなたの母国語を選んでください。</div>
        <div class="choose-language-hint" *ngIf="languageSelected.code !== 'en'">Please select your native language</div>
        <div class="choose-language-hint" *ngIf="languageSelected.code !== 'es-es'">Seleccione su lengua materna</div>
        <div class="choose-language-hint" *ngIf="languageSelected.code !== 'zh-cn'">请选择您的母语</div>
        <div class="choose-language-hint" *ngIf="languageSelected.code !== 'fr'">Veuillez sélectionner votre langue maternelle</div>
        <div class="choose-language-hint" *ngIf="languageSelected.code !== 'vn'">Vui lòng chọn ngôn ngữ</div>

        <mat-form-field class="proposal-title" appearance="outline" floatLabel="always">
          <mat-select [(ngModel)]="languageSelected" disableOptionCentering
                      panelClass="myPanelClass">
            <mat-option *ngFor="let language of languages" [value]="language">
              {{ language.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="button-row">
          <button class="btn-common" mat-button (click)="continue()">
            {{ getBtnText() }}
          </button>
        </div>
      </div>

    </div>

  </div>

</div>
