<section class="login" [fusePerfectScrollbar]="false" [fusePerfectScrollbarOptions]="{suppressScrollY:true}">
<div class="login-active__container">
    <div class="signup">
      <div class="signup__container">
        <h1 *ngIf="newUser | async; else updateTitle" class="signup__title">{{ 'account_create_new' | i18n }}</h1>
        <app-account-create [newUser]="!settings.isMultiWallet ? false : (newUser | async)"></app-account-create>
      </div>
    </div>

    <ng-template #updateTitle>
      <h1 class="signup__title">{{ 'update_fruits_account' | i18n }}</h1>
    </ng-template>
  </div>
</section>
