import {Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../environments/environment';
import {destroy} from '../../particles';
import {StoreService} from '../store/store.service';
import {Settings} from '../settings';
import {CreateService} from '../setup/account/create.service';
import {ActivatedRoute, Router} from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {

  settings: Settings;
  redirect: any;
  isAdmin = environment.isAdmin;

  constructor(private storeService: StoreService,
              private createService: CreateService,
              private activatedRouter: ActivatedRoute,
              private router: Router) {
    this.activatedRouter.queryParams.subscribe(params => {
      if (params) {
        this.redirect = params;
      }
    });
    this.storeService.getSettings().then(settings => {
      this.settings = settings;
    });
  }

  version = environment.version;

  ngOnInit(): void {
    this.createService.reset();
    // initialize('login-particles', particleConf);
  }

  ngOnDestroy(): void {
    destroy();
  }

  hasCallbackUrl(): boolean {
    return this.settings && !!this.settings.callbackUrl;
  }

  getRedirectQueryParams(): any {
    const result: any = _.cloneDeep(this.redirect);
    result.newUser = true;
    return result;
  }
}
