<h2 class="passphrase">{{ 'your_passphrase' | i18n }}</h2>
<div class="passphrase-container">
  <form autocomplete="off">
    <mat-form-field class="input" appearance="outline">
      <mat-label>{{ 'your_passphrase' | i18n }}<span style="color: red">&nbsp;*</span></mat-label>
      <input name="passphrase" matInput type="text" placeholder="{{ 'passphrase' | i18n }}" [(ngModel)]="passphrase">
      <mat-hint class="passphrase-hint">{{ 'error_passphrase_required' | i18n }}</mat-hint>
    </mat-form-field>
  </form>
</div>
<div class="text-center navigation">
  <button class="btn-common" (click)="validatePassphrase(this.passphrase)">
    {{ 'next' | i18n }}
  </button>
</div>
