import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Account} from '@fruitsjs/core';
import {decryptAES, encryptAES, hashSHA256} from '@fruitsjs/crypto';
import {NotifierService} from 'angular-notifier';
import {I18nService} from '../../../layout/components/i18n/i18n.service';
import {StoreService} from '../../../store/store.service';

@Component({
  selector: 'export-passphrase-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.scss']
})
export class ExportDialogComponent {

  pin: string;
  passphrase = '';
  agreeAll = false;
  tokens: Token[] = [];

  constructor(public dialogRef: MatDialogRef<ExportDialogComponent>,
              public notificationService: NotifierService,
              public i18nService: I18nService,
              public storeService: StoreService,
              @Inject(MAT_DIALOG_DATA) public account: Account) {
  }

  export(): void {
    if (this.account.encryptedPassphrase) {
      const passphrase = decryptAES(this.account.encryptedPassphrase, hashSHA256(this.pin));
      if (!passphrase) {
        this.notificationService.notify('error', this.i18nService.getTranslation('wrong_pin'));
        return;
      }
      this.passphrase = passphrase;
      const tokens = this.passphrase.split(' ');
      this.passphraseTokens(tokens);
    } else {
      const privateKey = decryptAES(this.account.keys.agreementPrivateKey, hashSHA256(this.pin));
      if (!privateKey) {
        this.notificationService.notify('error', this.i18nService.getTranslation('wrong_pin'));
        return;
      }
      this.passphrase = this.account.multiKeys.passphrase;
      this.account.encryptedPassphrase = encryptAES(this.account.multiKeys.passphrase, hashSHA256(this.pin));
      this.storeService.saveAccount(this.account);
    }
  }

  canSubmit(): boolean {
    return this.pin && this.pin.length >= 1 && this.agreeAll;
  }

  checkAgree(value: boolean): void {
    this.agreeAll = value;
  }

  public copy(): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.passphrase;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    const success = document.execCommand('copy');
    document.body.removeChild(selBox);

    if (success) {
      this.notificationService.notify('success', this.i18nService.getTranslation('success_clipboard_copy')
      );
    } else {
      this.notificationService.notify('error', this.i18nService.getTranslation('error_clipboard_copy')
      );
    }
  }

  private passphraseTokens(tokens: string[]): void {
    if (tokens && tokens.length) {
      this.tokens = tokens.map((t, index) => ({
        text: t,
        ok: null,
        index: index
      }));
    }
  }
}

interface Token {
  text: string;
  ok: boolean;
  index: number;
}
