<mat-table #table [dataSource]="dataSource" matSort (matSortChange)="onMatSortChange($event)">
  <ng-container matColumnDef="transaction_id">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{
      "transaction_id" | i18n
      }}</mat-header-cell>
    <mat-cell class="smaller" *matCellDef="let transaction">
      <a
        [routerLink]="['/frts-transactions/transaction', transaction.transaction]"
      >{{ transaction.transaction }}</a
      >
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="attachment">
    <mat-header-cell class="column-attachment" *matHeaderCellDef>
      <img src="./assets/icons/menu/icon-attachment.png" height="24" width="24" alt=""/>
    </mat-header-cell>
    <mat-cell class="column-attachment" *matCellDef="let transaction">
      <span *ngIf="transaction.attachment">
        <mat-icon>done</mat-icon>
      </span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="timestamp">
    <mat-header-cell style="min-width: 80px;" *matHeaderCellDef mat-sort-header>
<!--      <mat-icon>schedule</mat-icon>&nbsp;{{"date" | i18n}}-->
      <img src="./assets/icons/menu/icon-date.png" height="24" width="24" alt=""/>&nbsp;{{"date" | i18n}}
    </mat-header-cell>
    <mat-cell style="min-width: 80px;" class="smaller" *matCellDef="let transaction">
      {{getDate(transaction)}}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="message">
    <mat-header-cell [hidden]="true" style="min-width: 170px;" *matHeaderCellDef mat-sort-header>{{ 'message' | i18n }}</mat-header-cell>
    <mat-cell [hidden]="true" style="min-width: 170px; padding-bottom: 5px; padding-top: 5px;" class="smaller"  *matCellDef="let transaction">
      <span *ngIf="transaction.attachment && transaction.attachment['version.Message'] && transaction.attachment.messageIsText">
        {{ reduceLongMessage(transaction.attachment.message) }}
      </span>
      <span *ngIf="transaction.attachment && transaction.attachment['version.Message'] && !transaction.attachment.messageIsText">
        {{ reduceLongMessage(hexToASCII(transaction.attachment.message)) }}
      </span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="type">
    <mat-header-cell style="min-width: 170px;" *matHeaderCellDef mat-sort-header>{{ 'type' | i18n }}</mat-header-cell>
    <mat-cell style="min-width: 170px;" class="smaller"  *matCellDef="let transaction">
      {{ getTransactionNameFromType(transaction) | i18n }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="amount">
    <mat-header-cell class="min-width" *matHeaderCellDef mat-sort-header>
<!--      <mat-icon>local_atm</mat-icon>-->
      <img src="./assets/icons/menu/icon-amount.png" height="24" width="24" alt=""/>&nbsp;{{ 'amount' | i18n }}
    </mat-header-cell>
    <mat-cell class="min-width" *matCellDef="let transaction">
      <div *ngIf="!isCommitment(transaction)"
           [ngClass]="{fg_green: !isAmountNegative(transaction), fg_red: isAmountNegative(transaction)}">
        {{getAmount(transaction) | burstAmount:'burst':false}}
      </div>
      <div *ngIf="isCommitment(transaction)" class="fg_green">
        {{getCommitmentAmount(transaction) | burstAmount:'burst':false}}
      </div>
    </mat-cell>
  </ng-container>


  <ng-container matColumnDef="fee">
    <mat-header-cell class="min-width" *matHeaderCellDef mat-sort-header>
<!--      <mat-icon>compare_arrows</mat-icon>&nbsp;{{ "fee" | i18n }}-->
      <img src="./assets/icons/menu/icon-fee.png" height="24" width="24" alt=""/>&nbsp;{{ "fee" | i18n }}
    </mat-header-cell>
    <mat-cell class="smaller min-width"  *matCellDef="let transaction">
      {{transaction.feeNQT | burstAmount:'planck':true}}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="account">
    <mat-header-cell class="column-address" *matHeaderCellDef mat-sort-header>
<!--      <mat-icon>account_box</mat-icon>&nbsp;{{ "account" | i18n }}-->
      <img src="./assets/icons/menu/icon-account.png" height="24" width="24" alt=""/>&nbsp;{{ "account" | i18n }}
    </mat-header-cell>
    <mat-cell class="smaller column-address" *matCellDef="let transaction">

      <div *ngIf="!isMultiOutPayment(transaction) && !transaction.recipientRS"
           class="centered text-uppercase"
      >
        {{"self" | i18n}}
      </div>
      <a *ngIf="!isMultiOutPayment(transaction) && transaction.recipientRS"
         class="centered"
         [href]="fruitscanAccount + getAddressParams(getTransactionAddress(transaction))">
        <ng-container *ngIf="getTransactionAccount(transaction)">
          <span>{{getTransactionAccount(transaction)}}</span>
          <br>
        </ng-container>
        <span matTooltip="{{ getTransactionAddress(transaction) }}">{{getTransactionAddress(transaction, true)}}</span>
      </a>
      <div *ngIf="isMultiOutPayment(transaction)"
           class="centered text-uppercase"
      >
        {{"multiple_recipients" | i18n}}
      </div>



    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="confirmations">
    <mat-header-cell class="confirm-column" *matHeaderCellDef mat-sort-header>{{
      "confirmations" | i18n
      }}</mat-header-cell>
    <mat-cell class="smaller confirm-column" *matCellDef="let transaction">
      <span *ngIf="transaction.confirmations !== undefined">{{transaction.confirmations < 0 ? 0 : transaction.confirmations}}</span>
      <span *ngIf="transaction.confirmations === undefined">{{'pending' | i18n}}</span>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row style="align-items: stretch" *matRowDef="let row; columns: displayedColumns"
           [class]="getRowClass(row)"
  >
  </mat-row>
</mat-table>

<div [hidden]="!(dataSource && dataSource.data && dataSource.data.length > 10)">
  <mat-paginator
    *ngIf="paginationEnabled"
    #paginator
    [pageSize]="10"
    [pageSizeOptions]="[10, 20, 50]"
    [showFirstLastButtons]="true"
  >
  </mat-paginator>
</div>
