import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../environments/environment';
import {constants} from './constants';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AccountTransferService {

  marketUrl = environment.isMainNet ? constants.MARKET_MAIN : constants.MARKET_TEST;

  constructor(private http: HttpClient) {
  }

  public createAccountTransfer(data: FormData): Observable<any> {
    return this.http.post(this.marketUrl + '/fruits/account-transfer/create', data);
  }

  public getNewAccount(account: string): Observable<any> {
    return this.http.get(this.marketUrl + `/fruits/account-transfer/get/new/${account}`);
  }

  public getOldAccounts(): Observable<any> {
    return this.http.get(this.marketUrl + `/fruits/account-transfer/get/old`);
  }
}
