import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';

@Component({
    selector: 'manage-wallet-dialog',
    templateUrl: './manage-wallet-dialog.component.html',
    styleUrls: ['./manage-wallet-dialog.component.scss']
})
export class ManageWalletDialogComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<ManageWalletDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private router: Router) {
    }

    ngOnInit(): void {
    }

    toManageAccounts(): void {
      this.router.navigate(['/accounts']);
      this.dialogRef.close(true);
    }
}
