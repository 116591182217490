import {Injectable} from '@angular/core';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor() {
  }

  public logEvent(eventName: string, eventParams?: any): void {
    firebase.analytics().logEvent(eventName, eventParams);
  }
}
