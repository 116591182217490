
<div id="disclaimer" fxLayout="column">

  <div id="disclaimer-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

      <div id="disclaimer-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

          <div class="logo">
              <img src="assets/images/logos/logo_disclaimer.png" alt="logo-disclaimer">
          </div>
          <p class="logo-title">FRUITS</p>

          <div class="title">Disclaimer</div>

          <div class="subtitle">
            <p>This program is free software: you can redistribute it and/or modify
              it under the terms of the GNU General Public License as published by
              the Free Software Foundation, either version 3 of the License, or
              (at your option) any later version.</p>

              <p>This program is distributed in the hope that it will be useful,
              but WITHOUT ANY WARRANTY; without even the implied warranty of
              MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
              GNU General Public License for more details.</p>

              <p>You should have received a copy of the GNU General Public License
              along with this program.  If not, see <a href="http://www.gnu.org/licenses/" target="_blank">http://www.gnu.org/licenses/</a></p>

          </div>

          <div class="button-row">
            <button style="color: #ED6D00;" mat-button (click)="agree()">I Understand</button>
          </div>

      </div>

  </div>

</div>
