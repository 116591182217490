import {Component, OnInit, ViewChild} from '@angular/core';
import {Account, SuggestedFees} from '@fruitsjs/core';
import {Amount} from '@fruitsjs/util';
import {NgForm} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {AccountService} from 'app/setup/account/account.service';
import {StoreService} from 'app/store/store.service';
import {NotifierService} from 'angular-notifier';
import {I18nService} from 'app/layout/components/i18n/i18n.service';
import {burstAddressPattern} from 'app/util/burstAddressPattern';
import {decryptAES, hashSHA256} from '@fruitsjs/crypto';
import {Title} from '@angular/platform-browser';
import {constants} from '../../constants';

@Component({
  selector: 'app-set-account-info',
  templateUrl: './set-account-info.component.html',
  styleUrls: ['./set-account-info.component.scss']
})
export class SetAccountInfoComponent implements OnInit {
  @ViewChild('setAccountInfoForm', {static: false}) public setAccountInfoForm: NgForm;

  name: string;
  description: string;
  advanced = false;
  showMessage = false;
  isSending = false;
  immutable = false;
  burstAddressPatternRef = burstAddressPattern;
  deadline = '24';
  pin = '';
  fee: string;

  account: Account;
  fees: SuggestedFees;
  isEditing = false;

  constructor(private route: ActivatedRoute,
              private accountService: AccountService,
              private storeService: StoreService,
              private notifierService: NotifierService,
              private i18nService: I18nService,
              private title: Title) {
    this.title.setTitle(constants.pageTitle + 'Info');
  }

  ngOnInit(): void {
    this.account = this.route.snapshot.data.account as Account;
    this.fees = this.route.snapshot.data.suggestedFees as SuggestedFees;
    this.immutable = this.account.type === 'offline';
    setTimeout(() => {
      this.fee = Amount.fromPlanck(this.fees.standard + '').getSigna();
      this.name = this.account.name;
      this.description = this.account.description;
    }, 0);
  }

  async onSubmit(event): Promise<void> {
    event.stopImmediatePropagation();
    this.isSending = true;

    const err = this.validate();
    if (err) {
      this.notifierService.notify('error', err);
      this.isSending = false;
      return;
    }

    try {
      await this.accountService.setAccountInfo({
        name: this.name,
        description: this.description,
        feePlanck: Amount.fromSigna(this.fee).getPlanck(),
        deadline: parseFloat(this.deadline) * 60,
        pin: this.pin,
        keys: this.account.keys,
      });
      // this.notifierService.notify('success', this.i18nService.getTranslation('success_set_account_info'));
      this.notifierService.notify('success', this.i18nService.getTranslation('set_account_info_confirm_message'));
      this.setPin('');
      this.onCancel();
    } catch (e) {
      console.warn(e);
      this.notifierService.notify('error', this.i18nService.getTranslation('error_unknown'));
    } finally {
      this.isSending = false;
    }
  }

  isInfoNameValid(str: string): boolean {
    return /^[a-zA-Z0-9-_ ]+$/.test(str);
  }

  validate(): string {
    if (this.name && this.name.length > 30) {
      return this.i18nService.getTranslation('error_info_name_length');
    }

    if (!this.isInfoNameValid(this.name ? this.name.trim() : '')) {
      return this.i18nService.getTranslation('error_invalid_info_name');
    }

    if (Amount.fromPlanck(this.fee).getRaw().multipliedBy(1E8).isGreaterThan(Amount.fromPlanck(this.account.unconfirmedBalanceNQT).getRaw())) {
      return this.i18nService.getTranslation('error_not_enough_funds');
    }

    const privateKey = decryptAES(this.account.keys.signPrivateKey, hashSHA256(this.pin));
    if (!privateKey) {
      return this.i18nService.getTranslation('wrong_pin');
    }
    return null;
  }

  isDescriptionTooLong(): boolean {
    return this.description && this.description.length > 1000;
  }

  canSubmit(): boolean {
    const deadline = Number(this.deadline);
    const checkValid = (deadline && deadline >= 0 && deadline <= 24) || deadline === 0;
    return this.pin.length > 0 && this.deadline && checkValid && !this.isDescriptionTooLong();
  }

  setPin(pin: string): void {
    this.pin = pin;
  }

  onCancel(): void {
    this.isEditing = false;
    this.pin = '';
    this.deadline = '24';
    setTimeout(() => {
      this.fee = Amount.fromPlanck(this.fees.standard + '').getSigna();
      this.name = this.account.name;
      this.description = this.account.description;
    }, 0);
  }
}
