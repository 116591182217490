import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {destroy, initialize} from '../../../particles';
import particleConf from '../../../particles/config';
import {StoreService} from '../../store/store.service';
import {Settings} from '../../settings';

@Component({
  selector: 'app-login-active',
  templateUrl: './login-active.component.html',
  styleUrls: ['./login-active.component.scss']
})
export class LoginActiveComponent implements OnInit, OnDestroy {
  newUser: Observable<string | false>;

  settings: Settings;

  constructor(private route: ActivatedRoute,
                  private storeService: StoreService) {
    this.storeService.getSettings().then(settings => {
      this.settings = settings;
    });
  }

  ngOnInit(): void {
    // initialize('login-particles', particleConf);
    this.newUser = this.route.queryParamMap
      .pipe(map(params => params.get('newUser') || false));
  }

  ngOnDestroy(): void {
    destroy();
  }
}
