<h2 class="confirm-passphrase">
  {{"confirm_passphrase" | i18n}}
</h2>
<div class="activate-container">
  <div class="chip-list-wrapper">
    <mat-chip-list>
      <mat-chip *ngFor="let token of this.randomizedTokens"
                [ngClass]="{'button-ok': token.ok === true, 'button-not-ok': token.ok === false}"
                (click)="confirm($event, token.index)"
      >
        {{token.text}}
      </mat-chip>
    </mat-chip-list>
  </div>
  <mat-form-field class="passphrase-input" appearance="outline">
    <mat-label>{{ 'click_words' | i18n }}</mat-label>
    <input matInput placeholder="{{'click_words' | i18n}}" readonly [ngModel]="passphrase" type="password">
  </mat-form-field>
</div>
<small class="active-hint">
  {{"activate_hint" | i18n }}
</small>
<div class="text-center navigation">
  <button class="btn-common" (click)="back()">{{"back" | i18n }}</button>
  <button class="btn-common" (click)="reset()">{{"try_again" | i18n }}</button>
  <button class="{{!isValid && !isActivating ? 'btn-common-disable' : 'btn-common'}}" mat-raised-button (click)="activate()"
          disabled="{{!isValid && !isActivating}}">
    <ng-container *ngIf="isActivating">
      <span class="spinner" style="justify-content: center;">&nbsp;<mat-spinner diameter="20"></mat-spinner></span>
    </ng-container>
    <ng-container *ngIf="!isActivating">
      {{"finish" | i18n }}
    </ng-container>
  </button>
</div>
