import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {constants} from '../constants';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  notificationUrl = environment.isMainNet ? constants.notificationMainnet : constants.notificationTestnet;

  constructor(private http: HttpClient) {
  }

  public getNotificationById(params: HttpParams): Observable<any> {
    return this.http.get(`${this.notificationUrl}/fruits/admin/notification/get`, {params});
  }

  public createTransactionNotification(params: HttpParams): Observable<any> {
    return this.http.post(`${this.notificationUrl}/fruits/transaction/create`, null, {
      params: params
    });
  }

  public readNotification(params: HttpParams): Observable<any> {
    return this.http.post(`${this.notificationUrl}/fruits/user/notify`, null, {
      params: params
    });
  }

  public registerNewDevice(data: FormData): Observable<any> {
    return this.http.post(`${this.notificationUrl}/fruits/device`, data);
  }

  public removeDevice(params: HttpParams): Observable<any> {
    return this.http.post(`${this.notificationUrl}/fruits/device/remove`, null, {
      params: params
    });
  }

  public getUserNotification(params: HttpParams): Observable<any> {
    return this.http.get(`${this.notificationUrl}/fruits/user/history`, {params});
  }

  // Category api
  public getCategories(): Observable<any> {
    return this.http.get(`${this.notificationUrl}/fruits/category/get-all`);
  }

  public getCategoriesActive(): Observable<any> {
    return this.http.get(`${this.notificationUrl}/fruits/category/get-all-active`);
  }

  public createCategory(data: any): Observable<any> {
    return this.http.post(`${this.notificationUrl}/fruits/category/create`, data);
  }

  public updateCategory(data: any): Observable<any> {
    return this.http.post(`${this.notificationUrl}/fruits/category/update`, data);
  }

  // Notification API
  public getNotifications(params: HttpParams): Observable<any> {
    return this.http.get(`${this.notificationUrl}/fruits/admin/notification/get-all`, {params});
  }

  public createNotification(data: any): Observable<any> {
    return this.http.post(`${this.notificationUrl}/fruits/admin/notification`, data);
  }

  public updateNotification(data: any): Observable<any> {
    return this.http.post(`${this.notificationUrl}/fruits/admin/notification/update`, data);
  }

  public deleteNotification(id: number): Observable<any> {
    return this.http.post(`${this.notificationUrl}/fruits/admin/notification/delete?id=${id}`, {});
  }
}
