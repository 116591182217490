<div id="dashboard" class="page-layout blank">

  <app-balance-chart #appBalanceChart [account]="account"></app-balance-chart>

  <div class="body">
    <mat-grid-list [cols]="gridAttributes.columnCount" rowHeight="32px">
      <mat-grid-tile [colspan]="gridAttributes.columnSpans.table" [rowspan]="gridAttributes.rowSpans.table">
        <div class="fuse-card">
          <div style="display: flex; justify-content: space-between;">
            <div class="table-title mb-8">{{ 'recent_transactions' | i18n }}</div>
            <button class="{{ !isLoading ? 'btn-common' : 'btn-common-disable' }}" [disabled]="isLoading" (click)="synchronize()">{{ 'sync' | i18n }}</button>
          </div>
          <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
          <app-transaction-table *ngIf="dataSource && dataSource.data.length"
                                 [dataSource]="dataSource"
                                 [account]="account"
                                 [paginationEnabled]="false"
                                 [displayedColumns]="gridAttributes.tableColumns"
          ></app-transaction-table>
          <div *ngIf="(!dataSource || !account.transactions.length) && !isLoading">
            <div style="margin-left: 24px;">
              {{ 'no_transactions_yet' | i18n }}
            </div>
          </div>
          <a *ngIf="dataSource && dataSource.data.length"
             class="p-16" fxLayoutAlign="end"
             [routerLink]="'/frts-transactions'">{{ 'all_transactions' | i18n }}</a>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>
