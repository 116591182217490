<div class="account-component-item">
    <div class="account-item" (click)="showHideAccountButton()">
        <ng-container>
            <ng-container *ngIf="account.type === 'offline'">
                <img style="margin-right: 10px;" src="./assets/icons/menu/icon-user-watch.png" height="24" width="24" alt=""/>
            </ng-container>
            <ng-container *ngIf="account.type !== 'offline' && account.confirmed">
                <img style="margin-right: 10px;" src="./assets/icons/menu/icon-user-active.png" height="24" width="24" alt=""/>
            </ng-container>
            <ng-container *ngIf="account.type !== 'offline' && !account.confirmed">
                <img style="margin-right: 10px;" src="./assets/icons/menu/icon-user-locked.png" height="24" width="24" alt=""/>
            </ng-container>
        </ng-container>
        <div *ngIf="!account.multiWallet" style="color: #3A4667; font-size: 14px;">
            <div class="user" style="font-weight: 800;">{{getWalletName(account)}}</div>
            <div class="user">{{account.accountRS}}</div>
            <div class="user">{{account.balanceNQT | burstAmount: 'planck'}}</div>
        </div>
        <div *ngIf="account.multiWallet" style="color: #3A4667; font-size: 14px;">
            <div class="user" style="font-weight: 800;">{{getWalletName(account)}}</div>
            <div class="user">{{account.accountRS}}</div>
            <div class="user">{{account.balanceNQT | burstAmount: 'planck'}}</div>
        </div>
    </div>
    <div class="wallet-button" [class.opened]="show">
        <mat-form-field style="width: 70%;" class="message-text password-field" floatLabel="never" appearance="standard">
            <input type="password" matInput required placeholder="{{ 'enter_pin' | i18n }}" [(ngModel)]="pin" name="pin">
        </mat-form-field>
        <button class="{{ !isSending ? 'btn-common' : 'btn-common-disable' }}"
                (click)="onSubmit()"
                [disabled]="isSending"
        >
            {{ 'login' | i18n }}
            <mat-icon *ngIf="isSending" style="vertical-align: middle;">
                <mat-spinner diameter="20"></mat-spinner>
            </mat-icon>
        </button>
    </div>
</div>
