import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';
import { DisclaimerComponent } from './disclaimer.component';
import { MatButtonModule } from '@angular/material/button';
import { WalletComponent } from './wallet/wallet.component';
import {I18nModule} from '../layout/components/i18n/i18n.module';
import {AuComponent} from './login/au.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {LogoutComponent} from './logout/logout.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {ChangeAccountComponent} from './change-account/change-account.component';
import {MultiLanguageComponent} from './multi-language/multi-language.component';
import {MatSelectModule} from '@angular/material/select';

const routes = [
  {
    path: 'disclaimer',
    component: DisclaimerComponent
  },
  {
    path: 'wallet',
    component: WalletComponent
  },
  {
    path: 'authorize',
    component: AuComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'change-account',
    component: ChangeAccountComponent
  },
  {
    path: 'language',
    component: MultiLanguageComponent
  }
];

@NgModule({
  declarations: [
    DisclaimerComponent,
    WalletComponent,
    AuComponent,
    LogoutComponent,
    ChangeAccountComponent,
    MultiLanguageComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    FuseSharedModule,
    MatButtonModule,
    I18nModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatSelectModule
  ]
})
export class DisclaimerModule {
}
