import {Component, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '../../../@fuse/animations';
import {FuseConfigService} from '../../../@fuse/services/config.service';
import {StoreService} from '../../store/store.service';
import {ActivatedRoute, Router} from '@angular/router';
import {constants} from '../../constants';
import {I18nService} from '../../layout/components/i18n/i18n.service';
import {Settings} from '../../settings';

@Component({
  selector: 'multi-language',
  templateUrl: './multi-language.component.html',
  styleUrls: ['./multi-language.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class MultiLanguageComponent {

  redirect: any;
  languages: any[];
  languageSelected;
  settings: Settings;

  constructor(private fuseConfigService: FuseConfigService,
              private storeService: StoreService,
              private router: Router,
              private activatedRouter: ActivatedRoute,
              private i18nService: I18nService) {
    // get query params (for nft)
    if (activatedRouter.snapshot.queryParams) {
      this.redirect = this.activatedRouter.snapshot.queryParams;
    }
    // Configure the layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };

    // get setting and get current language
    this.storeService.getSettings().then(settings => {
      this.settings = settings;
      this.languages = constants.languages;
      this.languageSelected = this.languages.find(i => i.code === this.settings.language);
    }, () => {
      this.languages = constants.languages;
      this.languageSelected = this.languages.find(i => i.code === 'en');
    });
  }

  // redirect to wallet
  async continue(): Promise<void> {
    await this.i18nService.setLanguage(this.languageSelected);
    if (this.redirect) {
      await this.router.navigate(['/wallet'], {queryParams: this.redirect});
    } else {
      await this.router.navigate(['/wallet']);
    }
  }

  // get btn text
  getBtnText(): string {
    if (this.languageSelected) {
      switch (this.languageSelected.code) {
        case 'en':
          return 'Continue';
        case 'fr':
          return 'Continuez';
        case 'es-es':
          return 'Seguir';
        case 'pt':
          return 'Prosseguir';
        case 'cn':
          return '繼續';
        case 'zh-cn':
          return '继续';
        case 'ja':
          return '継続する';
        case 'vn':
          return 'Tiếp tục';
        case 'ko':
          return '계속하다';
        default:
          return '継続する';
      }
    }
    return '継続する';
  }
}
